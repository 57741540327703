import React from "react";
import MarketingAsset from "../components/MarketingAsset";
import Header from "../../../components/Header";
import CurbsideOrderConfirmedText from "../components/CurbsideDialogBox/CurbsideOrderConfirmedText";
import CurbsideOrderConfirmInfo from "../components/CurbsideOrderConfirmInfo";
import CurbsideOrderShareLink from "../components/CurbsideOrderShareLink";
import SocialMedia from "../../../components/SocialMedia";
import CurbsidePickupConstants from "../constants/CurbsidePickupConstants";
import { Modal } from "@material-ui/core";
import OrderDetails from "./OrderDetails";
import CloseIcon from '@material-ui/icons/Close';
import { isEmpty } from "lodash";
import CurbsideNeedHelpDrawer from "../components/CurbsideNeedHelpDrawer";


class PreCheckIn extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showNeedHelpDrawer: false
        }
    }

    setHelpModel = (show) => {
        this.setState({ showNeedHelpDrawer: show });
    }

    getNeedHelp = () => {
        let helpSectionText, callSupport, emailSupport;
        if (this.props.trackingDetails && this.props.trackingDetails.type === "CURBSIDE_PICKUP" && this.props.trackingDetails.customerInteractionThemeSettingsDTO && !isEmpty(this.props.trackingDetails.customerInteractionThemeSettingsDTO)) {
            helpSectionText = this.props.trackingDetails.customerInteractionThemeSettingsDTO.helpSectionText;
            helpSectionText = helpSectionText ? helpSectionText : containerConstants.formatString(containerConstants.NeedHelp);
            callSupport = this.props.trackingDetails.customerInteractionThemeSettingsDTO.supportContactNumber;
            emailSupport = this.props.trackingDetails.customerInteractionThemeSettingsDTO.supportEmail;
        }

        if (callSupport || emailSupport) {
            return {
                helpSectionText,
                callSupport,
                emailSupport
            }
        }
        return undefined;
    }


    changeScreenToPickupSchedule = () => {
        this.props.changeScreen(CurbsidePickupConstants.curbsidePageFlowTypes.TIME_SLOT, this.changeScreenToPreCheckIn);
    }

    changeScreenToPreCheckIn = () => {
        this.props.changeScreen(CurbsidePickupConstants.curbsidePageFlowTypes.PRE_CHECK_IN, null);
    }

    changeScreenToCheckIn = () => {
        this.props.changeScreen(CurbsidePickupConstants.curbsidePageFlowTypes.CHECK_IN, this.changeScreenToPreCheckIn);
    }

    changeScreenToOrderDetails = () => {
        this.props.changeScreen(CurbsidePickupConstants.curbsidePageFlowTypes.ORDER_DETAILS, this.changeScreenToPreCheckIn);
    }

    render() {
        const needHelp = this.getNeedHelp();
        if (this.props.deviceType == 'mobile') {
            return (
                <PreCheckInMobileView
                    {...this.props}
                    changeScreenToPickupSchedule={this.changeScreenToPickupSchedule}
                    changeScreenToCheckIn={this.changeScreenToCheckIn}
                    changeScreenToOrderDetails={this.changeScreenToOrderDetails}
                    showNeedHelpDrawer={this.state.showNeedHelpDrawer}
                    setHelpModel={this.setHelpModel}
                    showNeedHelp={needHelp ? true : false}
                    needHelp={needHelp}
                />
            )
        }
        else {
            return (
                <PreCheckInWebView
                    {...this.props}
                    changeScreenToPickupSchedule={this.changeScreenToPickupSchedule}
                    changeScreenToCheckIn={this.changeScreenToCheckIn}
                    changeScreenToOrderDetails={this.changeScreenToOrderDetails}
                    showNeedHelpDrawer={this.state.showNeedHelpDrawer}
                    setHelpModel={this.setHelpModel}
                    showNeedHelp={needHelp ? true : false}
                    needHelp={needHelp}
                />
            )
        }
    }
}

class PreCheckInMobileView extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Header
                    logo={this.props.companyLogo}
                    curbsidePickup={true}
                    primaryBgColor={"primaryBgColor"}
                    primaryTextColor={"primaryTextColor"}
                    fontSize={"fontSize"}
                    fontFamily={"fontFamily"}
                    // passing device type true for moble view
                    deviceType={true}
                    needHelp={this.props.showNeedHelp}
                    setHelpModel={() => this.props.setHelpModel(true)}
                    helpSectionText={this.props.needHelp && this.props.needHelp.helpSectionText}
                >
                </Header>

                {this.props.showNeedHelp &&
                    <CurbsideNeedHelpDrawer
                        {...this.props}
                    />
                }

                <div className="curb-side-container">

                    <CurbsideOrderConfirmedText
                        curbsidePickupData={this.props.curbsidePickupData}
                        onPreCheckInSubmit={this.props.changeScreenToCheckIn}
                    />

                    <div className="pre-check-order-confirm-details pl12 pr12">
                        <CurbsideOrderConfirmInfo
                            curbsidePickupJson={this.props.curbsidePickupJson}
                            curbsidePickupData={this.props.curbsidePickupData}
                            onCurbsidePageFlow={this.props.changeScreenToOrderDetails}
                            setCurbSideForm={this.props.changeScreenToPickupSchedule}
                            orderCount={this.props.orderCount}
                        />
                        <CurbsideOrderShareLink
                            curbsidePickupData={this.props.curbsidePickupData}
                            curbsidePickupUrl={this.props.curbsidePickupUrl}
                        />
                    </div>
                    {
                        this.props.curbsidePickupData.otherDetailsData &&
                        this.props.curbsidePickupData.otherDetailsData.marketingAssets &&
                        this.props.marketingAssetsData &&
                        <MarketingAsset
                            {...this.props}
                            marketingAssetsData={this.props.marketingAssetsData}
                        />
                    }

                    {this.props.layoutScreen && !this.props.layoutScreen.hideSocialMedia &&
                        <SocialMedia
                            curbsidePickup={true}
                            layoutScreen={this.props.layoutScreen}
                        />
                    }


                </div>
            </div>
        )
    }
}

class PreCheckInWebView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            orderDetailsModalOpen: false
        }
    }

    getOrderDetailsModalView = () => {
        return (
            <Modal
                open={this.state.orderDetailsModalOpen}
                onClose={() => {
                    this.setState({ orderDetailsModalOpen: false })
                }}
                className="order-details-modal"
            >
                <OrderDetails
                    {...this.props}
                    backScreenHandler={() => {
                        this.setState({ orderDetailsModalOpen: false })
                    }}
                    customBackIcon={() => {
                        return (<CloseIcon fontSize="large" />)
                    }}

                />

            </Modal>
        )
    }

    render() {
        return (
            <div className="curbside-web-header">
                <Header
                    // mobileView={this.isMobileView()}
                    logo={this.props.companyLogo}
                    // ctaColorWhite={theme && theme.ctaColorWhite}
                    // ctaFontColor={theme && theme.ctaFontColor}
                    // backScreen={this.props.backScreen}
                    curbsidePickup={true}
                    primaryBgColor={"primaryBgColor"}
                    primaryTextColor={"primaryTextColor"}
                    fontSize={"fontSize"}
                    fontFamily={"fontFamily"}
                    //passing device type false for web view
                    deviceType={false}
                    needHelp={this.props.showNeedHelp}
                    setHelpModel={() => this.props.setHelpModel(true)}
                    helpSectionText={this.props.needHelp && this.props.needHelp.helpSectionText}
                />

                {this.props.showNeedHelp &&
                    <CurbsideNeedHelpDrawer
                        {...this.props}
                    />
                }

                <div className="curbside-web-wrapper">
                    {this.state.orderDetailsModalOpen && this.getOrderDetailsModalView()}
                    <div className="curbside-web-container">
                        <div className="curbside-web-outer-wrap">
                            <div className="curbside-web-middle-header">

                                <div className="curbside-web-left-sidebar">
                                    <div className="curbside-web-pickup-banner"></div>
                                    <div className="pre-check-order-confirm-container">
                                        <CurbsideOrderConfirmedText
                                            curbsidePickupData={this.props.curbsidePickupData}
                                            onPreCheckInSubmit={this.props.changeScreenToCheckIn}></CurbsideOrderConfirmedText>
                                    </div>
                                </div>

                                <div className="curbside-web-right-sidebar">
                                    <CurbsideOrderConfirmInfo
                                        curbsidePickupJson={this.props.curbsidePickupJson}
                                        curbsidePickupData={this.props.curbsidePickupData}
                                        onCurbsidePageFlow={() => this.setState({ orderDetailsModalOpen: true })}
                                        setCurbSideForm={this.props.changeScreenToPickupSchedule}
                                        orderCount={this.props.orderCount}
                                    ></CurbsideOrderConfirmInfo>
                                    <CurbsideOrderShareLink
                                        curbsidePickupData={this.props.curbsidePickupData}
                                        curbsidePickupUrl={this.props.curbsidePickupUrl}></CurbsideOrderShareLink>
                                </div>
                            </div>
                        </div>
                        {
                            this.props.curbsidePickupData.otherDetailsData &&
                            this.props.curbsidePickupData.otherDetailsData.marketingAssets &&
                            this.props.marketingAssetsData &&
                            <MarketingAsset
                                {...this.props}
                                marketingAssetsData={this.props.marketingAssetsData}
                            />
                        }


                    </div>


                    {this.props.layoutScreen && !this.props.layoutScreen.hideSocialMedia &&
                        <SocialMedia
                            curbsidePickup={true}
                            layoutScreen={this.props.layoutScreen}
                        />
                    }


                </div>
            </div>
        )
    }
}


export default PreCheckIn;
