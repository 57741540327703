import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import { isEmpty } from 'loadsh'



function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

export default function MessageSnackBar(props) {
  const [open, setOpen] = React.useState(false);
  const [transition, setTransition] = React.useState(undefined);

  const handleClick = (Transition) => () => {
    setTransition(() => Transition);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="message-snack-bar">
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={true}
        onClose={handleClose}
        TransitionComponent={transition}
        message={isEmpty(props.message)?containerConstants.formatString(containerConstants.YourDeliveryPreferenceAreSavedSuccessFully):props.message}
        key={transition ? transition.name : ''}
      />
    </div>
  );
}