import React, { Component } from 'react';
import { isEmpty } from 'loadsh'
import { Swiper, SwiperSlide } from "swiper/react";

class CarouselListPrototype extends Component {
  getResponsiveObject = () => {
    var respo = {
      0: {
        slidesPerView: 1
      }
    };
    // assuiming width with less then 800 are mobile device
    for (var i = 200; i <= 800; i++) {
      respo[i] = { slidesPerView: i / 155 };
    }
    // in desktop view carousel-mainDiv is fixed 860 so fixing his value as 2.98
    respo[801] = { slidesPerView: 2.90 }
    return respo;
  }
  render() {
    if (this.props.mobileView) {
      return (
        <>
          {this.props.marketingAssets?.carouselDetails &&
            !isEmpty(this.props.marketingAssets.carouselDetails) &&
            Object.values(this.props.marketingAssets.carouselDetails).map(function (element, index) {
              return (
                <div className="wdcard flex flex-column mr15" style={{ width: 210, flexShrink: 0 }}>
                  <div className="p15" style={{ width: '100%' }}>
                    <div className="img-container mb30">
                      <img src={element.url} alt="" />
                    </div>
                    <p className="text-black mb10">{element.name}</p>
                    <p className="text-gray mb10">$48.99 | Qty: 1</p>
                  </div>
                </div>

              );

            })}
        </>
      )
    }
    // else{
    return (
      <>
        <Swiper
          slidesPerView={1}
          breakpoints={this.getResponsiveObject()}
          navigation
          className="carousel-item"
        >

          {this.props.marketingAssets?.carouselDetails &&
            !isEmpty(this.props.marketingAssets.carouselDetails) &&
            Object.values(this.props.marketingAssets.carouselDetails).map(function (element, index) {
              return (
                <SwiperSlide key={index}>
                  <div className="wdcard flex flex-column mr15" style={{ width: 210, flexShrink: 0 }}>
                    <div className="p15" style={{ width: '100%' }}>
                      <div className="img-container mb30">
                        <img src={element.url} alt="" />
                      </div>
                      <p className="text-black mb10">{element.name}</p>
                      <p className="text-gray mb10">$48.99 | Qty: 1</p>
                    </div>
                  </div>
                </SwiperSlide>
              );

            })}

        </Swiper>

      </>
    );
  // }
  }
}

export default CarouselListPrototype;
