import React, { Component, Fragment } from "react";
import DirectionsIcon from "@material-ui/icons/Directions";
import PhoneIcon from "@material-ui/icons/Phone";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import DetailMapView from "../DetailMapView";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";
import { BackPageArrow, PudoLocationIcon ,PudoCallIcon,PudoTimeIcon, PudoDirectionsIcon} from "../LayoutScreen/PudoReturnMap/Icons";
import PudoScreenList from "../../components/PudoScreenList";
import CarouselComponent from "../../components/Carousel";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";
import { PudoOtherDetails } from "../../components/PudoOtherDetails";
class ShopDetail extends Component {
  containerConstants = window.containerConstants;
  days=["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  updated = false;

  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      timingDropDown:false,
      timingText: containerConstants.formatString(containerConstants.ViewTimings),
    };
    this.herePoweredAccount =  window.localStorage.getItem('isHerePoweredAccount') === 'true' ? true : false
  }

  updatePudoShops(pudo) {
    this.updated = true;
    this.setState({ loader: true });
    this.props.updatePudoShops(this.props.detail);
  }
  showTiming =()=>{
    var timingText;
    if(this.state.timingDropDown)
    timingText = containerConstants.formatString(containerConstants.ViewTimings);
    else 
    timingText = containerConstants.formatString(containerConstants.HideTimings);

    this.setState({timingDropDown:!this.state.timingDropDown,timingText:timingText})
   }
  dropDownMenu=(timings)=>{
    let i=0;
    let list=[];
    for(i=0;i<7;i++){
      if(!isEmpty(timings[i])){list.push(<div className="pb5 pt5" style={{display:'flex'}}><p style={{width:'80px' ,border:'none'}}>{this.days[i]}</p> {timings[i]}</div>)}
    }
    return list;
  }


  render() {
    let lat = this.props.detail.latitude;
    let lng = this.props.detail.longitude;
    let mapLocationUrl = "http://maps.google.com/?z=8&q=" + lat + "," + lng;
    let contact = "tel:" + this.props.detail.contactNumber;
    let timings = "";
    if(!isEmpty(this.props.detail.timing)){
    timings= this.props.detail.timing.split(',');}
    var trimmedStoreName = "";
    if(!isEmpty(this.props.detail) && !isEmpty(this.props.detail.name) && this.props.detail.name.length>28){
      trimmedStoreName = this.props.detail.name.substr(0, 24) + "...";
    }
    return (
      <React.Fragment>
      <div className="shopDetailScreen bg-white">
            <div className='return-store-pudo-dtl-header'>
                <button className='return-back-arrow-pudo'
                onClick={this.props.gobackBtn}><BackPageArrow/></button>
                <div className='return-store-pudo-dtl-map'>
                  <DetailMapView
                    detail={this.props.detail}
                    searchLocationLat={this.props.searchLocationLat}
                    searchLocationLng={this.props.searchLocationLng}
                  />
                </div>
            </div>
      
          <div className='return-pudo-store-dtl-inner'>
                {/* Store Name */}
            <div className='return-pudo-store-name-title'>
            {this.props.detail.name ? (
              <h4 title={trimmedStoreName?this.props.detail.name:""}>{this.props.detail.name}</h4>
            ) : null}
            {this.props.detail.distance ? (
               <span>{this.props.detail.distance} km</span>
            ) : null}
               
            {/* {this.props.detailIndex == 0 ? (
              <button
                className="nearestBtn"
                style={{ backgroundColor: this.props.primaryBgColor }}
              >
                {containerConstants.formatString(containerConstants.Nearest)}
              </button>
            ) : null} */}
          </div>
          {/* {this.props.detail.distance? 
                    <p className="text-light-gray fs14 mb10 ">
                        {this.props.detail.distance ==0 ? '' : this.props.detail.distance + ' ' + this.props.unit + ' '+ 'away' }
                    </p>
                    : null} */}
          {/* {this.props.delayMessage ? (
            <p className="delayMessage">
              {containerConstants.formatString(containerConstants.Note) +
                ":" +
                this.props.delayMessage}
            </p>
          ) : null} */}
        
        <div className='return-pudo-store-outer-box'>
                {/*  Data List Row */}
        
            
        
                       
          {this.props.detail.addressLine1 ? (
            <div className='return-pudo-store-list-row'>
              <div className='return-pudo-store-img-icon'>
              <a style={{display: "flex"}} href={'https://maps.google.com/?q=' + lat + ',' + lng} target="_blank" id="getDirectionPudoCard"><PudoLocationIcon/></a>
             
                
              </div>
              <div className='return-pudo-store-data-col'>
                

                <p className="text-dark fs14">
                  {this.props.detail.addressLine1 &&
                    this.props.detail.addressLine1}
                  {this.props.detail.addressLine2 &&
                    " , " + this.props.detail.addressLine2}
                  {this.props.detail.landmark &&
                    " , " + this.props.detail.landmark}
                  {this.props.detail.pincode &&
                    " , " + this.props.detail.pincode}
                     {lat && lng &&<a style={{display: "flex", marginTop:"12px", marginBottom:"16px"}} href={'https://maps.google.com/?q=' + lat + ',' + lng} target="_blank" ><PudoDirectionsIcon/>Get Directions </a>}
                </p>
              
              </div>
           </div> 
          ) : null}
          {this.props.detail.contactNumber ? (
            <div className='return-pudo-store-list-row'>
              <div className='return-pudo-store-img-icon'>
              <a  href={"tel:"+this.props.detail.contactNumber} id="callIconMobile">
                <PudoCallIcon/>
              </a>
                  
              </div>
                
              <div className='return-pudo-store-data-col'>
                
                <p className="text-dark fs14">
                  {this.props.detail.contactNumber}
                </p>
              </div>
            </div>
          ) : null}
          {this.props.detail.timing ? (
            <div className='return-pudo-store-list-row'>
              <div className='return-pudo-store-img-icon'>
                  <PudoTimeIcon/>
              </div>
              <div className='return-pudo-store-data-col'>
              <p  onClick ={()=>{this.showTiming()}} style={{color:!this.state.timingDropDown?"#3F98D8":'#05223D', cursor:'pointer',lineHeight: "23px"}}><strong style={{marginRight:"4px"}}>{this.state.timingText}</strong> 
              {this.state.timingDropDown? <UpOutlined  style={{color:"#05223D"}}/>:
               <DownOutlined className="" style={{color:"#3F98D8"}}/>
             }
             {this.state.timingDropDown && this.dropDownMenu(timings)}
             </p>
          
              </div>
            </div>
          ) : null}
           {this.props.detail.otherDetails ? (
            <div className='return-pudo-store-list-row'>
              <div className='return-pudo-store-data-col'>
                <p className="text-dark fs14">{<PudoOtherDetails otherDetails={this.props.detail.otherDetails}/>}</p>
              </div>
            </div>
          ) : null}
        </div>
        </div>
        <div className='ci-pudo-store-detial-slides'>
          <div className='ci-pudo-return-bottom-drawer'>
            <h4 className='title-h4'>More nearby stores <a href='#' className='view-all' id="viewAllPudo" onClick={this.props.openPudoScreenList}>View all</a></h4>
            <CarouselComponent
            availableList={this.props.availableList}
            onForwardArrowClick={this.props.onForwardArrowClick}
            currentIndex={this.props.detailIndex}
            primaryBgColor={this.props.primaryBgColor}
            unit={this.props.unit}
            primaryTextColor={this.props.primaryTextColor}
            timingLabel={this.props.timingLabel}
            calledFromViewDetail={true}
            />
          </div>  
        </div>
        <div className='ci-return-pudo-web-confirm-btn'>
                <button 
                id="confirmStore"
                disabled={this.state.loader}
                type='button' className='return-pudo-web-confirm-btn'
                onClick={() => this.updatePudoShops(this.props.detail)}>
                {this.state.loader ? "Updating store...":  "Confirm Store"}</button>
        </div>
        {/* {this.props.attemptCount ? (
          <div className="bg-white faq-div">
            <h1 className="fs20 text-black fs-600 faq-heading">
              {containerConstants.formatString(containerConstants.FAQS)}
            </h1>
            <div className="attemptCount">
              <p className="common-grey fs12 mb5">
                {containerConstants.formatString(
                  containerConstants.HowManyTimesCanIChangeMyPickUpPoint
                )}
              </p>
              <p className="fs14">
                {containerConstants.formatString(
                  containerConstants.YouCanChangeItOnly
                )}{" "}
                {this.props.attemptCount == 1
                  ? "once."
                  : this.props.attemptCount + " times."}
              </p>
            </div>
          </div>
        ) : null} */}
      </div>  
        <div className="bg-white shopDetailScreen-bottomDiv">
          {this.props.pudoError ? (
            <div
              clasName="text-center"
              style={{ textAlign: "center", width: "80%" }}
            >
              <p className="error-message">
                {"Something went wrong,please try after sometime"}
              </p>
            </div>
          ) : this.state.loader ? (
            <div
              clasName="text-center"
              style={{ textAlign: "center", width: "80%" }}
            >
              <Fade
                in={this.state.loader}
                style={{
                  transitionDelay: this.state.loader ? "800ms" : "0ms",
                }}
                unmountOnExit
              >
                <CircularProgress />
              </Fade>
            </div>
          ) : (
            <React.Fragment>
            <div className="GoBackBtn">
              <button
                style={{ color: this.props.primaryBgColor }}
                onClick={this.props.gobackBtn}
              >
                <span>{containerConstants.formatString(containerConstants.GoBack)}</span>
              </button>
            </div>

            <div className="pickupStoreBtn">
              <button
                style={{
                  backgroundColor: this.props.primaryBgColor,
                  color: this.props.primaryTextColor,
                }}
                onClick={() => this.updatePudoShops(this.props.detail)}
              >
                <span>{containerConstants.formatString(containerConstants.SetAsPickupStore)}</span>
              </button>
            </div>
            </React.Fragment>
          )}
        </div>

        </React.Fragment>
    );
  }
}

export default ShopDetail;
