// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    display: flex;
	height: 55px;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding: 0 16px;
    color: #fff;
    font-size: 18px;
}
/* .need_help_box button{
    background: white;
    height:40px;
    min-width:137px;
    width:auto;
    border-radius:7px;
    color:#49bd94;
} */
.need_help_box button {
    background: white;
    height: 31px;
    min-width: 92px;
    width: auto;
    border-radius: 4px;
    color: #49bd94;
    /* margin-top: 13px; */

}
span.need_help_box button:hover {
    background: white;
}
.need_help_box button span{
    font-size: 12px;
}

`, "",{"version":3,"sources":["webpack://./components/Header/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;CAChB,YAAY;IACT,WAAW;IACX,mBAAmB;IACnB,2BAA2B;IAC3B,eAAe;IACf,WAAW;IACX,eAAe;AACnB;AACA;;;;;;;GAOG;AACH;IACI,iBAAiB;IACjB,YAAY;IACZ,eAAe;IACf,WAAW;IACX,kBAAkB;IAClB,cAAc;IACd,sBAAsB;;AAE1B;AACA;IACI,iBAAiB;AACrB;AACA;IACI,eAAe;AACnB","sourcesContent":[".header {\n    display: flex;\n\theight: 55px;\n    width: 100%;\n    align-items: center;\n    justify-content: flex-start;\n    padding: 0 16px;\n    color: #fff;\n    font-size: 18px;\n}\n/* .need_help_box button{\n    background: white;\n    height:40px;\n    min-width:137px;\n    width:auto;\n    border-radius:7px;\n    color:#49bd94;\n} */\n.need_help_box button {\n    background: white;\n    height: 31px;\n    min-width: 92px;\n    width: auto;\n    border-radius: 4px;\n    color: #49bd94;\n    /* margin-top: 13px; */\n\n}\nspan.need_help_box button:hover {\n    background: white;\n}\n.need_help_box button span{\n    font-size: 12px;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
