 
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import "../../components/Map/leaflet.css"
// import Marker from 'react-leaflet-animated-marker';
import  './MovingMarker'
import {processUpdateFormService } from '../../services/ProcessUpdateFormService';
import { liveTrackingService } from '../../services/LiveTrackingService';
import * as CustomerInteractionAction from '../../CustomerInteractionActions';
import * as LivetrackingAction from '../LiveTracking/LiveTrackingAction';
import {isEmpty} from 'loadsh'
// import Header from '../../components/Header';
import SubHeader from '../../components/SubHeader';
import EventScreen from '../EventScreen/index';
// import HelpSection from '../../components/HelpSection/index';
import MessageToFE from '../../components/MessageToFE'
import FieldExecutiveFooter from '../../components/FieldExecutiveFooter/index';
import { MyContext } from '../context';
import Snackbar from '@material-ui/core/Snackbar';
import { trackLogSource } from './liveTrackingConstants';
import CONSTANTS from '../../Utils/Constants';
import { enhancedLiveTrackingService } from '../../services/EnhancedLiveTrackingService';




const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


class LiveTracking extends React.Component{

   MAIN_QUEUE = [];
   dateTime = "";
   dataCallQueue = false;
   IS_QUEUE_RUN = false;
   myMovingMarker
   lastPlotted = {};
   firstpolyline
   feMarker;
   jobLocationMarker;
   messageToFE;
   isMapLoad = false;
   prevWindowHeight;
   etaSet = false;
   fakeCountMovement = 0;
  constructor(props){
    super(props)
    this.state = {
              message:false,
              jobUpdated:false,
              eventScreen:false,
              isEmbed:false, 
              eta:'' 
       }
    this.setMessageModal = this.setMessageModal.bind(this);
    this.herePoweredAccount =  window.localStorage.getItem('isHerePoweredAccount') === 'true' ? true : false
  }
  openEventScreen =()=>this.setState({'eventScreen':true})
  openProcessForm =(index)=>event=>this.setState({'openForm':true,'formIndex':index})
  setHelpModel =()=>this.setState({'showNeedHelpDialog':true});
  closeHelpModal =()=>this.setState({'showNeedHelpDialog':false});


  async componentDidMount(){
    if(window.location.href.indexOf('&embed=true')!=-1){
      this.setState({
        isEmbed:true,
      })
    }
    if(this.props.calledFrom && this.props.calledFrom=="eventScreen"){
      await  this.props.actions.getTrackingDetails(this.props.url,"",this.props.trackingDetails);
    }
    if(this.props.trackingDetails){
      this.getMapBaseLayer();
      if( this.props.trackingDetails.showEta &&  this.props.trackingDetails.showTracking && this.props.trackingDetails.liveMonitoringDto && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList 
         && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0] && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].duration 
         && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].duration.text && isEmpty(this.state.eta)){
          let cache_data;
          let details = this.props.trackingDetails;
          if(this.props.trackingDetails && !isEmpty(this.props.trackingDetails.predictiveTrackingInfo)) {
            cache_data = enhancedLiveTrackingService.handleTrackingWithCacheData(this.props.trackingDetails);
          } else {
            cache_data = window.localStorage.getItem('cache_data');
            cache_data = JSON.parse(cache_data);
            const url = this.props.url;
            cache_data = ((cache_data && cache_data[url]  && cache_data[url]["userId"]==details.userId)?cache_data[url]:undefined);
          }
          let eta = (cache_data && !isEmpty(cache_data["dateTime"]))?cache_data["eta"]: this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].duration.text; 
          this.setState({'eta':eta});
         }

      if(!isEmpty(this.props.trackingDetails.liveTrackingPreviousJobsInfo) && this.props.trackingDetails.liveTrackingPreviousJobsInfo.enabled) {
        this.props.actions.getPreviousJobsInfo(this.props.url, this.props.trackingDetails);
        if(this.props.trackingDetails.trackLogSource != trackLogSource.FAREYE_MOBI_USER_SUMMARY) {
          this.previousJobsInfoIntervalId = setInterval(() => this.props.actions.getPreviousJobsInfo(this.props.url, this.props.trackingDetails) , 60000);  // 60 sec
        }
      }
    }
  }

  async componentDidUpdate(prevProps){
       if((this.props.previousJobCount!=prevProps.previousJobCount)){
       
        if(prevProps.previousJobCount!=-1){
          await  this.props.actions.getTrackingDetails(this.props.url,"",this.props.trackingDetails);
          this.getMapBaseLayer(this.props.previousJobCount);
        } else {
          this.getMapBaseLayer(this.props.previousJobCount);
        }
       } 
       if(this.props.trackingDetails.liveTrackingPreviousJobsInfo && this.props.trackingDetails.liveTrackingPreviousJobsInfo.enabled 
        && this.props.previousJobCount!=-1 && this.props.previousJobCount>0  && prevProps.tripEta["time"] ){
          setTimeout(function(){
             this.props.actions.updatePopup(this.props.tripEta["time"],this.props.trackingDetails);
           }.bind(this),300);
           
       }

    if(this.props.trackingDetails && !isEmpty(this.props.trackingDetails.liveTrackingPreviousJobsInfo) && this.props.trackingDetails.liveTrackingPreviousJobsInfo.enabled) {

      if (this.props.trackingDetails.trackLogSource == trackLogSource.FAREYE_MOBI_USER_SUMMARY && this.props.nextJobTransactionId != prevProps.nextJobTransactionId) {
        
        this.props.actions.getPreviousJobsInfo(this.props.url, this.props.trackingDetails);
      }
    }
  }

  componentWillUnmount(){
    clearInterval(this.intervalId);
    clearInterval(this.intervalIdETa);
    clearInterval(this.previousJobsInfoIntervalId);
  }

  openEventScreen =()=>{
    clearInterval(this.intervalId);
    clearInterval(this.intervalIdETa);
    this.setState({'eventScreen':true})
  }
  


  async getMapBaseLayer(prevJob){
    this.isMapLoad = false;
    this.map = await this.props.actions.initializeMap(this,this.props.trackingDetails,this.props.url,this.state.isEmbed,prevJob);
    this.intervalId = setInterval(this.getTrackLogs.bind(this), 10000);   // 10 seconds
    window.addEventListener("resize", () => {setTimeout(() => {}, 500)});
    if(this.props.trackingDetails && this.props.trackingDetails.jobId){
      let timeFormat = processUpdateFormService.getTimeFormat(this.props.trackingDetails);
      await this.props.actions.getTripETA(this.props.url,this.props.trackingDetails.jobId,this.props.trackingDetails.min,this.props.trackingDetails.max,this.props.trackingDetails.liveTrackingEnableTime,this.props.trackingDetails.browserTimeZone,timeFormat, this.props.trackingDetails.orderId);
    }
    this.intervalIdETa = setInterval(this.getTripETA.bind(this), 5*60000);   // 5 min
  }

  closeProcessForm =()=>{
      this.isMapLoad = true;
      this.setState({'openForm':false,'formIndex':0,'showNeedHelpDialog':false})
  }


  async getTrackLogs(){
    if(!(this.props.tripEta && !isEmpty(this.props.tripEta) && this.props.tripEta.enableEta) && this.props.trackingDetails.showTracking){
      const eta = await this.props.actions.startFakeMovement(this.props.trackingDetails,this.props.url);
      if(this.props.trackingDetails.showEta  && eta && !isEmpty(eta) && eta!="0 mins" && eta!=this.state.eta){
         this.setState({'eta':eta});
       }
    }
  }

  async getTripETA(){
    if(this.props.trackingDetails && this.props.trackingDetails.jobId && this.props.trackingDetails.showEta){
      await this.props.actions.getTripETA(this.props.url,this.props.trackingDetails.jobId,this.props.trackingDetails.min,this.props.trackingDetails.max,this.props.trackingDetails.liveTrackingEnableTime,this.props.trackingDetails.browserTimeZone, this.props.trackingDetails.orderId);
    }
  }

  setMessageModal(){
    this.messageToFE ='';
    this.isMapLoad = true;
    this.setState({
        message: !this.state.message,
        openSendMsgSnack: false
      });
  }
  setMessageToFE=event=>{
    this.messageToFE = event.target.value;
  }


handleSuccessSendMessage=()=>{
  this.setState({
    message: false,
    openSendMsgSnack: true
  });
}
getSendMsgResponse=()=>{
  return (
    <div className="send-msg-snack-bar">
      <Snackbar
        anchorOrigin={this.props.trackingDetails.isMobileType ?
          {vertical: 'bottom',horizontal: 'center' }:{vertical: 'top',horizontal: 'right' }}
        open={ this.state.openSendMsgSnack}
        message={containerConstants.InstructionsSentSuccessfully}
        key="sendMsgSnack"
        autoHideDuration={3500}
        onClose={()=>{this.setState({openSendMsgSnack: false})}}
      />
    </div>

  );
}
clearZoom=()=>{
  this.map = this.props.actions.clearZoom();
}

getPreviousJobDisplay = () => (
  <div className="prev-job-display-message">
   <span style={{color:"white"}}>{this.props.previousJobsMessage}</span> 
  </div>
)

getMapView =()=>{
  if(!this.prevWindowHeight){
    this.prevWindowHeight = window.innerHeight;
    
  } 
    return (
      <div className="live-track-map-view layout-screen-customize">
      {
        this.state.isEmbed==false? 
          <div id="map" style={{height:this.prevWindowHeight-167}}/>
          :<div id="map" style={{height:this.prevWindowHeight }}/>
        }
        {
        this.state.isEmbed==false? 
         <div className="re-center" onClick={()=>this.clearZoom()}>
            <img src={require('../../images/map-recenter.png')} alt=""/>
        </div>:
        <div className="re-center" style={{bottom:"25px"}} onClick={()=>this.clearZoom()} >
            <img src={require('../../images/map-recenter.png')} alt=""/>
        </div>
        }
     

      {(this.state.isEmbed == false && this.props.trackingDetails.userName)?
        <FieldExecutiveFooter 
          trackingDetails={this.props.trackingDetails}
          setMessageModal={this.setMessageModal}
          isMobileView={this.props.trackingDetails.isMobileType}
        />:null
      }
     {
        this.props.previousJobsMessage && this.getPreviousJobDisplay()
      }
    </div>  
    )
}
getMesageToFE =()=>{
  return (
    <MessageToFE
      isMobileView={this.props.trackingDetails.isMobileType}
      feMsgDetails={{
        pageHeader: this.props.trackingDetails.feMsgPageHeader,
        supportingText: this.props.trackingDetails.feMsgSupportingText,
        preMessageArr: this.props.trackingDetails.preMessage
      }}
      sendMsgDetails={{
        url:this.props.url,
        orderId:this.props.trackingDetails.orderId,
        userId:this.props.trackingDetails.userId
      }}
      goBack={this.setMessageModal}
      handleSuccessSendMessage={this.handleSuccessSendMessage}
      openModal={this.state.message}
    />
  )
}
  getScreen = () => {
    if ((this.props.tripEta && !isEmpty(this.props.tripEta) && this.props.tripEta.enableEta) && !this.state.message) {
      this.isMapLoad = true;
    }

    if (this.state.eventScreen || (this.props.tripEta && !isEmpty(this.props.tripEta) && this.props.tripEta.enableEta)) {
      return (
        <EventScreen
          track={true}
          isLiveTracking={(this.props.tripEta && !isEmpty(this.props.tripEta) && this.props.tripEta.enableEta)}
          setMessageModal={this.setMessageModal}
        />
      )
    } else {
      this.etaSet = false;
      return (
        <div>
          <div id="top-area">
            {this.state.isEmbed == false ?
              <SubHeader trackingDetails={this.props.trackingDetails}
                details={true}
                openEventScreen={this.openEventScreen}
                eta={this.state.eta}
                tripEta={!this.props.trackingDetails.showTracking && this.props.tripEta.actualEta ? this.props.tripEta : null}
              />
              : null}
          </div>
          {this.getMapView()}
          {this.isMapLoad ? setTimeout(function () { this.getMapBaseLayer() }.bind(this), 100) : null}
        </div>
      );
    }

  }


  render() {
    return (

      <React.Fragment>
        {this.getSendMsgResponse()}
        {this.state.message && this.props.trackingDetails.isMobileType?
         this.getMesageToFE():this.getScreen()}
         {!this.props.trackingDetails.isMobileType && this.getMesageToFE()}
      </React.Fragment>

    )
  }


}

function mapStateToProps(state) {
  return {
    trackingDetails: state.customerInteractionReducer.trackingDetails,
    url:state.customerInteractionReducer.url,
    jobUpdated:state.customerInteractionReducer.jobUpdated,
    tripEta:state.customerInteractionReducer.tripEta,
    previousJobsMessage: state.customerInteractionReducer.previousJobsMessage,
    previousJobCount:state.customerInteractionReducer.previousJobCount,
    nextJobTransactionId: state.customerInteractionReducer.nextJobTransactionId
  };
}


function mapDispatchToProps(dispatch) {
  return {
      actions: bindActionCreators({ ...CustomerInteractionAction,...LivetrackingAction }, dispatch)
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(LiveTracking);