import React, { Component } from "react";
import "../../components/Map/leaflet.css";
import L from "leaflet";
import * as DetailMapAction from "./DetailMapAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

class DetailMapView extends Component {
  isMapLoad = false;
  singleDetail;
  searchLocationLat;
  searchLocationLng;

  async componentDidMount() {
    if (this.props.trackingDetails) {
      this.getMapBaseLayer();
    }
  }

  async getMapBaseLayer() {
    this.isMapLoad = false;
    this.map = await this.props.actions.initializeMap(
      this,
      this.props.trackingDetails,
      this.props.url,
      this.singleDetail,
      this.searchLocationLat,
      this.searchLocationLng
    );
  }
  getMapView = () => {
    return <div id="map" style={{height:"116px"}}></div>;
  };
  render() {
    this.singleDetail = this.props.detail;
    this.searchLocationLat = this.props.searchLocationLat;
    this.searchLocationLng = this.props.searchLocationLng;
    return <div>{this.getMapView()}</div>;
  }
}

function mapStateToProps(state) {
  return {
    trackingDetails: state.customerInteractionReducer.trackingDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...DetailMapAction }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailMapView);
