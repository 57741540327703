import { isEmpty } from "lodash";
import React, { useState } from "react";
import { mediaLinkService } from "../../services/MediaLinkService";
import {footerLinks as footerLinksConstant} from "../../Utils/containerConstants/footerLinks";

function getLinksObject(props) {
  if(isEmpty(props) || (isEmpty(props.theme) && isEmpty(props.layoutScreen))) {
    return null;
  }

  const theme = props.theme;
  const layoutScreen = props.layoutScreen;
  let linksObject = {};

  if(!isEmpty(layoutScreen)) {
    linksObject = {
      facebook: layoutScreen.facebook,
      twitter: layoutScreen.twitter,
      youtube: layoutScreen.youtube,
      insta: layoutScreen.insta,
      tiktok: layoutScreen.tiktok,
      linkedIn: layoutScreen.linkedIn,
      pinterest: layoutScreen.pinterest,
      extraInfo: layoutScreen.extraInfo
    }
  }

  if(!isEmpty(theme) && mediaLinkService.isFooterMediaLinkPresentInTheme(theme)) {
    linksObject = {
      facebook: theme.facebook,
      twitter: theme.twitter,
      youtube: theme.youtube,
      insta: theme.instagram,
      tiktok: theme.tiktok,
      linkedIn: theme.linkedIn,
      pinterest: theme.pinterest,
      extraInfo: theme.extraInfo
    }
  }

  if(Array.isArray(linksObject.extraInfo)) {
    linksObject.extraInfo.sort((a,b) => {
      if(isNaN(a.sequence) || isNaN(b.sequence)) {
        return 0;
      }

      return Number(a.sequence) - Number(b.sequence);
    })
  }

  return linksObject;
}
function getLabel(value, footerLinks ,index){
  if(value.includes("otrium")){
    if(index == 0)
    return footerLinks.label0;
    else if(index==1){
      return footerLinks.label1;
    }
  }
  else return value;
}
function getValue(value, footerLinks ,index){
  if(value.includes("otrium")){
    if(index == 0)
    return footerLinks.link0;
    else if(index==1){
      return footerLinks.link1;
    }
  }
  else return value;
}
function SocialMedia(props) {
  const [iframe, setIframe] = useState(false);
  if(window.location.href.indexOf('&iframe=true')!=-1 && iframe==false){
    setIframe(true);
  }
  const footerLinks = footerLinksConstant[props.language || "en"] || footerLinksConstant["en"];

  const linksObject = getLinksObject(props);

  if(isEmpty(linksObject)) {
    return null;
  }
  if(iframe){
    return null;
  }
  return (
    <div className="flex align-center justify-between">
      <div className="width-100">
      
        <div className="social-media-links fontFamily bg-white">
          <div className="social-inner-wrapper non-sticky flex align-center justify-between">
            {linksObject.facebook||linksObject.twitter||linksObject.youtube||linksObject.insta||linksObject.tiktok||linksObject.linkedIn||linksObject.pinterest?
            <p className="text-gray">
              <span className={props.curbsidePickup ? "display-flex" : ""}
                style={{
                  position: "relative",
                }}
              >
                {linksObject.facebook &&
                  <span style={{cursor: "pointer",marginRight: "20px"}}
                    onClick={() => window.open(linksObject.facebook, "_blank")}
                  >
                    <img id="facebook" src={require('../../images/facebook.svg')} />

                  </span>
                }
                {linksObject.twitter &&
                  <span style={{cursor: "pointer",marginRight: "20px"}}
                    onClick={() => window.open(linksObject.twitter, "_blank")}
                  >
                    <img id="twitter" src={require('../../images/twitter.svg')} />

                  </span>
                }
                {linksObject.youtube &&
                  <span style={{cursor: "pointer",marginRight: "20px"}}
                  onClick={() => window.open(linksObject.youtube, "_blank")}
                >
                  <img id="youtube" src={require('../../images/youtube.svg')} />

                </span>
                }
                {linksObject.insta &&
                  <span style={{cursor: "pointer",marginRight: "20px"}}
                  onClick={() => window.open(linksObject.insta, "_blank")}
                >
                  <img id="instagram" src={require('../../images/instagram.svg')} />

                </span>
                }
                {linksObject.tiktok &&
                  <span style={{cursor: "pointer",marginRight: "20px"}}
                    onClick={() => window.open(linksObject.tiktok, "_blank")}
                  >
                    <img id="tiktok" src={require('../../images/tiktok.svg')} />
                  </span>
                }
                {linksObject.linkedIn &&
                  <span style={{cursor: "pointer",marginRight: "20px"}}
                    onClick={() => window.open(linksObject.linkedIn, "_blank")}
                  >
                    <img id="linkedIn" src={require('../../images/linkedIn.svg')} />
                  </span>
                }
                {linksObject.pinterest &&
                  <span style={{cursor: "pointer",marginRight: "20px"}}
                    onClick={() => window.open(linksObject.pinterest, "_blank")}
                  >
                    <img id="pinterest" src={require('../../images/pinterest.svg')} />
                  </span>
                }
              </span>
            </p>:''}
            <div className="pr15 fs12 fw300 extrainfo">
              {linksObject.extraInfo && linksObject.extraInfo[0] && linksObject.extraInfo[0].label ?
                        <span id="privacyPolicy" title={getLabel(linksObject.extraInfo[0].label, footerLinks,0)} style={{color:"#212121",cursor: "pointer"}}
                          onClick={() => window.open(getValue(linksObject.extraInfo[0].value1, footerLinks,0), "_blank")}
                        >
                          {getLabel(linksObject.extraInfo[0].label, footerLinks,0)}
                        </span>
                        :''
              }
              
              {linksObject.extraInfo && linksObject.extraInfo[1] && linksObject.extraInfo[1].label ?
                        <span id="privacyPolicy" title={getLabel(linksObject.extraInfo[1].label, footerLinks,1)} style={{color:"#212121",cursor: "pointer"}}
                          onClick={() => window.open(getValue(linksObject.extraInfo[1].value1,footerLinks,1), "_blank")}
                        >&nbsp;&nbsp;|&nbsp;&nbsp;
                          {getLabel(linksObject.extraInfo[1].label, footerLinks,1)}
                        </span>
                        :''
              }

            </div>
          </div>
        </div>
      </div>
   
    </div>
  );
}

export default SocialMedia;
