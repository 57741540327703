import React, { Component } from 'react'
import { connect } from 'react-redux';
import Screen from '../Index'
import { setTrackingDetailsForShipment } from './shipmentTrackingAction';

class ShipmentTracking extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };

        this.url = this.props.location.search.substring(1);
    }
    async componentDidMount() {
        await this.props.setTrackingDetailsForShipment(this.url);
    }


    render() {
        console.log("DATA_trackingDetails", this.props.trackingDetails)
        return (
            <Screen {...this.props} shipmentTracking={true} />
        )
    }

}


var mapStateToProps = (state) => ({
    trackingDetails: state.customerInteractionReducer.trackingDetails,
})


export default connect(mapStateToProps, { setTrackingDetailsForShipment })(ShipmentTracking)