import React, { Component } from 'react'
import {BackPageArrow, PudoLocationIcon, PudoCallIcon, PudoTimeIcon, PudoInfoIcon, 
    PudoDownArrowIcon} from "../PudoReturnMap/Icons";
import CIPudoMapSwiper from "../PudoReturnMap/CIPudoMapSwiper";

class CIPudoReturnStoreDetails extends Component {
  render() {
    return (
      <>
        <div className='return-store-pudo-dtl-container'>
          {/* Header Back arrow with Map box */}
            <div className='return-store-pudo-dtl-header'>
                <button className='return-back-arrow-pudo'><BackPageArrow/></button>
                <div className='return-store-pudo-dtl-map'>
                    <img src="https://img.staticmb.com/mbphoto/locality/original_images/2021/Dec/24/77960_MAP.png" style={{width:'100%'}} height={120}/>
                </div>
            </div>
           {/* Store Location */}
            <div className='return-pudo-store-dtl-inner'>
                {/* Store Name */}
                <div className='return-pudo-store-name-title'>
                <h4>Fareye</h4>
                <span>3.6 km away</span>
                </div>
                {/* Store Detials */}
                <div className='return-pudo-store-outer-box'>
                {/*  Data List Row */}
                <div className='return-pudo-store-list-row'>
                    <div className='return-pudo-store-img-icon'>
                        <PudoLocationIcon/>
                    </div>
                    <div className='return-pudo-store-data-col'>
                        <p>Plot no 03, Mall of India, Sector 18, Noida, Uttar Pradesh 201301</p>
                    </div>
                </div>
                {/* Data List Row */}
                <div className='return-pudo-store-list-row'>
                    <div className='return-pudo-store-img-icon'>
                        <PudoCallIcon/>
                    </div>
                    <div className='return-pudo-store-data-col'>
                        <p>518 394 83948</p>
                    </div>
                </div>
                {/* Data List Row */}
                <div className='return-pudo-store-list-row'>
                    <div className='return-pudo-store-img-icon'>
                        <PudoTimeIcon/>
                    </div>
                    <div className='return-pudo-store-data-col'>
                        <p>
                        <span style={{color:'#24A148'}}>OPEN</span>| 10:00 AM - 8:00 PM
                        <button className='down-arrow'><PudoDownArrowIcon/></button>
                        </p>
                    </div>
                </div>
                {/* Data List Row */}
                <div className='return-pudo-store-ul-list'>
                    <div className='return-pudo-store-img-icon' style={{backgroundColor: '#F7F7F7'}}>
                        <PudoInfoIcon/>
                    </div>
                    <div className='return-pudo-store-data-ul-col'>
                        <ul className='return-pudo-store-info-list'>
                        <li>Open untill 23:59</li>
                        <li>Parking</li>
                        <li>Print in shop</li>
                        <li>7 Day Opening</li>
                        <li>Next day drop off</li>
                        <li>CCTV</li>
                        <li>Wheelchair Accessible</li>
                        </ul>
                    </div>
                </div>
                {/* Data List Row */}
                </div>
            </div>
            {/* Store Location */}
            {/* Swiper */}
            <div className='ci-pudo-store-detial-slides'>
                <CIPudoMapSwiper/>
            </div>
            {/* Swiper */}
            {/* Confirm Button */}
            <div className='ci-return-pudo-web-confirm-btn'>
                <button type='button' className='return-pudo-web-confirm-btn'>Confirm Store</button>
            </div>
            {/* Confirm Button */}
        </div>
      </>
    )
  }
}

export default CIPudoReturnStoreDetails;