import React, { Component } from "react";
import "../../components/Map/leaflet.css";
import L from "leaflet";
import * as MapViewAction from "./MapViewAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

class MapView extends Component {
  prevWindowHeight;
  isMapLoad = false;
  availableLocationList;
  fitBoundsArr=[];

  async componentDidMount() {
    if (this.props.trackingDetails) {
      this.getMapBaseLayer();
    }
  }

  async getMapBaseLayer() {
    this.isMapLoad = false;
    this.map = await this.props.actions.initializeMap(
      this,
      this.props.trackingDetails,
      this.props.url,
      this.availableLocationList
    );
    // if(this.props.availableLocationList){
    //   this.props.availableLocationList.map(location =>{
    //     this.fitBoundsArr.push([location.latitude,location.longitude]);
    //   })
    //   this.map.fitBounds(this.fitBoundsArr);
    // }

  }

  getMapView = () => {
    if (!this.prevWindowHeight) {
      this.prevWindowHeight = window.innerHeight;
    }
    return <div id="map" className="pudo-map"></div>;
  };

  render() {
    this.availableLocationList = this.props.availableLocationList;
    return <div>{this.getMapView()}</div>;
  }
}

function mapStateToProps(state) {
  return {
    trackingDetails: state.customerInteractionReducer.trackingDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...MapViewAction }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MapView);
