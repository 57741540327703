import { isEmpty } from 'lodash';
import LocalizedStrings from 'react-localization';


class ContainerConstants {
    containerInstance = null;
    languageCode;


loadlanguage(languageCode){
    const localizedStringObject = {};
    switch (languageCode) {
        case 'en' : {
            const englishStrings = require('./englishStrings');
            localizedStringObject.en = englishStrings.default;
            break;
        }
        case 'fi' : {
            const finnishStrings = require('./finnishStrings');
            localizedStringObject.fi = finnishStrings.default;
            break;
        }
        case 'hi' : {
            const hindiStrings = require('./hindiStrings');
            localizedStringObject.hi = hindiStrings.default;
            break;
        }
        case 'ro' : {
            const romanianStrings = require('./romanianStrings');
            localizedStringObject.ro = romanianStrings.default;
            break;
        }
        case 'th' : {
            const thaiStrings = require('./thaiStrings');
            localizedStringObject.th = thaiStrings.default;
            break;
        }
        case 'ar' : {
            const arabicStrings = require('./arabicStrings');
            localizedStringObject.ar = arabicStrings.default;
            break;
        }
        case 'bg' : {
            const bulgarianStrings = require('./bulgarianStrings');
            localizedStringObject.bg = bulgarianStrings.default;
            break;
        }
        case 'da' : {
            const danishStrings = require('./danishStrings');
            localizedStringObject.da = danishStrings.default;
            break;
        }
        case 'ms' : {
            const malayStrings = require('./malayStrings');
            localizedStringObject.ms = malayStrings.default;
            break;
        }
        case 'pt' : {
            const portugeseStrings = require('./portugeseStrings');
            localizedStringObject.pt = portugeseStrings.default;
            break;
        }
        case 'ur' : {
            const urduStrings = require('./urduStrings');
            localizedStringObject.ur = urduStrings.default;
            break;
        }
        case 'vi' : {
            const vietnameseStrings = require('./vietnameseStrings');
            localizedStringObject.vi = vietnameseStrings.default;
            break;
        }
        case 'es' : {
            const spanishStrings = require('./spanishStrings');
            localizedStringObject.es = spanishStrings.default;
            break;
        }
        case 'fr' : {
            const frenchStrings = require('./frenchStrings');
            localizedStringObject.fr = frenchStrings.default;
            break;
        }

        case 'el': {
            const greekStrings = require('./greekStrings');
            localizedStringObject.el = greekStrings.default;
            break;
        }

        case 'nl': {
            const dutchString = require('./dutchString');
            localizedStringObject.nl = dutchString.default;
            break;
        }

        case 'de': {
            const germanString = require('./germanString');
            localizedStringObject.de = germanString.default;
            break;
        }
        
        case 'cs': {
            const czechStrings = require('./czechStrings');
            localizedStringObject.de = czechStrings.default;
            break;
        }
        case 'hu': {
            const hungarianStrings = require('./hungarianStrings');
            localizedStringObject.de = hungarianStrings.default;
            break;
        }
        case 'it': {
            const italianStrings = require('./italianStrings');
            localizedStringObject.de = italianStrings.default;
            break;
        }
        case 'pl': {
            const polishStrings = require('./polishStrings');
            localizedStringObject.de = polishStrings.default;
            break;
        }
        case 'sk': {
            const solvakStrings = require('./solvakStrings');
            localizedStringObject.de = solvakStrings.default;
            break;
        }

        default: {
            const englishStrings = require('./englishStrings');
            localizedStringObject.en = englishStrings.default;
          }
        }
        return new LocalizedStrings(localizedStringObject);
    }


    

    getInstance(languageCode){
        if(!languageCode || languageCode==null){
            this.containerInstance = this.loadlanguage('en');
        } else {
            this.containerInstance = this.loadlanguage(languageCode);          
        }
        return this.containerInstance;
    }
}



export const containerConstantsService = new ContainerConstants();