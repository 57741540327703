import { isEmpty } from "lodash"
import { ATTRIBUTE_TYPE } from "./Constants"
import { isDateTimeAttribute, isDateTypeAttribute } from "./dateTimeUtil";

export const getAttributeTypeFromValue = (attributeValue) => {
  if(isEmpty(attributeValue)) {
    return ATTRIBUTE_TYPE.STRING;
  }

  if(isDateTypeAttribute(attributeValue)) {
    return ATTRIBUTE_TYPE.DATE;
  } else if(isDateTimeAttribute(attributeValue)) {
    return ATTRIBUTE_TYPE.DATE_TIME;
  } else if(!isNaN(Number(attributeValue))) {
    return ATTRIBUTE_TYPE.NUMBER;
  } else {
    return ATTRIBUTE_TYPE.STRING;
  }
  
}

