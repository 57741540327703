
import React from 'react';
import {isEmpty} from 'loadsh'
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import {eventService} from '../../containers/EventScreen/EventService';
import { MyContext } from '../../containers/context';
import {Call, Email, Edit, Pudo,ChatIcon} from '../HelpSection/icons';
// import ChatIcon from '../../Utils/icons/ChatIcon';



class HelpSection extends React.Component {

    constructor(props) {
        super(props);
    }

    getProcessFormList =(fontSize,fontFamily)=>{
        if(!isEmpty(this.props.trackingDetails) && this.props.trackingDetails.processUpdateForm){
         let forms = JSON.parse(this.props.trackingDetails.processUpdateForm);
         let arrayList  = [];
         if(forms && forms.formList && !isEmpty(forms.formList)){
            (forms.formList).map((form,index)=>{
              if(form.introText)
              arrayList.push(<ListItem button  key ={index} onClick={this.props.openProcessForm(index)}>
                    <Typography component="span" id="processUpdateForm">
                    <Edit fill={this.props.primaryBgColor} />
                     <span style={{fontFamily:fontFamily, fontSize:fontSize}} className='ml15'>   {form.introText}</span>
                    
                    </Typography>
                </ListItem>
                )
             })
         }
         return arrayList;
    }
}
    getDrawerNeedHelpText = () => {
        if (this.props.trackingDetails.drawerNeedHelp && !isEmpty(this.props.trackingDetails.drawerNeedHelp)) {
            return this.props.trackingDetails.drawerNeedHelp;
        }
        return containerConstants.formatString(containerConstants.NeedHelp);
    }
    
    getSection =()=>{
         let callSupport,emailSupport, helpLineLinkLabel, helpLineLink;
         if(this.props.trackingDetails && this.props.trackingDetails.help && !isEmpty(this.props.trackingDetails.help)){
            let help = JSON.parse(this.props.trackingDetails.help);
            if(help.helpLineContact && !isEmpty(help.helpLineContact) && help.helpLineContact.length > 2){
                callSupport = help.helpLineContact;
            }
            if(help.helpLineEmail && !isEmpty(help.helpLineEmail) && help.helpLineEmail.length > 2){
                emailSupport =  help.helpLineEmail;
             }
             if(help.helpLineLinkLabel && !isEmpty(help.helpLineLinkLabel)){
                helpLineLinkLabel =  help.helpLineLinkLabel;
             }
             if(help.helpLineLink && !isEmpty(help.helpLineLink)){
                helpLineLink =  help.helpLineLink;
             }
          }
        
      return(
        <MyContext.Consumer>
        {({ 
         primaryBgColor, 
         primaryTextColor,
         secondaryBgColor,
         secondaryTextColor,
         fontSize,
         fontFamily, 
      }) => (
          <div className="help_screen">
              <p className="fs16 p15 title" style={{fontFamily:fontFamily, fontSize:fontSize}}>
              {this.getDrawerNeedHelpText()}   
                <img src={require('../../images/cancel.svg')} height='15px' className="fl-right" onClick={this.props.setHelpSection} />
              </p>
              
              <List>
                  {!this.props.isSurveyFormNeedHelp && this.getProcessFormList(fontSize,fontFamily)}

                {!this.props.isSurveyFormNeedHelp && (this.props.trackingDetails && this.props.trackingDetails.pudo && this.props.trackingDetails.pudo.pudoScreen === true) &&
                <ListItem button>
                    <Typography component="span" onClick={(e)=>this.props.openPudoScreen(e)} id="pudoFromNeedHelp">
                        {/* <StorefrontIcon fontSize='small' className="mr10 ml0 pl0"/> */}

                        <Pudo id="pudoFromNeedHelp" fill={this.props.primaryBgColor} />
                         <div id="pudoFromNeedHelp" style={{fontFamily:fontFamily, fontSize:fontSize}} className='ml15'>
                            {(this.props.pudoPoints && this.props.pudoPoints.length > 0) ?
                                <div className="collectPoints" id="pudoFromNeedHelp">
                                {this.props.pudoTitle? 
                                    <h4 className="fs14">{this.props.pudoTitle}</h4>
                                    :  
                                    <h4 className="fs14">Collect from pickup point</h4>
                                }
                                {this.props.subLabel ? this.props.subLabel.indexOf("{STORE}")!=-1?
                                  <p className="fs12">{this.props.subLabel.substring(0,this.props.subLabel.indexOf("{STORE}"))+' '+this.props.pudoPoints.length+''+this.props.subLabel.substring(this.props.subLabel.indexOf("{STORE}")+7)}</p>
                                :
                                <p className="fs12">{this.props.subLabel}{' '}{this.props.pudoPoints.length}</p>
                                : 
                                <p className="fs12">Select from over {this.props.pudoPoints.length} store(s) around you</p>}
                                   
                                </div> 
                                : 
                                <div>Collect from pickup point</div>
                            }
                        </div>
                    </Typography>
                </ListItem>}

                {callSupport?<a  href={"tel:"+callSupport} style={{fontFamily:fontFamily, fontSize:fontSize}} target="_blank"><ListItem button>
                    <Typography component="span" id="callSupportButton">
                        <Call fill={this.props.primaryBgColor} />
                        <span className='ml15' id="callSupportButton">{containerConstants.formatString(containerConstants.CallSupport)}</span>
                      
                    </Typography>
                </ListItem></a>:null}
                {emailSupport?<a  href={"mailTo:"+emailSupport} style={{fontFamily:this.props.fontFamily, fontSize:this.props.fontSize}} target="_blank"><ListItem button>
                    <Typography component="span" id="emailButton">
                        <Email fill={this.props.primaryBgColor} />
                        <span className='ml15' id="emailButton">{containerConstants.formatString(containerConstants.EmailSupport)}</span>
                      
                    </Typography>
                </ListItem></a>:null}
                 {helpLineLink?<a  href={helpLineLink} target='_blank' style={{fontFamily:this.props.fontFamily, fontSize:this.props.fontSize}}><ListItem button>
                    <Typography component="span" id="helpLineLink">
                        <div><ChatIcon fill={this.props.primaryBgColor?this.props.primaryBgColor:'#212121'} /></div>
                        <div className='ellipse-chat ml15' id="helpLineLink">{!isEmpty(helpLineLinkLabel)?helpLineLinkLabel:containerConstants.formatString(containerConstants.HelpLineLink)}</div>
                      
                    </Typography>
                </ListItem></a>:null}
              </List>
          </div>
        )}
    </MyContext.Consumer>
      )
    }

   getHelpSection =()=>{
       return (
            <Drawer 
             anchor="bottom"
             open={this.props.showNeedHelpDialog}
             onClose={ this.props.setHelpSection}>
             {this.getSection()}
           </Drawer>
       )
   }

    render(){
        return(
                <div>
                {this.getHelpSection()}
                {/* {this.props.openPudoScreen && <AvailableLocation/>} */}
            </div>
        )
    }
}


export default HelpSection;