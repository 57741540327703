import React from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { ClockIcon, ViewMapIcon } from "../../containers/AvailableLocationV3/PudoReturnMap/Icons";
import { isEmpty } from "loadsh";
import { PudoOtherDetails } from "../PudoOtherDetails";
import { useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { StoreClockIcon } from "../PudoStore/Icons";
import CONSTANTS from "../../Utils/Constants";

function PudoScreenList(props) {
  const [showTimingMap, setShowTimingMap] = useState(new Map());
  function showTiming(index){
    let updatedMap = new Map(showTimingMap);
    let updatedValue = updatedMap.get(index) ?updatedMap.get(index) :false;
    updatedMap.set(index,!updatedValue);
   setShowTimingMap(updatedMap);
   }
function dropDownMenu(timings){
  var timing= timings.split(',');
  var days=["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  let i=0;
  let list=[];
  for(i=0;i<7;i++){
    if(!isEmpty(timing[i])){list.push(<div className="pb5 pt5" style={{display:'flex'}}><p style={{width:'80px'}}>{days[i]}</p> {timing[i]}</div>)}
  }
  return list;
}
  return (
    <React.Fragment>
      <div className='ci-pudo-return-map-preferred-store'
        >
        {props.availableList &&
          props.availableList.map((list, index) => {
            var trimmedStoreName = "";
            if(!isEmpty(list) && !isEmpty(list.name) && list.name.length>28){
              trimmedStoreName = list.name.substr(0, 24) + "...";
            }
            return (
              <div >
                <div className='ci-pudo-return-swiper-details'>
                {index==0 && <button class="nearest-btn">Nearest</button>}
                <div className='ci-pudo-return-swiper-list'>
                  <h3 title={trimmedStoreName?list.name:""}>{trimmedStoreName?trimmedStoreName:list.name}</h3>
              
                  <h6>{list.distance} km</h6>
                  </div>
                  
                

                <div className="flex mb5 mr15">
                  
                  <span className="common-grey fs14" style={{ width: "215px" }}>
                    {list.addressLine1 && list.addressLine1}
                    {list.addressLine2 && " , " + list.addressLine2}
                    {list.landmark && " , " + list.landmark}
                    {list.pincode && " , " + list.pincode}
                  </span>
                </div>

                    <div className='ci-pudo-order-store-details-row listView'>
                         {list.timing && <StoreClockIcon/>}
                         {list.timing && <p  style={{paddingTop:"11px"}}onClick={() => {showTiming(index) }}><strong style={{ color: !showTimingMap.get(index)  ? "#3F98D8" : '#05223D', cursor: 'pointer', lineHeight: "23px"}}>{
                          !showTimingMap.get(index)? containerConstants.formatString(containerConstants.ViewTimings): containerConstants.formatString(containerConstants.HideTimings)
                         }</strong>
                        {showTimingMap.get(index)  ? <UpOutlined style={{ color: "#05223D" }} /> :
                          <DownOutlined className="" style={{ color: "#3F98D8" }} />
                        }
                        {showTimingMap.get(index) && dropDownMenu(list.timing)}
                      </p>}
                     
                   <button className="listViewDetailButton" onClick={() =>props.onForwardArrowClick(list,index)}>
                      View Details
                      </button> 
                  
                  
                  {/* <div className="arrow-forward">
                    <ArrowForwardIosIcon className="common-grey" />
                  </div> */}
                    </div>
                  
                 
                {/* {list.otherDetails && <PudoOtherDetails otherDetails={list.otherDetails}/>} */}
              </div>
              </div>
            );
          })}
      </div>
      
      <button className='ci-pudo-return-list-view-map'
      onClick={props.backAvailableLocation} id="viewMapMobile">
                <ViewMapIcon/>
                <span>View Map</span>
            </button>
    </React.Fragment>
  );
}

export default PudoScreenList;
