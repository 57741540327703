import React from 'react';
import './style.css';

class SubHeader extends React.Component {

  
  render() {
    return (
      <div className="footer">
            {this.props.children}
      </div>
    );
  }
}

export default SubHeader