import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as CustomerInteractionAction from "../../CustomerInteractionActions";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import CarouselComponent from "../../components/Carousel";
import SocialMedia from "../../components/SocialMedia";
import Grid from "@material-ui/core/Grid";
import OrderInformation from "../../components/OrderInformation";
import AddressDetails from "../../components/AddressDetails";
import StatusTimeLine from "../../components/StatusTimeLine";
import { processUpdateFormService } from "../../services/ProcessUpdateFormService";
import * as LivetrackingAction from "../LiveTracking/LiveTrackingAction";
import EmbedTracking from "../LiveTracking/EmbedTracking";

import FieldExecutiveDetail from "../../components/FieldExecutiveDetail";
import MessageToFE from '../../components/MessageToFE'
import Snackbar from '@material-ui/core/Snackbar';
import ArrivingEta from "../../components/ArrivingEta";
import ReturnInstruction from "../../components/ReturnInstruction";
import { isEmpty, cloneDeep } from "loadsh";

import FeedbackV3 from "../FeedbackV3";

import 'antd/dist/antd.css';
import { Carousel as Carousel1 } from 'antd';
// import { RightOutlined } from '@ant-design/icons'
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { isEqual } from "lodash";
import IframeGoogleDocs from "../../components/GoogleDocViewer";
import { mediaLinkService } from "../../services/MediaLinkService";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import CIPudoMapDesktopModal from "../../containers/LayoutScreen/PudoReturnMap/CIPudoMapDesktopModal";
import PudoStoreDetails from "../../components/PudoStore/PudoStoreDetails";
import DesktopQuickAction from "../../components/QuickAction/DesktopQuickAction";
import { getPudoData } from "../MapView/MapViewAction"
import { listUtilities } from "../../services/ListUtilities";
import { commonUtilities } from "../../services/commonUtilities";
import DesktopViewOrder from "../DesktopViewOrder";
import ShipmentList from "../../components/Shipment";
import NewHelpSection from "../../components/NewHelpSection";
import { ReturnToOrderIcon } from "../../components/HelpSection/icons";
import StoreClickCountLabelConstants from "../../Utils/StoreClickCountlabelConstants";
// import "../LayoutScreen/style.css"




const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};

class DesktopInt extends Component {
    monthNames = [
        "Jan",
        "Feb",
        "March",
        "April",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
    ];

    constructor(props) {
        super(props);
        this.state = {
            marketingAssets: {},
            trackEnableTime: -1,
            message: false,
            isTripAPICalled: false,
            orderInfoDesk: false,
            trackerTimelineDesk: true,
            isEmbed: false,
        };

        this.getRefereshData = this.getRefereshData.bind(this);
        this.trackingDetailsRefreshTimeout = null;
    }
    componentWillMount() {
        if (window.location.href.indexOf('&embed=true') != -1) {
            this.setState({
                isEmbed: true,
            })
        }
    }
    async componentDidMount() {
        this.intervalId = setInterval(this.getRefereshData.bind(this), 5 * 60000);
        if (
            this.props.trackingDetails &&
            this.props.trackingDetails.jobId &&
            (!this.props.trackingDetails.layoutScreen || !this.props.trackingDetails.layoutScreen.eta)
        ) {
            var timeFormat = processUpdateFormService.getTimeFormat(this.props.trackingDetails);
            await this.props.actions.getTripETA(
                this.props.url,
                this.props.trackingDetails.jobId,
                this.props.trackingDetails.min,
                this.props.trackingDetails.max,
                this.props.trackingDetails.liveTrackingEnableTime,
                this.props.trackingDetails.browserTimeZone,
                timeFormat,
                this.props.trackingDetails.orderId,
            );
        }
        this.intervalIdETa = setInterval(this.getTripETA.bind(this), 5 * 60000);
        if (!this.props.trackingDetails.calledFromDiy) {
            const marketingAssets = await processUpdateFormService.getMarketingAssets(this.props.url, null, this.props.isReturn);
            if (!isEmpty(marketingAssets)) {
                this.setState({ marketingAssets: marketingAssets });
            }
        }
        processUpdateFormService.checkGoogleAnalyticsCode(this.props.trackingDetails);
        this.checkPudoWithQuickAction();

        if (!isEmpty(this.props.trackingDetails.liveTrackingPreviousJobsInfo) && this.props.trackingDetails.liveTrackingPreviousJobsInfo.enabled) {
            this.previousJobsInfoIntervalId = setInterval(() => this.props.actions.getPreviousJobsInfo(this.props.url, this.props.trackingDetails), 60000);  // 60 sec

        }
        this.addDiyEventListner();
    }

    checkPudoWithQuickAction = () => {
        if (this.props.trackingDetails && this.props.trackingDetails.customerInteractionThemeSettingsDTO
            && this.props.trackingDetails.customerInteractionThemeSettingsDTO.quickAction
            && this.props.trackingDetails.pudo && this.props.trackingDetails.pudo.pudoScreen === true
            && isEmpty(this.props.pudoPoints)) {
            this.props.actions.getPudoData(
                this.props.url,
                this.props.trackingDetails.jobLat,
                this.props.trackingDetails.jobLng,
                this.props.trackingDetails.masterCode
            );
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
        clearInterval(this.intervalIdETa);
        clearInterval(this.previousJobsInfoIntervalId);
        if (this.trackingDetailsRefreshTimeout) {
            clearTimeout(this.trackingDetailsRefreshTimeout);
            this.trackingDetailsRefreshTimeout = null;
        }
        this.removeNeedHelpEditElement();
    }
    removeNeedHelpEditElement = () => {
        var needHelp = document.getElementById('helpButtonDiy');
        if (needHelp) {
            needHelp.removeChild(needHelp.firstChild);
        }
    }

    async componentDidUpdate(nextProps) {
        if (isEmpty(this.state.marketingAssets) && this.props.trackingDetails.marketingAssetDIY) {
            this.setState({ marketingAssets: this.props.trackingDetails.marketingAssetDIY });
        }
        if (this.props.trackingDetails && this.props.trackingDetails.nextIntervalTime &&
            nextProps.trackingDetails.nextIntervalTime != this.props.trackingDetails.nextIntervalTime &&
            nextProps.trackingDetails.nextIntervalTime < this.props.trackingDetails.nextIntervalTime + 2000) {
            if (this.trackingDetailsRefreshTimeout) {
                clearTimeout(this.trackingDetailsRefreshTimeout);
                this.trackingDetailsRefreshTimeout = null;
            }

            this.trackingDetailsRefreshTimeout = setTimeout(this.getRefereshData.bind(this), this.props.trackingDetails.nextIntervalTime + 100);
        }
        // this will set overflow auto as set in componentDidMount after help modal close in case
        if (nextProps.showNeedHelpDialog && nextProps.showNeedHelpDialog != this.props.showNeedHelpDialog) {
            this.updateScroll();
        }


        if (this.props.trackingDetails && this.props.trackingDetails.jobId && !this.state.isTripAPICalled) {
            var timeFormat = processUpdateFormService.getTimeFormat(this.props.trackingDetails);
            if (this.props.trackingDetails.layoutScreen && this.props.trackingDetails.layoutScreen.etaSource == "TRIP_ETA") {
                this.props.actions.getTripETA(this.props.url, this.props.trackingDetails.jobId, this.props.trackingDetails.min, this.props.trackingDetails.max, this.props.trackingDetails.liveTrackingEnableTime, this.props.trackingDetails.browserTimeZone, timeFormat, this.props.trackingDetails.orderId);
            }
            this.setState({ isTripAPICalled: true });
        }

        this.checkAndFetchFeedbackInfo(nextProps);

        if (!isEmpty(this.props.trackingDetails.liveTrackingPreviousJobsInfo) && this.props.trackingDetails.liveTrackingPreviousJobsInfo.enabled &&
            this.props.trackingDetails && nextProps.trackingDetails && this.props.trackingDetails.jobId != nextProps.trackingDetails.jobId) {
            await this.props.actions.getPreviousJobsInfo(this.props.url, this.props.trackingDetails);
        }

        if (this.props.preview) {
            if (!(this.props.trackingDetails?.marketingAssets?.bannerDetails?.name == "demoBannerImageDiy")) {
                var marketingAsset = document.getElementById('marketingAsset');
                marketingAsset.classList.remove("diyFadeOut");
            }
        }

    }
    timeToShowMap = () => {
        let timeToShowMap, a, enableEta;
        if (this.props.trackingDetails && this.props.trackingDetails.layoutScreen && this.props.trackingDetails.layoutScreen.etaForMap && this.props.trackingDetails.liveTrackingEnableTime) {
            let trackingEnableETA = this.props.trackingDetails.layoutScreen.etaForMap - (this.props.trackingDetails.liveTrackingEnableTime * 60 * 1000)
            if (new Date().getTime() < trackingEnableETA) {
                let diffTime = Math.abs(trackingEnableETA - (new Date().getTime()));
                timeToShowMap = Math.ceil(diffTime / (1000 * 60))
            }
        }
        return timeToShowMap;
    }
    checkAndFetchFeedbackInfo = (prevProps) => {
        if (
            isEmpty(prevProps) ||
            isEmpty(prevProps.trackingDetails) ||
            isEmpty(this.props.trackingDetails) ||
            isEmpty(this.props.trackingDetails.feedback) ||
            isEmpty(this.props.url)
        ) {
            return;
        }

        if (!isEqual(prevProps.trackingDetails.feedback, this.props.trackingDetails.feedback) && !this.props.isFeedbackInfoFetch) {
            if(this.props.isReturn){
                this.props.actions.fetchFeedbackInfo(this.props.url,false,"",this.props.isReturn);
              }
              else{
                this.props.actions.fetchFeedbackInfo(this.props.url);
            }
        }
    }

    updateScroll() {
        setTimeout(function () {
        }.bind(this), 300);
    }

    getRefereshData() {
        // in case of live-tracking and fe movement no api call.
        if (this.props.url && this.props.trackingDetails && !(this.props.trackingDetails.liveMonitoringDto && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO
            && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0]
            && !isEmpty(this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0]) && this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList
            && !isEmpty(this.props.trackingDetails.liveMonitoringDto.jobEtaResponseDTO.userRouteDtoList[0].latLngList))) {
            this.props.actions.getTrackingDetails(this.props.url, this.props.isReturn, this.props.trackingDetails);
        }
    }

    async getTripETA() {
        if (this.props.trackingDetails
            && this.props.trackingDetails.jobId
            && this.props.trackingDetails.layoutScreen
            && this.props.trackingDetails.layoutScreen.etaSource == "TRIP_ETA") {
            var timeFormat = processUpdateFormService.getTimeFormat(this.props.trackingDetails);
            this.props.actions.getTripETA(
                this.props.url,
                this.props.trackingDetails.jobId,
                this.props.trackingDetails.min,
                this.props.trackingDetails.max,
                this.props.trackingDetails.liveTrackingEnableTime,
                this.props.trackingDetails.browserTimeZone,
                timeFormat,
                this.props.trackingDetails.orderId,
            );
        }
    }

    renderAddressContent = () => {
        if (
            this.props.trackingDetails &&
            this.props.trackingDetails.processUpdateForm
        ) {
            let forms = JSON.parse(this.props.trackingDetails.processUpdateForm);
            return (
                <AddressDetails
                    forms={forms}
                    screen={this.props.trackingDetails.screen}
                    addressLabel={this.props.trackingDetails.addressLabel}
                    contactLabel={this.props.trackingDetails.contactLabel}
                />
            );
        }
    };
    getPreviousJobDisplay = () => (
        <div className="prev-job-display-message">
            <span style={{ color: "white" }}>{this.props.previousJobsMessage}</span>
        </div>
    )
    renderLiveTracking = () => {
        if ((this.props.trackingDetails.layoutScreen && this.props.trackingDetails.layoutScreen.sequenceEnabled && !this.props.trackingDetails.layoutScreen.trackingEnabled) ||
            (this.props.tripEta && !isEmpty(this.props.tripEta) && this.props.tripEta.enableEta) || this.state.trackEnableTime != -1 || this.timeToShowMap()) {
            return;
        } else if (this.props.trackingDetails && this.props.trackingDetails.userName) {
            return (
                <div className="mb24">
                    <EmbedTracking trackingDetails={this.props.trackingDetails} />
                    {this.props.previousJobCount > 0 && this.state.isEmbed && this.getPreviousJobDisplay()}
                    {this.renderFE()}
                </div>
            );
        }

    };
    isThereValueInsideStatusHeader = () => {
        if ((this.props.trackingDetails.screen != "feedbackScreen" && isEmpty(this.props.trackingDetails.storeInfo) && !this.props.trackingDetails.disableMEta)) {
            if (this.props.trackingDetails.layoutScreen && this.props.trackingDetails.layoutScreen.etaSource == "TRIP_ETA") {
                if (this.props.tripEta.actualEta && this.props.tripEta.actualEta.trim() != "on NaN undefined")
                    return true;
            }
            else {
                if (!(isEmpty(this.props.updateEta) && isEmpty(this.props.trackingDetails.eta) && this.props.trackingDetails.layoutScreen && isEmpty(this.props.trackingDetails.layoutScreen.eta))) {
                    return true;
                }
            }
        }

        if (this.props.trackingDetails.layoutScreen.attributeList && this.props.trackingDetails.layoutScreen.attributeList.length > 0) {
            const attributeList = this.props.trackingDetails.layoutScreen.attributeList;
            let i = 0;
            for (i = 0; i < attributeList.length; i++) {
                if (!isEmpty(attributeList[i].value))
                    return true;
            }
        }
        return false;
    }
    renderStatusHeader = () => {
        return (
            <div className="bg-white" id="basicInformtion">
                {(this.props.trackingDetails.screen != "feedbackScreen" && isEmpty(this.props.trackingDetails.storeInfo) && !this.props.trackingDetails.disableMEta && window.location.href.indexOf('&embed=true') == -1) ?
                    <ArrivingEta
                        eta={this.props.updateEta}
                        trackingDetails={this.props.trackingDetails}
                        secondaryTextColor={this.props.secondaryTextColor}
                        url={this.props.url}
                        isJobRemoved={this.props.isJobRemoved}
                        isReturn={this.props.isReturn}
                        tripEta={this.props.tripEta}
                        preview={this.props.preview}
                        isMobileView={false}
                    />
                    : null}
                <OrderInformation
                    trackingDetails={this.props.trackingDetails}
                    attributeList={this.props.trackingDetails.layoutScreen && this.props.trackingDetails.layoutScreen.attributeList}
                    secondaryTextColor={this.props.secondaryTextColor}
                />
            </div>
        );
    };

    renderFieldExecutive = () => {
        let theme = this.props.trackingDetails && this.props.trackingDetails.customerInteractionThemeSettingsDTO;

        if (this.props.trackingDetails && this.props.trackingDetails.showFE) {
            return (
                <FieldExecutiveDetail
                    trackingDetails={this.props.trackingDetails}
                    setMessageModal={this.setMessageModal}
                    primaryBgColor={theme && theme.primaryBgColor}
                    primaryTextColor={theme && theme.primaryTextColor}
                    secondaryBgColor={theme && theme.secondaryBgColor}
                    secondaryTextColor={theme && theme.secondaryTextColor}
                    isMobileView={false}
                    isReturn={this.props.isReturn}
                    preview={this.props.preview}
                    newCiData ={this.props.newCiData}
                />
            );
        }
        if (this.props.trackingDetails.liveMonitoringDto && this.props.trackingDetails.liveMonitoringDto.isJobToRemove) {
            return;
        }

        if (this.props.trackingDetails && this.props.trackingDetails.layoutScreen && this.props.trackingDetails.userName) {
            if (
                (this.props.trackingDetails.layoutScreen.sequenceEnabled &&
                    !this.props.trackingDetails.layoutScreen.trackingEnabled) ||
                (this.props.tripEta &&
                    !isEmpty(this.props.tripEta) &&
                    this.props.tripEta.enableEta) ||
                this.state.trackEnableTime != -1 || this.timeToShowMap()
            ) {
                let time = this.state.trackEnableTime != -1 ? this.state.trackEnableTime : this.props.tripEta.enableEta;
                if (this.props.trackingDetails && this.props.trackingDetails.layoutScreen && this.props.trackingDetails.layoutScreen.etaSource == 'PROCESS_ATTRIBUTE' || this.props.trackingDetails.layoutScreen.etaSource == 'JOB_TRANSACTION') {
                    time = this.timeToShowMap();
                }
                return (
                    <div
                        className="height10 flex"
                        style={{ backgroundColor: "white", marginTop: "5px",
                         fontFamily: this.props.newCiData?.defaultSettings?.typeface?.fontType,
                        }}
                    >
                        <div>
                            <img src={require("../../images/tracking-mob.png")} />
                        </div>
                        <div className="ml10 pt10">
                            <p className="fs14 mb5 fs-600" style={{ color: "#212121",
                                fontFamily: this.props.defaultSettings?.typeface,
                             }}>
                                {time < 60 ?
                                    time < 10 ? containerConstants.formatString(containerConstants.LiveTrackingWillStart) + ' ' +
                                        containerConstants.formatString(containerConstants.In) + ' ' + time + ' ' + containerConstants.formatString(containerConstants.Min) :
                                        containerConstants.formatString(containerConstants.LiveTrackingWillStart) + ' ' +
                                        containerConstants.formatString(containerConstants.In) + ' ' + time + ' ' + containerConstants.formatString(containerConstants.Mins)
                                    :
                                    containerConstants.formatString(containerConstants.TrackMovementInRealTime)
                                }
                            </p>
                            <p className="fs12" style={{ color: "#727272",
                                fontFamily:  this.props?.newCiData?.efaultSettings?.typeface,
                            }}>
                                {containerConstants.formatString(
                                    containerConstants.DriverTrackerWillGetActivatedOnceTheyAreOnWay
                                )}
                            </p>
                        </div>
                    </div>
                );
            } else {
                return (
                    <FieldExecutiveDetail
                        trackingDetails={this.props.trackingDetails}
                        setMessageModal={this.setMessageModal}
                        primaryBgColor={theme && theme.primaryBgColor}
                        primaryTextColor={theme && theme.primaryTextColor}
                        secondaryBgColor={theme && theme.secondaryBgColor}
                        secondaryTextColor={theme && theme.secondaryTextColor}
                        isMobileView={false}
                        isReturn={this.props.isReturn}
                        preview={this.props.preview}
                        newCiData = {this.props.newCiData}
                    />
                );
            }
        }
    };

    renderFEMessageDialoge = () => {
        return (
            <MessageToFE
                isMobileView={false}
                feMsgDetails={{
                    pageHeader: this.props.trackingDetails.feMsgPageHeader,
                    supportingText: this.props.trackingDetails.feMsgSupportingText,
                    preMessageArr: this.props.trackingDetails.preMessage
                }}
                sendMsgDetails={{
                    url: this.props.url,
                    orderId: this.props.trackingDetails.orderId,
                    userId: this.props.trackingDetails.userId
                }}
                goBack={this.setMessageModal}
                handleSuccessSendMessage={this.handleSuccessSendMessage}
                openModal={true}
            />
        );
    };
    setMessageModal = () => {
        this.setState({
            message: !this.state.message,
            openSendMsgSnack: false
        });
        this.updateScroll();
    }

    handleSuccessSendMessage = () => {
        this.setState({
            message: false,
            openSendMsgSnack: true
        });
    }
    getSendMsgResponse = () => {
        return (
            <div className="send-msg-snack-bar">
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={this.state.openSendMsgSnack}
                    message={containerConstants.InstructionsSentSuccessfully}
                    key="sendMsgSnack"
                    autoHideDuration={3500}
                    onClose={() => { this.setState({ openSendMsgSnack: false }) }}
                />
            </div>

        );
    }

    renderBannerAndCarsoul = () => {
        var marketingAssets = isEmpty(this.state.marketingAssets) ? this.props.trackingDetails?.marketingAssets : this.state.marketingAssets;
        if (!isEmpty(marketingAssets)) {
            return (
                <div className="desktop-view-market-asset" >
                    <div className="desktop-view-market-box-space" id="marketingAsset">
                        {!isEmpty(marketingAssets.bannerDetails) && marketingAssets.enableBanner && (
                            <Banner
                                image={
                                    marketingAssets.bannerDetails.url
                                }
                                link={
                                    marketingAssets.bannerDetails.redirectUrl
                                }
                            />
                        )}
                        {marketingAssets.enableCarousel && !isEmpty(marketingAssets.carouselDetails) &&
                            (marketingAssets.carouselDetails.length > 2) && (
                                <div className="carousel-maiDiv">
                                    <div className="carousel-wrapper background-grey clearfix ">
                                        <CarouselComponent
                                            secondaryTextColor={this.props.secondaryTextColor}
                                            carouselList={
                                                marketingAssets && marketingAssets.carouselDetails
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            );
        }
    };

    renderSocialMedia = () => {

        if (
            !isEmpty(this.props.trackingDetails) ||
            !isEmpty(this.props.trackingDetails.customerInteractionThemeSettingsDTO) ||
            mediaLinkService.isFooterMediaLinkPresentInTheme(this.props.trackingDetails.customerInteractionThemeSettingsDTO)
        ) {
            return (
                <SocialMedia
                    theme={this.props.trackingDetails.customerInteractionThemeSettingsDTO}
                    layoutScreen={this.props.trackingDetails.layoutScreen}
                    secondaryBgColor={this.props.secondaryBgColor}
                    language={this.props.trackingDetails?.language || "en"}
                />
            );
        }


        if (this.props.trackingDetails && this.props.trackingDetails.layoutScreen) {
            let layoutScreen = this.props.trackingDetails.layoutScreen;
            if (!layoutScreen.facebook && !layoutScreen.twitter && !layoutScreen.youtube && !layoutScreen.insta && !layoutScreen.tiktok && !layoutScreen.linkedIn && !layoutScreen.pinterest
                && !(layoutScreen.extraInfo && layoutScreen.extraInfo[0] && layoutScreen.extraInfo[0].label)
                && !(layoutScreen.extraInfo && layoutScreen.extraInfo[1] && layoutScreen.extraInfo[1].label)) {
                return;
            }
        }

        return (
            <SocialMedia
                theme={this.props.trackingDetails.customerInteractionThemeSettingsDTO}
                layoutScreen={this.props.trackingDetails.layoutScreen}
                secondaryBgColor={this.props.secondaryBgColor}
                language={this.props.trackingDetails?.language || "en"}
            />
        );
    };

    addDiyEventListner = () => {
        var needHelp = document.getElementById('helpButtonDiy');
        if (needHelp && this.props.preview) {
            const buttonForEdit = commonUtilities.createButtonElement();
            needHelp.classList.add("borderHover");
            needHelp.insertBefore(buttonForEdit, needHelp.firstChild);
            buttonForEdit.addEventListener("click", () => {
                window.parent.postMessage("need-help", "*");
            })
        }

        var trackerTimeLine = document.getElementById('trackerTimeLineForDiy');
        if (trackerTimeLine && this.props.preview) {
            const buttonForEdit = commonUtilities.createButtonElement();
            trackerTimeLine.classList.add("borderHover");
            trackerTimeLine.insertBefore(buttonForEdit, trackerTimeLine.firstChild);
            buttonForEdit.addEventListener("click", () => {
                window.parent.postMessage("trackerTimeLine", "*");
            })
        }
        var orderInformation = document.getElementById('orderInformationForDiy');
        if (orderInformation && this.props.preview) {
            const buttonForEdit = commonUtilities.createButtonElement();
            orderInformation.classList.add("borderHover");
            orderInformation.insertBefore(buttonForEdit, orderInformation.firstChild);
            buttonForEdit.addEventListener("click", () => {
                window.parent.postMessage("orderInformation", "*");
            })
        }
        var marketingAsset = document.getElementById('marketingAsset');
        if (marketingAsset && this.props.preview) {
            const buttonForEdit = commonUtilities.createButtonElement();
            marketingAsset.classList.add("borderHover");
            if (this.props.trackingDetails?.marketingAssets?.bannerDetails?.name == "demoBannerImageDiy") {
                marketingAsset.classList.add("diyFadeOut");
            }
            marketingAsset.insertBefore(buttonForEdit, marketingAsset.firstChild);

            buttonForEdit.addEventListener("click", () => {
                window.parent.postMessage("marketingAsset", "*");
            })
        }


    }
    ArrayPod = (feedback) => {
        let podImageCss=this.state.feedPopup?"":"pod-img";
        let arr = [];
        if (feedback && feedback.podImageArrayList) {
            {
                for (let i = 0; i < feedback.podImageArrayList.length; i++) {

                    if (!isEmpty(feedback.podImageArrayList[i]) && feedback.podImageArrayList[i].match(/\.pdf$/)) {
                        arr.push(<div className="box relative">
                            <div onClick={this.togglePopup.bind(this)}>
                                {feedback.podImageArrayList[i].indexOf("https://") == 0 ?
                                    <iframe
                                        className="feedback-iframe"
                                        src={feedback.podImageArrayList[i] + "#toolbar=0"}
                                    /> :
                                    <IframeGoogleDocs
                                        url={feedback.podImageArrayList[i]}
                                    />
                                }

                                <div className="feedback-iframe-layer"></div>
                            </div>
                        </div>)
                    } else if (this.checkIfPodImageExist(feedback.podImageArrayList[i])) {
                        arr.push(<div className={"box"+ podImageCss}>
                            <img src={feedback.podImageArrayList[i]} onClick={this.togglePopup.bind(this)} className="" />
                        </div>)
                    }
                }
            }
            return arr;
        }
        else {
            return arr;
        }
    }
    renderCarousel = (feedback, status) => {
        if (this.ArrayPod(feedback).length >= 1) {
            return (
                <div className="feedback_carousel">
                    <Carousel1
                        arrows nextArrow={<span className="right_arrow"><FiChevronRight /></span>}
                        prevArrow={<span className="left_arrow"><FiChevronLeft /></span>}
                    >
                        {this.ArrayPod(feedback)}
                    </Carousel1>
                </div>
            )
        }
    }

    getShipmentData = (url) => {
        if (url) {
            const start = url.indexOf("tracking?") + "tracking?".length;
            const end = url.indexOf("#/");
            url = url.substring(start, end);
        }
        this.props.actions.getTrackingDetails(url, this.props.isReturn, this.props.trackingDetails, true);
    }

    renderPodImages = (feedback) => {
        if (this.checkIfPodImageExist(feedback.podImage) && this.ArrayPod(feedback).length == 0) {
            if (feedback.podImage.match(/\.pdf$/)) {
                return (
                    this.state.feedPopup ?
                        <div className='feed_popup'>
                            <div className="podsinglepop">
                                {feedback.podImage.indexOf("https://") == 0 ?
                                    <iframe
                                        className="feedback-iframe"
                                        src={feedback.podImage + "#toolbar=0"}
                                    /> :
                                    <IframeGoogleDocs
                                        url={feedback.podImage}
                                    />
                                }

                                <div className="cross align-center flex" onClick={this.togglePopup.bind(this)}><button><i class="bi bi-x-lg"></i>&nbsp; Close</button></div>
                            </div>
                        </div>
                        :
                        <div className="box relative">
    
                            <div onClick={this.togglePopup.bind(this)}>
                                {feedback.podImage.indexOf("https://") == 0 ?
                                    <iframe
                                        className="feedback-iframe"
                                        src={feedback.podImage + "#toolbar=0"}
                                    /> :
                                    <IframeGoogleDocs
                                        url={feedback.podImage}
                                    />

                                }

                                <div className="feedback-iframe-layer"></div>
                            </div>
                        </div>

                );
            }

            return (
                this.state.feedPopup ?
                    <div className='feed_popup'>
                        <div className="podsinglepop box">
                            <img width="100%" src={feedback.podImage} onClick={this.togglePopup.bind(this)} className="" />
                            <div className="cross align-center flex" onClick={this.togglePopup.bind(this)}><button><i class="bi bi-x-lg"></i>&nbsp; Close</button></div>
                        </div>
                    </div>
                    :
                    <div className="relative mb24">
                        <img width="100%" src={feedback.podImage} onClick={this.togglePopup.bind(this)} className="" />
                    </div>
            );
        }
        else if (feedback.podImageArrayList && !isEmpty(feedback.podImageArrayList) && feedback.podImageArrayList.length == 1 && this.checkIfPodImageExist(feedback.podImageArrayList[0])) {
            if (feedback.podImageArrayList[0].match(/\.pdf$/)) {
                return (
                    this.state.feedPopup ?
                        <div className='feed_popup'>
                            <div className="podsinglepop">
                                {feedback.podImageArrayList[0].indexOf("https://") == 0 ?
                                    <iframe
                                        className="feedback-iframe"
                                        src={feedback.podImageArrayList[0] + "#toolbar=0"}
                                    /> :
                                    <IframeGoogleDocs
                                        url={feedback.podImageArrayList[0]}
                                    />
                                }

                                <div className="cross align-center flex" onClick={this.togglePopup.bind(this)}><button><i class="bi bi-x-lg"></i>&nbsp; Close</button></div>
                            </div>
                        </div>
                        :
                        <div className="box relative">
                            <div onClick={this.togglePopup.bind(this)}>
                                {feedback.podImageArrayList[0].indexOf("https://") == 0 ?
                                    <iframe
                                        className="feedback-iframe"
                                        src={feedback.podImageArrayList[0] + "#toolbar=0"}
                                    /> :
                                    <IframeGoogleDocs
                                        url={feedback.podImageArrayList[0]}
                                    />
                                }

                                <div className="feedback-iframe-layer"></div>
                            </div>
                        </div>

                );
            }
            return (
                this.state.feedPopup ?
                    <div className='feed_popup'>
                        <div className="podsinglepop box">
                            <img width="100%" src={feedback.podImageArrayList[0]} onClick={this.togglePopup.bind(this)} className="" />
                            <div className="cross align-center flex" onClick={this.togglePopup.bind(this)}><button><i class="bi bi-x-lg"></i>&nbsp; Close</button></div>
                        </div>
                    </div>
                    :
                    <div className="relative mb24" >
                        <img width="100%" src={feedback.podImageArrayList[0]} onClick={this.togglePopup.bind(this)} className="" />
                    </div>
            );
        }
        else if (feedback.podImageArrayList && !isEmpty(feedback.podImageArrayList)) {
            return (
                this.state.feedPopup ?
                    <div className='feed_popup'>
                        {this.renderCarousel(feedback, true)}
                        <div className="cross align-center flex" onClick={this.togglePopup.bind(this)}><button><i class="bi bi-x-lg"></i>&nbsp; Close</button></div>
                    </div>
                    :
                    this.renderCarousel(feedback, false)
            );
        }
    }

    closeRattingScreenDrawer = () => {
        setTimeout(function () {
            document.body.style.overflowY = "auto";
        }.bind(this), 300);
    }

    checkIfPodImageExist = (img) => {
        return ((!isEmpty(img))) && ((img.indexOf("http://") == 0 || img.indexOf("https://") == 0));
    }

    checkFeedbackScreen = () => {
        if (!this.props.trackingDetails.feedback) {
            return;
        }

        let feedback = JSON.parse(this.props.trackingDetails.feedback);
        let marginTopVal = "0px";
        if (!((this.checkIfPodImageExist(feedback.podImage)) || this.ArrayPod(feedback).length >= 1)) {
            marginTopVal = "95px"
        }
        if (this.props.trackingDetails.screen == "feedbackScreen") {
            return (
                <div className="feedback_page" style={{ marginTop: marginTopVal, marginBottom: '20px' }}>
                    {this.renderPodImages(feedback)}

                    <div className="feedbackScreenNew" id="feedbackDiy">
                        {this.props.trackingDetails.screen == "feedbackScreen" &&
                            <FeedbackV3
                                primaryBgColor={this.props.primaryBgColor}
                                closeRattingScreenDrawer={this.closeRattingScreenDrawer}
                                primaryTextColor={this.props.primaryTextColor}
                                fontFamily={this.props.fontFamily}
                                fontSize={this.props.fontSize}
                                secondaryBgColor={this.props.secondaryBgColor}
                                secondaryTextColor={this.props.secondaryTextColor}
                                feedbackInfo={this.props.feedbackInfo}
                                preview={this.props.preview}
                            />}
                    </div>
                </div>
            )
        }
    }
    hasQuickAction = () => {
        if ((this.props.trackingDetails && this.props.trackingDetails.processUpdateForm && !isEmpty(JSON.parse(this.props.trackingDetails.processUpdateForm).formList)) ||
            (this.props.trackingDetails && this.props.trackingDetails.pudo && this.props.trackingDetails.pudo.pudoScreen === true && this.props.pudoPoints && !isEmpty(this.props.pudoPoints)) ||
            (this.props.trackingDetails && this.props.trackingDetails.help && !isEmpty(this.props.trackingDetails.help) &&
                (!isEmpty(JSON.parse(this.props.trackingDetails.help).helpLineContact) || !isEmpty(JSON.parse(this.props.trackingDetails.help).helpLineEmail))
            )) {
            return true;
        }
        return false;
    }
    checkPudoScreen = () => {
        this.checkPudoWithQuickAction();
        this.props.openPudoScreen();
    }
    getDesktopQuickAction = () => {
        return (
            this.hasQuickAction() &&
            <div className="ci-pudo-detail-quick-action-div">
                <DesktopQuickAction
                    showNeedHelpDialog={this.state.showNeedHelpDialog}
                    setHelpSection={this.closeHelpModal}
                    trackingDetails={this.props.trackingDetails}
                    openProcessForm={this.props.openQuickActionForm}
                    screen={this.props.trackingDetails.screen}
                    pudoPoints={this.props.pudoPoints}
                    openPudoScreen={() => this.checkPudoScreen()}
                    pudoTitle={
                        this.props.trackingDetails &&
                        this.props.trackingDetails.pudo &&
                        this.props.trackingDetails.pudo.title
                    }
                    subLabel={
                        this.props.trackingDetails &&
                        this.props.trackingDetails.pudo &&
                        this.props.trackingDetails.pudo.subLabel
                    }
                    fontFamily={this.props.fontFamily}
                    fontSize={this.props.fontSize}
                />
            </div>
        );
    }

    renderFE = () => {
        return (
            <div>
                {(this.props.tripEta &&
                    !isEmpty(this.props.tripEta) &&
                    this.props.tripEta.enableEta) ||
                    this.state.trackEnableTime != -1 ? (
                    <div className="border-bottom"></div>
                ) : null}
                <div  style={{
                    fontFamily: this.props.defaultSettings?.typeface,
                }}>
                    {this.renderFieldExecutive()}
                </div>
            </div>

        )
    }

    generateReturnValidationUrl = (returnIntegration, merchantCode) => {
        if (isEmpty(returnIntegration)) {
            return "";
        }
        const returnUrl = new URL(returnIntegration.returnUrl);
        returnUrl.pathname = "validate";
        returnUrl.searchParams.append("companyCode", returnIntegration.companyCode);
        returnUrl.searchParams.append("firstValidation", returnIntegration.firstValidation);
        returnUrl.searchParams.append("secondValidation", returnIntegration.secondValidation);
        if (!isEmpty(merchantCode) && "null" != merchantCode) {
            returnUrl.searchParams.append("merchantCode", merchantCode);
        }
        return returnUrl.href;
    }

    renderInitiateReturn = () => {
        if (isEmpty(this.props.trackingDetails) ||
            isEmpty(this.props.trackingDetails.layoutScreen) ||
            isEmpty(this.props.trackingDetails.layoutScreen.returnIntegration) ||
            !this.props.trackingDetails.layoutScreen.returnIntegration.enabled
        ) {
            return null;
        }

        const returnIntegration = this.props.trackingDetails.layoutScreen.returnIntegration;
        const merchantCode = this.props.trackingDetails.vCode;
        return (
            <div className="desktop-layout-screen-cta-btn">
                <a href={this.generateReturnValidationUrl(returnIntegration, merchantCode)}
                    style={{
                        backgroundColor: this.props.secondaryBgColor,
                        color: this.props.secondaryTextColor,
                    }}
                    target="_blank">{containerConstants.formatString(containerConstants.ReturnOrder)}</a>
            </div>
        );
    }




    checkReturnInfo = () => {
        return (
            <>
                {
                    (this.props.isReturn && this.props.trackingDetails && this.props.trackingDetails.layoutScreen && this.props.trackingDetails.layoutScreen.instructionDetails && this.props.trackingDetails.screen != "feedbackScreen") &&
                    <ReturnInstruction
                        instructionDetails={this.props.trackingDetails.layoutScreen.instructionDetails}
                        labelPDF={this.props.trackingDetails.layoutScreen.labelPDF}
                        storeDetailsData={this.props.trackingDetails.layoutScreen.storeDetailsData}
                        lastDate={this.props.trackingDetails.layoutScreen.lastDate}
                        primaryBgColor={this.props.primaryBgColor}
                        secondaryBgColor={this.props.secondaryBgColor}
                        primaryTextColor={this.props.primaryTextColor}
                        secondaryTextColor={this.props.secondaryTextColor}
                    />
                }
            </>
        )
    }


    renderPudoStoreInfo = () => {
        if (this.props.trackingDetails && this.props.trackingDetails.screen != "feedbackScreen"
            && this.props.trackingDetails.storeInfo && !isEmpty(this.props.trackingDetails.storeInfo)) {
            return (
                <>
                    <div className="ci-pudo-order-details-desktop">
                        <PudoStoreDetails
                            trackingDetails={this.props.trackingDetails}
                            eta={this.props.updateEta}
                            url={this.props.url}
                            isJobRemoved={this.props.isJobRemoved}
                            isReturn={this.props.isReturn}
                            tripEta={this.props.tripEta}
                            showDirection={true}
                            showContact={true}
                        />
                    </div>
                    <div className="border-bottom-2 "></div>
                </>
            )
        }
    }
    renderEtaAndBasicInfo = () => {
        return (
            <div 
            className="bg-white mr24" 
            id="basicInformtion" 
            style={{paddingTop:"24px", 
                backgroundColor:this.props.newCiData?.eta?.card?.default ? this.props.newCiData?.defaultSettings?.card?.cardBgcolour:this.props.newCiData?.eta?.card?.cardBgcolour,
                borderRadius:this.props.newCiData?.eta?.card?.default ? this.props.newCiData?.defaultSettings?.card?.cornerRedius:this.props.newCiData?.eta?.card?.cornerRedius,
             }}>
                {(this.props.trackingDetails.screen != "feedbackScreen" && isEmpty(this.props.trackingDetails.storeInfo) && !this.props.trackingDetails.disableMEta) &&
                    <ArrivingEta
                        eta={this.props.updateEta}
                        trackingDetails={this.props.trackingDetails}
                        secondaryTextColor={this.props.secondaryTextColor}
                        url={this.props.url}
                        isJobRemoved={this.props.isJobRemoved}
                        isReturn={this.props.isReturn}
                        tripEta={this.props.tripEta}
                        preview={this.props.preview}
                        isMobileView={false}
                        newCiData = {this.props.newCiData}
                    />
                }

                <OrderInformation
                    trackingDetails={this.props.trackingDetails}
                    newCiData ={this.props.newCiData}
                    attributeList={this.props.trackingDetails.layoutScreen && this.props.trackingDetails.layoutScreen.attributeList}
                    secondaryTextColor={this.props.secondaryTextColor}
                />
                {this.checkReturnInfo()}
            </div>
        );
    }
    renderStarRating = () => {
        let showDiv = this.props.trackingDetails.screen == "feedbackScreen";
        return (<div className={`feedbackScreenNew feedback_page ${showDiv ? 'pr24' : ''}`} id="feedbackDiy" style={{
            backgroundColor:this.props.newCiData?.orderDeliver?.card?.default ? this.props.newCiData?.defaultSettings?.card?.cardBgcolour:this.props.newCiData?.orderDeliver?.card?.cardBgcolour,
            borderRadius:this.props.newCiData?.orderDeliver?.card?.default ? this.props.newCiData?.defaultSettings?.card?.cornerRedius:this.props.newCiData?.orderDeliver?.card?.cornerRedius,
            marginBottom: "24px",
            fontFamily:this.props.newCiData?.defaultSettings?.typeface?.fontType
              }}>
            {this.props.trackingDetails.screen == "feedbackScreen" &&
                <FeedbackV3
                    primaryBgColor={this.props.primaryBgColor}
                    newCiData={this.props.newCiData}
                    closeRattingScreenDrawer={this.closeRattingScreenDrawer}
                    primaryTextColor={this.props.primaryTextColor}
                    fontFamily={this.props.fontFamily || this.props.newCiData?.defaultSettings?.typeface?.fontType}
                    fontSize={this.props.fontSize}
                    secondaryBgColor={this.props.secondaryBgColor}
                    secondaryTextColor={this.props.secondaryTextColor}
                    feedbackInfo={this.props.feedbackInfo}
                    preview={this.props.preview}
                    isReturn={this.props.isReturn}
                />}
        </div>);
    }
    doHaveOrderInformation(){
        if( (this.props.trackingDetails.layoutScreen.orderInformationDTOList &&  this.props.trackingDetails.layoutScreen.orderInformationDTOList.length>0)||
            (this.props.trackingDetails.layoutScreen.orderAttributeList && this.props.trackingDetails.layoutScreen.orderAttributeList.length>0)
        ){
            return true;
        }
        return false ;
    }
    renderOrderInformation = () => {
        return (
            <>{this.doHaveOrderInformation() &&<header className="mb5" 
            style={{ 
                borderBottom: "1px solid #DADBDB",
                fontWeight:this.props.newCiData?.orderInfo?.headerText?.fontWeight,
                color:this.props.newCiData?.orderInfo?.headerText?.fontColour,
                fontFamily: this.props.newCiData?.orderInfo?.headerText?.fontType  ? this.props.newCiData?.orderInfo?.headerText?.fontType : this.props.newCiData?.defaultSettings?.typeface?.fontType,
                backgroundColor:this.props.newCiData?.orderInfo?.card?.default ? this.props.newCiData?.defaultSettings?.card?.cardBgcolour:this.props.newCiData?.orderInfo?.card?.cardBgcolour,
                borderRadius:this.props.newCiData?.orderInfo?.card?.default ? this.props.newCiData?.defaultSettings?.card?.cornerRedius:this.props.newCiData?.orderInfo?.card?.cornerRedius,
             }}>
                {this.props.trackingDetails.layoutScreen.orderInfoTitle || this.props.newCiData?.orderInfo?.headerText?.textValue || containerConstants.formatString(containerConstants.OrderInformation)}
                </header>}
                <div className="pt15 pl25 pr25 img-wrapper"
                  style={{
                    backgroundColor:this.props.newCiData?.orderInfo?.card?.default ? this.props.newCiData?.defaultSettings?.card?.cardBgcolour:this.props.newCiData?.orderInfo?.card?.cardBgcolour,
                    borderRadius:this.props.newCiData?.orderInfo?.card?.default ? this.props.newCiData?.defaultSettings?.card?.cornerRedius:this.props.newCiData?.orderInfo?.card?.cornerRedius,
                  }}
                >
                <OrderInformation
                    trackingDetails={this.props.trackingDetails}
                    arrayList={
                        this.props.trackingDetails.layoutScreen.orderInformationDTOList
                    }
                    orderAttributeList={
                        this.props.trackingDetails.layoutScreen.orderAttributeList
                    }
                    secondaryBgColor={this.props.secondaryBgColor}
                    newCiData = {this.props.newCiData}
                />
                </div>
               
            </>
        );
    }

    renderMarketingAsset = () => {
        var marketingAssets = isEmpty(this.state.marketingAssets) ? this.props.trackingDetails?.marketingAssets : this.state.marketingAssets;
        if (!isEmpty(marketingAssets)) {
            return (
                <div className="desktop-view-market-asset " >
                    <div className="desktop-view-market-box-space" id="marketingAsset">
                        {!isEmpty(marketingAssets.bannerDetails) && marketingAssets.enableBanner && (
                            <Banner
                                image={
                                    marketingAssets.bannerDetails.url
                                }
                                link={
                                    marketingAssets.bannerDetails.redirectUrl
                                }
                            />
                        )}
                    </div>
                </div>
            );
        };
    }

    renderLiveTrackingMap = () => {
        return (<> {(this.props.trackingDetails.screen != "feedbackScreen") && (this.props.trackingDetails && !this.props.trackingDetails.showFE) && this.renderLiveTracking()}
        </>)
    }
    renderFeedbackImages = () => {
        if (this.props.trackingDetails?.feedback) {
            let feedback = JSON.parse(this.props.trackingDetails.feedback);
            let pod = this.props.newCiData?.pod;
            const feedbackImages = this.renderPodImages(feedback);
            if(feedbackImages){
           return(
            <div className="feedback_page">
                  <div style={{
                    fontWeight:pod?.headerText?.fontWeight,
                    color : pod?.headerText?.fontColour,
                    fontFamily:pod?.headerText?.fontType ? pod?.headerText?.fontType : this.props.newCiData?.defaultSettings?.typeface?.fontType,
                    backgroundColor: pod?.headerContainer?.backgroundColour,
                    padding:'14px 24px',
                  }}>
                    {pod?.headerText?.textValue}
                  </div>
                { feedbackImages}
               </div>
            )}
        }
    }

    renderStatusTimeLine = () => {
        let theme =
            this.props.trackingDetails &&
                this.props.trackingDetails.customerInteractionThemeSettingsDTO
                ? this.props.trackingDetails.customerInteractionThemeSettingsDTO
                : undefined;
        let timeLine = this.props.newCiData?.timeLine;
        let defaultSettings = this.props.newCiData?.defaultSettings;
        return (
            <div 
               style={{
                backgroundColor:this.props.newCiData?.timeLine?.card?.default ? this.props.newCiData?.defaultSettings?.card?.cardBgcolour:this.props.newCiData?.timeLine?.card?.cardBgcolour,
                borderRadius:this.props.newCiData?.timeLine?.card?.default ? this.props.newCiData?.defaultSettings?.card?.cornerRedius:this.props.newCiData?.timeLine?.card?.cornerRedius,
               }}
            >
                <h3 style={{ 
                    borderBottom: "1px solid #dadada", 
                    fontWeight: timeLine?.headerText?.fontWeight,
                    // color: timeLine?.headerText?.fontColour,
                    fontFamily:timeLine?.headerText?.fontType ?  timeLine?.headerText?.fontType : this.props.newCiData?.defaultSettings?.typeface?.fontType,
                  }}>
                   {timeLine?.headerText?.textValue || containerConstants.formatString(containerConstants.TrackerTimeline)}
                </h3>
                
                <StatusTimeLine
                    trackingDetails={this.props.trackingDetails}
                    primaryBgColor={theme && theme.primaryBgColor}
                    primaryTextColor={theme && theme.primaryTextColor}
                    secondaryBgColor={theme && theme.secondaryBgColor}
                    secondaryTextColor={theme && theme.secondaryTextColor}
                    timeLine={timeLine}
                    leftPosition= {timeLine?.timeLine?.name == 'millerknoll' ? '0px' :'2px'}
                    defaultSettings ={defaultSettings}
                />
            </div>
        )
    }
    renderHelpSection = () => {
        return (<NewHelpSection
            trackingDetails={this.props.trackingDetails}
            openNeedHelpProcessForm={this.props.openNeedHelpProcessForm}
            newCiData ={this.props.newCiData}
        />)
    }
    doHaveShipments(){
        if(this.props.trackingDetails?.orderLevelLayoutScreenDTO?.shipments && this.props.trackingDetails?.orderLevelLayoutScreenDTO?.shipments.length>1){
            return true;
        }
        return false;
    }
    renderShipmentList=()=>{
        return(
            <>
                <div style={{borderRadius:"8px",backgroundColor:this.props.newCiData?.multiShipment?.card?.default ? this.props.newCiData?.defaultSettings?.card?.cardBgcolour:this.props.newCiData?.multiShipment?.card?.cardBgcolour,display: "flex", justifyContent: "space-between", alignItems: "center", padding: "14px 24px", fontWeight: "700", fontSize: "16px" }}>
                    <p style={{ margin: 0 }}>{containerConstants.formatString(containerConstants.OtherShipments)}</p>
                    <div id={StoreClickCountLabelConstants.RETURN_TO_ORDER_TRACKING} onClick={() => { this.getShipmentData(window.location.href) }} style={{ cursor: "pointer" }}>
                        <ReturnToOrderIcon />
                    </div>
                </div>

            <ShipmentList
            containerConstants={containerConstants}
            referenceNumber = {this.props.trackingDetails.referenceNumber}
            orderData = {this.props.trackingDetails.orderLevelLayoutScreenDTO}
            getShipmentData = {this.getShipmentData}
            theme={this.props.trackingDetails.customerInteractionThemeSettingsDTO}
            isNewLayout={this.props.trackingDetails.newLayout}
            />
            </>
        )
    }
       



    renderDeskTopView = () => {
        return (
            <React.Fragment>
                <div className="ci-top ">
                    <div className="ci-background-grey"
                    style={{backgroundColor: this.props.newCiData?.defaultSettings?.colourSystem?.bg_colour}}
                    >
                        <div className="desktopView ci-desktopView ">
                            <Grid container className=" p15 position-rtv">
                                <Grid item xs={7} style={{ zIndex: "1" }}>
                                    <div className="widget1"  >
                                        {this.props.trackingDetails.screen == "feedbackScreen"?this.renderStarRating():this.renderEtaAndBasicInfo()}
                                    </div>
                                    <div className="widget2"  >
                                        {this.props.trackingDetails.screen == "feedbackScreen"?this.renderEtaAndBasicInfo():""}
                                    </div>
                                    <div className="widget3">{
                                            this.renderOrderInformation()
                                        }
                                    </div>
                                    {
                                        this.doHaveShipments() && <div className="shipmentList bg-white">{this.renderShipmentList()}</div>
                                    }
                                    <div className="widget4">
                                        {this.renderMarketingAsset()}
                                    </div>
                                </Grid>
                                <Grid item xs={5} style={{ zIndex: "1" }}>
                                    <div className="widget5">
                                        {(this.props.trackingDetails.screen == "feedbackScreen")?this.renderFeedbackImages():this.renderLiveTrackingMap()}
                                    </div>
                                    <div className="widget7"
                                      style={{
                                        background:this.props.newCiData?.timeLine?.card?.default ? this.props.newCiData?.defaultSettings?.card?.cardBgcolour:this.props.newCiData?.timeLine?.card?.cardBgcolour,
                                        borderRadius:this.props.newCiData?.timeLine?.card?.default ? this.props.newCiData?.defaultSettings?.card?.cornerRedius:this.props.newCiData?.timeLine?.card?.cornerRedius,
                                      }}
                                    >{this.renderStatusTimeLine()}</div>
                                    <div className="widget8">{this.hasQuickAction() &&this.renderHelpSection()}</div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    {!this.props.universalTracking && this.renderSocialMedia()}
                </div>
            </React.Fragment>
        );
    };

    togglePopup() {
        // alert("Hello")
        this.setState({
            feedPopup: !this.state.feedPopup
        });
    }
    checkLandingPage = () => {
         let newLayout = this.props.trackingDetails?.newLayout?"miller-knoll":"";
        if (this.props.trackingDetails && this.props.trackingDetails.processUpdateForm) {
            let processUpdateForm = JSON.parse(this.props.trackingDetails.processUpdateForm);
            if (processUpdateForm.formList) {
                const formList = processUpdateForm.formList;
                for (let i = 0; i < formList.length; i++) {
                    if (formList[i].landingPage && formList[i].landingPage == true) {
                        return (

                            this.props.openProcessForm(i)
                        );
                    }
                }
            }
        }
        return (
           
            <div className={newLayout} > 
                {this.renderDeskTopView()}
                {this.state.message && this.renderFEMessageDialoge()}
                {this.getSendMsgResponse()}
            </div>
        );

    }
    render() {
        return (
            <React.Fragment>
                {this.checkLandingPage()}
            </React.Fragment>
        )

    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            { ...CustomerInteractionAction, ...LivetrackingAction, getPudoData },
            dispatch
        ),
    };
}

function mapStateToProps(state) {
    return {
        trackingDetails: state.customerInteractionReducer.trackingDetails,
        url: state.customerInteractionReducer.url,
        tripEta: state.customerInteractionReducer.tripEta,
        updateEta: state.customerInteractionReducer.updateEta,
        isJobRemoved: state.customerInteractionReducer.isJobRemoved,
        isFeedbackInfoFetch: state.customerInteractionReducer.isFeedbackInfoFetch,
        feedbackInfo: state.customerInteractionReducer.feedbackInfo,
        pudoPoints: state.pudoReducer.pudoPoints,
        previousJobCount: state.customerInteractionReducer.previousJobCount,
        previousJobsMessage: state.customerInteractionReducer.previousJobsMessage,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DesktopInt);