import React from "react";

const FEIcon = (props) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_639_9656" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
                <circle cx="16" cy="16" r="16" fill="#F0F8FF" />
            </mask>
            <g mask="url(#mask0_639_9656)">
                <path d="M24.6947 23.8893L34.1377 29.9276L27.5172 37.1949L19.4426 33.3744L24.6947 23.8893Z" fill={props.color? props.color: "#0460A9"} />
                <path d="M24.6946 23.8892C23.3281 23.188 18.6005 22.324 18.6005 22.324L9.46151 21.4959L1.53577 24.5294L4.7934 35.9025L1.94104 57.3436L29.1499 58.5787L24.6946 23.8892Z" fill={props.color? props.color: "#0460A9"} />
                <path d="M8.69024 11.0608L9.6903 22.8687C9.85359 24.7967 11.7772 26.2911 14.0957 26.2911C16.6489 26.2911 18.6708 24.4919 18.503 22.3686C18.4147 21.2446 18.3342 20.3245 18.3342 20.3245C18.3342 20.3245 21.7636 19.8799 21.8061 17.0219C21.8309 15.371 21.4891 11.625 21.1962 8.75499C20.9982 6.81498 19.0501 5.32579 16.7139 5.32579H14.6425C11.0753 5.49947 8.38325 8.09292 8.69024 11.0608Z" fill="#F8A58D" />
                <path d="M18.3401 20.2832C18.3401 20.2832 16.1731 20.4301 13.8697 19.2242C13.8697 19.2242 14.9262 21.2813 18.3479 21.0238L18.3401 20.2832Z" fill="#F68260" />
                <path d="M9.84621 13.2062C9.78747 13.1846 7.49034 12.6251 7.56533 14.5793C7.63996 16.5332 9.97811 16.0672 9.97995 16.0112C9.98217 15.9552 9.84621 13.2062 9.84621 13.2062Z" fill="#F8A58D" />
                <path d="M9.23558 15.2901C9.22524 15.2842 9.19567 15.315 9.12769 15.343C9.0612 15.3708 8.94631 15.3914 8.82255 15.3566C8.57208 15.2873 8.35116 14.9652 8.32604 14.6132C8.31311 14.4359 8.35153 14.265 8.42172 14.123C8.48785 13.978 8.59499 13.8754 8.71912 13.8536C8.84214 13.8262 8.93264 13.8862 8.96367 13.9404C8.99692 13.9943 8.98363 14.0337 8.99619 14.0365C9.00284 14.0411 9.04533 14.0026 9.02316 13.9222C9.01208 13.8837 8.98289 13.8385 8.92637 13.8024C8.86837 13.7658 8.78488 13.7486 8.69843 13.7584C8.51815 13.7738 8.36077 13.917 8.28356 14.0728C8.19674 14.2302 8.14872 14.4217 8.16276 14.6212C8.19305 15.0148 8.4487 15.3766 8.79079 15.4493C8.95703 15.4807 9.09298 15.4357 9.16244 15.3877C9.233 15.3375 9.24408 15.2935 9.23558 15.2901Z" fill="#F68260" />
                <path d="M8.5418 13.1397C8.89941 13.0677 9.51822 13.3427 9.67042 13.6217C9.823 13.901 9.85661 14.2169 10.0084 14.4962C10.1603 14.7755 10.5042 15.028 10.8622 14.9572C11.1086 14.9085 11.2856 14.7158 11.3506 14.5116C11.416 14.3071 11.3894 14.091 11.3572 13.8812C11.2191 12.9685 10.9889 12.0638 10.9453 11.1446C10.9014 10.2257 11.0584 9.27079 11.6369 8.48771C12.2158 7.70493 13.2794 7.12877 14.3795 7.20298C15.108 7.25194 15.776 7.56851 16.4997 7.65288C17.3582 7.75327 18.2656 7.50076 18.8796 6.99081C18.9582 6.92584 19.324 6.72476 19.324 6.72476C19.324 6.67703 19.2704 6.26901 19.0772 6.12027C17.6412 5.01354 15.0537 4.70437 13.1571 5.10223C11.2604 5.50008 9.48238 6.61636 8.66631 8.09816C7.85023 9.57965 8.09221 11.6404 8.5418 13.1397Z" fill="#17282F" />
                <path d="M21.2344 8.31397C21.2344 8.31397 12.2388 5.90158 8.3476 12.3889C8.3476 12.3889 7.76611 10.7655 7.76611 9.89523C7.76611 9.31415 8.18284 5.85262 12.456 4.76375C12.456 4.76375 18.8933 2.71565 21.2344 8.31397Z" fill="#F03D5F" />
                <path d="M13.505 8.47595C13.8227 8.90399 14.3902 9.19623 14.9916 9.24211C15.593 9.28768 16.2147 9.08536 16.6196 8.71214C16.7973 9.08136 17.2739 9.33541 17.7508 9.31447C18.2278 9.29353 18.67 8.99976 18.8004 8.61668C19.0619 8.82793 19.3334 9.04503 19.6755 9.14942C20.0172 9.25381 20.4506 9.21439 20.6748 8.97543C20.9035 8.73216 20.8374 8.36171 20.6128 8.11536C20.3889 7.86901 20.0431 7.72274 19.701 7.60356C18.6327 7.23158 17.4542 7.04527 16.3067 7.17461C15.1589 7.30425 14.0477 7.76801 13.3303 8.52584L13.505 8.47595Z" fill="#17282F" />
                <path d="M8.3476 12.3889C8.68341 11.8291 9.06945 11.3228 9.4917 10.8668C9.07684 11.3096 8.69228 11.8143 8.3476 12.3889C8.3476 12.3889 8.34723 12.3883 8.34686 12.3871C8.34723 12.3883 8.3476 12.3889 8.3476 12.3889Z" fill="#0C1418" />
                <path d="M8.3476 12.3889C8.69228 11.8143 9.07684 11.3096 9.49171 10.8668C10.7692 9.48783 12.3814 8.56678 13.9622 7.99617C14.6268 7.54997 15.4569 7.27067 16.3069 7.1746C16.553 7.14688 16.8001 7.13364 17.0476 7.13364C17.4374 7.13364 17.8271 7.16659 18.2117 7.22725C18.2664 7.22787 18.3211 7.22941 18.3739 7.23126C20.2107 7.29901 21.2344 8.31397 21.2344 8.31397C19.8823 5.08092 17.1644 4.39792 15.1089 4.39792C13.6053 4.39792 12.456 4.76375 12.456 4.76375C8.18284 5.85262 7.76611 9.31415 7.76611 9.89523C7.76611 10.7479 8.32433 12.3236 8.34686 12.3871C8.34723 12.3883 8.3476 12.3889 8.3476 12.3889Z" fill={props.color? props.color: "#0460A9"} />
                <path d="M13.9619 7.99622C15.4695 7.45179 16.9484 7.22608 18.0806 7.22608C18.1246 7.22608 18.1682 7.22639 18.2114 7.22731C17.8269 7.16665 17.4371 7.1337 17.0474 7.1337C16.7999 7.1337 16.5527 7.14694 16.3067 7.17465C15.4566 7.27073 14.6265 7.55002 13.9619 7.99622Z" fill="#0C1418" />
                <path d="M8.72321 11.6296C10.0572 9.92859 11.8962 8.52193 13.9639 7.44507C16.032 6.36852 19.4511 5.30367 21.8073 4.78202C22.5148 4.62559 23.2525 4.48887 23.974 4.59264C24.6951 4.69673 25.4011 5.09704 25.5799 5.68859C25.8042 6.42918 24.2275 7.57163 23.4864 8.02091C22.7457 8.4705 21.2343 9.12949 21.2343 9.12949C21.2343 9.12949 21.2547 7.75609 18.6177 7.47278C15.0301 7.08755 8.72321 11.6296 8.72321 11.6296Z" fill={props.color? props.color: "#0460A9"} />
                <path d="M16.1007 6.02637C16.1007 6.02637 17.397 5.42865 18.6985 5.28946C18.4314 5.12625 18.2884 5.04803 17.9925 4.93625C17.3268 5.0468 16.6519 5.18753 15.9684 5.50379L16.1007 6.02637Z" fill="#DADBD8" />
                <path d="M9.23778 13.2743C9.23113 13.2943 9.42285 13.3894 9.72319 13.5782C10.0996 13.8076 10.4838 14.042 10.9046 14.2985C11.8304 14.8595 12.5944 15.2999 12.6269 15.2645C12.6587 15.2294 11.9457 14.7314 11.0173 14.1688C10.0971 13.6019 9.27657 13.2093 9.23778 13.2743Z" fill="#E7E8E5" />
                <path d="M8.99724 16.1245C8.9566 16.1464 9.48489 16.8048 10.3734 17.4296C11.2582 18.059 12.1234 18.386 12.1426 18.3494C12.1707 18.3069 11.3631 17.9232 10.4979 17.3073C9.62824 16.6964 9.04268 16.0944 8.99724 16.1245Z" fill="#E7E8E5" />
                <path d="M21.8895 15.1793C21.8895 15.1793 20.9616 15.3998 20.7177 15.3887C19.0202 15.3123 18.4232 14.8344 15.6499 15.3277C15.2029 15.4072 14.7411 15.4176 14.2885 15.3628C13.8825 15.3139 13.307 15.2646 12.6269 15.2646L12.093 18.3526C12.093 18.3526 15.3835 20.7517 18.3478 20.4829C22.1799 20.1352 22.6336 16.4083 21.8895 15.1793Z" fill="#FAFAF9" />
                <path d="M21.8897 15.1792C21.8897 15.1792 21.9241 15.2605 21.9906 15.4047C22.0475 15.5531 22.1118 15.7803 22.1494 16.0769C22.2041 16.6647 22.1609 17.5633 21.6141 18.5333C21.3326 19.0088 20.9074 19.4931 20.2938 19.8537C19.6846 20.2183 18.8944 20.4274 18.0547 20.4641C17.2127 20.4912 16.3269 20.3283 15.4779 20.037C14.6245 19.749 13.7892 19.3546 12.9898 18.8865C12.6883 18.7076 12.3935 18.5231 12.1183 18.3285L12.1309 18.3572C12.3174 17.2745 12.4962 16.2361 12.6629 15.2689L12.627 15.2947C13.3101 15.2889 13.9588 15.3545 14.5876 15.4173C14.9045 15.4351 15.216 15.4204 15.5134 15.3769C15.81 15.3314 16.0915 15.2756 16.3719 15.2414C16.9287 15.1645 17.4595 15.1251 17.952 15.1318C18.4452 15.1358 18.8959 15.1971 19.303 15.2538C19.7101 15.3129 20.0781 15.3606 20.4009 15.3837C20.5624 15.389 20.7127 15.4124 20.8524 15.3936C20.9902 15.3769 21.1124 15.3535 21.2218 15.3323C21.6596 15.2442 21.8897 15.1792 21.8897 15.1792C21.8916 15.1275 20.9495 15.5753 19.3107 15.2171C18.9036 15.158 18.4503 15.0933 17.9531 15.0875C17.4566 15.0779 16.9224 15.1158 16.362 15.1913C16.0812 15.2242 15.7945 15.2803 15.5023 15.324C15.2097 15.3659 14.9034 15.3797 14.5924 15.3612C13.9736 15.2975 13.3112 15.2294 12.627 15.2344L12.5956 15.2347L12.5912 15.2602C12.4231 16.2275 12.2432 17.2655 12.0551 18.3479L12.0518 18.3655L12.0677 18.3766C12.347 18.5743 12.6418 18.7587 12.9455 18.9392C13.7493 19.4094 14.5891 19.8057 15.4495 20.0952C16.3062 20.3883 17.202 20.5521 18.0576 20.5232C18.9099 20.4847 19.7127 20.2698 20.3304 19.8972C20.9532 19.5286 21.3818 19.0349 21.6629 18.553C22.2096 17.5701 22.2429 16.6657 22.179 16.0744C22.1361 15.7757 22.0678 15.5494 22.005 15.3994C21.9296 15.2513 21.8897 15.1792 21.8897 15.1792Z" fill="#17282F" />
                <path d="M1.53549 24.5294C1.53549 24.5294 -0.303529 25.6651 -0.823687 28.0304C-1.31872 30.2823 -3.00037 36.575 -3.00037 36.575L5.85966 37.449L5.8545 35.7199L6.67944 32.4752L5.02808 28.0304L1.53549 24.5294Z" fill={props.color? props.color: "#0460A9"} />
                <path d="M2.26039 24.9347C2.2482 24.9603 2.58217 25.0758 3.07389 25.3274C3.56598 25.5777 4.20251 25.9854 4.77107 26.544C5.33704 27.1048 5.71349 27.6964 5.92739 28.1423C6.14314 28.5885 6.22368 28.8822 6.25619 28.8764C6.2754 28.8736 6.24253 28.5691 6.05485 28.1025C5.86977 27.6369 5.50106 27.0177 4.91846 26.4406C4.33328 25.8656 3.66203 25.4592 3.14224 25.2294C2.62171 24.9972 2.26704 24.919 2.26039 24.9347Z" fill="#17282F" />
            </g>
        </svg>
    );
}

export default FEIcon;