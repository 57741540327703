// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaflet-container{
    height: 300px;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./containers/UpdateAddress/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;AACf","sourcesContent":[".leaflet-container{\n    height: 300px;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
