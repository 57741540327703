import React from "react";
import Screen from './Index'

class ReturnScreen extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Screen {...this.props} isReturn={true}/>
        )
    }
}

export default ReturnScreen;