import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import LocateAddress from "../LocateAddress";
import ViewListIcon from "@material-ui/icons/ViewList";
import MyLocation from "@material-ui/icons/MyLocation";
import PudoScreenList from "../../components/PudoScreenList";
import CarouselComponent from "../../components/Carousel";
import ShopDetail from "../ShopDetail";
import { isEmpty } from "loadsh";
import MapView from "../MapView";
import TextField from "@material-ui/core/TextField";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { setPudoPoints } from "../MapView/MapViewAction";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import * as CustomerInteractionAction from "../../CustomerInteractionActions";
import EventScreen from "../EventScreen/index";
import { Input } from 'antd';
import { Menu, Dropdown, Icon } from 'antd';
import {  Space } from 'antd';
import { BackIconArrow, SearchIcon, GpsIcon, ListViewIcon, ClockIcon, LocationIcon, DarkSearchIcon} from "./PudoReturnMap/Icons";
import { MapBackArrow, MapSearchIcon, PudoCallIcon, PudoDirectionsIcon, PudoDownArrowIcon, PudoLocationIcon, PudoModalCloseIcon, PudoTimeIcon, WhiteCloseIconn } from "../LayoutScreen/PudoReturnMap/Icons";
import PudoScreenListDesktop from "../../components/PudoScreenListDesktop";
import { DownOutlined, SmileOutlined, UpOutlined } from '@ant-design/icons';
import MenuItem from "antd/lib/menu/MenuItem";
import { PudoOtherDetails } from "../../components/PudoOtherDetails";
import { isEqual } from "lodash";
import { processUpdateFormService } from "../../services/ProcessUpdateFormService";
import TableLoader from "../../components/TableLoader";
import { mapViewService } from "../MapView/services/MapViewService";

class AvailableLocationV3Desktop extends React.Component {
  storeSingleDetailIndex;
  map;
  bounds;
  jobLatitude;
  jobLongitude;
  searchLocationLat;
  searchLocationLng;
  isBackButtonClicked = false;
  days=["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  constructor(props) {
    super(props);
    this.state = {
      pudoList: false,
      shopDetail: false,
      currentMarkerIndex: "",
      showLocateAddress: false,
      addressValue: "",
      sortedOnJob: true,
      pudoUpdated: false,
      pudoError: false,
      openPudoPopup: false,
      pudoPopupIndex: 0,
      isUpdating: false,
      pudoPoints: props.pudoPoints,
      searchLocation: null,
      timingDropDown:false,
      timingText: containerConstants.formatString(containerConstants.ViewTimings),
    };
    this.openPudoScreenList = this.openPudoScreenList.bind(this);
    this.clickBackAvailableLocationScreen = this.clickBackAvailableLocationScreen.bind(
      this
    );
    this.openCardDetail = this.openCardDetail.bind(this);
    this.closeCardDetail = this.closeCardDetail.bind(this);
    this.points = this.points.bind(this);
    this.mapFitBound = this.mapFitBound.bind(this);
    this.setCurrentLocation = this.setCurrentLocation.bind(this);
    this.updatePudoPointAccordingToCurrentLocation = this.updatePudoPointAccordingToCurrentLocation.bind(
      this
    );

    this.unitMultiplier = null;
    this.updatePudo = this.updatePudo.bind(this);
    this.herePoweredAccount =  window.localStorage.getItem('isHerePoweredAccount') === 'true' ? true : false
  }
  
  openPudoScreenList() {
    this.setState({
      pudoList: true,
    });
  }
  clickBackAvailableLocationScreen() {
    this.setState({
      pudoList: false,
    });
    this.isBackButtonClicked = false;
  }
  openCardDetail(singleDetail, index) {
    this.storeSingleDetail = singleDetail;
    this.storeSingleDetailIndex = index;
    if (!isEmpty(singleDetail)) {
      this.setState({
        shopDetail: true,
        currentMarkerIndex: index,
      });
    }
  }
  openPudoPopup=(singleDetail,index)=> {
   if (!isEmpty(singleDetail)) {
      this.setState({
        openPudoPopup: true,
        pudoPopupIndex: index,
      });
    }
  }
  closePudoPopup=()=> {
    this.setState({
        openPudoPopup: false,
      });
  }

  closeCardDetail(singleDetail) {
    this.setState({
      shopDetail: false,
      pudoError: false,
    });
    this.isBackButtonClicked = false;
  }

  points(index) {
    this.setState({
      ...index,
      currentMarkerIndex: index,
    });
    this.closePudoPopup();
  }

  

  componentDidMount() {
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + this.props.url
    ); // on back button from browser/mobile it will set current url
    window.addEventListener("popstate", this.onBackButtonEvent);
  }
  componentWillUnmount = () => {
    window.removeEventListener("popstate", this.onBackButtonEvent);
  };

  onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!this.isBackButtonClicked) {
      this.isBackButtonClicked = true;
      setTimeout(
        function () {
          if (this.state.showLocateAddress) {
            this.setState({ showLocateAddress: false });
            window.history.pushState(
              null,
              null,
              window.location.pathname + "?" + this.props.url
            ); // on back button from browser/mobile it will set current url
            this.isBackButtonClicked = false;
          } else if (this.state.shopDetail) {
            this.closeCardDetail();
            window.history.pushState(
              null,
              null,
              window.location.pathname + "?" + this.props.url
            ); // on back button from browser/mobile it will set current url
          } else if (this.state.pudoList) {
            this.clickBackAvailableLocationScreen();
            window.history.pushState(
              null,
              null,
              window.location.pathname + "?" + this.props.url
            ); // on back button from browser/mobile it will set current url
          } else {
            this.backToScreen();
          }
        }.bind(this),
        100
      );
    }
  };

  backToScreen() {
    if (!this.state.sortedOnJob) {
      if (
        this.props.trackingDetails &&
        this.props.trackingDetails.jobLat &&
        this.props.trackingDetails.jobLng
      ) {
        this.sortPudoPoints(
          this.props.trackingDetails.jobLat,
          this.props.trackingDetails.jobLng
        );
      }
    }
    this.props.onBackClick();
    this.isBackButtonClicked = false;
  }

  componentDidUpdate(prevProps) {
    if (
      !this.unitMultiplier &&
      this.props.trackingDetails &&
      this.props.trackingDetails.pudo &&
      this.props.trackingDetails.pudo.distanceUnit
    ) {
      // conversion from kilometers to conversion unit
      switch (this.props.trackingDetails.pudo.distanceUnit) {
        case "miles":
          this.unitMultiplier = 0.6214;
          break;

        case "kilometers":
          this.unitMultiplier = 1;
          break;

        case "yards":
          this.unitMultiplier = 1093.61;
          break;

        default:
          this.unitMultiplier = 1;
      }
    }

    if(!isEqual(prevProps.pudoPoints, this.props.pudoPoints)) {
      this.setState({pudoPoints: [...this.props.pudoPoints]});
    }
  }

  getDistanceBetweenLatLng = (lat1, lng1, lat2, lng2) => {
    const p = 0.017453292519943295;
    const c = Math.cos;
    let a =
      0.5 -
      c((lat2 - lat1) * p) / 2 +
      (c(lat1 * p) * c(lat2 * p) * (1 - c((lng2 - lng1) * p))) / 2;
    let distanceInKilometer = 12742 * Math.asin(Math.sqrt(a));
    let convertedDistance = this.unitMultiplier
      ? distanceInKilometer * this.unitMultiplier
      : distanceInKilometer;
    return convertedDistance.toFixed(2);
  };

  sortPudoPoints = (lat, lng) => {
    if (!isEmpty(this.state.pudoPoints)) {
      const changedPudoPoints = [];
      this.state.pudoPoints.forEach((pudoPoint) => {
        let temp = { ...pudoPoint };
        temp.distance = this.getDistanceBetweenLatLng(
          lat,
          lng,
          pudoPoint.latitude,
          pudoPoint.longitude
        );
        changedPudoPoints.push(temp);
      });
      changedPudoPoints.sort((a, b) => (a.distance < b.distance ? -1 : 1));
      this.props.actions.setPudoPoints(changedPudoPoints);
    }
  };

  setSearchAddress = (address) => {
    if (!isEmpty(address)) {
      this.searchLocationLat = address.geometry ?address.geometry.location.lat : address.latitude;
      this.searchLocationLng = address.geometry ?address.geometry.location.lng : address.longitude;
      this.sortPudoPoints(
        this.searchLocationLat ,
        this.searchLocationLng 
      );
      this.setState({
        showLocateAddress: false,
        sortedOnJob: false,
        addressValue: address.formatted_address,
      });
    }
  };

  onInputClear = () => {
    if (
      this.props.trackingDetails &&
      this.props.trackingDetails.jobLat &&
      this.props.trackingDetails.jobLng
    ) {
      this.sortPudoPoints(
        this.props.trackingDetails.jobLat,
        this.props.trackingDetails.jobLng
      );
    }
    this.setState({
      addressValue: "",
      sortedOnJob: true,
    });
  };

  setCurrentLocation = () => {
    this.mapFitBound(
      this.bounds,
      this.jobLatitude,
      this.jobLongitude,
      this.map
    );
  };

  updatePudoPointAccordingToCurrentLocation = () => {
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition((position) => {
        this.sortPudoPoints(
          position.coords.latitude,
          position.coords.longitude
        );
        this.setState({
          showLocateAddress: false,
          sortedOnJob: false,
          addressValue: "Your Address",
        });
      });
    }
  };

  mapFitBound(bounds, lat, lng, map) {
    this.map = map;
    this.bounds = bounds;
    this.jobLatitude = lat;
    this.jobLongitude = lng;
    map.fitBounds([bounds, this.jobMarker], {
      paddingBottomRight: [20, 250],
      paddingTopLeft: [20, 50],
    });
  }

  backToPreviousPage = () => {
    this.backToScreen();
  };

  updatePudo = async (pudo) => {
    this.setState({isUpdating:true});
    let form = {
      processInteractionKeysDtos: [],
    };
    let updateList = this.props.updateProcessAttributeList;
    for (let i = 0; i < updateList.length; i++) {
      if (
        this.props.dsMappedKeyValue[pudo.id][updateList[i].dsAttributeKey] !=
          undefined &&
        !isEmpty(
          this.props.dsMappedKeyValue[pudo.id][updateList[i].dsAttributeKey]
        )
      ) {
        let attributes = {
          key: updateList[i]["processAttributeKey"],
          value: this.props.dsMappedKeyValue[pudo.id][
            updateList[i].dsAttributeKey
          ],
          typeId: updateList[i]["processAttributeTypeId"],
        };
        form["processInteractionKeysDtos"].push(attributes);
      }
    }
    if (!isEmpty(form["processInteractionKeysDtos"])) {
      let res = await this.props.actions.updateFormData(
        form.processInteractionKeysDtos,
        this.props.trackingDetails.pudo.updateProcessMasterCode,
        this.props.pudpReferenceNumber,
        this.props.url,
        "IS_PUDO",
        pudo,
        "",
        "",
        "",
        this.props.trackingDetails
      );
      if (res == "200") {
        this.errorMessage = "";
        this.setState({ pudoUpdated: true ,isUpdating:false });
      } else {
        this.errorMessage = containerConstants.formatString(
          containerConstants.OopsSomethingWentWrongPleaseTryAfterSomeTime
        );
        this.setState({ pudoUpdated: false, pudoError: true,isUpdating:false });
      }
    }
  };

  checkAddressValue = (addressValue) => {
    if (!isEmpty(addressValue)) {
      return addressValue;
    }
  };
  showTiming =()=>{
    var timingText;
    if(this.state.timingDropDown)
    timingText = containerConstants.formatString(containerConstants.ViewTimings);
    else 
    timingText = containerConstants.formatString(containerConstants.HideTimings);

    this.setState({timingDropDown:!this.state.timingDropDown,timingText:timingText})
   }
  dropDownMenu=(timings)=>{
    let i=0;
    let list=[];
    for(i=0;i<7;i++){
      if(!isEmpty(timings[i])){list.push(<div className="pb5 pt5" style={{display:'flex'}}><p style={{width:'80px'}}>{this.days[i]}</p> {timings[i]}</div>)}
    }
    return list;
  }
  
  renderPudoPopup=(index)=>{
    let list = this.state.pudoPoints[index];
    let lat = list.latitude;
    let lng = list.longitude;
    let timings = "";
    if(!isEmpty(list.timing)) {
      timings = list.timing.split(',');
    }
    var trimmedStoreName = "";
    if(!isEmpty(list) && !isEmpty(list.name) && list.name.length>28){
      trimmedStoreName = list.name.substr(0, 24) + "...";
    }
    let mapLocationUrl = "http://maps.google.com/?z=8&q=" + lat + "," + lng;
    return(
      <div className="return-store-pudo-web-view">
          <div className="return-pudo-store-dtl-inner">
              <button className="return-pudo-store-web-close"
              onClick={()=>this.closePudoPopup()}>
              <PudoModalCloseIcon/>
              </button>
              <div className="return-pudo-store-name-title">
              <h4 title={trimmedStoreName?list.name:""}>{trimmedStoreName?trimmedStoreName:list.name}</h4>
              {list.distance?<span>{list.distance} {this.props.pudoConfig.distanceUnit} away</span>:null}
              </div>
              <div className="return-pudo-store-outer-box">
              {list.addressLine1 ?
              <div className="return-pudo-store-list-row">
                  <div className="return-pudo-store-img-icon">
                  <PudoLocationIcon/>
                  </div>
                  <div className="return-pudo-store-data-col pt25">
                  <p >
                      {list.addressLine1 && list.addressLine1}
                      {list.addressLine2 && " , " + list.addressLine2}
                      {list.landmark && " , " + list.landmark}
                      {list.pincode && " , " + list.pincode}
                    </p>
                  <a href={mapLocationUrl} target="_blank" id="getDirectionPudoCard"><PudoDirectionsIcon/>Get Directions </a>
                  </div>
              </div>:null}
              {list.contactNumber?
              <div className="return-pudo-store-list-row">
                  
                  <a  href={"tel:"+list.contactNumber}>
                    <div className="return-pudo-store-img-icon" id="callStoreDesktop">
                      <PudoCallIcon/> 
                    </div>
                  </a>
                  
                  <div className="return-pudo-store-data-col">
                  <p>
                      {list.contactNumber}
                  </p>
                  </div>
                  
              </div>:null}
              {list.timing?
              <div className="return-pudo-store-ul-list">
                  <div className="return-pudo-store-img-icon" style={{backgroundColor: "rgb(247, 247, 247)"}}>
                      <PudoTimeIcon/>
                  </div>
                  <div className="return-pudo-store-data-col">
                  <p onClick ={()=>{this.showTiming()}} style={{paddingTop:"5px", color:!this.state.timingDropDown?"#3F98D8":'#05223D',cursor:'pointer', lineHeight: "23px"}}><strong style={{marginRight:"4px"}}>{this.state.timingText}</strong>
                  {this.state.timingDropDown? <UpOutlined  style={{color:"#05223D"}}/>:
                   <DownOutlined className="" style={{color:"#3F98D8"}}/>
                  }
                  {this.state.timingDropDown && this.dropDownMenu(timings)}
                 </p>
                      
                  </div>
                  </div>:null}
              {list.otherDetails ?
              <div className="return-pudo-store-ul-list">
                <div className="return-pudo-store-data-col">
                  <p>
                    <PudoOtherDetails otherDetails={list.otherDetails}/>
                  </p>
                </div>
              </div> : null}
                  {/* <div className='return-pudo-store-data-ul-col'>
                      <ul className='return-pudo-store-info-list'>
                      <li>Open untill 23:59</li>
                      <li>Parking</li>
                      <li>Print in shop</li>
                      <li>7 Day Opening</li>
                      <li>Next day drop off</li>
                      <li>CCTV</li>
                      <li>Wheelchair Accessible</li>
                      </ul>
                  </div> */}
              
              </div>
              <button
              id="confirmStore" 
              disabled={this.state.isUpdating}
              type="button" className="return-pudo-web-confirm-btn" 
              onClick={()=>this.updatePudo(list)}>
                {this.state.isUpdating?"Updating store..":"Confirm Store"}</button>
          </div>
      </div>
    );
  }

  onPudoAddressSearch = async (inputAddress) => {
    if(isEmpty(inputAddress)) {
      this.setState({pudoPoints: this.props.pudoPoints, searchLocation: null});
      return;
    }

    const addressObj = await processUpdateFormService.searchAddress(inputAddress, this.props.url, false);
    if(isEmpty(addressObj) || !addressObj.latitude) {
      this.setState({pudoPoints:[], searchLocation: null});
      console.log("invalid location received, returning...");
      return;
    }
    const searchLocation = {
      latitude: addressObj.latitude,
      longitude: addressObj.longitude
    }
  
    const newPudoData = await mapViewService.fetchPudoData(this.props.url, addressObj.latitude,addressObj.longitude,this.props.trackingDetails.masterCode);
    if(newPudoData && newPudoData.visibleList && !isEqual(this.state.pudoPoints , newPudoData.visibleList)){
    this.setState({pudoPoints:newPudoData.visibleList,searchLocation:searchLocation});}
  }


  render() {
    if(this.state.pudoPoints==null)
    return (
      <div>
      <div style={{position: 'absolute', top: '45%', transform: 'translate(-50%, -50%)', left: '50%'}}>Fetching PudoStores</div> 
      <TableLoader />
     </div>
    );
    if (this.state.pudoUpdated) {
      this.props.updatedShop();
    } 

    if (this.state.pudoList) {
      return (
        <>
        <div className='ci-pudo-return-map-header-search-bar'>
        <button className='back-arrow' onClick={this.backToPreviousPage}><BackIconArrow/></button>
        <h3>Pick your preferred store</h3>
        <button className="dark-search-icon" onClick={() => this.setState({ showLocateAddress: true })}><DarkSearchIcon/></button>
        </div>
        <PudoScreenList
            availableList={this.state.pudoPoints}
            backAvailableLocation={this.clickBackAvailableLocationScreen}
            onForwardArrowClick={this.openCardDetail}
            primaryBgColor={this.props.primaryBgColor}
            unit={this.props.pudoConfig.distanceUnit}
            primaryTextColor={this.props.primaryTextColor}
          />
        </>
      );
    } else {
      return (
        <>
        <div className="ci-pudo-return-desktop-map-modal">
        <div className='ci-pudo-return-desktop-map-modal-container'>
            {/* Header */}
            <div className='returns-modal-box-map-header'>
                <h3>Nearby Stores</h3>
                <button className='close'
                onClick={this.backToPreviousPage}><WhiteCloseIconn/></button>
            </div>
            <div className='ci-pudo-return-desktop-map-modal-body'>
              <div className='ci-pudo-return-desktop-map-frame'>
                <MapView
                  key={this.state.searchLocation && this.state.searchLocation.latitude}
                  availableLocationList={this.state.pudoPoints}
                  points={this.points}
                  mapFitBound={this.mapFitBound}
                  searchLocation={this.state.searchLocation}
                />
              </div>
              
              <div className='ci-pudo-return-desktop-map-store-list'>
                {/* Desktop Map left list store */}
                <div className="returns-map-list-view">
                    
                    
                      <PudoScreenListDesktop
                        availableList={this.state.pudoPoints}
                        backAvailableLocation={this.clickBackAvailableLocationScreen}
                        onForwardArrowClick={this.openCardDetail}
                        primaryBgColor={this.props.primaryBgColor}
                        unit={this.props.pudoConfig.distanceUnit}
                        primaryTextColor={this.props.primaryTextColor}
                        openPudoPopup={this.openPudoPopup}
                        closePudoPopup={this.closePudoPopup}
                        backToPreviousPage={this.backToPreviousPage}
                        currentMarkerIndex={this.state.currentMarkerIndex}
                        onPudoAddressSearch={this.onPudoAddressSearch}
                      />
                      </div>
                      {this.state.openPudoPopup?
                      this.renderPudoPopup(this.state.pudoPopupIndex):null}
                    
              </div>    
        </div> 
        </div>    
        </div>
        </>  
      );
    }
  }
}

const mapStateToProps = (state) => ({
  trackingDetails: state.customerInteractionReducer.trackingDetails,
  url: state.customerInteractionReducer.url,
  updateProcessAttributeList: state.pudoReducer.updateProcessAttributeList,
  dsMappedKeyValue: state.pudoReducer.dsMappedKeyValue,
  pudpReferenceNumber: state.pudoReducer.referenceNumber,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { setPudoPoints, ...CustomerInteractionAction },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AvailableLocationV3Desktop);
