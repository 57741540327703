import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { processUpdateFormService } from "../../services/ProcessUpdateFormService";
import * as curbsidePickupActionCreator from './redux/curbsidePickupActionCreator'
import { MyContext } from "../context";
import { containerConstantsService } from "../../Utils/containerConstants";
import { isEmpty } from "lodash";
import TableLoader from "../../components/TableLoader";
import MainScreen from "./screens/MainScreen";
import { fetchFeedbackInfo } from "../../CustomerInteractionActions";

window.containerConstants = containerConstantsService.getInstance("en");
class CurbsidePickup extends React.Component {

  constructor(props) {
    super(props);
    this.deviceType = null;
  }

  async componentDidMount() {
    require('./app.css');
    document.title = "Curbside Pickup";
    let curbsidePickupUrl = this.props.location.search.substring(1);
    if (curbsidePickupUrl) {
      this.props.actions.setCurbsidePickupUrl(curbsidePickupUrl);
      this.props.actions.setCurbsidePickupData(curbsidePickupUrl);
    }
    this.deviceType = await processUpdateFormService.getDeviceType();
  }

  checkIfDataLoading = () => {
    if (isEmpty(this.props.curbsidePickupData)) {
      return true;
    }

    if (this.deviceType == null) {
      return true;
    }

    return false;
  }

  renderLoader = () => {
    return (
      <div className="clearfix">
        <TableLoader />
      </div>
    );
  }

  changeScreen = (screenType) => {
    this.props.actions.setCurbsidePageFlow(screenType);
  }

  getDefaultTheme = () => {
    return {
      "primaryBgColor": "#0460A9",
      "secondaryBgColor": "#F0F8FF",
      "primaryTextColor": "#fff",
      "secondaryTextColor": "#0460A9"
    }
  }

  getTheme = (isContext) => {
    let curbsidePickupData = this.props.curbsidePickupData;
    let themeData = this.getDefaultTheme();
    if (curbsidePickupData &&
      curbsidePickupData.customerInteractionServiceDTO &&
      curbsidePickupData.customerInteractionServiceDTO.customerInteractionThemeSettingsDTO) {
      themeData = curbsidePickupData.customerInteractionServiceDTO.customerInteractionThemeSettingsDTO;
      if (!isContext) {
        return {
          "--primary-color": themeData.primaryBgColor,
          "--secondary-color": themeData.secondaryBgColor,
          "--primary-text-color": themeData.primaryTextColor,
          "--secondary-text-color": themeData.secondaryTextColor,
        }
      } else {
        return {
          "primaryBgColor": themeData.primaryBgColor,
          "secondaryBgColor": themeData.secondaryBgColor,
          "primaryTextColor": themeData.primaryTextColor,
          "secondaryTextColor": themeData.secondaryTextColor,
        }
      }
    }
    return themeData;
  }

  getCompanyLogo = () => {
    let curbsidePickupData = this.props.curbsidePickupData;
    if (curbsidePickupData && curbsidePickupData.customerInteractionServiceDTO) {
      return curbsidePickupData.customerInteractionServiceDTO.companyLogo;
    }
  }

  render() {
    return (
      <div style={this.getTheme()} className="curbside-pickup-main-screen">
        <MyContext.Provider
          value={this.getTheme(true)}
        >
          {
            this.checkIfDataLoading() ?
              this.renderLoader() :
              <MainScreen
                {...this.getTheme(true)}
                deviceType={this.deviceType ? "mobile" : "web"}
                screenType={this.props.curbsidePageFlow}
                curbsidePickupData={this.props.curbsidePickupData}
                curbsidePickupUrl={this.props.curbsidePickupUrl}
                trackingDetails={this.props.trackingDetails}
                changeScreen={this.changeScreen}
                companyLogo={this.getCompanyLogo()}
                isFeedbackInfoFetch={this.props.isFeedbackInfoFetch}
                feedbackInfo={this.props.feedbackInfo}
                fetchFeedbackInfo={this.props.actions.fetchFeedbackInfo}
              />
          }
        </MyContext.Provider>
      </div>
    )
  }

}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...curbsidePickupActionCreator, fetchFeedbackInfo },
      dispatch
    ),
  };
}

function mapStateToProps(state) {
  return {
    timeSlotData: state.curbsidePickupReducer.timeSlotData,
    curbsidePageFlow: state.curbsidePickupReducer.curbsidePageFlow,
    curbsidePickupReducer: state.curbsidePickupReducer,
    curbsidePickupUrl: state.curbsidePickupReducer.curbsidePickupUrl,
    curbsidePickupData: state.curbsidePickupReducer.curbsidePickupData,
    trackingDetails: state.customerInteractionReducer.trackingDetails,
    isFeedbackInfoFetch:state.customerInteractionReducer.isFeedbackInfoFetch,
    feedbackInfo:state.customerInteractionReducer.feedbackInfo
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CurbsidePickup);