import {setState} from '../../../reactApp/Utils/setState'
import  CONSTANTS, { ScreenType }  from '../../../reactApp/Utils/Constants'
import {isEmpty,cloneDeep} from 'loadsh'
import {liveTrackingService}  from '../../services/LiveTrackingService';
import { enhancedLiveTrackingService } from '../../services/EnhancedLiveTrackingService';
import {processUpdateFormService } from '../../services/ProcessUpdateFormService';


function getRequiredLiveTrackingService() {
  const enableNewLiveTracking = window.localStorage.getItem('newLiveTracking');
  if(enableNewLiveTracking && enableNewLiveTracking === "true") {
    return enhancedLiveTrackingService;
  }
  return liveTrackingService;
}


export function initializeMap(obj,detail,url,isEmbed,prevJob){
    return async function (dispatch) {
        try{
            const liveTrackingService = getRequiredLiveTrackingService();
            const map = await liveTrackingService.initializeMap(obj,detail,url,isEmbed,prevJob);
            return map;
        }catch(err){
        }
    }
}  

export function updatePopup(text,details){
  return async function (dispatch) {
      try{
          const liveTrackingService = getRequiredLiveTrackingService();
          liveTrackingService.bindPopUp(text);
      }catch(err){
      }
  }
}  

export function clearZoom(){
  return async function (dispatch) {
      try{
          const liveTrackingService = getRequiredLiveTrackingService();
          liveTrackingService.clearZoom();
         
      }catch(err){
        console.log("err",err);
      }
  }
}  


export function setTrackLogData(data){
  return async function (dispatch) {
      try{
          const liveTrackingService = getRequiredLiveTrackingService();
          const eta = await liveTrackingService.getTrackData(data);
          return eta;
      }catch(err){
      }
  }
}

export function getTripETA(url,jobId,min,max,trackingEnableTime,browserTimeZone,timeFormat,orderId){
  return async function (dispatch) {
      try{
        const liveTrackingService = getRequiredLiveTrackingService();
        let tripEta = await liveTrackingService.getTripEta(url,jobId,min,max,trackingEnableTime,browserTimeZone,timeFormat,orderId);
        if(!isEmpty(tripEta)){
          dispatch(setState(CONSTANTS.SET_TRIP_ETA,cloneDeep(tripEta)));
        }
      }catch(err){
      }
  }
}

export function startFakeMovement(detail,url){
  return async function (dispatch) {
      try{
        const liveTrackingService = getRequiredLiveTrackingService();
        let res = await liveTrackingService.startFakeMovement(detail,url);
        if(res && res=="REFRESH"){
          try{
            let holidayMaster;
            const deviceType = await processUpdateFormService.getDeviceType()        // check device is mobile/tablet  or desktop
            const response =   await processUpdateFormService.getTrackingDetails(url,deviceType);
            
            if(response && response["feedbackUpdated"]){
                if(response.customerInteractionThemeSettingsDTO) {
                    try{
                        response.customerInteractionThemeSettingsDTO = JSON.parse(response.customerInteractionThemeSettingsDTO);
                    } catch(err) {
                        console.log(err);
                        response.customerInteractionThemeSettingsDTO = null;
                    }
                }
                dispatch(setState(CONSTANTS.SET_FEEDBACK,{feedback:true,details:response}))
            }
            else if(response=="notFound"){
                dispatch(setState(CONSTANTS.NOT_FOUND,true))
            } else {
                if(typeof response == 'object'){response.isMobileType=deviceType;}
                let check={},alreadyUpdated = false;
                if(response.updated){
                     alreadyUpdated = true;
                    dispatch(setState(CONSTANTS.GET_TRACKING_DETAILS,{response,url,check,alreadyUpdated,holidayMaster}))
                } else {
                    if(response.hubId){
                        holidayMaster = await processUpdateFormService.getHolidayMaster(url,response.hubId);
                    }
                    if(response.processInteractionKeysDtos){
                                response.processInteractionKeysDtos.map(attributes=>{
                                    if(attributes.typeId==8 && attributes.value && !isEmpty(attributes.value)){
                                        let value = JSON.parse(attributes.value)
                                        value.map(values=>{
                                            check[values] =true;
                                        })     
                                    }
                                }) 
                            }
                        dispatch(setState(CONSTANTS.GET_TRACKING_DETAILS,{response,url,check,alreadyUpdated,holidayMaster}))
                   }
            }
            }catch(err){
              dispatch(setState(CONSTANTS.NOT_FOUND,true))
              console.log('error',err);
        }
        } else if (res && res.showPreviousJobsInfo == true && res.trackLogResponse) {
          if(!isEmpty(res.trackLogResponse.userSummaryDTO)) {
            const nextJobTransactionId = res.trackLogResponse.userSummaryDTO.nextTransactionId;
            dispatch(setState(CONSTANTS.SET_NEXT_JOB_TRANSACTION_ID, nextJobTransactionId));
          }
        } else if (res && res!="CLOSE"){
          dispatch(setState(CONSTANTS.SET_UPDATE_ETA, res));
          return res;
        } else if(res && res=="CLOSE"){
          dispatch(setState(CONSTANTS.SET_JOB_REMOVED,true));
        }
      }catch(err){
      }
  }
}

export function getPreviousJobsInfo(url, trackingDetails) {
  return async dispatch => {
    const liveTrackingService = getRequiredLiveTrackingService();
    if(isEmpty(trackingDetails) || isEmpty(trackingDetails.liveTrackingPreviousJobsInfo) || !trackingDetails.jobId) {
      return;
    }
    const prevJobsInfoDetails = await liveTrackingService.getPreviousJobsInfo(url, trackingDetails.jobId, trackingDetails.orderId, trackingDetails.liveTrackingPreviousJobsInfo.statusListToExclude);
    let displayMessage = null;
    let previousJobCount = 0;
    if(prevJobsInfoDetails && prevJobsInfoDetails.previousJobCount && prevJobsInfoDetails.previousJobCount > 0) {
      previousJobCount = prevJobsInfoDetails.previousJobCount;
      displayMessage = trackingDetails.liveTrackingPreviousJobsInfo.displayMessage;
      if(!displayMessage) {
        displayMessage = "Delivering " + prevJobsInfoDetails.previousJobCount + " other order(s) before you!"
      } else if (displayMessage.indexOf("{PREVIOUS_ORDERS_COUNT}") > -1) {
        displayMessage = displayMessage.substring(0, displayMessage.indexOf("{PREVIOUS_ORDERS_COUNT}")) + prevJobsInfoDetails.previousJobCount + displayMessage.substring(displayMessage.indexOf("{PREVIOUS_ORDERS_COUNT}") + 23);
      }
    }
    dispatch(setState(CONSTANTS.SET_PREVIOUS_JOBS_MESSAGE, {displayMessage,previousJobCount}));
  }
}

export function isNewLiveTrackingEnabled(trackingDetails) {
  if(isEmpty(trackingDetails) || isEmpty(trackingDetails.screen)) {
    return false;
  }

  if(trackingDetails.newLiveTracking != true && trackingDetails.newLiveTracking != "true") {
    return false;
  }

  if(trackingDetails.screen != ScreenType.LAYOUT_SCREEN && trackingDetails.screen != ScreenType.DESKTOP_SCREEN) {
    return false;
  }

  return true;
}
