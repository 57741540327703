import { isEmpty } from 'lodash';

class FormServices {

    getFormDetailsMap = (processInteractionKeysDtos, fromAddressUpdation) => {
        if (isEmpty(processInteractionKeysDtos)) {
            return {};
        }
        var formDetailsMap = {};
        for (let dto of processInteractionKeysDtos) {
            if((fromAddressUpdation && !dto.addressPart) || 
                    (!fromAddressUpdation && dto.addressPart) || 
                    (!isEmpty(dto.mappingWith) && dto.mappingWith != "[]")){
                continue;
            }
            var detailObj = {
                required: dto.required,
                value: dto.typeId==8 && dto.value ? JSON.parse(dto.value) : dto.value ,
                validations: isEmpty(dto.validation) ? null : JSON.parse(dto.validation),
                hide: dto.hide,
                typeId: dto.typeId
            }
            formDetailsMap[dto.key] = detailObj;
        }

        return formDetailsMap;

    }

   

    runAllValidation=(formDetailsMap,executionTime)=>{
        if(isEmpty(formDetailsMap)) return;

        for(let key in formDetailsMap){
            this.runValidation(formDetailsMap,key,executionTime);
        }
    }

    runValidation = (formDetailsMap, key, executionTime) => {

        if (isEmpty(formDetailsMap[key]) || isEmpty(formDetailsMap[key].validations)) return;

        for (let validationObj of formDetailsMap[key].validations) {
            let validation = validationObj.validation;
            if (!validation || validation.executionTime != executionTime || 
                !formDetailsMap[validation.leftKey] || 
                !(validation.rightKey == 'text' || formDetailsMap[validation.rightKey])) {
                continue;
            }

            var leftValue = formDetailsMap[validation.leftKey].value
            var rightValue = validation.rightKey == 'text' ? validation.value : formDetailsMap[validation.rightKey].value

            if (this.validate(leftValue, rightValue, validation.condition)) {
                this.takeAction(formDetailsMap, validationObj.valid)
            }
            else {
                this.takeAction(formDetailsMap, validationObj.inValid)
            }
        }

    }

    validate = (leftValue, rightValue, condition) => {
        const rightArray = rightValue.split(',').map(function (value) {
            return value.trim();
         });
        switch (condition) {
            case 'equal':
                if (rightArray.indexOf(leftValue+"") > -1) {
                    return true;
                }
                return false;
            case 'notEqual':
                if (rightArray.indexOf(leftValue+"") == -1) {
                    return true;
                }
                return false;
            case 'contains':
                if (leftValue && _.isString(leftValue) && leftValue.includes(rightValue)) {
                    return true;
                }
                return false;
        }

    }

    takeAction = (formDetailsMap, actionArray) => {

        if (isEmpty(actionArray)) return;

        for (let actionObj of actionArray) {
            if(!formDetailsMap[actionObj.attribute]){
                continue;
            }

            if (actionObj.action == 'hide') {
                formDetailsMap[actionObj.attribute].hide = true;
            }
            else if (actionObj.action == 'show') {
                formDetailsMap[actionObj.attribute].hide = false;
            }
        }

    }

    setValuesToProcessInteractionKeysDtos = (formDetailsMap, processInteractionKeysDtos) => {
        if (isEmpty(formDetailsMap) || isEmpty(processInteractionKeysDtos)) return;
        for (let dto of processInteractionKeysDtos) {
            if (!formDetailsMap[dto.key].hide) {
                dto.value = formDetailsMap[dto.key].value;
            }
        }

    }

    


}

export const formServices = new FormServices();