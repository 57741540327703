import React from "react";

export const RightArrow = ()=>{
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_6794_80743)">
            <path d="M6.20672 4.47331C5.94672 4.73331 5.94672 5.15331 6.20672 5.41331L8.79339 7.99997L6.20672 10.5866C5.94672 10.8466 5.94672 11.2666 6.20672 11.5266C6.46672 11.7866 6.88672 11.7866 7.14672 11.5266L10.2067 8.46664C10.4667 8.20664 10.4667 7.78664 10.2067 7.52664L7.14672 4.46664C6.89339 4.21331 6.46672 4.21331 6.20672 4.47331Z" fill="#4F758B"/>
            </g>
            <defs>
            <clipPath id="clip0_6794_80743">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>
        </svg>

    )
};

export const LikeIcon = ()=>{
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_6794_80785)">
            <path d="M3 9.75H7.5V19.5H3C2.80109 19.5 2.61032 19.421 2.46967 19.2803C2.32902 19.1397 2.25 18.9489 2.25 18.75V10.5C2.25 10.3011 2.32902 10.1103 2.46967 9.96967C2.61032 9.82902 2.80109 9.75 3 9.75V9.75Z" stroke-width="1.6875" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.5 9.75L11.25 2.25C12.0456 2.25 12.8087 2.56607 13.3713 3.12868C13.9339 3.69129 14.25 4.45435 14.25 5.25V7.5H20.0531C20.2658 7.49947 20.4762 7.54447 20.67 7.63197C20.8639 7.71946 21.0368 7.84743 21.1771 8.00728C21.3174 8.16712 21.4219 8.35514 21.4835 8.55871C21.5451 8.76228 21.5625 8.97667 21.5344 9.1875L20.4094 18.1875C20.3639 18.5488 20.1885 18.8812 19.9159 19.1227C19.6434 19.3642 19.2923 19.4983 18.9281 19.5H7.5" stroke-width="1.6875" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_6794_80785">
            <rect width="24" height="24"/>
            </clipPath>
            </defs>
        </svg>

    )
};

export const DisLikeIcon = ()=>{
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_6794_80790)">
            <path d="M21.9971 14.25L17.4971 14.25L17.4971 4.5L21.9971 4.5C22.196 4.5 22.3867 4.57902 22.5274 4.71967C22.6681 4.86032 22.7471 5.05109 22.7471 5.25L22.7471 13.5C22.7471 13.6989 22.6681 13.8897 22.5274 14.0303C22.3867 14.171 22.196 14.25 21.9971 14.25V14.25Z" stroke-width="1.6875" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.4971 14.25L13.7471 21.75C12.9514 21.75 12.1884 21.4339 11.6258 20.8713C11.0631 20.3087 10.7471 19.5456 10.7471 18.75V16.5L4.94395 16.5C4.73126 16.5005 4.52092 16.4555 4.32706 16.368C4.1332 16.2805 3.96031 16.1526 3.82 15.9927C3.67969 15.8329 3.57521 15.6449 3.51358 15.4413C3.45196 15.2377 3.43461 15.0233 3.4627 14.8125L4.5877 5.8125C4.63321 5.4512 4.8086 5.1188 5.08115 4.8773C5.3537 4.6358 5.7048 4.5017 6.06895 4.5L17.4971 4.5" stroke-width="1.6875" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_6794_80790">
            <rect width="24" height="24" fill="white" transform="translate(24.9971 24) rotate(180)"/>
            </clipPath>
            </defs>
        </svg>


    )
};

