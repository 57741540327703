import React, { Fragment } from 'react';
import '../../style';
import { isEmpty, cloneDeep } from 'loadsh';

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { constant } from 'lodash';
import CONSTANTS from '../../Utils/Constants';
import { processUpdateFormService } from '../../services/ProcessUpdateFormService';
import { commonUtilities } from '../../services/commonUtilities';





class StatusTimeLinePrototype extends React.Component {

  constructor(props) {
    super(props);
    this.lastStatusCode;
    this.statusList = {};
    this.statusTimeLineRef = null;
  }

  componentDidUpdate() {
    if (
      this.statusTimeLineRef && this.statusTimeLineRef.scrollTop == 0
    ) {
      this.statusTimeLineRef.scrollTop = this.statusTimeLineRef.scrollHeight - this.statusTimeLineRef.clientHeight;
    }
  }

  getFormat(hours) {
    hours = hours > 9 ? hours : "0" + hours;
    return hours;
  }



  getNewTime = (deliveryTime) => {
    var timeFormat = processUpdateFormService.getTimeFormat(this.props.trackingDetails);
    if (deliveryTime && !(deliveryTime.indexOf(containerConstants.formatString(containerConstants.PM)) != -1 || deliveryTime.indexOf(containerConstants.formatString(containerConstants.AM)) != -1) && timeFormat != CONSTANTS.TWENTYFOUR_HOUR_FORMAT) {
      let time = deliveryTime.trim();
      let newTime;
      let a = parseInt((time).substring(0, 2));
      let b = time.substring(3, 5);
      if (a == 0) {
        newTime = time.substring(0, 6) + " " + containerConstants.formatString(containerConstants.AM)
      }
      else if (a >= 12) {
        a = (a == 12 ? a : a - 12);
        newTime = this.getFormat(a) + ":" + b + " " + containerConstants.formatString(containerConstants.PM);
      } else {
        newTime = this.getFormat(a) + ":" + b + " " + containerConstants.formatString(containerConstants.AM);
      }
      return newTime;
    } else if (deliveryTime && timeFormat == CONSTANTS.TWENTYFOUR_HOUR_FORMAT) {
      let time = deliveryTime.trim();
      let a = this.getFormat(parseInt((time).substring(0, 2)));
      let b = time.substring(3, 5);
      return a + ":" + b;
    }
    else
      return deliveryTime;
  }

  renderStatusLog = () => {
    let Shipments = [];
    if (this.props.trackingDetails && this.props.trackingDetails.processStatusLogDomainList && this.props.trackingDetails.processUpdateForm) {
      let form = JSON.parse(this.props.trackingDetails.processUpdateForm);
      if (form && this.props.trackingDetails.eventProcessMasterId) {
        let statusLogs = this.props.trackingDetails.processStatusLogDomainList;
        var responseSortedStatusList = commonUtilities.getSortedStatusLogOnDateTime(this.props.trackingDetails);
        let statusLogList = responseSortedStatusList && responseSortedStatusList["statusLogList"] ? responseSortedStatusList["statusLogList"] : [];
        if (!isEmpty(responseSortedStatusList) && !isEmpty(responseSortedStatusList["statusList"])) {
          this.statusList = responseSortedStatusList["statusList"];
        }
        statusLogs.map((status, statusIndex) => {
          if (status.processStatusLogDto && status.processStatusLogDto[0] && status.processStatusLogDto[0].processMasterId == this.props.trackingDetails.eventProcessMasterId) {
            Shipments.push(
              <Fragment key={statusIndex}>
                {statusLogList.map((status, outerIndex) => {
                  this.lastStatusCode = status
                  let hiddenStatusOnThatDay = 0;

                  return (
                    <div style={{ overflow: 'auto' }}>
                      {status.status.map((singleStatus, index) => {
                        const newTime = this.getNewTime(status.timings[index])
                        const configuredStatus = commonUtilities.typeOfStatus(singleStatus, this.props.trackingDetails)
                        if (configuredStatus && configuredStatus.mileStone == CONSTANTS.HIDDEN)
                          hiddenStatusOnThatDay++;

                        return (
                      //                     <div className="flex mb15 mt15">
                      //   <div style={{width: '80px'}}>
                      //     <p>Mar 04</p>
                      //     <p>10:45 PM</p>
                      //   </div>
                      //   <div>
                      //     <p>ORIGIN SCAN</p>
                      //     <p>South Brunswick, NJ</p>
                      //   </div>
                      // </div>

                                            <div className="flex mb15 mt15">
                                              <div style={{ width: '80px' }}>
                                                {/* <p>Mar 04</p>
                                                <p>10:45 PM</p> */}
                                                {(index == 0 || index == hiddenStatusOnThatDay) && status.previousDate ? <p className="date-timeline text-black">{status.previousDate}</p> : null}
                                                <p>{newTime}</p>
                                              </div>
                                              <div>
                                                {/* <p>ORIGIN SCAN</p>
                                                 <p>South Brunswick, NJ</p> */}
                                                <p>{singleStatus?.status}</p>
                                                <p>{singleStatus?.remark}</p>
                                              </div>
                                            </div>
                        )
                      })
                      }
                    </div>
                  )
                })}
              </Fragment>
            )
          }
        });
        return Shipments;
      }

    }

  }

  renderUpComingStatus = () => {
    let list = [];
    let upcomingStatusList = {}
    let upcomingStatusLog = []
    if (this.props.trackingDetails && this.props.trackingDetails.processStatusList && !isEmpty(this.props.trackingDetails.processStatusList)) {
      let code;
      if (!isEmpty(this.lastStatusCode) && this.lastStatusCode["status"] && this.lastStatusCode["status"].length > 0) {
        let list = this.props.trackingDetails.processStatusList;
        for (let i = 0; i < list.length; i++) {
          if (!upcomingStatusList[list[i].name]) {
            for (let j = 0; j < list.length; j++) {
              if (list[j].parent && list[j].statusCategory != "fail" && list[j].parent == list[i].code) {
                upcomingStatusList[list[i].name] = list[j].name;
                break;
              }
            }
          }
        }
      }
    }
    if (!isEmpty(upcomingStatusList) && this.lastStatusCode["status"][this.lastStatusCode["status"].length - 1] && this.lastStatusCode["status"][this.lastStatusCode["status"].length - 1].status) {
      let key = this.lastStatusCode["status"][this.lastStatusCode["status"].length - 1].status;
      let visitedMap = {};// to handle circular reference
      let statusEnd = false;
      while (!statusEnd) {
        if (upcomingStatusList[key]) {                              // check if status is exist in upcoming List
          if (!visitedMap[key]) {                                   // if not already added in upcoming status Log
            if (!this.statusList[upcomingStatusList[key]]) {        // if not already visited
              upcomingStatusLog = upcomingStatusLog.concat(upcomingStatusList[key]);
            }
            visitedMap[key] = true;
            key = upcomingStatusList[key];
          }
        } else {
          statusEnd = true;
        }
      }
    }
    return (<div className="upcomimgTimeline mb20">
      <VerticalTimeline>
        {upcomingStatusLog.map((upcomingStatus) => {
          return (
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ color: '#0p00' }}
              animate={false}>
              <div className="flex">
                <p>{upcomingStatus}</p>
              </div>
            </VerticalTimelineElement>
          )
        })}
      </VerticalTimeline>
    </div>)
  }


  render() {
    return (
      <div className="bg-white mainDivHeadingTimeline" ref={el => this.statusTimeLineRef = el}>
        {this.renderStatusLog()}
        {/*
                  commented because of FNU-3546

                  this.renderUpComingStatus()
                */}
      </div>
    );
  }

}

export default StatusTimeLinePrototype