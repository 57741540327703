import React from 'react';
import '../style';
import {isEmpty} from 'loadsh';

class DeliveryInfo extends React.Component {
    
    getAddress=(details)=>{
        let address= '';
        if(!isEmpty(details.addressLine1)){
           address=details.addressLine1;
        }
        if(!isEmpty(details.addressLine2)){
          address = !isEmpty(address)?address+", "+details.addressLine2:details.addressLine2;
        }
        if(!isEmpty(details.landMark)){
         address = !isEmpty(address)?address+", "+details.landMark:details.landMark;
     
        }
         if(!isEmpty(details.pinCode)){
           address = !isEmpty(address)?address+", "+details.pinCode:details.pinCode;      
         }
            
        return address;
     }

   

    render(){
        let address = this.props.trackingDetails.processUpdateForm ?JSON.parse(this.props.trackingDetails.processUpdateForm):'';
        return (
            <div className="orderInfo mb20">
             {!isEmpty(this.getAddress(address))?<div className="p10 pl0">
             <span className="fontFamilyLight light-grey">   {this.props.trackingDetails && this.props.trackingDetails.addressLabel
                ? this.props.trackingDetails.addressLabel
                : containerConstants.formatString(
                    containerConstants.DeliveryAt
                  )}</span> 
             <span className="fontFamilyRegular" style={{color: this.props.secondaryTextColor}}>{this.getAddress(address)}</span></div>:null}
             {!isEmpty(address.contact)?<div className="p10 pl0">
             <span  className="fontFamilyLight light-grey">
             {this.props.trackingDetails && this.props.trackingDetails.contactLabel
                      ? this.props.trackingDetails.contactLabel
                      : containerConstants.formatString(
                          containerConstants.ContactNo
                        )}
             
             </span>
             <span className="fontFamilyRegular" style={{color: this.props.secondaryTextColor}}><bdi>{address.contact}</bdi></span></div>:null}
            </div>
        )
    }
}


  

export default DeliveryInfo