
import moment from 'moment';
import { isEmpty } from "lodash"
import { DATE_FORMAT, TIME_FORMAT } from "./Constants"


/**
 * converts date/dateTime from YYYY-MM-DD format to the required format
 * @param {String} dateTime date/dateTime in YYYY-MM-DD format
 * @param {String} format format to be convert, one of {@link DATE_FORMAT}
 */
export const convertFromStandardFormat = (dateTime, format) => {
  if(isEmpty(dateTime) || isEmpty(format)) {
    return dateTime;
  }

  if(Object.values(DATE_FORMAT).indexOf(format) < 0) {
    return dateTime;
  }

  const datePart = dateTime.substring(0, 10); // since expected dateTime format is YYYY-MM-DD HH:mm:ss
  let dateMomentObj = moment(datePart, DATE_FORMAT.YYYY_MM_DD, true);

  if(!dateMomentObj.isValid()) {
    return dateTime;
  } 

  const formatedDate = dateMomentObj.format(format);

  return formatedDate + dateTime.substring(10);
  
}

/**
 * parses the recieved value and infer on the basis of its format
 * @param {*} attributeValue // value to check for the Date attribute type
 * @returns {boolean} whether the value is Date or not
 */
export const isDateTypeAttribute = (attributeValue) => {
  if(isEmpty(attributeValue)) {
    return false;
  }

  const dateMomentObj = moment(attributeValue, DATE_FORMAT.YYYY_MM_DD, true);

  return dateMomentObj.isValid();
}

/**
 * parses the recieved value and infer on the basis of its format
 * @param {*} attributeValue // value to check for the DateTime attribute type
 * @returns {boolean} whether the value is DateTime or not
 */
export const isDateTimeAttribute = (attributeValue) => {
  if(isEmpty(attributeValue)) {
    return false;
  }

  const dateTimeMomentObj = moment(attributeValue, DATE_FORMAT.YYYY_MM_DD + " " + TIME_FORMAT, true);

  return dateTimeMomentObj.isValid();
}