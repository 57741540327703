import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SubHeader from "../../components/SubHeader";
import Footer from "../../components/Footer";
import AddressDetails from "../../components/AddressDetails";
import Shipment from "../../components/ShipmentCard";
import ProcessUpdateForm from "../ProcessUpdateForm/index";
import * as CustomerInteractionAction from "../../CustomerInteractionActions";
import { isEmpty } from "loadsh";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import LiveTracking from "../LiveTracking/index";
import { eventService } from "../EventScreen/EventService";
import TrackingStatus from "../../components/TrackingStatus/index";
import MessageSnackBar from "../../components/MessageSnackBar";
import StatusTimeLine from "../../components/StatusTimeLine";
import { MyContext } from "../context";

class EventScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      liveTracking: false,
      pudoUpdate: true,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    this.setState({
      height:
        window.innerHeight -
        document.getElementById("top-area").offsetHeight -
        document.getElementById("top-area").offsetHeight,
    });
    setTimeout(
      function () {
        this.setState({ pudoUpdate: false });
      }.bind(this),
      2000
    );
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + this.props.url
    ); // on back button from browser/mobile it will set current url
    window.addEventListener("popstate", this.onBackButtonEvent);
  }

  onBackButtonEvent = (e) => {
    e.preventDefault();
    if (this.props.track) {
      this.setState({ liveTracking: true });
    }
  };

  updateWindowDimensions() {
    this.setState({
      height:
        window.innerHeight -
        document.getElementById("top-area").offsetHeight -
        document.getElementById("top-area").offsetHeight,
    });
  }

  backToTrackScreen = () => {
    this.setState({ liveTracking: true });
  };

  renderAddressContent = () => {
    if (
      this.props.trackingDetails &&
      this.props.trackingDetails.processUpdateForm
    ) {
      let forms = JSON.parse(this.props.trackingDetails.processUpdateForm);
      return (
        <AddressDetails
          forms={forms}
          track={this.props.track}
          backToTrackScreen={this.backToTrackScreen}
          screen={this.props.trackingDetails.screen}
          addressLabel={this.props.trackingDetails.addressLabel}
          contactLabel={this.props.trackingDetails.contactLabel}
          fontSize={this.props.fontSize}
          fontFamily={this.props.fontFamily}
          primaryBgColor={this.props.primaryBgColor}
          primaryTextColor={this.props.primaryTextColor}
        />
      );
    }
  };

  renderShipmentContent = () => {
    let Shipments = [];
    if (
      this.props.trackingDetails &&
      this.props.trackingDetails.processStatusLogDomainList &&
      this.props.trackingDetails.processUpdateForm
    ) {
      let form = JSON.parse(this.props.trackingDetails.processUpdateForm);
      if (form && this.props.trackingDetails.eventProcessMasterId) {
        let statusLogs = this.props.trackingDetails.processStatusLogDomainList;
        statusLogs.map((status) => {
          if (
            status.processStatusLogDto &&
            status.processStatusLogDto[0] &&
            status.processStatusLogDto[0].processMasterId ==
              this.props.trackingDetails.eventProcessMasterId
          ) {
            Shipments.push(
              <Shipment
                logs={status.processStatusLogDto}
                track={this.props.track}
                trackingDetails={this.props.trackingDetails}
              />
            );
          }
        });
      }
    }
    return Shipments;
  };

  render() {
    let count = eventService.getShipmentCount(this.props.trackingDetails);
    if (this.state.liveTracking) {
      return <LiveTracking calledFrom={"eventScreen"} />;
    }
    return (
      <MyContext.Consumer>
        {({
          secondaryBgColor,
          secondaryTextColor,
          fontSize,
          fontFamily,
          primaryBgColor,
          primaryTextColor,
        }) => (
          <>
            <div>
              <div id="top-area">
                <SubHeader trackingDetails={this.props.trackingDetails} />
              </div>
              <div className="content" style={{ height: window.innerHeight }}>
                <div
                  className="height30 bg-white"
                  style={{ marginBottom: "2px" }}
                >
                  <p
                    className="bold fs14 pv15"
                    style={{
                      color: this.props.trackingDetails.fontColor,
                      fontFamily: this.props.trackingDetails.fontType,
                      fontSize: this.props.trackingDetails.fontSize,
                    }}
                  >
                    {this.props.trackingDetails.introText}
                  </p>
                  <div className="event-address-details mb10">
                    {this.renderAddressContent()}
                  </div>
                </div>
                <div className="status_log_screen" style={{ height: '50%' }}>
                  <p
                    className="pl5 fs12 shipment-title"
                    style={{
                      color: this.props.trackingDetails.fontColor,
                      fontFamily: this.props.trackingDetails.fontType,
                      fontSize: this.props.trackingDetails.fontSize,
                    }}
                  >
                    {count > 1 ? "SHIPMENT PARTS :" + count : ""}
                  </p>
                  {count > 1 ? (
                    this.renderShipmentContent()
                  ) : (
                    <StatusTimeLine
                      trackingDetails={this.props.trackingDetails}
                    />
                  )}
                </div>
              </div>
              {this.props.isPudoUpdate && this.state.pudoUpdate ? (
                <MessageSnackBar message={containerConstants.formatString(containerConstants.Pickuppointisupdated)} />
              ) : null}
            </div>
          </>
        )}
      </MyContext.Consumer>
    );
  }
}

function mapStateToProps(state) {
  return {
    trackingDetails: state.customerInteractionReducer.trackingDetails,
    url: state.customerInteractionReducer.url,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...CustomerInteractionAction }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventScreen);
