import React from "react";

import { isEmpty } from "loadsh";


class DeliveryDetails extends React.Component {
    getAddress = () => {
        let details = this.props.forms;
        let address = "";
        if (!isEmpty(details.addressLine1)) {
            address = details.addressLine1;
        }
        if (!isEmpty(details.addressLine2)) {
            address = !isEmpty(address)
                ? address + ", " + details.addressLine2
                : details.addressLine2;
        }
        if (!isEmpty(details.landMark)) {
            address = !isEmpty(address)
                ? address + ", " + details.landMark
                : details.landMark;
        }
        if (!isEmpty(details.pinCode)) {
            address = !isEmpty(address)
                ? address + ", " + details.pinCode
                : details.pinCode;
        }

        return address;
    };

    getLocationIntro = () => {
        // let locationType = this.props.trackingDetails.customerInteractionDetailsDTO.addressIntro;
        return containerConstants.formatString(
            containerConstants.ChangeDeliveryLocation
        );
    };

    getContact = () => {
        if (this.props.forms.contact) return this.props.forms.contact;
    };

    getDeliveryTime = () => {
        let locationType = this.props.trackingDetails.customerInteractionDetailsDTO
            .addressIntro;
        let eta;
        if (this.props.trackingDetails.eta) {
            let a = this.props.trackingDetails.eta.substring(0, 2);
            let b = this.props.trackingDetails.eta.substring(3);
            if (a > 12) {
                eta = a - 12 + ":" + b + " PM";
            } else {
                eta = a + ":" + b + " PM";
            }
        }
        return locationType + " by " + eta;
    };

    render() {
        const {
            fontSize,
            fontFamily,
            primaryBgColor,
            primaryTextColor,
        } = this.props;

        return (
            this.getAddress() || this.getContact() ?
                <div>
                    <p className="text-gray mb15 mt15">Delivering to</p>
                    <div className="flex align-center mb10">
                        <img src="../../images/wduser.png" alt="" />
                        <p className="ml15">Golden Walker</p>
                    </div>
                    <div className="flex align-center mb10" style={{wordBreak:'break-word'}}>
                        <img src="../../images/wdHouse.png" alt="" />
                        <p className="ml15">{this.getAddress()}</p>
                    </div>
                    <div className="flex align-center mb10">
                        <img src="../../images/wdPhone.png" alt="" />
                        <p className="ml15">{this.getContact()}</p>
                    </div>
                    <div className="flex align-center mb10">
                        <img src="../../images/EnvelopeSimple.png" alt="" />
                        <p className="ml15">Goldenwalker86@gmail.com</p>
                    </div>
                </div>
                : ''
        );
    }
}

export default DeliveryDetails;
