import React, { Component } from 'react'
import { ClockIcon, LocationIcon} from "../PudoReturnMap/Icons";
import { Swiper, SwiperSlide } from 'swiper/react';
class CIPudoMapSwiper extends Component {
  render() {
    return (
      <>
        <div className='ci-pudo-return-bottom-drawer'>
                <h4 className='title-h4'>Pick your preferred store <a href='#' className='view-all'>View all</a></h4>
                <Swiper
                    className='ci-pudo-return-swiper'
                    spaceBetween={8}
                    slidesPerView={1.15}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    >
                    <SwiperSlide className="ci-pudo-return-swipe-slider">
                        <div className='ci-pudo-return-swiper-details'>
                            <div className='ci-pudo-return-swiper-list'>
                                <button><LocationIcon/><span>1</span></button>
                                <h3>Fareye</h3>
                                <h6>3.6 km</h6>
                            </div>
                            <div className='ci-pudo-return-swiper-address'>
                                <p>Plot no 03, Mall of India, Sector 18, Noida, Uttar Pradesh 201301</p>
                                <div className='ci-pudo-return-swiper-timer'>
                                    <ClockIcon/>
                                    <span><strong>OPEN</strong>till 10:30 PM</span>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="ci-pudo-return-swipe-slider">
                        <div className='ci-pudo-return-swiper-details'>
                            <div className='ci-pudo-return-swiper-list'>
                                <button><LocationIcon/><span>2</span></button>
                                <h3>Fareye</h3>
                                <h6>3.6 km</h6>
                            </div>
                            <div className='ci-pudo-return-swiper-address'>
                                <p>Plot no 03, Mall of India, Sector 18, Noida, Uttar Pradesh 201301</p>
                                <div className='ci-pudo-return-swiper-timer'>
                                    <ClockIcon/>
                                    <span><strong>OPEN</strong>till 10:30 PM</span>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
      </>
    )
  }
}

export default CIPudoMapSwiper;