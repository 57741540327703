const finnishStrings={
YourShipmentHasBeenDelivered:'Lähetyksesi on toimitettu',
DeliveredBy:'Toimitus:',
RateYourExperience:'Arvioi Kokemuksesi',
ThankYouForYourValuableFeedback:'Kiitokset arvokkaasta palautteestasi',
YourFeedbackWillContinuallyHelpUsImproveOurServices:'Palautteenne auttaa meitä jatkuvasti parantamaan palveluitamme.',
DeliveryFailed:'Toimitus Epäonnistui',
AddDeliveryPreferences:'Lisää Toimitusasetukset',
YourDeliveryPreferenceAreSavedSuccessFully:'Toimitusasetuksesi tallennetaan onnistuneesti',
WeAreUnableToTrackYourShipmentRightNow:'Emme pysty jäljittämään lähetystänne juuri nyt.',
PleaseUpdateLocationInsideCircle:'Päivitä sijainti ympyrän sisällä',
Ok:'OK',
Update:'Päivitys',
PickCurrentLocation:'Valitse Nykyinen sijainti',
SearchFor:'Etsi',
ThisSchedulingLinkHasExpired:'Tämä aikataululinkki on vanhentunut',
WeWillShareANewLinkWithYouShortly:'Jaamme pian uuden yhteyden kanssanne.',
UhHo:'Uh ho!',
NeedHelp:'Tarvitsetko Apua?',
CallSupport:'Soita tukeen',
EmailSupport:'Lähetä sähköpostia tukeen',
DeliveryAt:'Toimituspäivä',
DeliveredAt: 'Toimitettu',
ContactNo:'Yhteystiedot',
TrackOnMap:'Kappale kartalla',
MessageToExecutive:'Viesti toimeenpanevalle elimelle',
Cancel:'Peruuttaa',
Send:'Lähettää',
Executive:'Toimeenpaneva',
Comments:'Huomautukset (valinnainen))',
SubmitFeedback:'LÄHETÄ PALAUTETTA',
TrackingNo:'Seurantanumero',
Details:'Tiedot',
Characters:'Merkki',
Updating:'päivittää…',
OopsSomethingWentWrongPleaseTryAfterSomeTime:'Hups! Jokin meni vikaan. Yritä jonkin ajan kuluttua uudelleen.',
NotListedAboveSendACustomMessage:'Ei löydy listasta, lähetä mukautettu viesti',
ChangeDeliveryLocation:'Muuta toimituspaikka',
AttemptedBy:'Yritetty toimittaa',
DeliveredTo:'Toimitettu kohteeseen',
SendMessage:'LÄHETÄ VIESTI',
VIEW_DETAILS:'Näytä tiedot',
SAVE_LOCATION:'TALLENNA SIJAINTI',
LOCATION:'sijainti',
SEARCH_ADDRESS:'Etsi osoitetta',
OpenHours:'Aukioloajat',
Note:'Huomautus',
Address:'Osoite',
Nearest: 'Lähin',
ContactNumber:'Yhteysnumero',
FAQS:'UKK',
HowManyTimesCanIChangeMyPickUpPoint: 'Kuinka monta kertaa voin muuttaa noutopistettä?',
YouCanChangeItOnly: 'Voit muuttaa sen vain',
GoBack:'Palaa takaisin',
SetAsPickupStore:'Valitse noutopaikaksi',
MapView:'Karttanäkymä',
SearchForPickupPointNearYou:'Etsi lähelläsi olevaa noutopistettä',
Arriving:'Saapuu',
LiveTrackingWillStart:'Live-seuranta alkaa',
DriverTrackerWillGetActivatedOnceTheyAreOnWay:  'Kuljettajan seurantaohjelma aktivoituu, kun matka on aloitettu',
TrackerTimeline:'Seurannan aikajana',
PM:'Puolenpäivän jälkeen',
AM:'Ennen puoltapäivää',
TodayAt:'tänään kello',
TomorrowAt:'huomenna kello',
Today:'Tänään',
Tomorrow:'Huomenna',
On:'pian',
In:'in',
Soon:'soon',
Min:'min',
Mins:'min',
Items:'TAVARANIMIKKEET',
ViewDetails: 'Näytä tiedot',
DeliveryInformation:'Toimitustiedot',
TrackingHistory:'Seurantahistoria',
StayConnected:'Pysy yhteydessä',
YouHaveAlreadyAttemptedToMakeAPaymentInLastFewMinutes: 'Olet jo yrittänyt maksaa muutama minuutti sitten. Haluatko perua aiemmat maksutapahtumat',
No: 'EI',
yes:'KYLLÄ',
YourPackageWillBeDeliveredBy : 'Pakettisi toimitetaan',
OrderDetails: 'Tilauksen yksityiskohdat',
OrderInformation:'Tilauksen tiedot',
LastAttempted:'Yritetty viimeksi',
DeliveryBy:'Toimittaja',
Pickuppointisupdated:'Noutopiste päivitetään',
MsgToFePageHeader:'Lähetä toimitusohjeet',
MsgToFeSupportingText:'Mitä haluat kertoa kuljettajalle?',
AddYourMsgHere:'Lisää viestisi tähän',
InstructionsSentSuccessfully:'Ohjeiden lähetys onnistui',
SomethingWentWrongPlsTryAgain:'Jokin meni vikaan, yritä uudelleen.',
times:"kertaa",
TrackMovementInRealTime:"Seuraa liikkumista reaaliajassa",
Hours:"hours",
Hour:"hour",
WriteYourFeedbackOptional:"Kirjoita palautteesi (valinnainen)",
ReturnOrder: "Return Order",
TheLinkYouAreTryingToOpenHasExpired: "	Linkki, jota yrität avata, on vanhentunut",
SelectAnAvailableTimeSlot:"Select an available time slot",
January:"JANUARY",
February:"FEBRUARY",
March :"MARCH",
April:"APRIL",
May:"MAY",
June:"JUNE",
July:"JULY",
August:"AUGUST",
September:"SEPTEMBER",
October:"OCTOBER",
November:"NOVEMBER",
December:"DECEMBER",
Monday:"MONDAY",
Tuesday:"TUESDAY",
Wednesday:"WEDNESDAY",
Thursday:"THURSDAY",
Friday:"FRIDAY",
Saturday:"SATURDAY",
Sunday:"SUNDAY",
ViewTimings: "View Timings",
HideTimings: "Hide Timings",
PleaseAnswerAllMandatoryQuestions:"Vastaa kaikkiin pakollisiin kysymyksiin",
HelpLineLink: 'Helpline Link',
ShipmentOf: "Lähetys%/",
OtherShipments: "Muut Lähetykset",
OrderInformation:"Order Information",
}
export default finnishStrings;