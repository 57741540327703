import React from "react";

export const BackIconArrow =() =>{
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7170_87775)">
            <path d="M16.875 10H3.125" stroke="#647788" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.75 4.375L3.125 10L8.75 15.625" stroke="#647788" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_7170_87775">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
};

export const SearchIcon =() =>{
    return(
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7170_87783)">
            <path d="M7.25 12.5C10.1495 12.5 12.5 10.1495 12.5 7.25C12.5 4.35051 10.1495 2 7.25 2C4.35051 2 2 4.35051 2 7.25C2 10.1495 4.35051 12.5 7.25 12.5Z" stroke="#ADADAD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.9624 10.9625L13.9999 14" stroke="#ADADAD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_7170_87783">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
};

export const GpsIcon =() =>{
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7170_87764)">
            <path d="M10.0002 6.66665C8.1585 6.66665 6.66683 8.15831 6.66683 9.99998C6.66683 11.8416 8.1585 13.3333 10.0002 13.3333C11.8418 13.3333 13.3335 11.8416 13.3335 9.99998C13.3335 8.15831 11.8418 6.66665 10.0002 6.66665ZM17.4502 9.16665C17.0668 5.69165 14.3085 2.93331 10.8335 2.54998V1.66665C10.8335 1.20831 10.4585 0.833313 10.0002 0.833313C9.54183 0.833313 9.16683 1.20831 9.16683 1.66665V2.54998C5.69183 2.93331 2.9335 5.69165 2.55016 9.16665H1.66683C1.2085 9.16665 0.833496 9.54165 0.833496 9.99998C0.833496 10.4583 1.2085 10.8333 1.66683 10.8333H2.55016C2.9335 14.3083 5.69183 17.0666 9.16683 17.45V18.3333C9.16683 18.7916 9.54183 19.1666 10.0002 19.1666C10.4585 19.1666 10.8335 18.7916 10.8335 18.3333V17.45C14.3085 17.0666 17.0668 14.3083 17.4502 10.8333H18.3335C18.7918 10.8333 19.1668 10.4583 19.1668 9.99998C19.1668 9.54165 18.7918 9.16665 18.3335 9.16665H17.4502ZM10.0002 15.8333C6.77516 15.8333 4.16683 13.225 4.16683 9.99998C4.16683 6.77498 6.77516 4.16665 10.0002 4.16665C13.2252 4.16665 15.8335 6.77498 15.8335 9.99998C15.8335 13.225 13.2252 15.8333 10.0002 15.8333Z" fill="#647788"/>
            </g>
            <defs>
            <clipPath id="clip0_7170_87764">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
};

export const ListViewIcon =() =>{
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7170_87766)">
            <path d="M7.5 5H16.875" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.5 10H16.875" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.5 15H16.875" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.125 5H4.375" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.125 10H4.375" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.125 15H4.375" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_7170_87766">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
};

export const ClockIcon =() =>{
    return(
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7441_11274)">
            <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#647788" stroke-miterlimit="10"/>
            <path d="M8 4.5V8H11.5" stroke="#647788" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_7441_11274">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
};
export const LocationIcon =() =>{
    return(
        <svg width="25" height="32" viewBox="0 0 25 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.352 0C19.0891 0 24.2712 5.18222 24.2712 11.4009C24.2712 15.8056 18.0525 25.1336 14.4254 30.834C13.389 32.3887 11.3161 32.3887 10.5386 30.834C6.65186 25.1335 0.433594 15.8055 0.433594 11.4009C0.433594 5.1821 5.87424 0 12.352 0Z" fill="#EB4E4E"/>
        </svg>
    );
};

export const DarkSearchIcon =() =>{
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7170_87676)">
            <path d="M9.0625 15.625C12.6869 15.625 15.625 12.6869 15.625 9.0625C15.625 5.43813 12.6869 2.5 9.0625 2.5C5.43813 2.5 2.5 5.43813 2.5 9.0625C2.5 12.6869 5.43813 15.625 9.0625 15.625Z" stroke="#05223D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.7031 13.7031L17.5 17.5" stroke="#05223D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_7170_87676">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
};

export const ViewMapIcon =() =>{
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7170_87734)">
            <path d="M7.5 14.375L2.5 15.625V4.375L7.5 3.125" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.5 16.875L7.5 14.375V3.125L12.5 5.625V16.875Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.5 5.625L17.5 4.375V15.625L12.5 16.875" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_7170_87734">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
};


