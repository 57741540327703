import React, { Component } from 'react'


const storesList=
    [{
        "id": "7fa91335-fd01-4e02-ab85-355940b7fd81-16938",
        "name": "Shop 21",
        "addressLine1": "Noida delhi",
        "pincode": "892323",
        "contactNumber": "973209432",
        "latitude": 28.135906920121494,
        "longitude": 77.9301887512207,
        "timing": "7:30 AM - 6:45 PM , Closed , 2:30 PM - 8:00 PM , 8:00 AM - 3:30 PM ,10:30 AM - 7:30 PM , CLosed",
        "distance": 7.24,
        "city": "Noida",
        "state": "UP",
        "country": "India",
        "carrierCode": "101",
        "position": 1
    },
    {
        "id": "7fa91335-fd01-4e02-ab85-355940b7fd81-1693224273358",
        "name": "Shop 2",
        "addressLine1": "Noida Fareye",
        "pincode": "892323",
        "contactNumber": "973209432",
        "latitude": 28.535906920121494,
        "longitude": 77.3301887512207,
        "timing": "7:30 AM - 6:45 PM , Closed , 2:30 PM - 8:00 PM , 8:00 AM - 3:30 PM ,10:30 AM - 7:30 PM , CLosed",
        "distance": 7.24,
        "city": "Noida",
        "state": "UP",
        "country": "India",
        "carrierCode": "101",
        "position": 2
    }
];

class PudoIframe extends Component{
    
    constructor(props) {
        super(props);
    
        this.state = {
        };
    
      }

    componentDidMount(){
        setTimeout(
            function() {
                this.sendDataToPreviewIframe()
            }.bind(this),
            1500
          );  
        window.addEventListener("message", (e) => {
            if (e.data?.type == "SELECTED_PUDO_DATA") {
                console.log(e.data.payload,"selected store data received from returns")
                // this.setState({ 'pudoData': e.data.payload });
            }
        })  
        
    }
    componentDidUpdate(){
        this.sendDataToPreviewIframe()
    }
    sendDataToPreviewIframe = () => { 
        var previewIframe = document.getElementById('ci-preview-iframe')
        previewIframe.contentWindow.postMessage(
            { 
                type: 'PUDO_DATA', 
                payload: {
                    storesList:storesList,
                    jobLocation:{
                        latitude: 28.435906920121494,
                        longitude: 77.6301887512207,
                        address: "rag",
                    },
                    pincode:this.props.match.params.pincode,
                    getStoresFromDataStore : true
                }, 
            }, 
            '*'
        ) 
        console.log("dataSent")
    }
    render(){
        var returnsLink = "";
        if(this.props.match.params.type=="a"){
            returnsLink ="https://"+this.props.match.params.returnsUrl+"/pudo-iframe/"+this.props.match.params.companyCode+"/"+this.props.match.params.dataStore+"/300";
        }
        else if(this.props.match.params.type=="b"){
            returnsLink ="http://"+this.props.match.params.returnsUrl+"/pudo-iframe/"+this.props.match.params.companyCode+"/"+this.props.match.params.dataStore+"/300";
        }
        else if(this.props.match.params.type=="c"){
            returnsLink ="https://"+this.props.match.params.returnsUrl+"/pudo-iframe/"+this.props.match.params.companyCode+"/"+this.props.match.params.dataStore+"/300";
        }
        else if(this.props.match.params.type=="d"){
            returnsLink ="https://qatest-env-returns.public.fareye.ml"+"/pudo-iframe/"+this.props.match.params.companyCode+"/"+this.props.match.params.dataStore+"/300";
        }
        return(
            <React.Fragment>
                <iframe
                id="ci-preview-iframe"
                height="100%"
                width="100%"
                // style={this.state.deviceType=='MOBILE'?{padding: "0 20%"}:{}}
                // src="http://localhost:9001/d-tracking-preview?"       /*for local*/
                src={returnsLink}
              ></iframe>
              
            </React.Fragment>
        )
    }
}
export default PudoIframe