import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Header from "../../components/Header";
import * as CustomerInteractionAction from "../../CustomerInteractionActions";
import { isEmpty } from "loadsh";
import Checkbox from "@material-ui/core/Checkbox";
import Feedback from "../Feedback/index";
import "./style.css";
import moment from 'moment';

const ColorButton = withStyles((theme) => ({
  root: {
    color: "white",
    width: "100%",
    height: 45,
    backgroundColor: "#18BF9A",
    "&:hover": {
      backgroundColor: "#18BF9A",
    },
  },
}))(Button);

class FeedbackScreen extends React.Component {
  containerConstants = window.containerConstants;

  ratingMap = ["One", "Two", "Three", "Four", "Five"];

  constructor(props) {
    super(props);
    this.state = {
      ratingValue: "",
      checkBoxValue: [],
      comment: "",
      isFeedback:false
    };
  }

  setRating = (event) => {
    this.setState({ ratingValue: event.target.value });
  };

  getRatingValue = (value) => {
    let checked = value === this.state.ratingValue ? true : false;
    return checked;
  };

  getCheckedValues = (value) => {
    if (!isEmpty(this.state.checkBoxValue)) {
      for (let i = 0; i < this.state.checkBoxValue.length; i++) {
        if (this.state.checkBoxValue[i] == value) return true;
      }
    }
    return false;
  };

  updateCheckedValues = (checked, value) => {
    const reason = this.state.checkBoxValue;
    if (checked) {
      reason.push(value);
      this.setState({ checkBoxValue: reason });
    } else {
      let index = -1;
      for (let i = 0; i < this.state.checkBoxValue.length; i++) {
        if (this.state.checkBoxValue[i] == value) {
          index = i;
          break;
        }
      }
      if (index != -1) {
        reason.splice(index, 1);
        this.setState({ checkBoxValue: reason });
      }
    }
  };

  getReasons = () => {
    const feedback = this.props.trackingDetails.feedback
      ? JSON.parse(this.props.trackingDetails.feedback)
      : "";
    let values = [];
    let ratingTemp = parseInt(this.state.ratingValue);
    if (feedback && this.state.ratingValue && feedback.reason) {
      while (ratingTemp < 6) {
        let found = false;
        for (let i = 0; i < feedback.reason.length; i++) {
          if (
            feedback.reason[i] &&
            feedback.reason[i].rating &&
            feedback.reason[i].values &&
            feedback.reason[i].rating == this.ratingMap[ratingTemp - 1]
          ) {
            values = feedback.reason[i].values;
            found = true;
            break;
          }
        }
        ratingTemp = found ? 6 : ratingTemp + 1;
      }
    }

    if (!isEmpty(values)) {
      return (
        <div className="cust-feedback pd20">
          {values.map(
            function (box, index) {
              return (
                <div className="reason-div clearfix" key={index}>
                  <span className="fl-right">
                    <Checkbox
                      checked={this.getCheckedValues(box)}
                      value={box}
                      onChange={(event) => {
                        this.updateCheckedValues(event.target.checked, box);
                      }}
                    />
                  </span>
                  <span className="fl-left">{box}</span>
                </div>
              );
            }.bind(this)
          )}
        </div>
      );
    }
  };

  getRatingDiv = () => {
    return (
      <div className="feedbackScreen">
        <div className="star-rating">
          <input
            type="radio"
            id="1-star"
            name="rating"
            value="5"
            checked={this.getRatingValue("5")}
            onClick={this.setRating}
          />
          <label htmlFor="1-star" className="star">
            ★
          </label>
          <input
            type="radio"
            id="2-stars"
            name="rating"
            value="4"
            checked={this.getRatingValue("4")}
            onClick={this.setRating}
          />
          <label htmlFor="2-stars" className="star">
            ★
          </label>
          <input
            type="radio"
            id="3-stars"
            name="rating"
            value="3"
            checked={this.getRatingValue("3")}
            onClick={this.setRating}
          />
          <label htmlFor="3-stars" className="star">
            ★
          </label>
          <input
            type="radio"
            id="4-stars"
            name="rating"
            value="2"
            checked={this.getRatingValue("2")}
            onClick={this.setRating}
          />
          <label htmlFor="4-stars" className="star">
            ★
          </label>
          <input
            type="radio"
            id="5-stars"
            name="rating"
            value="1"
            checked={this.getRatingValue("1")}
            onClick={this.setRating}
          />
          <label htmlFor="5-stars" className="star">
            ★
          </label>
        </div>
      </div>
    );
  };

  getCommentDiv = () => {
    return (
      <div className="comments-optional pd20">
        <p style={{ fontSize: this.props.fontSize }}>
          {containerConstants.formatString(containerConstants.Comments)}
        </p>
        <textarea
          rows="8"
          value={this.state.comment}
          onChange={(event) => {
            this.setState({ comment: event.target.value });
          }}
        ></textarea>
      </div>
    );
  };

  saveFeedback = async(event) => {
    const feedback = JSON.parse(this.props.trackingDetails.feedback);
    let themeSetting = null;
    if (
      !isEmpty(this.props.trackingDetails.customerInteractionThemeSettingsDTO)
    ) {
      themeSetting = JSON.stringify(
        this.props.trackingDetails.customerInteractionThemeSettingsDTO
      );
    }
    var feedbackObj = {
      ratingValue: this.state.ratingValue,
      checkBoxValue: this.state.checkBoxValue,
      comment: this.state.comment,
      code: feedback.processMasterCode,
      ratingMappingKey: feedback.ratingMappingKey,
      tagMappingKey: feedback.tagMappingKey,
      commentMappingKey : feedback.commentMappingKey,
      referenceNumber: feedback.referenceNumber,
      logo: this.props.trackingDetails.companyLogo,
      customerInteractionThemeSettingsDTO: themeSetting,
      orderId:this.props.trackingDetails.orderId,
      feedback_config: this.props.trackingDetails.awb
    };

    if(!isEmpty(feedback.feedbackSubmissionTimestampKey)) {
      feedbackObj['feedbackSubmissionTimestampKey'] = feedback.feedbackSubmissionTimestampKey;
      feedbackObj['feedbackSubmissionTimestamp'] = moment().format('YYYY-MM-DD HH:mm:ss');
    }

   this.setState({'isFeedback':!this.state.isFeedback});
   let res = await this.props.actions.saveFeedback(this.props.url, feedbackObj,this.props.trackingDetails,true,false,this.props.isReturn);
   this.setState({'isFeedback':!this.state.isFeedback})
  };

  render() {
    if (this.props.feedback) {
      return (
        <Feedback
          primaryBgColor={this.props.primaryBgColor}
          primaryTextColor={this.props.primaryTextColor}
          fontFamily={this.props.fontFamily}
          fontSize={this.props.fontSize}
          secondaryBgColor={this.props.secondaryBgColor}
          secondaryTextColor={this.props.secondaryTextColor}
        />
      );
    }
    if (!this.state.ratingValue || isEmpty(this.state.ratingValue)) {
      this.setState({ ratingValue: this.props.ratings });
    }
    return (
      <React.Fragment>
        <div id="top-area">
          <Header
            heading={this.props.trackingDetails.companyName}
            title={containerConstants.formatString(
              containerConstants.RateYourExperience
            )}
            needHelp={false}
            back={true}
            backScreen={this.props.backScreen}
            primaryBgColor={this.props.primaryBgColor}
            primaryTextColor={
              this.props.primaryTextColor
                ? this.props.primaryTextColor
                : this.props.trackingDetails.fontColor
            }
            fontFamily={
              this.props.fontFamily
                ? this.props.fontFamily
                : this.props.trackingDetails.fontType
            }
            fontSize={
              this.props.fontSize
                ? this.props.fontSize
                : this.props.trackingDetails.fontSize
            }
          />
        </div>
        <div className="feeback-screen-content">
        {this.getRatingDiv()}
        {this.getReasons()}
        {this.getCommentDiv()}
        </div>  
        <div className="feedbackDiv">
          <ColorButton
            disabled = {this.state.isFeedback}
            variant="contained"
            color="primary"
            onClick={this.saveFeedback}
            style={{
              background: this.props.primaryBgColor
                ? this.props.primaryBgColor
                : this.props.trackingDetails.buttonBackGroundColor,
              color: this.props.primaryTextColor
                ? this.state.isFeedback ?'':this.props.primaryTextColor
                : this.props.trackingDetails.buttonFontColor,
            }}
          >
            {containerConstants.formatString(containerConstants.SubmitFeedback)}
          </ColorButton>
        </div>
        </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    trackingDetails: state.customerInteractionReducer.trackingDetails,
    url: state.customerInteractionReducer.url,
    feedback: state.customerInteractionReducer.feedback,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...CustomerInteractionAction }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackScreen);
