import React from "react";
import RightTick from "../images/tick-inside-a-circle.svg";
import { isEmpty } from "loadsh";
import "../style";

class SuccessMessage extends React.Component {
  render() {
    return (
      <div>
        <img
          src={require("../images/tick-inside-a-circle.svg")}
          className="successImg"
        />
        <p className="successMsg">
          {isEmpty(this.props.message)
            ? containerConstants.formatString(
                containerConstants.YourDeliveryPreferenceAreSavedSuccessFully
              )
            : this.props.message}
        </p>
      </div>
    );
  }
}

export default SuccessMessage;
