import React from "react";
import "../style";
import { isEmpty } from "loadsh";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

class AddressDetails extends React.Component {
  getAddress = () => {
    let details = this.props.forms;
    let address = "";
    if (!isEmpty(details.addressLine1)) {
      address = details.addressLine1;
    }
    if (!isEmpty(details.addressLine2)) {
      address = !isEmpty(address)
        ? address + ", " + details.addressLine2
        : details.addressLine2;
    }
    if (!isEmpty(details.landMark)) {
      address = !isEmpty(address)
        ? address + ", " + details.landMark
        : details.landMark;
    }
    if (!isEmpty(details.pinCode)) {
      address = !isEmpty(address)
        ? address + ", " + details.pinCode
        : details.pinCode;
    }

    return address;
  };

  getLocationIntro = () => {
    // let locationType = this.props.trackingDetails.customerInteractionDetailsDTO.addressIntro;
    return containerConstants.formatString(
      containerConstants.ChangeDeliveryLocation
    );
  };

  getContact = () => {
    if (this.props.forms.contact) return this.props.forms.contact;
  };

  getDeliveryTime = () => {
    let locationType = this.props.trackingDetails.customerInteractionDetailsDTO
      .addressIntro;
    let eta;
    if (this.props.trackingDetails.eta) {
      let a = this.props.trackingDetails.eta.substring(0, 2);
      let b = this.props.trackingDetails.eta.substring(3);
      if (a > 12) {
        eta = a - 12 + ":" + b + " PM";
      } else {
        eta = a + ":" + b + " PM";
      }
    }
    return locationType + " by " + eta;
  };

  render() {
    const {
      fontSize,
      fontFamily,
      primaryBgColor,
      primaryTextColor,
    } = this.props;

    return (
      this.getAddress()||this.getContact()?
      <div className="address-detail bg-white">
        <Grid container>
          <Grid item xs={12}>
            {this.getAddress()?
            <div className="flex justify-between align-top">
            <p className="fs12 text-gray ci-add-label">
              {this.props.addressLabel
                ? this.props.addressLabel
                : containerConstants.formatString(
                    containerConstants.DeliveryAt
                  )}
            </p>
            <p className="fs14 pt5 align-right">{this.getAddress()}</p></div>:''}
            {/* {this.props.screen=='liveTracking'? <p className="fs14 mt10 mb15 text-underline" style={{color:'#58c5a3'}}>{this.getLocationIntro()}</p>:null} */}
            <div className="contact-sec clearfix">
              {this.getContact()?
              <Grid item xs={6} className="contact-left  ">
                <div className="flex justify-between">
                  <span className="fs12 text-gray mt15 ci-con-label">
                    {this.props.contactLabel
                      ? this.props.contactLabel
                      : containerConstants.formatString(
                          containerConstants.ContactNo
                        )}
                  </span>
                  <br />
                  <span className="fs14 mb15 align-right max-min-width" style={{ fontFamily: fontFamily }}>
                    <bdi>{this.getContact()}</bdi>
                  </span>
                </div>
              </Grid>:''}
              {this.props.track ? (
                <Grid
                  item
                  xs={6}
                  className="track-map-right"
                  onClick={this.props.backToTrackScreen}
                >
                  <span
                    className="track"
                    style={{
                      borderColor: primaryBgColor,
                      color: primaryTextColor,
                    }}
                  >
                    {containerConstants.formatString(
                      containerConstants.TrackOnMap
                    )}
                  </span>
                </Grid>
              ) : null}
            </div>
          </Grid>
          <Grid item xs={12}>
            {/* {!this.props.track?<Button className="fs12 text-blue" onClick ={this.props.backToTrackScreen}>Track on Map</Button>:null} */}
          </Grid>
        </Grid>
      </div>:''
    );
  }
}

export default AddressDetails;
