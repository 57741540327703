// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .need_help_box{display:none !important;} */



span.sub-categ{
    display: inherit !important;
    
    position: relative;
    top: -9px;
}


button.action-btn:hover,button.action-btn:focus, button.CloseBtn:hover, button.CloseBtn:focus{
        background: none !important;
        border: none !important;
}
.auto-scroll-modal{
    max-height: 400px;
    overflow: scroll;
}`, "",{"version":3,"sources":["webpack://./containers/CustomScreen/style.css"],"names":[],"mappings":"AAAA,6CAA6C;;;;AAI7C;IACI,2BAA2B;;IAE3B,kBAAkB;IAClB,SAAS;AACb;;;AAGA;QACQ,2BAA2B;QAC3B,uBAAuB;AAC/B;AACA;IACI,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":["/* .need_help_box{display:none !important;} */\n\n\n\nspan.sub-categ{\n    display: inherit !important;\n    \n    position: relative;\n    top: -9px;\n}\n\n\nbutton.action-btn:hover,button.action-btn:focus, button.CloseBtn:hover, button.CloseBtn:focus{\n        background: none !important;\n        border: none !important;\n}\n.auto-scroll-modal{\n    max-height: 400px;\n    overflow: scroll;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
