import React from "react";

export const LocationStoreIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_7278_88805)">
      <path d="M9 9.5625C10.2426 9.5625 11.25 8.55514 11.25 7.3125C11.25 6.06986 10.2426 5.0625 9 5.0625C7.75736 5.0625 6.75 6.06986 6.75 7.3125C6.75 8.55514 7.75736 9.5625 9 9.5625Z" stroke="#647788" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M14.625 7.3125C14.625 12.375 9 16.3125 9 16.3125C9 16.3125 3.375 12.375 3.375 7.3125C3.375 5.82066 3.96763 4.38992 5.02252 3.33502C6.07742 2.28013 7.50816 1.6875 9 1.6875C10.4918 1.6875 11.9226 2.28013 12.9775 3.33502C14.0324 4.38992 14.625 5.82066 14.625 7.3125V7.3125Z" stroke="#647788" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_7278_88805">
      <rect width="18" height="18" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  );
};

export const StoreClockIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7278_88810)">
            <path d="M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z" stroke="#647788" stroke-width="1.125" stroke-miterlimit="10"/>
            <path d="M9 5.0625V9H12.9375" stroke="#647788" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_7278_88810">
            <rect width="18" height="18" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
  };

  export const StoreCallIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7278_88823)">
            <path d="M11.2078 2.8125C12.1619 3.06856 13.0319 3.57107 13.7304 4.26959C14.4289 4.96812 14.9315 5.83809 15.1875 6.79219" stroke="#647788" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.6242 4.98535C11.198 5.13775 11.7214 5.43914 12.1412 5.85896C12.561 6.27879 12.8624 6.80214 13.0148 7.37598" stroke="#647788" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.50391 8.77484C7.08273 9.97016 8.04929 10.9342 9.24609 11.51C9.33437 11.5518 9.43202 11.5699 9.52942 11.5625C9.62681 11.5551 9.72061 11.5225 9.80156 11.4678L11.5594 10.2936C11.637 10.2409 11.7268 10.2088 11.8202 10.2002C11.9137 10.1916 12.0078 10.2068 12.0937 10.2444L15.3844 11.6577C15.4968 11.7045 15.5908 11.7869 15.6518 11.8924C15.7128 11.9978 15.7374 12.1204 15.7219 12.2412C15.6176 13.0553 15.2202 13.8034 14.6042 14.3457C13.9882 14.8879 13.1957 15.1872 12.375 15.1873C9.83887 15.1873 7.40661 14.1799 5.61329 12.3865C3.81997 10.5932 2.8125 8.16097 2.8125 5.62484C2.81268 4.80415 3.1119 4.01165 3.65416 3.39562C4.19642 2.7796 4.94456 2.38226 5.75859 2.27796C5.87942 2.26244 6.00199 2.28707 6.10745 2.34806C6.2129 2.40904 6.29538 2.503 6.34219 2.61546L7.75547 5.91312C7.79222 5.99774 7.8076 6.0901 7.80024 6.18206C7.79288 6.27402 7.76302 6.36276 7.71328 6.44046L6.53906 8.2264C6.48679 8.30718 6.4561 8.40002 6.44995 8.49604C6.44379 8.59206 6.46237 8.68805 6.50391 8.77484V8.77484Z" stroke="#647788" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_7278_88823">
            <rect width="18" height="18" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
  };
  
  export const StoreArrowIcon = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7278_88817)">
            <path d="M11.375 5.25L7 9.625L2.625 5.25" stroke="#3F98D8" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_7278_88817">
            <rect width="14" height="14" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
  };

  export const QuickLocationIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7278_87627)">
            <path d="M9 9.5625C10.2426 9.5625 11.25 8.55514 11.25 7.3125C11.25 6.06986 10.2426 5.0625 9 5.0625C7.75736 5.0625 6.75 6.06986 6.75 7.3125C6.75 8.55514 7.75736 9.5625 9 9.5625Z" stroke="#3F98D8" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.625 7.3125C14.625 12.375 9 16.3125 9 16.3125C9 16.3125 3.375 12.375 3.375 7.3125C3.375 5.82066 3.96763 4.38992 5.02252 3.33502C6.07742 2.28013 7.50816 1.6875 9 1.6875C10.4918 1.6875 11.9226 2.28013 12.9775 3.33502C14.0324 4.38992 14.625 5.82066 14.625 7.3125V7.3125Z" stroke="#05223D" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_7278_87627">
            <rect width="18" height="18" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
  };

  export const QuickClockIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7278_87634)">
            <path d="M11.2078 2.8125C12.1619 3.06856 13.0319 3.57107 13.7304 4.26959C14.4289 4.96812 14.9314 5.83809 15.1875 6.79219" stroke="#3F98D8" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.6242 4.98535C11.198 5.13775 11.7214 5.43914 12.1412 5.85896C12.5611 6.27879 12.8624 6.80214 13.0148 7.37598" stroke="#3F98D8" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.50391 8.77484C7.08273 9.97016 8.04929 10.9342 9.24609 11.51C9.33437 11.5518 9.43202 11.5699 9.52942 11.5625C9.62681 11.5551 9.72061 11.5225 9.80156 11.4678L11.5594 10.2936C11.637 10.2409 11.7268 10.2088 11.8202 10.2002C11.9137 10.1916 12.0078 10.2068 12.0937 10.2444L15.3844 11.6577C15.4968 11.7045 15.5908 11.7869 15.6518 11.8924C15.7128 11.9978 15.7374 12.1204 15.7219 12.2412C15.6176 13.0553 15.2202 13.8034 14.6042 14.3457C13.9882 14.8879 13.1957 15.1872 12.375 15.1873C9.83887 15.1873 7.40661 14.1799 5.61329 12.3865C3.81997 10.5932 2.8125 8.16097 2.8125 5.62484C2.81268 4.80415 3.1119 4.01165 3.65416 3.39562C4.19642 2.7796 4.94456 2.38226 5.75859 2.27796C5.87942 2.26244 6.00199 2.28707 6.10745 2.34806C6.2129 2.40904 6.29538 2.503 6.34219 2.61546L7.75547 5.91312C7.79222 5.99774 7.8076 6.0901 7.80024 6.18206C7.79288 6.27402 7.76302 6.36276 7.71328 6.44046L6.53906 8.2264C6.48679 8.30718 6.4561 8.40002 6.44995 8.49604C6.44379 8.59206 6.46237 8.68805 6.50391 8.77484V8.77484Z" stroke="#05223D" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_7278_87634">
            <rect width="18" height="18" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
  };
  
  export const ChangeDeliveryIcon = () => {
    return (
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_7278_87627)">
        <path d="M9 9.5625C10.2426 9.5625 11.25 8.55514 11.25 7.3125C11.25 6.06986 10.2426 5.0625 9 5.0625C7.75736 5.0625 6.75 6.06986 6.75 7.3125C6.75 8.55514 7.75736 9.5625 9 9.5625Z" stroke="#3F98D8" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14.625 7.3125C14.625 12.375 9 16.3125 9 16.3125C9 16.3125 3.375 12.375 3.375 7.3125C3.375 5.82066 3.96763 4.38992 5.02252 3.33502C6.07742 2.28013 7.50816 1.6875 9 1.6875C10.4918 1.6875 11.9226 2.28013 12.9775 3.33502C14.0324 4.38992 14.625 5.82066 14.625 7.3125V7.3125Z" stroke="#05223D" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_7278_87627">
        <rect width="18" height="18" fill="white"/>
        </clipPath>
        </defs>
      </svg>      
    );
  };
  

  export const UpdateContactIcon = () => {
    return (
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_7278_87634)">
        <path d="M11.2078 2.8125C12.1619 3.06856 13.0319 3.57107 13.7304 4.26959C14.4289 4.96812 14.9314 5.83809 15.1875 6.79219" stroke="#3F98D8" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.6242 4.98535C11.198 5.13775 11.7214 5.43914 12.1412 5.85896C12.5611 6.27879 12.8624 6.80214 13.0148 7.37598" stroke="#3F98D8" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.50391 8.77484C7.08273 9.97016 8.04929 10.9342 9.24609 11.51C9.33437 11.5518 9.43202 11.5699 9.52942 11.5625C9.62681 11.5551 9.72061 11.5225 9.80156 11.4678L11.5594 10.2936C11.637 10.2409 11.7268 10.2088 11.8202 10.2002C11.9137 10.1916 12.0078 10.2068 12.0937 10.2444L15.3844 11.6577C15.4968 11.7045 15.5908 11.7869 15.6518 11.8924C15.7128 11.9978 15.7374 12.1204 15.7219 12.2412C15.6176 13.0553 15.2202 13.8034 14.6042 14.3457C13.9882 14.8879 13.1957 15.1872 12.375 15.1873C9.83887 15.1873 7.40661 14.1799 5.61329 12.3865C3.81997 10.5932 2.8125 8.16097 2.8125 5.62484C2.81268 4.80415 3.1119 4.01165 3.65416 3.39562C4.19642 2.7796 4.94456 2.38226 5.75859 2.27796C5.87942 2.26244 6.00199 2.28707 6.10745 2.34806C6.2129 2.40904 6.29538 2.503 6.34219 2.61546L7.75547 5.91312C7.79222 5.99774 7.8076 6.0901 7.80024 6.18206C7.79288 6.27402 7.76302 6.36276 7.71328 6.44046L6.53906 8.2264C6.48679 8.30718 6.4561 8.40002 6.44995 8.49604C6.44379 8.59206 6.46237 8.68805 6.50391 8.77484V8.77484Z" stroke="#05223D" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_7278_87634">
        <rect width="18" height="18" fill="white"/>
        </clipPath>
        </defs>
      </svg>     
    );
  };
  

  export const GetDirectionIcon = () => {
    return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_7319_102767)">
        <path d="M6.02198 6.51735L8.86792 14.7395C8.98933 15.094 9.49441 15.0843 9.60611 14.7249L10.7523 11.0048C10.7696 10.944 10.8024 10.8887 10.8475 10.8444C10.8926 10.8002 10.9485 10.7684 11.0097 10.7523L14.7249 9.60611C15.0843 9.49441 15.094 8.98933 14.7395 8.86792L6.51735 6.02198C6.44826 5.9977 6.37373 5.99344 6.30233 6.00968C6.23093 6.02593 6.16557 6.06202 6.1138 6.1138C6.06202 6.16557 6.02593 6.23093 6.00968 6.30233C5.99344 6.37373 5.9977 6.44826 6.02198 6.51735Z" stroke="#3F98D8" stroke-linecap="round" stroke-linejoin="round"/>
        <circle cx="10" cy="10" r="9.5" stroke="black"/>
        </g>
        <defs>
        <clipPath id="clip0_7319_102767">
        <rect width="20" height="20" fill="white"/>
        </clipPath>
        </defs>
      </svg>     
    );
  };

  

