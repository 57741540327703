const footerLinks = {
    
    nl:{
        label0:"DHL Servicepunt",
        link0:"https://www.dhl.com/global-en/home/our-divisions/ecommerce-solutions/europe/dhl-finder.html",
        label1:"Retourbeleid",
        link1:"https://help.otrium.nl/nl/articles/98-was-sind-unsere-rueckgaberichtlinien"
    },
    en:{
        label0:"DHL Service Point",
        link0:"https://www.dhl.com/global-en/home/our-divisions/ecommerce-solutions/europe/dhl-finder.html",
        label1:"Return Policy",
        link1:"https://help.otrium.nl/en/articles/98-was-sind-unsere-rueckgaberichtlinien"
    },
    de:{
        label0:"DHL Service Point",
        link0:"https://www.dhl.com/global-en/home/our-divisions/ecommerce-solutions/europe/dhl-finder.html",
        label1:"Rückgaberecht",
        link1:"https://help.otrium.nl/de/articles/98-was-sind-unsere-rueckgaberichtlinien"
    },
    fr:{
        label0:"Point de service DHL",
        link0:"https://www.dhl.com/global-en/home/our-divisions/ecommerce-solutions/europe/dhl-finder.html",
        label1:"Politique de retour",
        link1:"https://help.otrium.nl/fr/articles/98-was-sind-unsere-rueckgaberichtlinien"
    }
   

    }
    export {footerLinks};
    