import React from "react";
import ProcessUpdateForm from "../../ProcessUpdateForm";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';


class CheckIn extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="check-in-form puf-control">
                <ProcessUpdateForm
                    {...this.props}
                    customBackIcon={() => {
                        return (<ChevronLeftIcon className="cb-text-primary" fontSize="large" />)
                    }}
                    formIndex={this.props.formIndex}
                    back={this.props.isBackAllowed}
                    backScreen={this.props.backScreenHandler}
                    hideSubHeader={true}
                />
            </div>
        );
    }
}

export default CheckIn;