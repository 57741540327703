import { setState } from "../../../Utils/setState";
import curbsidePickupService from "../services/CurbsidePickupService";
import { curbsidePickupActions } from "./curbsidePickupActionConstants";
import  CONSTANTS  from '../../../Utils/Constants';
import { processUpdateFormService } from "../../../services/ProcessUpdateFormService";


export function setCurbsidePageFlow(curbsidePageFlow) {
    return async (dispatch) => {
        try {
            dispatch(setState(curbsidePickupActions.SET_CURBSIDE_PAGE_FLOW, curbsidePageFlow))
        } catch (e) {
            console.log(e)
        }
    }
}

export function setTimeSlotPageData(timeSlotPageData) {
    return async (dispatch) => {
        try {
            dispatch(setState(curbsidePickupActions.SET_TIME_SLOT_PAGE_DATA, timeSlotPageData))
        } catch (e) {
            console.log(e)
        }
    }
}

export function setCurbsidePickupUrl(url) {
    return async (dispatch) => {
        try {
            dispatch(setState(curbsidePickupActions.SET_CURBSIDE_PICKUP_URL, url))
        } catch (e) {
            console.log(e)
        }
    }
}

export function setCurbsidePickupData(curbsidePickupUrl) {
    return async (dispatch) => {
        try {
            let responseData = await curbsidePickupService.getCurbsidePickupDetail(curbsidePickupUrl);
            console.log("response Data",responseData);
            if (responseData) {
                dispatch(setState(curbsidePickupActions.SET_CURBSIDE_PICKUP_DATA, responseData));
                let response = responseData.customerInteractionServiceDTO;
                response.type = CONSTANTS.CURBSIDE_PICKUP;
                let url = curbsidePickupUrl;
                let check={},alreadyUpdated = false;
                const nextJobTransactionId = null;
                let holidayMaster;
                console.log("url curbside",url);
                dispatch(setState(CONSTANTS.GET_TRACKING_DETAILS,{response,url,check,alreadyUpdated,holidayMaster, nextJobTransactionId}))
                dispatch(setState(curbsidePickupActions.SET_CURBSIDE_PAGE_FLOW, responseData.screen));
                let favIconUrl = response.customerInteractionThemeSettingsDTO && response.customerInteractionThemeSettingsDTO.favIcon;
                if (favIconUrl) processUpdateFormService.setFavIconUrl(favIconUrl);

            }
        } catch (e) {
            console.log(e)
        }
    }
}