import React from "react";
import Header from "../../../components/Header";
import GettingYourOrder from "../components/GettingYourOrder";
import CurbsidePostCheckInOrderConfirmInfo from "../components/CurbsidePostCheckInOrderConfirmInfo";
import MarketingAsset from "../components/MarketingAsset";
import SocialMedia from "../../../components/SocialMedia";
import CurbsidePickupConstants from "../constants/CurbsidePickupConstants";
import { Modal } from "@material-ui/core";
import OrderDetails from "./OrderDetails";
import CloseIcon from '@material-ui/icons/Close';
import CurbsideNeedHelpDrawer from "../components/CurbsideNeedHelpDrawer";
import { isEmpty } from "lodash";

class PostCheckIn extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showNeedHelpDrawer: false
        }
    }

    setHelpModel = (show) => {
        this.setState({ showNeedHelpDrawer: show });
    }

    getNeedHelp = () => {
        let helpSectionText, callSupport, emailSupport;
        if (this.props.trackingDetails && this.props.trackingDetails.type === "CURBSIDE_PICKUP" && this.props.trackingDetails.customerInteractionThemeSettingsDTO && !isEmpty(this.props.trackingDetails.customerInteractionThemeSettingsDTO)) {
            helpSectionText = this.props.trackingDetails.customerInteractionThemeSettingsDTO.helpSectionText;
            helpSectionText = helpSectionText ? helpSectionText : containerConstants.formatString(containerConstants.NeedHelp);
            callSupport = this.props.trackingDetails.customerInteractionThemeSettingsDTO.supportContactNumber;
            emailSupport = this.props.trackingDetails.customerInteractionThemeSettingsDTO.supportEmail;
        }

        if (callSupport || emailSupport) {
            return {
                helpSectionText,
                callSupport,
                emailSupport
            }
        }
        return undefined;
    }

    changeScreenToPostCheckIn = () => {
        this.props.changeScreen(CurbsidePickupConstants.curbsidePageFlowTypes.POST_CHECK_IN, null);
    }

    changeScreenToCheckIn = () => {
        this.props.changeScreen(CurbsidePickupConstants.curbsidePageFlowTypes.CHECK_IN, this.changeScreenToPostCheckIn);
    }

    changeScreenToOrderDetails = () => {
        this.props.changeScreen(CurbsidePickupConstants.curbsidePageFlowTypes.ORDER_DETAILS, this.changeScreenToPostCheckIn);
    }

    render() {
        const needHelp = this.getNeedHelp();
        if (this.props.deviceType == 'mobile') {
            return (
                <PostCheckInMobileView
                    {...this.props}
                    changeScreenToPostCheckIn={this.changeScreenToPostCheckIn}
                    changeScreenToCheckIn={this.changeScreenToCheckIn}
                    changeScreenToOrderDetails={this.changeScreenToOrderDetails}
                    showNeedHelpDrawer={this.state.showNeedHelpDrawer}
                    setHelpModel={this.setHelpModel}
                    showNeedHelp={needHelp ? true : false}
                    needHelp={needHelp}
                />
            )
        }
        else {
            return (
                <PostCheckInWebView
                    {...this.props}
                    changeScreenToPostCheckIn={this.changeScreenToPostCheckIn}
                    changeScreenToCheckIn={this.changeScreenToCheckIn}
                    changeScreenToOrderDetails={this.changeScreenToOrderDetails}
                    showNeedHelpDrawer={this.state.showNeedHelpDrawer}
                    setHelpModel={this.setHelpModel}
                    showNeedHelp={needHelp ? true : false}
                    needHelp={needHelp}
                />
            )
        }
    }
}

class PostCheckInMobileView extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Header
                    logo={this.props.companyLogo}
                    curbsidePickup={true}
                    primaryBgColor={"primaryBgColor"}
                    primaryTextColor={"primaryTextColor"}
                    fontSize={"fontSize"}
                    fontFamily={"fontFamily"}
                    deviceType={true}
                    needHelp={this.props.showNeedHelp}
                    setHelpModel={() => this.props.setHelpModel(true)}
                    helpSectionText={this.props.needHelp && this.props.needHelp.helpSectionText}
                />

                {this.props.showNeedHelp &&
                    <CurbsideNeedHelpDrawer
                        {...this.props}
                    />
                }

                <div className="curb-side-container">
                    <div className="curbside-postcheckin">
                        <div className="curb-side-order-notification-wrap">
                            <GettingYourOrder
                                primaryBgColor={this.props.primaryBgColor}
                                secondaryBgColor={this.props.secondaryBgColor}
                                curbsidePickupData={this.props.curbsidePickupData}
                            ></GettingYourOrder>
                        </div>
                        <div className="mtop-3 mb30">
                            <CurbsidePostCheckInOrderConfirmInfo
                                primaryBgColor={this.props.primaryBgColor}
                                trackingDetails={this.props.trackingDetails}
                                curbsidePickupData={this.props.curbsidePickupData}
                                curbsidePickupJson={this.props.curbsidePickupJson}
                                onCurbsidePageFlow={this.props.changeScreenToOrderDetails}
                                setCurbSideForm={this.props.changeScreenToCheckIn}
                                orderCount={this.props.orderCount}
                            ></CurbsidePostCheckInOrderConfirmInfo>
                        </div>
                    </div>
                    {
                        this.props.curbsidePickupData.otherDetailsData &&
                        this.props.curbsidePickupData.otherDetailsData.marketingAssets &&
                        this.props.marketingAssetsData &&
                        <MarketingAsset
                            {...this.props}
                            marketingAssetsData={this.props.marketingAssetsData}
                        />
                    }

                </div>

                {this.props.layoutScreen && !this.props.layoutScreen.hideSocialMedia &&
                    <SocialMedia
                        curbsidePickup={true}
                        layoutScreen={this.props.layoutScreen}
                    />
                }
            </div>
        )
    }
}

class PostCheckInWebView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            orderDetailsModalOpen: false
        }
    }

    getOrderDetailsModalView = () => {
        return (
            <Modal
                open={this.state.orderDetailsModalOpen}
                onClose={() => {
                    this.setState({ orderDetailsModalOpen: false })
                }}
                className="order-details-modal"
            >
                <OrderDetails
                    {...this.props}
                    backScreenHandler={() => {
                        this.setState({ orderDetailsModalOpen: false })
                    }}
                    customBackIcon={() => {
                        return (<CloseIcon fontSize="large" />)
                    }}

                />

            </Modal>
        )
    }

    render() {
        return (
            <div>
                <Header
                    logo={this.props.companyLogo}
                    curbsidePickup={true}
                    primaryBgColor={"primaryBgColor"}
                    primaryTextColor={"primaryTextColor"}
                    fontSize={"fontSize"}
                    fontFamily={"fontFamily"}
                    deviceType={false}
                    needHelp={this.props.showNeedHelp}
                    setHelpModel={() => this.props.setHelpModel(true)}
                    helpSectionText={this.props.needHelp && this.props.needHelp.helpSectionText}
                />

                {this.props.showNeedHelp &&
                    <CurbsideNeedHelpDrawer
                        {...this.props}
                    />
                }

                <div className="curbside-web-wrapper">
                    {this.state.orderDetailsModalOpen && this.getOrderDetailsModalView()}
                    <div className="curbside-web-container">
                        <div className="curbside-web-outer-wrap">
                            <div className="curbside-web-middle-header">
                                <div className="curbside-web-left-sidebar">
                                    <div className="post-check-order-confirm-container">
                                        <GettingYourOrder
                                            primaryBgColor={this.props.primaryBgColor}
                                            secondaryBgColor={this.props.secondaryBgColor}
                                            curbsidePickupData={this.props.curbsidePickupData}
                                        ></GettingYourOrder>
                                    </div>

                                </div>

                                <div className="curbside-web-right-sidebar">
                                    <div>
                                        <CurbsidePostCheckInOrderConfirmInfo
                                            primaryBgColor={this.props.primaryBgColor}
                                            curbsidePickupData={this.props.curbsidePickupData}
                                            curbsidePickupJson={this.props.curbsidePickupJson}
                                            trackingDetails={this.props.trackingDetails}
                                            onCurbsidePageFlow={() => this.setState({ orderDetailsModalOpen: true })}
                                            setCurbSideForm={this.props.changeScreenToCheckIn}
                                            orderCount={this.props.orderCount}
                                        ></CurbsidePostCheckInOrderConfirmInfo>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.props.curbsidePickupData.otherDetailsData &&
                            this.props.curbsidePickupData.otherDetailsData.marketingAssets &&
                            this.props.marketingAssetsData &&
                            <MarketingAsset
                                {...this.props}
                                marketingAssetsData={this.props.marketingAssetsData}
                            />
                        }

                    </div>
                          
                    {this.props.layoutScreen && !this.props.layoutScreen.hideSocialMedia &&
                        <SocialMedia
                            curbsidePickup={true}
                            layoutScreen={this.props.layoutScreen}
                        />
                    }

                </div>
            </div>
        )
    }
}

export default PostCheckIn;