const solvakStrings = {
YourShipmentHasBeenDelivered: 'Vaša zásielka bola doručená', 
DeliveredBy: 'Doručil', 
RateYourExperience: 'Ohodnoťte svoje skúsenosti', 
ThankYouForYourValuableFeedback: 'Ďakujeme za cennú spätnú väzbu', 
YourFeedbackWillContinuallyHelpUsImproveOurServices: 'Vaša spätná väzba nám neustále pomáha zlepšovať naše služby', 
DeliveryFailed: 'Doručenie sa nepodarilo', 
AddDeliveryPreferences: 'Pridať preferencie doručenia', 
YourDeliveryPreferenceAreSavedSuccessFully: 'Vaše preferencie doručenia boli úspešne uložené', 
WeAreUnableToTrackYourShipmentRightNow: 'Momentálne nedokážeme sledovať vašu zásielku', 
PleaseUpdateLocationInsideCircle: 'Aktualizujte polohu v krúžku', 
Ok: 'Ok', 
Update: 'Aktualizovať', 
PickCurrentLocation: 'Vyberte aktuálnu polohu', 
SearchFor: 'Hľadať', 
ThisSchedulingLinkHasExpired: 'Platnosť tohto plánovacieho odkazu vypršala', 
WeWillShareANewLinkWithYouShortly: 'Čoskoro vám pošleme nový odkaz', 
UhHo: 'Oh, nie!', 
NeedHelp: 'POTREBUJETE POMOC?', 
CallSupport: 'Volajte podporu', 
EmailSupport: 'Napíšte e-mail podpore', 
DeliveryAt: 'Doručenie o', 
ContactNo: 'Kontakt č.', 
TrackOnMap: 'Sledovať na mape', 
MessageToExecutive: 'Správa výkonnému pracovníkovi', 
Cancel: 'Zrušiť', 
Send: 'Poslať', 
Executive: 'Výkonný pracovník', 
Comments: 'Komentáre (voliteľné)', 
SubmitFeedback: 'ODOSLAŤ SPÄTNÚ VÄZBU', 
TrackingNo: 'Sledovacie č.', 
Details: 'Podrobnosti', 
Characters: 'Znaky', 
Updating: 'aktualizuje sa…', 
OopsSomethingWentWrongPleaseTryAfterSomeTime: 'Hups! Niečo sa stalo, skúste to neskôr', 
NotListedAboveSendACustomMessage: 'Neuvedené vyššie, pošlite vlastnú správu', 
ChangeDeliveryLocation: 'Zmeniť miesto dodania', 
AttemptedBy: 'Pokus o doručenie', 
DeliveredTo: 'Doručené komu', 
SendMessage: 'POSLAŤ SPRÁVU', 
VIEW_DETAILS: 'Pozrieť podrobnosti', 
SAVE_LOCATION: 'ULOŽIŤ POLOHU', 
LOCATION: 'poloha', 
SEARCH_ADDRESS: 'Hľadať adresu', 
OpenHours: 'Otváracie hodiny', 
Note: 'Poznámka', 
Address: 'Adresa', 
Nearest: 'Najbližšia', 
ContactNumber: 'Kontaktné číslo', 
FAQS: 'Často kladené otázky', 
HowManyTimesCanIChangeMyPickUpPoint: 'Koľkokrát môžem zmeniť miesto vyzdvihnutia?', 
YouCanChangeItOnly: 'Môžete ho zmeniť len', 
GoBack: 'Vrátiť sa', 
SetAsPickupStore: 'Nastaviť ako obchod na vyzdvihnutie', 
MapView: 'Pohľad na mapu', 
SearchForPickupPointNearYou: 'Hľadať miesto vyzdvihnutia vo vašej blízkosti', 
Arriving: 'Prichádza', 
LiveTrackingWillStart: 'Začne sa sledovanie v reálnom čase', 
DriverTrackerWillGetActivatedOnceTheyAreOnWay: 'Zariadenie na sledovanie vodiča sa aktivuje, keď bude na ceste', 
TrackerTimeline: 'Časová os sledovacieho zariadenia', 
PM: 'poobede', 
AM: 'doobeda', 
TodayAt: 'dnes o', 
TomorrowAt: 'zajtra o', 
Today: 'Dnes', 
Tomorrow: 'Zajtra', 
On: 'dňa', 
In: 'v', 
Soon: 'čoskoro', 
Min: 'min.', 
Mins: 'minúty', 
Items: 'POLOŽKY', 
ViewDetails: 'Pozrieť podrobnosti', 
DeliveryInformation: 'Informácie o doručení', 
TrackingHistory: 'História sledovania', 
StayConnected: 'Zostaňte pripojený', 
YouHaveAlreadyAttemptedToMakeAPaymentInLastFewMinutes: 'Už ste sa pokúsili zaplatiť pred niekoľkými minútami. Chcete zrušiť predchádzajúce transakcie', 
No: 'NIE', 
yes: 'ÁNO', 
YourPackageWillBeDeliveredBy: 'Vašu zásielku dodá', 
OrderDetails: 'Podrobnosti o objednávke', 
OrderInformation: 'Informácie o objednávke', 
Thankyou: 'Ďakujeme', 
LastAttempted: 'Posledný pokus', 
DeliveryBy: 'Dodanie', 
MsgToFePageHeader: 'Poslať pokyny na doručenie', 
MsgToFeSupportingText: 'Chcete informovať vodiča?', 
AddYourMsgHere: 'Pridajte svoju správu sem', 
InstructionsSentSuccessfully: 'Pokyny sú úspešne odoslané', 
SomethingWentWrongPlsTryAgain: 'Niečo sa stalo, skúste to neskôr.', 
times: 'krát', 
TrackMovementInRealTime: 'Sledovať pohyb v reálnom čase', 
SetAsPickupStore: 'Nastaviť ako obchod na vyzdvihnutie', 
MapView: 'Pohľad na mapu', 
DeliveredAt: 'Doručené o', 
LastAttempted: 'Posledný pokus', 
Tomorrow: 'Zajtra',
WriteYourFeedbackOptional:"Write your feedback (Optional)",
ReturnOrder: "Return Order" ,
TheLinkYouAreTryingToOpenHasExpired: "Platnosť odkazu, ktorý sa pokúšate otvoriť, vypršala",
SelectAnAvailableTimeSlot:"Select an available time slot",
January:"JANUARY",
February:"FEBRUARY",
March :"MARCH",
April:"APRIL",
May:"MAY",
June:"JUNE",
July:"JULY",
August:"AUGUST",
September:"SEPTEMBER",
October:"OCTOBER",
November:"NOVEMBER",
December:"DECEMBER",
Monday:"MONDAY",
Tuesday:"TUESDAY",
Wednesday:"WEDNESDAY",
Thursday:"THURSDAY",
Friday:"FRIDAY",
Saturday:"SATURDAY",
Sunday:"SUNDAY",
ViewTimings: "View Timings",
HideTimings: "Hide Timings",
PleaseAnswerAllMandatoryQuestions:"Please answer all mandatory questions",
HelpLineLink: 'Helpline Link',
ShipmentOf: "Zásielka%z",
OtherShipments: "Iné Zásielky",
OrderInformation:"Order Information",
}
export default solvakStrings;