import React from "react";
import PickupSchedule from "./PickupSchedule";
import CurbsidePickupConstants from "../constants/CurbsidePickupConstants";
import PreCheckIn from "./PreCheckIn";
import { processUpdateFormService } from "../../../services/ProcessUpdateFormService";
import OrderDetails from "./OrderDetails";
import CheckIn from "./CheckIn";
import PostCheckIn from "./PostCheckIn";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import FeedbackV3 from "../../FeedbackV3";
import CurbsideFeedback from "./CurbsideFeedback";
import { isEmpty } from "lodash";

class MainScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            marketingAssetsData: null,
            backScreenHandler: null
        }
    }

    async componentDidMount() {
        if (
            this.props.curbsidePickupData.otherDetailsData &&
            this.props.curbsidePickupData.otherDetailsData.marketingAssets
        ) {
            let marketingAssetsData = await processUpdateFormService.getMarketingAssets(this.props.curbsidePickupUrl, true);
            this.setState({ marketingAssetsData });
        }
    }

    renderPickupScheduleScreen = (backScreenHandler) => {
        return (
            <PickupSchedule
                {...this.props}
                formIndex={0}
                isMobileType = {this.props.deviceType=="mobile"?true:false}
                isBackAllowed={backScreenHandler ? true : false}
                backScreenHandler={backScreenHandler}
                isCurbsidePickup={true}
            />
        )
    }

    renderPreCheckInScreen = (backScreenHandler) => {
        return (
            <PreCheckIn
                {...this.props}
                marketingAssetsData={this.state.marketingAssetsData}
                changeScreen={this.changeScreen}
                layoutScreen={this.getSocialMediaLayoutData()}
                orderCount={this.getOrderCount()}
            />
        )
    }

    renderOrderDetailsScreen = (backScreenHandler) => {
        return (
            <OrderDetails
                {...this.props}
                backScreenHandler={backScreenHandler}
                customBackIcon={() => {
                    return (<ChevronLeftIcon className="cb-text-primary" fontSize="large" />)
                }}
            />
        )
    }

    renderCheckInScreen = (backScreenHandler) => {
        return (
            <CheckIn
                {...this.props}
                formIndex={1}
                isMobileType = {this.props.deviceType=="mobile" ? true:false}
                isBackAllowed={backScreenHandler ? true : false}
                backScreenHandler={backScreenHandler}
                isCurbsidePickup={true}
            />
        )
    }

    renderPostCheckIn = (backScreenHandler) => {
        return (
            <PostCheckIn
                {...this.props}
                marketingAssetsData={this.state.marketingAssetsData}
                changeScreen={this.changeScreen}
                layoutScreen={this.getSocialMediaLayoutData()}
                orderCount={this.getOrderCount()}
            />
        )
    }

    renderFeedback = (backScreenHandler) => {
        return (
            <CurbsideFeedback
                {...this.props}
                marketingAssetsData={this.state.marketingAssetsData}
                changeScreen={this.changeScreen}
                layoutScreen={this.getSocialMediaLayoutData()}
            />
        )
    }

    changeScreen = (screenType, backScreenHandler) => {
        this.props.changeScreen(screenType);
        this.setState({ backScreenHandler });
    }

    renderScreen = (screenType, backScreenHandler) => {
        switch (screenType) {
            case CurbsidePickupConstants.curbsidePageFlowTypes.TIME_SLOT:
                return this.renderPickupScheduleScreen(backScreenHandler);
            case CurbsidePickupConstants.curbsidePageFlowTypes.PRE_CHECK_IN:
                return this.renderPreCheckInScreen(backScreenHandler);
            case CurbsidePickupConstants.curbsidePageFlowTypes.ORDER_DETAILS:
                return this.renderOrderDetailsScreen(backScreenHandler);
            case CurbsidePickupConstants.curbsidePageFlowTypes.CHECK_IN:
                return this.renderCheckInScreen(backScreenHandler);
            case CurbsidePickupConstants.curbsidePageFlowTypes.POST_CHECK_IN:
                return this.renderPostCheckIn(backScreenHandler);
            case CurbsidePickupConstants.curbsidePageFlowTypes.FEEDBACK:
                return this.renderFeedback(backScreenHandler);
        }
    }

    getSocialMediaLayoutData = () => {
        let curbsidePickupData = this.props.curbsidePickupData;
        if (curbsidePickupData &&
            curbsidePickupData.customerInteractionServiceDTO &&
            curbsidePickupData.customerInteractionServiceDTO.customerInteractionThemeSettingsDTO) {
            let themeData = curbsidePickupData.customerInteractionServiceDTO.customerInteractionThemeSettingsDTO;
            return {
                facebook: themeData.facebook,
                twitter: themeData.twitter,
                youtube: themeData.youtube,
                insta: themeData.instagram,
                tiktok: themeData.tiktok,
                linkedIn: themeData.linkedIn,
                pinterest: themeData.pinterest,
                hideSocialMedia: !themeData.facebook && !themeData.twitter && !themeData.youtube && !themeData.instagram && !themeData.tiktok && !themeData.linkedIn && !themeData.pinterest
            }
        }
    }

    getOrderCount = () => {
        let count;
        if (this.props.curbsidePickupData && this.props.curbsidePickupData.orderDetailsData && this.props.curbsidePickupData.orderDetailsData.orderDetailAttributeList && !isEmpty(this.props.curbsidePickupData.orderDetailsData.orderDetailAttributeList)) {
            const childAttrLengths = this.props.curbsidePickupData.orderDetailsData.orderDetailAttributeList
                .filter(order => order.childAttributeArray && !isEmpty(order.childAttributeArray))
                .map(order => order.childAttributeArray.length);
            if (childAttrLengths && childAttrLengths.length > 0) {
                count = childAttrLengths[0];
            }
        }
        return count;
    }

    render() {
        return this.renderScreen(this.props.screenType, this.state.backScreenHandler);
    }
}

export default MainScreen;