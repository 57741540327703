import { Record } from 'immutable';
const InitialState = Record({
    trackingDetails:{},
    url:'',
    slotList:[],
    check:{},
    alreadyUpdated:false,
    holidayMaster:'',
    alertMessage:'',
    notFound:false,
    updateAddress:false,
    updateAddressDetail:{},
    circleCenter:{
        set:'',
        lat:'',
        lng:'',
        radius:''
    },
    jobUpdated:false,
    feedback:false,
    dynamicSlots:[],
    paymentStatus:"",
    tripEta:{},
    initiatedTime:-1,
    holidayMasterInfo:{},
    previousJobsMessage: null,
    previousJobCount:-1,             // it will update to either 0 or more number after first API response
    nextJobTransactionId: null,
    config3PLDataMap: {},
    processUrl: "",
    availableDatesFromCoordinator:{},
    updateEta:'',
    isJobRemoved:false,
    isFeedbackInfoFetch:false,
    feedbackInfo:{},
    availableDatesFetchingLoader:false,
    selectedSlotFenceId:0,
    autoSavePufFlag:0,
    holidayMasterApiFlag:0,
    shipmentTracking:false,
});
export default new InitialState();