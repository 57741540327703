import initialState from '../../CustomerInteractionInitialStates';
import  CONSTANTS   from '../../Utils/Constants'
export default function customerInteractionReducer(state = initialState, action) {
  switch(action.type) {
      case CONSTANTS.GET_TRACKING_DETAILS:
          return state.set('trackingDetails',action.payload.response)
                      .set('url',action.payload.url)
                      .set('check',action.payload.check)
                      .set('alreadyUpdated',action.payload.alreadyUpdated)
                      .set('holidayMaster',action.payload.holidayMaster)
                      .set('nextJobTransactionId', action.payload.nextJobTransactionId)
                      .set('alertMessage',"")
                      .set("config3PLDataMap",action.payload.config3PLDataMap)
                      .set("processUrl",action.payload.processUrl);   

      case CONSTANTS.SET_AVAILABLE_DATES_FROM_COORDINATOR:
           return state.set('availableDatesFromCoordinator',action.payload);   

      case CONSTANTS.UPDATE_TRACKING_DETAILS:
           return state.set('trackingDetails',action.payload)
                  //      .set('updateAddress',action.payload.isUpdateAdress)
                  //      .set('updateAddressDetail',action.payload.updateAddressDetails)
      case CONSTANTS.SET_SLOT_LIST:
           return state.set('slotList',action.payload.res)  
                      .set('alertMessage',action.payload.alertMessage)   
      case CONSTANTS.SET_CHECKBOX:
           return state.set('check',action.payload)  
      case CONSTANTS.ALREADY_UPDATED:
           return state.set('alreadyUpdated',action.payload) 
      case CONSTANTS.NOT_FOUND:
           return state.set('notFound',action.payload) 
      case CONSTANTS.SET_CIRCLE_CENTER:
           return state.set('circleCenter',action.payload) 
      case CONSTANTS.SET_JOB_UPDATE:
           return state.set('jobUpdated',action.payload) 
      case CONSTANTS.SET_FEEDBACK:
           return state.set('feedback',action.payload.feedback) 
                         .set('trackingDetails',action.payload.details)
      case CONSTANTS.SET_DYNAMIC_SLOTS:
           return state.set('dynamicSlots',action.payload.res)
                         .set('alertMessage',action.payload.alertMessage)
     case CONSTANTS.SET_DYNAMIC_SLOTS_DATA:
          return state.set('dynamicSlots',action.payload.availableSlots)
                         .set('alertMessage',action.payload.alertMessage)
                         .set('selectedSlotFenceId',action.payload.selectedSlotFenceId)
     case CONSTANTS.SET_PAYMENT_STATUS:
            return state.set('paymentStatus',action.payload)     
     case CONSTANTS.SET_TRIP_ETA:
               return state.set('tripEta',action.payload)                                
     case CONSTANTS.SET_INITIATED_TIME:
            return state.set('initiatedTime',action.payload.time) 
                        .set('alertMessage',action.payload.alertMessage)

     case CONSTANTS.SET_HOLIDAY_MASTER:
          return state.set('holidayMasterInfo', action.payload);

     case CONSTANTS.SET_PREVIOUS_JOBS_MESSAGE:
          return state.set("previousJobsMessage", action.payload.displayMessage)
                      .set("previousJobCount",action.payload.previousJobCount)

     case CONSTANTS.SET_NEXT_JOB_TRANSACTION_ID:
          return state.set('nextJobTransactionId', action.payload);
     case CONSTANTS.SET_UPDATE_ETA:
          return state.set('updateEta', action.payload);   
     case CONSTANTS.SET_JOB_REMOVED:
          return state.set('isJobRemoved',action.payload)
     case CONSTANTS.SET_FEEDBACK_INFO:
               return state.set('feedbackInfo',action.payload.feedbackInfo)
                           .set('isFeedbackInfoFetch',action.payload.isFeedbackInfoFetch)            
     case CONSTANTS.SET_FORM_LOADING:
          return state.set('availableDatesFetchingLoader',action.payload); 
     case CONSTANTS.INCREASE_AUTO_SAVE_FLAG_COUNT:
          return state.set('autoSavePufFlag',action.payload);                
     case CONSTANTS.INCREASE_HOLIDAY_MASTER_API_COUNT:
          return state.set('holidayMasterApiFlag',action.payload);
     case CONSTANTS.UPDATE_THEME_FOR_CI_DIY:
          return state.set('trackingDetails',action.payload);
     case CONSTANTS.SET_SHIPMENT_TRACKING:
          return state.set('shipmentTracking',true);     
     default:
           return state;
  }
}