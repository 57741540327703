import React from "react";
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import DirectionsOutlinedIcon from '@material-ui/icons/DirectionsOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import { curbsidePageFlowTypes } from "../constants/CurbsidePickupConstants";
import { isEmpty, cloneDeep } from "loadsh";

export default class CurbsideOrderConfirmInfo extends React.Component {

    getDayWord = () => {
        if (this.props.curbsidePickupData.preCheckInData.pickupScheduleDateTimeAttribute) {
            let date = new Date((this.props.curbsidePickupData.preCheckInData.pickupScheduleDateTimeAttribute).replace(/-/g, "/"));
            console.log("date", date, date.getDate());
            let currentDate = new Date();
            if (date.getDate() === currentDate.getDate()) {
                return 'Today';
            } else if (date.getDate() === currentDate.getDate() + 1) {
                return "Tommorow";
            }
            return date.toLocaleDateString();
        }

    }

    getStoreAddress = () => {
        let storeDetailsData = this.props.curbsidePickupData.storeDetailsData;
        let storeAddress = "";
        if (storeDetailsData) {
            if (storeDetailsData.addressLine1) {
                storeAddress = storeDetailsData.addressLine1;
            }
            if (storeDetailsData.addressLine2) {
                storeAddress = (!isEmpty(storeAddress) ? storeAddress + ", " : "") + storeDetailsData.addressLine2;
            }
            if (storeDetailsData.pinCode) {
                storeAddress = (!isEmpty(storeAddress) ? storeAddress + ", " : "") + storeDetailsData.pinCode;
            }
            return storeAddress;
        } else {
            return "";
        }
    }

    renderPickupScheduleOtherAttributes = () => {
        let array = this.props.curbsidePickupData.preCheckInData.pickupScheduleAttributeArray;
        let data = [];
        if (array) {
            array.map((attribute) => {
                if (attribute.value) {
                    data.length == 0 ? data.push(", " + attribute.value) : data.push(" " + attribute.value);
                }
            });
        }
        return data;
    }

    getPickupDateTime = () => {
        let dayWord = this.getDayWord();
        let otherAttribute = this.renderPickupScheduleOtherAttributes();
        let pickupDateTime;
        if (dayWord && otherAttribute && otherAttribute.length != 0) {
            pickupDateTime = dayWord + otherAttribute.toString();
        } else if (dayWord) {
            pickupDateTime = dayWord;
        }
        return pickupDateTime;
    }

    render() {
        let mapLocationUrl = "";
        if (this.props.curbsidePickupData.storeDetailsData && this.props.curbsidePickupData.storeDetailsData.location) {
            var lat = this.props.curbsidePickupData.storeDetailsData.location.latitude;
            var lng = this.props.curbsidePickupData.storeDetailsData.location.longitude;
            if (lat && lng) {
                mapLocationUrl = "http://maps.google.com/?z=8&q=" + lat + "," + lng;
            }
        }
        let pickupDateTime = this.getPickupDateTime();
        return (
            <div>
                <div className="pre-check-order-confirm-summary">

                    {pickupDateTime &&
                        <div className="pre-check-order-confirm-row order-confirm-box-sapce-one" onClick={() => this.props.setCurbSideForm(true)}>
                            <div className="order-icon-box">
                                <QueryBuilderIcon />
                            </div>
                            <div className="order-right-content-info">
                                <h4>{pickupDateTime}</h4>
                                <span>{(this.props.curbsidePickupData && this.props.curbsidePickupData.preCheckInData && this.props.curbsidePickupData.preCheckInData.pickupScheduleDateTimeLabel) ? this.props.curbsidePickupData.preCheckInData.pickupScheduleDateTimeLabel : "Pickup Time"}</span>
                            </div>
                            <div className="order-right-arrow">
                                <ChevronRightIcon ></ChevronRightIcon>
                            </div>
                        </div>
                    }

                    <div className="pre-check-order-confirm-row order-confirm-box-sapce-two" onClick={() => mapLocationUrl && window.open(mapLocationUrl, '_blank', 'noopener')}>
                        <div className="order-icon-box">
                            <DirectionsOutlinedIcon />
                        </div>
                        <div className="order-right-content-info">
                            {/* <h4>Name of the Store</h4> */}
                            {/* <address>531 5th Ave, New York, NY 10017, United States</address>
                            <p>Pull up towards one of the dedicated parking spots</p> */}
                            <h4>{this.props.curbsidePickupData.storeDetailsData && this.props.curbsidePickupData.storeDetailsData.name}</h4>
                            <address>{this.getStoreAddress()}</address>
                            <p>Pull up towards one of the dedicated parking spots</p>
                        </div>
                        <div className="order-right-arrow">
                            <ChevronRightIcon />
                        </div>
                    </div>

                    <div className="pre-check-order-confirm-row" onClick={() => this.props.onCurbsidePageFlow(curbsidePageFlowTypes.ORDER_DETAILS)}>
                        <div className="order-icon-box">
                            <LocalMallOutlinedIcon />
                        </div>
                        <div className="order-right-content-info">
                            <h4>Order #{this.props.curbsidePickupData.storeDetailsData && this.props.curbsidePickupData.storeDetailsData.orderNumber}</h4>
                            {this.props.orderCount && <span>{this.props.orderCount} Items</span>}
                        </div>
                        <div className="order-right-arrow" >
                            <ChevronRightIcon />
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}