import {setState} from '../../Utils/setState'
import  PUDO_CONSTANTS  from '../../Utils/PudoConstants'
import {isEmpty} from 'loadsh'
import {mapViewService}  from '../MapView/services/MapViewService';


export function initializeMap(obj,detail,url, list){
    return async function (dispatch) {
        try{
            const map = await mapViewService.initializeMap(obj,detail,url, list );
            return map;
        }catch(err){
          console.log("not working");
        }
    }
}    

export function getPudoData(url, lat, lng, masterCode) {
  return async dispatch => {
    const response = await mapViewService.fetchPudoData(url, lat, lng, masterCode);
    if(!isEmpty(response)) {
      dispatch(setState(PUDO_CONSTANTS.SET_PUDO_DATA, response));
    }
  }
}

export function setPudoPoints(pudoPoints) {
  return async dispatch => {
    dispatch(setState(PUDO_CONSTANTS.SET_PUDO_POINTS, pudoPoints));
  }
}







