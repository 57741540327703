import { Record } from 'immutable';

const CurbsidePickupInitialState = Record({
    curbsidePickupUrl: null,
    timeSlotData: [
        {
            date: new Date(2022, 10, 9),
            timeSlots: ["10am - 11am", "11am - 12pm", "12pm - 1pm", "1pm - 2pm", "2pm - 3pm", "3pm - 4pm", "4pm - 5pm"]
        },
        {
            date: new Date(2022, 10, 10),
            timeSlots: ["10am - 11am", "11am - 12pm", "12pm - 1pm", "1pm - 2pm", "2pm - 3pm", "3pm - 4pm", "4pm - 5pm"]
        },
        {
            date: new Date(2022, 10, 11),
            timeSlots: ["10am - 11am", "11am - 12pm", "12pm - 1pm", "1pm - 2pm", "2pm - 3pm", "3pm - 4pm", "4pm - 5pm"]
        },
        {
            date: new Date(2022, 10, 12),
            timeSlots: ["10am - 11am", "11am - 12pm", "12pm - 1pm", "1pm - 2pm", "2pm - 3pm", "3pm - 4pm", "4pm - 5pm"]
        },
        {
            date: new Date(2022, 10, 13),
            timeSlots: ["10am - 11am", "11am - 12pm", "12pm - 1pm", "1pm - 2pm", "2pm - 3pm", "3pm - 4pm", "4pm - 5pm"]
        },
    ],
    curbsidePageFlow: "TIME_SLOT",
    // curbsidePageFlow: "POST_CHECK_IN",
    // curbsidePageFlow: "PRE_CHECK_IN",
    curbsidePickupGeneralSettingJson: {
        "processMasterCode": "007",
        "pickupScheduleFormId": 1,
        "checkInFormId": 1,
        "preCheckInCardConfig": {
            "useHeroImage": true,
            "s3ImageList": [
                {
                    "fileName": "Test3333333333333333333333333333333333333333333333333333333333333333333333333333333.png",
                    "s3URL": "https://s3-us-west-2.amazonaws.com/fareye.development/image_asset/12/1642055146158"
                },
                {
                    "fileName": "Test2.png",
                    "s3URL": "https://s3-us-west-2.amazonaws.com/fareye.development/image_asset/12/1642067931798"
                },
                {
                    "fileName": "Test.png",
                    "s3URL": "https://s3-us-west-2.amazonaws.com/fareye.development/image_asset/12/1642065969283"
                }
            ],
            "headerText": "Your Order is Ready/",
            "subText": "Pull over to pickup area in front of the store.Click on the button below as you reach & we’ll quickly get your order.",
            "buttonText": "I Have Arrived"
        },
        "storeDetailsCardConfig": {
            "name": "str12",
            "addressLine1": "adderss",
            "addressLine2": "add",
            "pincode": "pin",
            "timings": "str12",
            "location": "dec12",
            "orderNumber": "111"
        },
        "orderDetailsCardConfig": {
            "orderDetailsAttributeArray": [
                {
                    "attribute": "111",
                    "label": "Sarit",
                    "sequence": 1
                },
                {
                    "attribute": "adderss",
                    "label": "ad12",
                    "sequence": 2
                }
            ]
        },
        "postCheckInCardConfig": {
            "headerText": "Getting your order.",
            "subText": "We have notified the valet, soon they’ll be getting your orderjhgjhgj",
            "deliveryStaffAttribute": "adderss",
            "deliverProcessTaskList": [
                "pick"
            ]
        },
        "otherDetailsCardConfig": {
            "shareTextBody": "Use my order link <URL> to check in while picking up the order from Fareye",
            "marketingAssets": true
        }
    },
    curbsidePickupJson: {
        timeSlotPageData: {
            date: null,
            timeSlot: null 
        }
    },
    curbsidePickupData: null
});
export default new CurbsidePickupInitialState();
