import React from "react";

const ChatIcon = (props) => (
    <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path 
            d="M3 3H15V12H3.8775L3 12.8775V3ZM3 1.5C2.175 1.5 1.5075 2.175 1.5075 3L1.5 16.5L4.5 13.5H15C15.825 13.5 16.5 12.825 16.5 12V3C16.5 2.175 15.825 1.5 15 1.5H3ZM4.5 9H13.5V10.5H4.5V9ZM4.5 6.75H13.5V8.25H4.5V6.75ZM4.5 4.5H13.5V6H4.5V4.5Z" 
            fill={props.fill?props.fill:"#56c09a"}
        />
    </svg>
);

export default ChatIcon;