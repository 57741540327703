// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    position: fixed;
    padding: 10px;
    bottom: 0;
    left: 0;
    right: 0;
}
.footer .mdl-button {
    width: 100%;
    height: 50px;
    background-color: #18BF9A !important;
}`, "",{"version":3,"sources":["webpack://./components/Footer/style.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,SAAS;IACT,OAAO;IACP,QAAQ;AACZ;AACA;IACI,WAAW;IACX,YAAY;IACZ,oCAAoC;AACxC","sourcesContent":[".footer {\n    position: fixed;\n    padding: 10px;\n    bottom: 0;\n    left: 0;\n    right: 0;\n}\n.footer .mdl-button {\n    width: 100%;\n    height: 50px;\n    background-color: #18BF9A !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
