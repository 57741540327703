import React from "react";
import Header from "../../../components/Header";
import CurbsideOrderInfoDetail from "../components/CurbsideOrderInfoDetail";

class OrderDetails extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="curbside-mob-text order-details-modal-container">
                <Header
                    curbsidePickup={true}
                    backScreen={this.props.backScreenHandler}
                    back={true}
                    title={'Order Details'}
                    primaryBgColor={"primaryBgColor"}
                    primaryTextColor={"primaryTextColor"}
                    fontSize={"fontSize"}
                    fontFamily={"fontFamily"}
                    deviceType={this.props.deviceType}
                    customBackIcon={this.props.customBackIcon}
                />
                <div className="curb-side-order-info-header">
                    <h4>Order Information</h4>
                </div>
                <div className="curb-side-container">
                    <div className="desktop-order-details">
                        <CurbsideOrderInfoDetail curbsidePickupData={this.props.curbsidePickupData}></CurbsideOrderInfoDetail>
                    </div>
                </div>
            </div>
        )
    }
}

export default OrderDetails;