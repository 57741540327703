import { isEmpty } from "lodash";

class PrototypeService {
    
  
    getETAString = (etaFromServer,trackingDetails) => {
        if (isEmpty(etaFromServer)) return null;
        // return "wowow";
        
        if(trackingDetails.browserTimeZone && trackingDetails.browserTimeZone==true && !(trackingDetails.layoutScreen.etaSource && (trackingDetails.layoutScreen.etaSource=="PROCESS_ATTRIBUTE"))) {
            return this.getEtaBrowserTime(etaFromServer,trackingDetails);
        }
        else {
            if(etaFromServer && (etaFromServer.indexOf("today")!=-1 || etaFromServer.indexOf("tomorrow")!=-1)){
                  let arr = etaFromServer.split(" ");
                  for(let i=0;i<arr.length;i++){
                      if(arr[i] && !isEmpty(arr[i])){
                            if((arr[i].indexOf("today")!=-1 || arr[i].indexOf("tomorrow")!=-1)){
                                  if(arr[i+1] && !isEmpty(arr[i+1]) && arr[i+1].indexOf("at")!=-1){
                                    arr[i] = arr[i].indexOf("today")!=-1?containerConstants.formatString(containerConstants.TodayAt):containerConstants.formatString(containerConstants.TomorrowAt);
                                    arr[i+1]="";
                                } else {
                                  arr[i] = arr[i].indexOf("today")!=-1?containerConstants.formatString(containerConstants.Today):containerConstants.formatString(containerConstants.Tomorrow);
                                }
                            }  else if(arr[i].indexOf("PM")!=-1 || arr[i].indexOf("AM")!=-1){
                              arr[i]=arr[i].indexOf("PM")!=-1?containerConstants.formatString(containerConstants.PM):containerConstants.formatString(containerConstants.AM);
                            }
                            
                      }
                  }
                  etaFromServer ="";
                  for(let i=0;i<arr.length;i++){
                     etaFromServer = etaFromServer+arr[i]+" ";
                  }
            }
            return " " + etaFromServer;
          }
        
      };
      getEtaBrowserTime=(etaFromServer,trackingDetails)=>{
        let currentDate = new Date();
        let etaString = etaFromServer.split("xwwx");
        let etaStringLeft = this.getEtaBrowserTimeSingle(etaString[0],trackingDetails);
        let etaStringRight = this.getEtaBrowserTimeSingle(etaString[1],trackingDetails);
        let etaR = new Date(etaString[0].replace(/-/g, "/")+" UTC");
        if(etaString[2]==true){
        if (currentDate.getMonth() == etaR.getMonth()) {
            if (currentDate.getDate() == etaR.getDate()) {
                return containerConstants.formatString(containerConstants.Today);
            } else if (currentDate.getDate() + 1 === etaR.getDate()) {
                return containerConstants.formatString(containerConstants.Tomorrow);
            }
        }
        else{
        return (
            containerConstants.formatString(containerConstants.On) +
            etaR.getDate() +
            " " +
            this.monthNames[etaR.getMonth()] +
            " "
        );}}
        else{
        let finalTime;
        if (currentDate.getMonth() == etaR.getMonth()) {
            if (currentDate.getDate() == etaR.getDate()) {
                finalTime = containerConstants.formatString(containerConstants.TodayAt)+" ";
            } else if (currentDate.getDate() + 1 === etaR.getDate()) {
                finalTime = containerConstants.formatString(containerConstants.TomorrowAt)+" ";
            }
        }
        else{
            finalTime =
            containerConstants.formatString(containerConstants.On) +
            etaR.getDate() +
            " " +
            this.monthNames[etaR.getMonth()] +
            " ,";
        } 
        if(etaString[1] && etaString[1]=="kk"){
            finalTime = finalTime + etaStringLeft;
        }
        else{
            finalTime = finalTime + etaStringLeft +" - "+ etaStringRight;
        } 
        
        return finalTime;
        }
        

    } 
    getEtaBrowserTimeSingle = (deliveryDate,trackingDetails) => {
        var timeFormat = processUpdateFormService.getTimeFormat(trackingDetails);
        if (deliveryDate && !isEmpty(deliveryDate)) {
          let eta;
          if(trackingDetails.browserTimeZone && trackingDetails.browserTimeZone==true){
             eta = new Date(deliveryDate.replace(/-/g, "/")+" UTC");
          }
          else{
             eta = new Date(deliveryDate.replace(/-/g, "/"));
          }
          if(timeFormat==CONSTANTS.TWENTYFOUR_HOUR_FORMAT){
            var hours = eta.getHours() ;
            var minutes = eta.getMinutes() < 10 ? '0' + eta.getMinutes() : eta.getMinutes();
            return  hours + ":" + minutes;
        }
            var hours = eta.getHours() ; // gives the value in 24 hours format
            var AmOrPm = hours >= 12 ? containerConstants.formatString(containerConstants.PM):containerConstants.formatString(containerConstants.AM);
            hours = (hours % 12) || 12;
            // hours = hours < 10 ? '0'+hours : hours;
            var minutes = eta.getMinutes() < 10 ? '0' + eta.getMinutes() : eta.getMinutes();
            var finalTime = hours + ":" + minutes + " " + AmOrPm; 
            return finalTime
        }
      
       }
}

export const prototypeService = new PrototypeService();
