import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { isEmpty } from "loadsh";
import * as CustomerInteractionAction from "../../CustomerInteractionActions";
import Header from "../../components/Header";
import SubHeader from "../../components/SubHeader";
import FeedbackScreen from "../Feedback/FeedbackScreen";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import "./style.css";
import FeedBackDetail from "../../components/FeedBackDetail";
import ProcessUpdateForm from "../ProcessUpdateForm/index";
import HelpSection from "../../components/HelpSection/index";

const ColorButton = withStyles((theme) => ({
  root: {
    color: "white",
    width: "100%",
    height: 45,
    backgroundColor: "#18BF9A",
    "&:hover": {
      backgroundColor: "#18BF9A",
    },
  },
}))(Button);

class Feedback extends React.Component {
  containerConstants = window.containerConstants;

  constructor(props) {
    super(props);
    this.state = {
      ratings: "",
      update: false,
      showNeedHelpDialog: false,
      openForm: false,
      formIndex: 0,
    };
    this.setHelpModel = this.setHelpModel.bind(this);
  }

  componentDidMount(){
    this.props.actions.fetchFeedbackInfo(this.props.url,true)
  }

  setRating = (event) => {
    this.setState({ ratings: event.target.value });
  };

  validation = () => {
    if (!isEmpty(this.state.ratings)) {
      return false;
    }
    return true;
  };
  updateSelectedLocation = async () => {
    let message = "Nps Rating By Customer - ".concat(this.state.rating);
    let res = await this.props.actions.updateLocation(
      this.props.url,
      null,
      null,
      message
    );
    this.setState({ update: true });
  };

  getRatingDiv = () => {
    let feedback = JSON.parse(this.props.trackingDetails.feedback);
    if(feedback && feedback.disableFeedbackRating) {
      return;
    }
    return (
      <div
        className="rating-div feedbackScreen"
        style={{ backgroundColor: "white" }}
      >
        <p
          className="rate-your-experience"
          style={{ fontSize: this.props.fontSize }}
        >
          {containerConstants.formatString(
            containerConstants.RateYourExperience
          )}
        </p>
        <div
          className="star-rating"
          style={
            feedback["podImage"] && !isEmpty(feedback["podImage"])
              ? { fontSize: this.props.fontSize, marginTop: "10px" }
              : { fontSize: this.props.fontSize, marginTop: "50px" }
          }
        >
          <input
            type="radio"
            id="1-stars"
            name="rating"
            value="5"
            onClick={this.setRating}
          />
          <label htmlFor="1-stars" className="star">
            ★
          </label>
          <input
            type="radio"
            id="2-stars"
            name="rating"
            value="4"
            onClick={this.setRating}
          />
          <label htmlFor="2-stars" className="star">
            ★
          </label>
          <input
            type="radio"
            id="3-stars"
            name="rating"
            value="3"
            onClick={this.setRating}
          />
          <label htmlFor="3-stars" className="star">
            ★
          </label>
          <input
            type="radio"
            id="4-stars"
            name="rating"
            value="2"
            onClick={this.setRating}
          />
          <label htmlFor="4-stars" className="star">
            ★
          </label>
          <input
            type="radio"
            id="5-star"
            name="rating"
            value="1"
            onClick={this.setRating}
          />
          <label htmlFor="5-star" className="star">
            ★
          </label>
        </div>
      </div>
    );
  };

  backScreen = (event) => {
    this.setState({ ratings: 0 });
  };

  // METHODS REQUIRED FOR NEED HELP IN HEADER

  openProcessForm = (index) => (event) => {
    this.setState({
      openForm: true,
      formIndex: index,
    });
  };

  getHelpSection = () => {
    return (
      <HelpSection
        showNeedHelpDialog={this.state.showNeedHelpDialog}
        setHelpSection={this.closeHelpModal}
        trackingDetails={this.props.trackingDetails}
        openProcessForm={this.openProcessForm}
        screen={this.props.trackingDetails.screen}
        fontSize={this.props.fontSize}
        fontFamily={this.props.fontFamily}
      />
    );
  };

  setHelpModel = () => {
    this.setState({ showNeedHelpDialog: true });
  };

  closeHelpModal = () => {
    this.setState({ showNeedHelpDialog: false });
  };

  closeProcessForm = () => {
    this.setState({
      openForm: false,
      formIndex: 0,
      showNeedHelpDialog: false,
    });
  };

  render() {
    // FOR OPEN THE PROCESS FORM FROM NEED HELP MODAL
    if (this.state.openForm) {
      return (
        <ProcessUpdateForm
          formIndex={this.state.formIndex}
          back={true}
          backScreen={this.closeProcessForm}
          primaryBgColor={this.props.primaryBgColor}
          primaryTextColor={this.props.primaryTextColor}
          secondaryBgColor={this.props.secondaryBgColor}
          secondaryTextColor={this.props.secondaryTextColor}
          fontSize={this.props.fontSize}
          fontFamily={this.props.fontFamily}
          isReturn={this.props.isReturn}
        />
      );
    }
    if (this.props.feedback) {
      return (
        <React.Fragment>
          <div id="top-area" className="feedback-custom feedback-custom2">
            <Header
              logo={this.props.trackingDetails.logo?this.props.trackingDetails.logo:this.props.trackingDetails.companyLogo}
              primaryBgColor={this.props.primaryBgColor}
              primaryTextColor={this.props.primaryTextColor}
              secondaryBgColor={this.props.secondaryBgColor}
              secondaryTextColor={this.props.secondaryTextColor}
              fontSize={this.props.fontSize}
              fontFamily={this.props.fontFamily}
            />
            <SubHeader
              trackingDetails={this.props.trackingDetails}
              secondaryBgColor={this.props.secondaryBgColor}
              secondaryTextColor={this.props.secondaryTextColor}
              fontSize={this.props.fontSize}
              fontFamily={this.props.fontFamily}
            />
          </div>
          <div className="card" style={{ marginTop: "95px" }}>
            <div className="card-body p15">
              <div className="text-center width30 margin30-auto">
                <img
                  src={require("../../images/hand.svg")}
                  alt=""
                  className="width100"
                />
              </div>
              <p
                className="fs16 text-center"
                style={{
                  fontSize: this.props.fontSize,
                  fontFamily: this.props.fontFamily,
                }}
              >
                {containerConstants.formatString(
                  containerConstants.ThankYouForYourValuableFeedback
                )}
              </p>
              <p
                className="fs14 text-light-gray text-center mb15"
                style={{
                  fontSize: this.props.fontSize,
                  fontFamily: this.props.fontFamily,
                }}
              >
                {containerConstants.formatString(
                  containerConstants.YourFeedbackWillContinuallyHelpUsImproveOurServices
                )}
              </p>
            </div>
          </div>
        </React.Fragment>
      );
    }
    if (this.state.ratings && !isEmpty(this.state.ratings)) {
      return (
        <div className="feedback-custom feedback-custom2" style={{ backgroundColor: "white" }}>
          <FeedbackScreen
            ratings={this.state.ratings}
            backScreen={this.backScreen}
            fontSize={this.props.fontSize}
            fontFamily={this.props.fontFamily}
            primaryBgColor={this.props.primaryBgColor}
            primaryTextColor={this.props.primaryTextColor}
            secondaryBgColor={this.props.secondaryBgColor}
            secondaryTextColor={this.props.secondaryTextColor}
            isReturn={this.props.isReturn}
          />
        </div>
      );
    }

    return (
      <div className="feedbackv2_customize">
        {this.getHelpSection()}

        <div id="top-area" style={{ backgroundColor: "white" }}>
          <SubHeader
            trackingDetails={this.props.trackingDetails}
            secondaryBgColor={this.props.secondaryBgColor}
            secondaryTextColor={this.props.secondaryTextColor}
            fontSize={this.props.fontSize}
            fontFamily={this.props.fontFamily}
          />
        </div>

        <FeedBackDetail
          trackingDetails={this.props.trackingDetails}
          fontSize={this.props.fontSize}
          fontFamily={this.props.fontFamily}
        />

        {this.getRatingDiv()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    trackingDetails: state.customerInteractionReducer.trackingDetails,
    url: state.customerInteractionReducer.url,
    feedback: state.customerInteractionReducer.feedback,
    isFeedbackInfoFetch:state.customerInteractionReducer.isFeedbackInfoFetch,
    feedbackInfo:state.customerInteractionReducer.feedbackInfo
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...CustomerInteractionAction }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
