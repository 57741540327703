import React from 'react';
import TableLoaderStyle from './tableLoader.style';

class TableLoader extends React.Component {
  render() {
    return (
        <TableLoaderStyle>

      <div style={{position: 'absolute', top: '50%', transform: 'translate(-50%, -50%)', left: '50%'}}>
      <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
          </div>
        {/* <div className="topDiv clearfix">
        <div className="ant-card-loading-block" 
        style={{ width: '60px', marginTop: '0', marginBottom: '8px', float: 'left', height: '40px'}} />
        <div className="ant-card-loading-block" style={{ width: '40%', marginTop: '0', marginBottom: '8px' , float: 'right'}} />

        </div>
        <div className="loader-table-first">
            <div className="loader-table-header" /></div>
        <div style={{ display: 'flex', justifyContent: 'center'}}>

          <div className="loader-table-body">
            <div className="loader-table-col" style={{margin: '0 auto'}}>

              <div className="clearfix">
              <div className="loader-table-header" style={{height: '15px', marginBottom: '2px', width: '15px', float: 'left', marginLeft: '2px'}}></div>
              <div className="loader-table-header" style={{height: '15px', marginBottom: '2px', width: '15px', float: 'left', marginLeft: '2px'}}></div>
              </div>
               <div className="clearfix">
              <div className="loader-table-header" style={{height: '15px', marginBottom: '2px', width: '15px', float: 'left', marginLeft: '2px'}}></div>
              <div className="loader-table-header" style={{height: '15px', marginBottom: '2px', width: '15px', float: 'left', marginLeft: '2px'}}></div>
              </div>
            </div>
           
    </div>
        </div>
            <div className="ant-card-loading-block" style={{ width: '90%', 'position':'absolute', 'bottom': '20px'}} />
       */}
       </TableLoaderStyle>
    );
  }
}

export default TableLoader