import React, { Component } from 'react';
import {RightArrow, LikeIcon, DisLikeIcon} from "../../containers/ProcessUpdateForm/Icons";
import { Radio, Space, Checkbox } from 'antd';


const plainOptions = ['Ease of navigation', 'Product information', 'Product availability',
'Available delivery dates', 'Ease in finding installation information without any much searching', 
'Installation accessories/parts needed to repair', 'Ease of Checkout'];
const options = [
  { label: 'Ease of navigation', value: 'Ease of navigation' },
  { label: 'Product information', value: 'Product information' },
  { label: 'Product availability', value: 'Product availability' },
  { label: 'Available delivery dates', value: 'Available delivery dates' },
  { label: 'Ease in finding installation information without any much searching', value: 'Ease in finding installation information without any much searching' },
  { label: 'Installation accessories/parts needed to repair', value: 'Installation accessories/parts needed to repair' },
  { label: 'Ease of Checkout', value: 'Ease of Checkout' }
]

class MonkeySurveyForm extends Component {

  render() {
    return (
      <>
        <div className='monkey-survey-form-fluid'>
            <div className='monkey-survey-form-container'>
                {/* Share your Experience */}
                    <div className='monkey-survey-form-group-row'>
                        <div className='monkey-survey-form-share-heading'>
                            <h4>Share your Experience</h4>
                            <p>We'd like to know more about your experience delivering with us.</p>
                        </div>
                        <div className='monkey-survey-form-share-inner'>
                            <div className='monkey-survey-form-share-order-no'>
                                <label className='monkey-survey-form-label'>Order Number</label>
                                <span>9382 3884 9483</span>
                            </div>
                            <button className='monkey-survey-form-view-btn'>View Details <RightArrow/></button>
                        </div>
                    </div>
                {/* Share your Experience */}
                {/* Default Delivery Date */}
                <div className='monkey-survey-form-group-row'>
                    <div className='monkey-survey-form-delivery-date'>
                        <label className='monkey-survey-form-label'>Default Delivery Date</label>
                        <input type='date' className='monkey-survey-input-format' />
                    </div>
                </div>
                {/* Default Delivery Date */}
                {/* Brand to a friend or colleague */}
                <div className='monkey-survey-form-group-row'>
                    <div className='monkey-survey-form-delivery-date'>
                        <label className='monkey-survey-form-label'>How likely are you to recommend this brand to a friend or colleague? </label>
                        <ul className='monkey-survey-like-counts'>
                          <li>1</li><li>2</li><li>3</li><li>4</li><li>5</li>
                          <li>6</li><li>7</li><li>8</li><li>9</li><li>10</li>
                        </ul>
                    </div>
                </div>
                {/* Brand to a friend or colleague */}
                {/* How satisfied are you */}
                <div className='monkey-survey-form-group-row'>
                    <div className='monkey-survey-form-delivery-date'>
                        <label className='monkey-survey-form-label'>How satisfied are you with Online order process (ease of navigation, product availability, ease of checkout, etc.)  </label>
                        <div className='monkey-survey-radio-groups'>
                          <Radio.Group>
                            <Space direction="vertical">
                              <Radio value={1}>Very Satisfied</Radio>
                              <Radio value={2}>Neutral</Radio>
                              <Radio value={3}>Not Satisfied</Radio>
                              <Radio value={4}>Disspointed</Radio>
                            </Space>
                          </Radio.Group>
                        </div>
                        <div className='monkey-survey-form-delivery-textarea'>
                          <label className='monkey-survey-form-label'>Any other comments?</label>
                          <div className='monkey-survey-form-inner-textarea'>
                              <textarea className='monkey-survey-input-format' placeholder='Short answer'></textarea>
                          </div>
                        </div>
                    </div>
                </div>
                {/* How satisfied are you */}
                {/* Did you call customer Support? */}
                <div className='monkey-survey-form-group-row'>
                    <div className='monkey-survey-form-delivery-date'>
                        <label className='monkey-survey-form-label'>Did you call customer Support?</label>
                        <div className='monkey-survey-like-dislike'>
                          <button className='monkey-survey-like-btn'><LikeIcon/></button>
                          <button className='monkey-survey-like-btn'><DisLikeIcon/></button>
                        </div>
                    </div>
                </div>
                {/* Did you call customer Support? */}
                {/* How was the installation by partner? */}
                <div className='monkey-survey-form-group-row'>
                    <div className='monkey-survey-form-delivery-date'>
                        <label className='monkey-survey-form-label'>How was the installation by partner?</label>
                        <div className='monkey-survey-rating-box'>
                          
                        </div>
                    </div>
                </div>
                {/* How was the installation by partner? */}
                {/* Identify the specific areas Checkbox*/}
                <div className='monkey-survey-form-group-row'>
                    <div className='monkey-survey-form-delivery-date'>
                        <label className='monkey-survey-form-label'>Please identify the specific areas where we can improve for online order process</label>
                        <div className='monkey-survey-checkbox-groups'>
                          <Checkbox.Group options={plainOptions} />
                        </div>
                    </div>
                </div>
                {/* Identify the specific areas Checkbox*/}
                {/* Confirm Button */}
                <div className='monkey-survey-form-group-button'>
                  <button>Confirm</button>
                </div>
                {/* Confirm Button */}
            </div>
        </div>
      </>
    )
  }
}

export default MonkeySurveyForm;
