import { isEmpty } from "lodash";
import CONSTANTS from "../../../Utils/Constants";
import ciDiyService from "./ciDiyService";
import { setState } from "../../../Utils/setState";


export function setTrackingDetailsForPreview(diyConfig){
    return async function(dispatch){
        try{
            var trackingDetails = await ciDiyService.getTrackingDetailsForPreview(diyConfig);
        
            if(!isEmpty(trackingDetails)){
                dispatch(setState(CONSTANTS.UPDATE_TRACKING_DETAILS,trackingDetails))
            }
        } catch(err){
            console.log("ciDiyActions>setTrackingDetails>Err",err);
        }
    }
}

export function getThemeFromDiyConfig(url,trackingDetails){
    return async function(dispatch){
        try{
            var themeDetails = await ciDiyService.getThemeFromCiDiyConfig(url,trackingDetails);
        
            if(!isEmpty(themeDetails)){
                dispatch(setState(CONSTANTS.UPDATE_THEME_FOR_CI_DIY,themeDetails))
            }
        } catch(err){
            console.log("ciDiyActions>setThemeFromDiy>Err",err);
        }
    }
}

