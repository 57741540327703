const CONSTANTS = {
    GET_TRACKING_DETAILS: 'saveProcessCustomForms',
    UPDATE_TRACKING_DETAILS:'updateTrackingDetails',
    SET_SLOT_LIST:'set_slot_list',
    SET_CHECKBOX:'set_checkbox',
    ALREADY_UPDATED:'already_updated',
    NOT_FOUND:'not_found',
    SET_CIRCLE_CENTER:'set_circle_center',
    SET_JOB_UPDATE:'set_job_updated',
    SET_FEEDBACK:'set_feedback',
    SET_DYNAMIC_SLOTS:'set_dynamic_slots',
    SET_DYNAMIC_SLOTS_DATA:'set_dynamic_slots_data',
    SET_PAYMENT_STATUS:'set_payment_status',
    SET_TRIP_ETA:'SET_TRIP_ETA',
    SET_INITIATED_TIME:'SET_INITIATED_TIME',
    SET_HOLIDAY_MASTER: 'SET_HOLIDAY_MASTER',
    SET_PREVIOUS_JOBS_MESSAGE: 'SET_PREVIOUS_JOBS_MESSAGE',
    SET_NEXT_JOB_TRANSACTION_ID: 'SET_NEXT_JOB_TRANSACTION_ID',
    SET_UPDATE_ETA:'SET_UPDATE_ETA',
    CURBSIDE_PICKUP:'CURBSIDE_PICKUP',
    SET_AVAILABLE_DATES_FROM_COORDINATOR: 'SET_AVAILABLE_DATES_FROM_COORDINATOR',
    SET_UPDATE_ETA:'SET_UPDATE_ETA',
    SET_JOB_REMOVED:'SET_JOB_REMOVED',
    SET_FEEDBACK_INFO:'SET_FEEDBACK_INFO',
    SET_FORM_LOADING:'SET_FORM_LOADING',
    TWENTYFOUR_HOUR_FORMAT:"yes",
    TWELEV_HOUR_FORMAT:"half",
    INCREASE_AUTO_SAVE_FLAG_COUNT:'INCREASE_AUTO_SAVE_FLAG_COUNT',
    INCREASE_HOLIDAY_MASTER_API_COUNT:'INCREASE_HOLIDAY_MASTER_API_COUNT',
    HIDDEN:'hidden',
    MAJOR:'major',
    MINOR:'minor',
    RENAME:'rename',
    UPDATE_THEME_FOR_CI_DIY: 'UPDATE_THEME_FOR_CI_DIY',
    SET_SHIPMENT_TRACKING: 'SET_SHIPMENT_TRACKING',
    UNIVERSAL_TRACKING:"universal-tracking",
  };

  export const ScreenType = {
    LIVE_TRACKING_SCREEN: "liveTracking",
    LAYOUT_SCREEN: "layoutScreen",
    DESKTOP_SCREEN: "DeskTopView",
    FEEDBACK_SCREEN: "feedbackScreen",
    EVENT_SCREEN: "eventScreen",
  }

  export const DATE_FORMAT = {
    YYYY_MM_DD: 'YYYY-MM-DD',
    DD_MM_YYYY: 'DD-MM-YYYY',
    MM_DD_YYYY: 'MM-DD-YYYY'
  }

  export const TIME_FORMAT = "HH:mm:ss";

  export const ATTRIBUTE_TYPE = {
    STRING: 'STRING',
    DATE: 'DATE',
    DATE_TIME: 'DATE_TIME',
    NUMBER: 'NUMBER',
  }
  
  export default CONSTANTS;