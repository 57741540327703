import React from "react";
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import { curbsidePageFlowTypes } from "../constants/CurbsidePickupConstants";
import { isEmpty } from "lodash";
import FEIcon from "../icons/fe";

export default class CurbsidePostCheckInOrderConfirmInfo extends React.Component {

    getDayWord = () => {
        let date = this.props.curbsidePickupJson.timeSlotPageData.date;
        if (!date)
            return 'Today';
        if (date.getDate() === new Date().getDate()) {
            return 'Today';
        }
        return date.toLocaleDateString();
    }

    renderParkingDetails =()=>{
        let array = [];
        if (this.props.trackingDetails && this.props.trackingDetails.processUpdateForm) {
            let forms = JSON.parse(this.props.trackingDetails.processUpdateForm);
            var form=forms.formList[1];
            if(form && form.processInteractionKeysDtos){
                form.processInteractionKeysDtos.map((attribue,index)=>{
                     if(attribue && !isEmpty(attribue.label) && !isEmpty(attribue.value)){
                         array.push(<p>{attribue.label} : {attribue.value}</p>)
                     }
                })
            }
        }
        return array;
    }

    render() {
        return (
            <div>
                <div className="pre-check-order-confirm-summary">

                    <div className="pre-check-order-confirm-row" onClick={() => this.props.onCurbsidePageFlow(curbsidePageFlowTypes.ORDER_DETAILS)}>
                        <div className="order-icon-box">
                            <LocalMallOutlinedIcon />
                        </div>
                        <div className="order-right-content-info">
                            <h4>Order #{this.props.curbsidePickupData.storeDetailsData && this.props.curbsidePickupData.storeDetailsData.orderNumber}</h4>
                            {this.props.orderCount && <span>{this.props.orderCount} Items</span>}
                        </div>
                        <div className="order-right-arrow">
                            <ChevronRightIcon />
                        </div>
                    </div>

                    <div className="pre-check-order-confirm-row order-confirm-box-sapce-two" onClick={() => this.props.setCurbSideForm(true,"CheckIn")}>
                        <div className="order-icon-box">
                            <LocalParkingIcon />
                        </div>
                        <div className="order-right-content-info">
                            {this.renderParkingDetails()}
                        </div>
                        <div className="order-right-arrow">
                            <ChevronRightIcon/>
                        </div>
                    </div>

                    <div className="pre-check-order-confirm-row order-confirm-box-sapce-one">
                        <div className="order-icon-box">
                            <FEIcon color={this.props.primaryBgColor}/>
                        </div>
                        <div className="order-right-content-info">
                            <h4>{this.props.curbsidePickupData && this.props.curbsidePickupData.postCheckInData && this.props.curbsidePickupData.postCheckInData.deliveryStaff}</h4>
                            <span>Will be delivering your package</span>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}