import { isEmpty } from "lodash";
import React from "react";
import { MapBackArrow, MapLocateIcon, MapSearchIcon, PudoTimeIcon } from "../../containers/LayoutScreen/PudoReturnMap/Icons";
import { Alert } from 'antd';
import TableLoader from "../TableLoader";
import { PudoOtherDetails } from "../PudoOtherDetails";

class PudoScreenListDesktop extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
    }
  }
  componentDidMount(){
    if(!isEmpty(document.getElementById('marker' + 0))){
      document.getElementById('marker' + 0).style.width="44px";
      document.getElementById('marker' + 0).src=require('../../images/big-icon.svg');
    }
  }
  componentDidUpdate(){
    if(true){
      let index=0;
    let total=this.props.availableList ? this.props.availableList.length : 0;
    let current = this.props.currentMarkerIndex ? Number(this.props.currentMarkerIndex) : 0;
    for(index;index<=total;index++)
    {
      if(!isEmpty(document.getElementById('marker' + index))){
      document.getElementById('marker' + index).style.width="28px";
      document.getElementById('marker' + index).src=require('../../images/location.svg');
    }
    }
    if(!isEmpty(document.getElementById('marker' + current))){
      document.getElementById('marker' + current).style.width="44px";
      document.getElementById('marker' + current).src=require('../../images/big-icon.svg');
    }}
  }

  renderSearchBar=(storesList)=>{
    return(
      <div className="returns-map-list-search-field">
                          <button className="returns-map-list-back-arrow"
                          onClick={this.props.backToPreviousPage}>
                              <MapBackArrow/>
                          </button>
                          <input
                            id="searchPudoDesktop"
                            className="returns-map-list-search-input"
                            placeholder="Search by block, area, city, etc"
                            name=""
                            type="text"
                            value={this.state.searchValue}
                            onChange={e => this.setState({searchValue: e.target.value})}
                            onKeyUp={e => e.keyCode == 13 && this.props.onPudoAddressSearch(this.state.searchValue)}
                          />
                          {/* <button className="returns-map-list-search-icon">
                              <MapSearchIcon/>
                          </button> */}
                          {!this.state.searchValue?
                            <button className="returns-map-list-search-icon">
                                <MapSearchIcon/>
                            </button>:
                            <button className="returns-map-list-search-icon"
                            onClick={()=>{this.setState({searchValue:""}); this.props.onPudoAddressSearch(null)}}
                            >&#10006;</button>
                            }
                          
                      </div>
    )
  }
  beforeMarkerChange = (current,total)=>{
    let index=0;
    for(index;index<=total;index++)
    {
      if(!isEmpty(document.getElementById('marker' + index))){
      document.getElementById('marker' + index).style.width="28px";
      document.getElementById('marker' + index).src=require('../../images/location.svg');
    }
    }
    if(!isEmpty(document.getElementById('marker' + current))){
      document.getElementById('marker' + current).style.width="44px";
      document.getElementById('marker' + current).src=require('../../images/big-icon.svg');
    }
    
  }
  showLoader = () => {
    if (this.props.availableList == null) {
      return (
        <div>
          <div style={{ position: 'absolute', top: '45%', transform: 'translate(-50%, -50%)', left: '50%' }}>Fetching PudoStores</div>
          <TableLoader />
        </div>
      );
    }
  }
  closePudoDetailPopup =(index)=>{
    if(index!=this.props.currentMarkerIndex){
      this.props.closePudoPopup()
    }
  }
  handleClickScroll = () => {
    const element = document.getElementById(this.props.currentMarkerIndex);
    if (element ) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  showStoresList=()=>{
    if(Array.isArray(this.props.availableList) && isEmpty(this.props.availableList)){
      return(
        <div className="returns-map-list-group" style={{zIndex:this.props.availableList==null && '-1'}}>
            <Alert
                message="No store available"
                description="Sorry, there is no store available in the area. Try searching in a different location."
                type="warning"
                showIcon
              />
            
          </div>
      )
    }
    else{
      return(
        <div className="returns-map-list-group" style={{zIndex:this.props.availableList==null &&'-1'}}>
        {this.props.availableList &&
          this.props.availableList.map((list, index) => {
          let timings = "";
          if(!isEmpty(list.timing)) {
            timings = list.timing.split(',');
          }
            return (
              <div>
                <div 
                id={index}
                className={(this.props.currentMarkerIndex == index) ? "simple-list-store-detail highlighted-store-detail" : "simple-list-store-detail"}
                onClick={()=>{this.props.onForwardArrowClick(list,index);
                              this.beforeMarkerChange(index,this.props.availableList.length);
                              this.closePudoDetailPopup(index)}}
                >
                <div className="returns-map-list-address-row">
                <div className="returns-map-list-address-name">
                  
                <div className="returns-map-list-address-group">
                    <div className="returns-map-list-point-num">
                        <MapLocateIcon/>
                        <span className="address-map-shape" >{index+1}</span>
                    </div>
                    
                    <div className="returns-map-list-point-right">
                    {/* <div className="returns-map-list-address-name"> */}
                    <h4>{list.name}</h4>
                    {/* </div> */}
                    {list.addressLine1?<p >
                      {list.addressLine1 && list.addressLine1}
                      {list.addressLine2 && " , " + list.addressLine2}
                      {list.landmark && " , " + list.landmark}
                      {list.pincode && " , " + list.pincode}
                    </p>:null}
                    {/* {timings[0]?
                    <span>{timings[0]}</span>:null} */}
                   <span> {<PudoOtherDetails otherDetails={list.otherDetails}/>}</span>
                    </div>
                  </div>  
                </div>
                <div className="returns-map-list-address-dtl">
                    {index==0?<button className="nearest-btn">Nearest</button>:null}
                    {list.distance?<h6>{list.distance} 
                    {this.props.unit == "kilometers"? " km":this.props.unit} away</h6>:null}
                    <div className="pudo-map-select-arrow-div" id={"view-detail-pudo-list-desktop"}>
                        <button className="pudo-map-select-arrow" 
                        onClick={(event) => {this.props.openPudoPopup(list, index);
                                            this.props.onForwardArrowClick(list,index);
                                            this.beforeMarkerChange(index,this.props.availableList.length);
                                            event.stopPropagation();}} 
                        id="selectButtonPudoCard">
                          <span>View Details</span>
                        </button>
                    </div>
                </div>    
              </div>
              </div>
              </div>
            );
          })
          
        }
      </div>
      )
    }
  }
  render() {
    return(
    <React.Fragment>
      {this.renderSearchBar(this.props.availableList)}
      {this.showLoader()}
      {this.showStoresList()}   
      {this.handleClickScroll()} 
    </React.Fragment>
    )
  
  }
}

export default PudoScreenListDesktop;
