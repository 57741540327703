import React from "react";
import Grid from "@material-ui/core/Grid";
import DirectionsIcon from "@material-ui/icons/Directions";
import PhoneIcon from "@material-ui/icons/Phone";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import { isEmpty } from "loadsh";

class FeedBackDetail extends React.Component {
  monthNames = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
];

  getEtaDate = (deliveryDate) => {
    if (deliveryDate && !isEmpty(deliveryDate)) {
      let eta;
      if(this.props.trackingDetails.browserTimeZone && this.props.trackingDetails.browserTimeZone==true){
         eta = new Date(deliveryDate.replace(/-/g, "/")+" UTC");
      }
      else{
         eta = new Date(deliveryDate.replace(/-/g, "/"));
      }
        
        var hours = eta.getHours() ; // gives the value in 24 hours format
        var AmOrPm = hours >= 12 ? 'PM' : 'AM';
        hours = (hours % 12) || 12;
        // hours = hours < 10 ? '0'+hours : hours;
        var minutes = eta.getMinutes() < 10 ? '0' + eta.getMinutes() : eta.getMinutes();
        var finalTime = eta.getDate()+ " " + this.monthNames[eta.getMonth()] + " " +  eta.getFullYear();
        return finalTime
    }
  
   }
   getEtaTime = (deliveryDate) => {
    if (deliveryDate && !isEmpty(deliveryDate)) {
      let eta;
      if(this.props.trackingDetails.browserTimeZone && this.props.trackingDetails.browserTimeZone==true){
         eta = new Date(deliveryDate.replace(/-/g, "/")+" UTC");
      }
      else{
         eta = new Date(deliveryDate.replace(/-/g, "/"));
      }
        
        var hours = eta.getHours() ; // gives the value in 24 hours format
        var AmOrPm = hours >= 12 ? 'PM' : 'AM';
        hours = (hours % 12) || 12;
        // hours = hours < 10 ? '0'+hours : hours;
        var minutes = eta.getMinutes() < 10 ? '0' + eta.getMinutes() : eta.getMinutes();
        var finalTime =  hours + ":" + minutes + " " + AmOrPm; 
        return finalTime
    }
  
   }

  getJobStatusHeader = () => {
    return (
      <div className="jobStatusHeader">
        {this.props.trackingDetails.orderStatus ? (
          <Grid container spacing={2} className="jobStatusHeader">
            <Grid
              item
              xs={10}
              container
              direction="row"
              alignItems="center"
              className="m-auto disp-block text-center"
            >
              {this.props.trackingDetails.orderStatus == 3 ? (
                <div className="check-circle ">
                  <img src={require("../../images/check.svg")} alt="" />
                </div>
              ) : (
                <div className="danger-circle">
                  <span>!</span>
                </div>
              )}

              {this.props.trackingDetails.orderStatus == 3 ? (
                <span
                  className="fs16 green ml5"
                  style={{
                    fontSize: this.props.fontSize,
                    position: "relative",
                    top: "-2px",
                    marginLeft: "10px",
                  }}
                >
                  {this.props.trackingDetails.status}
                </span>
              ) : (
                <span
                  className="fs16 red ml5"
                  style={{
                    fontSize: this.props.fontSize,
                    position: "relative",
                    top: "-2px",
                    marginLeft: "10px",
                  }}
                >
                  {this.props.trackingDetails.status}
                </span>
              )}
            </Grid>
          </Grid>
        ) : null}
      </div>
    );
  };

  getFailedHeader = (feedback) => {
    return (
      <div className="failedHeader">
        {feedback["failedReason"] && !isEmpty(feedback["failedReason"]) ? (
          <div className="ml10 mb2">
            <span className="fs12 text-gray">Fail Reason </span>
            <p className="fs14">{feedback["failedReason"]}</p>
            {/* <p className="fs12 text-gray mb5">{this.props.address}</p> */}
          </div>
        ) : null}
        {feedback["attemptedDate"] && !isEmpty(feedback["attemptedDate"]) ? (
          <div
            className="ml10 border-bottom mb5 "
            style={
              feedback["podImage"] && !isEmpty(feedback["podImage"])
                ? {}
                : { marginBottom: "10px", paddingBottom: "10px" }
            }
          >
            <span className="fs12 text-gray">{containerConstants.formatString(containerConstants.LastAttempted)}</span>
            <p className="fs13">{this.getEtaDate(feedback["attemptedDate"])}</p>
            <p className="fs13">{this.getEtaTime(feedback["attemptedDate"])}</p>
          </div>
        ) : null}
        {feedback["podImage"] && !isEmpty(feedback["podImage"]) ? (
          <div
            className="epod-image border-bottom mb5"
            style={
              !feedback["attemptedDate"] && !feedback["failedReason"]
                ? { height: "170px" }
                : { height: "100px" }
            }
          >
            <img
              src={feedback["podImage"]}
              style={{ width: "auto", height: "100%", maxWidth: "100%" }}
            />
          </div>
        ) : null}
        <div className="ml10 lg-grey">
          <span className="fs12 text-gray pb10">{containerConstants.formatString(containerConstants.AttemptedBy)}</span>
          <Grid container spacing={2} className="mt5 ml10 ">
            <Grid item xs={8} container direction="row" alignItems="center">
                {this.props.trackingDetails.feImage &&
                !isEmpty(this.props.trackingDetails.feImage) ? (
                  <img
                    src={this.props.trackingDetails.feImage}
                    height="40px"
                    width="40px"
                  />
                ) : (
                  <img src={require("../../images/user.svg")} />
                )}
              <span className="fs14 pl10 lg-black">
                {this.props.trackingDetails.userName}
              </span>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

  getDeliveredHeader = (feedback) => {
    if (feedback["pudoShop"] && !isEmpty(feedback["pudoShop"])) {
      let mapLocationUrl;
      if (feedback["pudoLocation"] && !isEmpty(feedback["pudoLocation"])) {
        let loc = feedback["pudoLocation"].split(",");
        console.log("this.props.detail", loc);
        let lat = loc[0];
        let lng = loc[1];
        mapLocationUrl = "http://maps.google.com/?z=8&q=" + lat + "," + lng;
      }
      let contact =
        feedback["pudoContactNumber"] && !isEmpty(feedback["pudoContactNumber"])
          ? "tel:" + feedback["pudoContactNumber"] &&
            !isEmpty(feedback["pudoContactNumber"])
          : "";
      return (
        <div className="pudoShopFeedbackHeader">
            {feedback["attemptedDate"] && !isEmpty(feedback["attemptedDate"]) ? (
              <div
                className="border-bottom mb5 "
                style={
                  feedback["podImage"] && !isEmpty(feedback["podImage"])
                    ? {}
                    : { marginBottom: "10px", paddingBottom: "10px" }
                }
              >
                <span className="fs12 text-gray">{containerConstants.formatString(containerConstants.DeliveryAt)}</span>
                <p className="fs13">{this.getEtaDate(feedback["attemptedDate"])}</p>
                <p className="fs13">{this.getEtaTime(feedback["attemptedDate"])}</p>
              </div>
            ) : null}
          <div className="flex ">

            {feedback["pudoLocation"] && !isEmpty(feedback["pudoLocation"]) ? (
              <span className="directionIcon">
                <a href={mapLocationUrl} target="_blank">
                  <DirectionsIcon />
                </a>
              </span>
            ) : null}
            <div className="address-part mb20">
              <p className="text-light-gray fs12 mb5">Pick Up Point</p>
              <h4 className="text-dark fs-600 locationName">
                {feedback["pudoShop"]}
              </h4>
              <p className="text-dark fs14">
                {feedback["pudoAddressLine1"] && feedback["pudoAddressLine1"]}
                {feedback["pudoAddressLine2"] &&
                  " , " + feedback["pudoAddressLine2"]}
                {feedback["pudoLandMark"] && " , " + feedback["pudoLandMark"]}
                {feedback["pudoPincode"] && " , " + feedback["pudoPincode"]}
              </p>
            </div>
          </div>
          {feedback["pudoContactNumber"] &&
          !isEmpty(feedback["pudoContactNumber"]) ? (
            <div className="flex">
              <span className="phoneIcon">
                <a href={contact}>
                  <PhoneIcon />
                </a>
              </span>
              <div className="contact-part mb20">
                <p className="text-light-gray fs12 mb5">Contact Number</p>
                <p className="text-dark fs14">
                  {feedback["pudoContactNumber"]}
                </p>
              </div>
            </div>
          ) : null}

          {feedback["pudoTiming"] && !isEmpty(feedback["pudoTiming"]) ? (
            <div className="flex">
              <span className="queryBuilderIcon">
                <QueryBuilderIcon />
              </span>
              <div className="open-hours-part">
                <p className="text-light-gray fs12 mb5">Open Hours</p>
                <p className="text-dark fs14">
                  Open now: {feedback["pudoTiming"]}
                </p>
              </div>
            </div>
          ) : null}
        </div>
      );
    }
    return (
      <div className="deliverHeader" style={{ fontSize: this.props.fontSize }}>
        {feedback["attemptedDate"] && !isEmpty(feedback["attemptedDate"]) ? (
          <div
            className="ml10 border-bottom mb5 "
            style={
              feedback["podImage"] && !isEmpty(feedback["podImage"])
                ? {}
                : { marginBottom: "10px", paddingBottom: "10px" }
            }
          >
            <span className="fs12 text-gray">{containerConstants.formatString(containerConstants.DeliveredAt)}</span>
            <p className="fs13">{this.getEtaDate(feedback["attemptedDate"])}</p>
            <p className="fs13">{this.getEtaTime(feedback["attemptedDate"])}</p>
          </div>
        ) : null}

        {feedback["deliverTo"] && !isEmpty(feedback["deliverTo"]) ? (
          <div
            className="ml10 border-bottom "
            style={
              feedback["podImage"] && !isEmpty(feedback["podImage"])
                ? {}
                : { marginBottom: "10px", paddingBottom: "20px" }
            }
          >
            <span className="fs12 text-gray">
              {containerConstants.formatString(containerConstants.DeliveredTo)}
            </span>
            <p className="fs16">{feedback["deliverTo"]}</p>
            {/* <p className="fs12 text-gray mb5">{this.props.address}</p> */}
          </div>
        ) : null}
        {feedback["podImage"] && !isEmpty(feedback["podImage"]) ? (
          <div
            className="epod-image border-bottom mb5"
            style={
              !feedback["deliverTo"] ? { height: "170px" } : { height: "100px" }
            }
          >
            <img
              src={feedback["podImage"]}
              style={{ width: "auto", height: "100%", maxWidth: "100%" }}
            />
          </div>
        ) : null}

        <div className="ml10 lg-grey">
          <span className="fs12 text-gray pb10">
            {containerConstants.formatString(containerConstants.AttemptedBy)}
          </span>
          <Grid container spacing={2} className="ml10 mt10">
            <Grid item xs={8} container direction="row" alignItems="center">
                {this.props.trackingDetails.feImage &&
                !isEmpty(this.props.trackingDetails.feImage) ? (
                  <img
                    src={this.props.trackingDetails.feImage}
                    height="40px"
                    width="40px"
                  />
                ) : (
                  <img src={require("../../images/user.svg")} />
                )}
              <span className="fs14 pl10 lg-black">
                {this.props.trackingDetails.userName}
              </span>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

  render() {
    if (
      this.props.trackingDetails &&
      !isEmpty(this.props.trackingDetails.feedback)
    ) {
      let feedback = JSON.parse(this.props.trackingDetails.feedback);
      return (
        <div className="feedbackDetail" style={{ backgroundColor: "white" }}>
          {this.getJobStatusHeader()}
          {this.props.trackingDetails.orderStatus == 3
            ? this.getDeliveredHeader(feedback)
            : this.getFailedHeader(feedback)}
        </div>
      );
    }
    return null;
  }
}

export default FeedBackDetail;
