import {isEmpty} from 'loadsh'
import React from "react";
import  CONSTANTS  from '../Utils/Constants';
import { convertFromStandardFormat } from '../Utils/dateTimeUtil';
import { processUpdateFormService } from './ProcessUpdateFormService';
import { cloneDeep } from 'lodash';


const svgConstant= `<svg width="19" height="18" viewBox="0 0 20 16" fill={props.color} style={props.style} xmlns="http://www.w3.org/2000/svg">
    <path d="M11.06 6.02L11.98 6.94L2.92 16H2.25C2.11193 16 2 15.8881 2 15.75V15.08L11.06 6.02ZM14.66 0C14.41 0 14.15 0.1 13.96 0.29L12.13 2.12L15.88 5.87L17.71 4.04C18.1 3.65 18.1 3.02 17.71 2.63L15.37 0.29C15.17 0.09 14.92 0 14.66 0ZM11.06 3.19L0 14.25V17.5C0 17.7761 0.223858 18 0.5 18H3.75L14.81 6.94L11.06 3.19Z" fill="#212121"/>
  </svg>`;
const svgEditIcon=  `<img  height='14px' src={require('../../images/cross.png')}/>`
class CommonUtilities {
    statusList = {}
    getFormat(hours){
      hours =  hours>9?hours:"0"+hours;
      return hours;
    }
    getBrowserTimeZone(statusDateTime,trackingDetails,calledFromFeedback){
      let statusTime = cloneDeep(statusDateTime);
      var timeFormat = processUpdateFormService.getTimeFormat(trackingDetails);
     if(!isEmpty(statusTime) && !(statusTime.indexOf("PM")!=-1 || statusTime.indexOf("AM")!=-1)){
         let eta = new Date((statusTime).replace(/-/g, "/")+" UTC");
         let hours = eta.getHours();
         let minutes = eta.getMinutes();
         minutes = minutes < 10 ? '0'+minutes : minutes;
         if(timeFormat!=CONSTANTS.TWENTYFOUR_HOUR_FORMAT){
          var ampm = "";
          if(calledFromFeedback){
            ampm = hours >= 12 ? "PM" : "AM";
          }
          else{
            ampm = hours >= 12 ? containerConstants.formatString(containerConstants.PM) : containerConstants.formatString(containerConstants.AM);
          }
           hours = hours % 12;
           hours = hours ? hours : 12; // the hour '0' should be '12'
           hours = this.getFormat(hours);
           statusTime = hours + ':' + minutes + ' ' + ampm;
         }
         else{
           hours = this.getFormat(hours);
           statusTime = hours + ':' + minutes;
         }
         statusTime = eta.getFullYear()+"-"+this.getFormat((eta.getMonth()+1))+"-"+this.getFormat(eta.getDate())+" "+statusTime;
     }
     return statusTime;
}
  //to get sorted status list acc to time
  getSortedStatusLogOnDateTime = (trackingDetails,calledFromFeedback) => {
    let previousDate = ''
    let timings = [];
    let statusLogList = [];
    let status = [];

    for (let i = 0; i < trackingDetails.processStatusLogDomainList.length; i++) {
        if (trackingDetails.processStatusLogDomainList[i].processStatusLogDto[0].processMasterId == trackingDetails.eventProcessMasterId) {

        const statusLogs = trackingDetails.processStatusLogDomainList[i].processStatusLogDto;
        let statusLogsSortedArray = statusLogs.sort(function (a, b) {
          let dateA = new Date(a.statusTime)
          let dateB = new Date(b.statusTime);
          return (dateA.getTime() - dateB.getTime());
        });
        for (let i = 0; i < statusLogsSortedArray.length; i++) {
           statusLogsSortedArray[i].statusTime = trackingDetails.browserTimeZone?this.getBrowserTimeZone(statusLogsSortedArray[i].statusClientTime,trackingDetails,calledFromFeedback):statusLogsSortedArray[i].statusTime;
          statusLogsSortedArray[i].statusTime = convertFromStandardFormat(statusLogsSortedArray[i].statusTime, trackingDetails && trackingDetails.customerInteractionThemeSettingsDTO && trackingDetails.customerInteractionThemeSettingsDTO.dateFormat);
           if (previousDate !== statusLogsSortedArray[i].statusTime.substring(0, 10)) {
            let obj = {};
            obj.previousDate = statusLogsSortedArray[i].statusTime.substring(0, 10)
            obj.timings = obj.timings || []
            obj.timings.push(statusLogsSortedArray[i].statusTime.substring(10, 19))
            obj.status = obj.status || []
            let statusObj = {
               "status":statusLogsSortedArray[i].status,
               "remark":statusLogsSortedArray[i].remark?statusLogsSortedArray[i].remark:'',
               "statusCategory": statusLogsSortedArray[i].statusCategory
            };
               
            obj.status.push(statusObj)
            statusLogList.push(obj);
            this.statusList[statusLogsSortedArray[i].status] = statusLogsSortedArray[i].status;
            previousDate = statusLogsSortedArray[i].statusTime.substring(0, 10)
          }
          else {
            let statusObj = {
              "status":statusLogsSortedArray[i].status,
              "remark":statusLogsSortedArray[i].remark?statusLogsSortedArray[i].remark:'',
              "statusCategory": statusLogsSortedArray[i].statusCategory
           };
            this.statusList[statusLogsSortedArray[i].status] = statusLogsSortedArray[i].status;
            statusLogList[statusLogList.length - 1].timings.push(statusLogsSortedArray[i].statusTime.substring(10, 19))
            statusLogList[statusLogList.length - 1].status.push(statusObj)
          }
        }
        return {"statusLogList":statusLogList,"statusList":this.statusList};
        // return [statusLogList,this.statusList];
      }
    }
  }
  // returns type of status whether it is major ,minor or hidden
  typeOfStatus=(status,trackingDetails)=>{
    let trackerStatus = {}, configuredStatusList=[];
    if(trackingDetails && trackingDetails.trackerTimeLine){
    trackerStatus = JSON.parse(trackingDetails.trackerTimeLine);
  }
  configuredStatusList= trackerStatus?.statusList || [];
   for(let i=0;i<configuredStatusList.length;i++){
    if(status.status==configuredStatusList[i].status){
      return configuredStatusList[i];
    }
   }
   return '';
  }
  createButtonElement =()=>{
      const  buttonForEdit = document.createElement('Button');
      const imgElement =  document.createElement("img");
      imgElement.setAttribute("height","30px");
      imgElement.setAttribute("id","editIconDiy");
      imgElement.setAttribute("src",require("../images/editIcon.png"));

      buttonForEdit.className = "tooltiptext fe-chat-circle";
      buttonForEdit.appendChild(imgElement);
      return buttonForEdit;
  }

}
export const commonUtilities = new CommonUtilities();