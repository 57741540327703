import React from "react";

function Banner(props) {
  const bannerImg = {
    backgroundImage: "url(" + props.image + ")",
    height: "50%",
    backgroundPosition: "top center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    position: "relative",
    top: "0",
    height: "100%",
    borderRadius: '8px 8px 0px 0px'
  };

  return (
    <div className="background-grey desktop ">
      <div className="banner-image-image"
        id="bannerClicked"
        style={bannerImg}
        onClick={() => window.open(props.link, "_blank")}
      ></div>
    </div>
  );
}

export default Banner;
