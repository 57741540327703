import React, { Component } from 'react';
import { isEmpty } from 'loadsh'


class HelpSectionPrototype extends Component {


    getProcessFormList = (fontSize, fontFamily) => {
        if (!isEmpty(this.props.trackingDetails) && this.props.trackingDetails.processUpdateForm) {
            let forms = JSON.parse(this.props.trackingDetails.processUpdateForm);
            let arrayList = [];
            if (forms && forms.formList && !isEmpty(forms.formList)) {
                (forms.formList).map((form, index) => {
                    if (form.introText)
                        arrayList.push(
                            <li className='ci-pudo-detail-quick-action-delivery' button key={index} onClick={this.props.openProcessForm(index)}>
                                <span component > </span>
                                {form.introText.includes(' ') ?
                                    <p>{form.introText.length > 32 ? <>{form.introText.substring(0, 32)}&hellip;</> : form.introText}</p>
                                    :
                                    <p>{form.introText.length > 13 ? <>{form.introText.substring(0, 13)}&hellip;</> : form.introText}</p>
                                }
                            </li>
                        )
                })
            }
            return arrayList;
        }
    }
    hasQuickAction = (callSupport, emailSupport) => {
        if (this.props.trackingDetails && this.props.trackingDetails.processUpdateForm && !isEmpty(JSON.parse(this.props.trackingDetails.processUpdateForm).formList) ||
            this.props.trackingDetails && this.props.trackingDetails.pudo && this.props.trackingDetails.pudo.pudoScreen === true && this.props.pudoPoints && !isEmpty(this.props.pudoPoints) ||
            callSupport ||
            emailSupport) {
            return true;
        }
        return false;
    }
    render() {
        let callSupport, emailSupport;
        if (this.props.trackingDetails && this.props.trackingDetails.help && !isEmpty(this.props.trackingDetails.help)) {
            let help = JSON.parse(this.props.trackingDetails.help);
            if (help.helpLineContact && !isEmpty(help.helpLineContact) && help.helpLineContact.length > 2) {
                callSupport = help.helpLineContact;
            }
            if (help.helpLineEmail && !isEmpty(help.helpLineEmail) && help.helpLineEmail.length > 2) {
                emailSupport = help.helpLineEmail;
            }
        }
        return (
            <div className="mb20">
                <p className="fs14 semibold mb10" style={{ fontWeight: '500' }}>Manage Delivery</p>
                <div className="flex mb10 bg-white pv15 ph15 wdcard flex align-center">
                    <img src="../../images/CalendarBlank.png" className="mr10" alt="" />
                    <p>Reschedule the delivery</p>
                    <img src="../../images/ArrowSquareOut.png" style={{ marginLeft: 'auto' }} alt="" />
                </div>
                <div className="flex mb10 bg-white pv15 ph15 wdcard flex align-center">
                    <img src="../../images/ReceiptX.png" className="mr10" alt="" />
                    <p>Reschedule the delivery</p>
                    <img src="../../images/ArrowSquareOut.png" style={{ marginLeft: 'auto' }} alt="" />
                </div>
                {!isEmpty(callSupport) ?
                    <a href={"tel:" + callSupport} className="flex mb10 bg-white pv15 ph15 wdcard flex align-center" style={{ color: '#05223D' }}>
                        <img src="../../images/PhoneCall.png" className="mr10" alt="" />
                        <p>Reschedule the delivery</p>
                        <p style={{ marginLeft: 'auto' }}>{callSupport}</p>
                    </a> : ''}
                {!isEmpty(emailSupport) ?
                    <a href={"mailTo:" + emailSupport} className="flex mb10 bg-white pv15 ph15 wdcard flex align-center" style={{ color: '#05223D' }}>
                        <img src="../../images/EnvelopeSimple.png" className="mr10" alt="" />
                        <p>Write to us</p>
                        <p style={{ marginLeft: 'auto' }}>{emailSupport}</p>
                    </a> : ''}
            </div>
        );
    }
}

export default HelpSectionPrototype;