import React from "react";

import { isEmpty } from "loadsh";


class OrderInfoPrototype extends React.Component {


    render() {
        const {
            fontSize,
            fontFamily,
            primaryBgColor,
            primaryTextColor,
        } = this.props;

        return (
            <div className="pb10" style={{ borderBottom: '1px solid #F0F0F0' }}>
                {this.props.arrayList &&
                    !isEmpty(this.props.arrayList) &&
                    Object.values(this.props.arrayList).map(function (element, index) {
                        return (
                            <div className="flex mv15 align-center">
                                <div className="mr10" style={{ width: 80, height: 80, borderRadius: 4, overflow: 'hidden' }}>
                                    <img style={{height:80,width:80}}src={element.itemImage} alt="" />
                                </div>
                                <div>
                                    <p className="text-black">{element.itemName}</p>
                                    <p className="text-gray">$48.99 {element.itemQuantity != 0 ? "| Qty:" + element.itemQuantity :''}</p>
                                </div>
                            </div>

                        );

                    })}


                
            </div>
        );
    }
}

export default OrderInfoPrototype;




