import React from "react";
import RightTick from "../images/tick-inside-a-circle.svg";
import Header from "../components/Header";

class ExpireMessage extends React.Component {
  render() {
    return (
      <div>
        <div id="top-area"></div>
        <div className="circle-excl">
          {" "}
          <span>!</span>{" "}
        </div>
        {/* <img src={require('../images/unable-track.svg')} className="successImg"/> */}
        <p
          className="scheduling-link"
          style={{ fontSize: this.props.fontSize }}
        >
          {containerConstants.formatString(containerConstants.UhHo)}
          <br />
          {containerConstants.formatString(
            containerConstants.ThisSchedulingLinkHasExpired
          )}{" "}
        </p>
        <p className="share-link" style={{ fontSize: this.props.fontSize }}>
          {containerConstants.formatString(
            containerConstants.WeWillShareANewLinkWithYouShortly
          )}{" "}
        </p>
      </div>
    );
  }
}

export default ExpireMessage;
