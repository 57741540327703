import { restClientService } from "../../../services/RestClientService";

class CurbsidePickupService {

    async getCurbsidePickupDetail(url) {
        const response = await restClientService.getCurbsidePickupDetail(url);
        return response;
    }
}

const curbsidePickupService = new CurbsidePickupService();

export default curbsidePickupService;