import React from "react";
import Grid from "@material-ui/core/Grid";
import { isEmpty } from "loadsh";
import { MyContext } from "../../containers/context";
import CallIcon from "../../Utils/icons/CallIcon"
import ChatIcon from "../../Utils/icons/ChatIcon"

class FieldExecutiveFooter extends React.Component {
  constructor(props) {
    super(props);
  }

  callToExecutiveByType = () => {
    let enableCalling = this.props.trackingDetails.cloudTelephony || this.props.trackingDetails.callToFE;
    let callee = undefined;
    if(this.props.trackingDetails.cloudTelephony) {
      enableCalling = true;
      callee = this.props.trackingDetails.cloudTelephonyUrl;
    } else if(this.props.trackingDetails.callToFE && this.props.trackingDetails.feMob) {
      enableCalling = true;
      callee = this.props.trackingDetails.feMob;
    }

    if(this.props.isMobileView && enableCalling) {
      return (
        <div
          className="fe-chat-circle"
          style={{
            backgroundColor: this.props.secondaryBgColor,
            borderColor: this.props.secondaryBgColor,
          }}
        >
          <a href={"tel:" + callee}>
            <CallIcon fill={this.props.primaryBgColor} />
          </a>
        </div>
      );
    }
  }

  render() {
    return (
      <MyContext.Consumer>
        {({ primaryBgColor , secondaryBgColor}) => (
          <div
            style={{
              position: "absolute",
              bottom: 10,
              left: 10,
              right: 10,
              padding: "12px 0px 12px 16px",
              background: "#fff",
              borderRadius: "5px",
            }}
          >
            <Grid container >
              <Grid item xs={12}>
                <p className="fe-details-label">
                  {this.props.trackingDetails.executiveLabel
                    ? this.props.trackingDetails.executiveLabel
                    : containerConstants.formatString(containerConstants.Executive)
                    }
                </p>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={8} container direction="row" alignItems="center">
                {this.props.trackingDetails.feImage &&
                !isEmpty(this.props.trackingDetails.feImage) ? (
                  <img
                    src={this.props.trackingDetails.feImage}
                    height="40px"
                    width="40px"
                    style={{borderRadius : "50%"}}
                  />
                ) : (
                  <img src={require("../../images/user.svg")} />
                )}
                <span className="layoutPhone" >
                  <p>{this.props.trackingDetails.userName
                    ? this.props.trackingDetails.userName
                    : ""}
                  </p>
                  <p className="feMob">
                    {this.props.trackingDetails.callToFE && this.props.trackingDetails.feMob
                      ? this.props.trackingDetails.feMob
                      : ""}
                  </p>
                </span>
              </Grid>
              <Grid
                item
                xs={4}
              >
                <div className="fe-chats">
                  {this.props.trackingDetails.messageToFE &&
                    this.props.trackingDetails.orderId && (
                      <div
                        className="fe-chat-circle"
                        style={{
                          backgroundColor: secondaryBgColor,
                          borderColor: secondaryBgColor,
                        }}
                        onClick={this.props.setMessageModal}
                      >
                        <ChatIcon fill={primaryBgColor} />
                      </div>
                    )}

                  {this.callToExecutiveByType()}
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}

export default FieldExecutiveFooter;
