import React from "react";
import PickupBoardIcon from "../icons/pickup-board";

export default class GettingYourOrder extends React.Component {
    render() {
        return (
            <div>
                <div className="curb-side-your-order-details">
                    <div className="curb-side-your-order-notified">
                        <h1>{this.props.curbsidePickupData && this.props.curbsidePickupData.postCheckInData && this.props.curbsidePickupData.postCheckInData.headerText}</h1>
                        <p>{this.props.curbsidePickupData && this.props.curbsidePickupData.postCheckInData && this.props.curbsidePickupData.postCheckInData.subText}</p>
                    </div>
                    <div className="pickup-parking-board bg-image-banner">
                        <PickupBoardIcon color={this.props.primaryBgColor}/>                    </div>
                </div>
            </div>
        )
    }
}