import initialState from './pudoInitialState';
import PUDO_CONSTANTS   from '../../Utils/PudoConstants'

export default function pudoReducer(state = initialState, action) {
  switch(action.type) {

    case PUDO_CONSTANTS.SET_PUDO_DATA:
      return state.set('pudoPoints', action.payload.visibleList)
                  .set('updateProcessAttributeList', action.payload.updateProcessAttributeMapping)
                  .set('dsMappedKeyValue', action.payload.dsMappedKeyValue)
                  .set('referenceNumber',action.payload.referenceNumber);

    case PUDO_CONSTANTS.SET_PUDO_POINTS:
      return state.set('pudoPoints', action.payload);

    default: 
      return state;
  }
}