import styled from 'styled-components';

const TableLoaderStyle = styled.div`



.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #999;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}






${'' /* .table-loader {
    display: flex;
    justify-content: flex-start;
    padding: 7px;
}
.loader-table-first {
    width: 100%;
    border-right: 1px solid #f1f3f6;
}
.loader-table-body {
    width: 100%;
}
.loader-table-col {
    width: 115px !important;
}
.ant-card-loading-block {
    height: 40px;
    width: 100%;
    margin: 10px 0 0;
    border-radius: 2px;
    background: -webkit-gradient(linear, left top, right top, from(rgba(221, 221, 221, 1)), color-stop(rgba(221, 221, 221, 1)), to(rgba(221, 221, 221, 1)));
    background: -webkit-linear-gradient(left, rgba(221, 221, 221, 1), rgba(207, 216, 220, .4), rgba(221, 221, 221, 1));
    background: linear-gradient(90deg, rgba(221, 221, 221, 1), rgba(207, 216, 220, .4), rgba(221, 221, 221, 1));
    -webkit-animation: card-loading 1.4s ease infinite;
    animation: card-loading 1.4s ease infinite;
    background-size: 600% 600%
}
.loader-table-header {
    height: 55px;
    background: -webkit-gradient(linear, left top, right top, from(rgba(221, 221, 221, 1)), color-stop(rgba(221, 221, 221, 1)), to(rgba(221, 221, 221, 1)));
    background: -webkit-linear-gradient(left, rgba(221, 221, 221, 1), rgba(207, 216, 220, .4), rgba(221, 221, 221, 1));
    background: linear-gradient(90deg, rgba(221, 221, 221, 1), rgba(207, 216, 220, .4), rgba(221, 221, 221, 1));
    -webkit-animation: card-loading 1.4s ease infinite;
    animation: card-loading 1.4s ease infinite;
    background-size: 600% 600%;
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
}
.loader-table-header2{
    height: 15px;
    background: -webkit-gradient(linear,left top,right top,from(rgba(221,221,221,1)),color-stop(rgba(221,221,221,1)),to(rgba(221,221,221,1)));
    background: -webkit-linear-gradient(left,rgba(221,221,221,1),rgba(207,216,220,.4),rgba(221,221,221,1));
    background: linear-gradient(90deg,rgba(221,221,221,1),rgba(207,216,220,.4),rgba(221,221,221,1));
    -webkit-animation: card-loading 1.4s ease infinite;
    -webkit-animation: card-loading 1.4s ease infinite;
    animation: card-loading 1.4s ease infinite;
    background-size: 600% 600%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 7px;
    -webkit-align-items: flex-end;
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
    margin-bottom: 10px;
}
@-webkit-keyframes card-loading {
    0%,
    to {
        background-position: 0 50%
    }
    50% {
        background-position: 100% 50%
    }
}
@keyframes card-loading {
    0%,
    to {
        background-position: 0 50%
    }
    50% {
        background-position: 100% 50%
    }
}
.mb0 {
    margin-bottom: 0px;
} */}
`
export default TableLoaderStyle;