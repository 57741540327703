import axios from 'axios';
import { isEmpty } from 'loadsh';

class DetailMapService {
  details;
  url;
  index;
  markerUpdate
  
  async initializeMap(obj, details, url, list, searchLat, searchLng) {
    try {
      this.details = details;
      this.url = url;
      this.markerUpdate= obj;
      const map = this.map = L.map('map', { closePopupOnClick: false, zoomControl:false,  dragging: false, scrollWheelZoom: false }).setView([28, 77],18);
      map.panTo(new L.LatLng(26, 77));
      let herePoweredAccount =  window.localStorage.getItem('isHerePoweredAccount') === 'true' ? true : false
      if(herePoweredAccount){
        hereMapService.getHereMapLayer(this.map)
      }
      else{
        L.tileLayer('https://map.fareye.co/styles/klokantech-basic/{z}/{x}/{y}.png', { maxZoom: 18, id: 'fareye', zoomControl:false, scrollWheelZoom: false }).addTo(map);
      }
      map.doubleClickZoom.disable(); 
      this.setNearestLocation(map, details, list,  searchLat, searchLng)
      return map;
    } catch (err) {
      console.log("err", err);
    }
  }

   setNearestLocation(map, details , list,  searchLat, searchLng) {
     

    if (map && !isEmpty(list)) {
        L.Icon.Big = L.Icon.extend({
          options: {
            iconUrl: require('../../images/new-icon2.png'),
            iconRetinaUrl: require('../../images/new-icon2.png'),
            iconSize: [28, 'auto'],
          }
        });
        var jobIcon =  new L.Icon({
            //iconUrl: this.details&& this.details.customerInteractionThemeSettingsDTO && this.details.customerInteractionThemeSettingsDTO.jobLocationIcon,
            //iconRetinaUrl: this.details&& this.details.customerInteractionThemeSettingsDTO && this.details.customerInteractionThemeSettingsDTO.jobLocationIcon,
            iconUrl: require('../../images/job-icon.png'),
            iconRetinaUrl: require('../../images/job-icon.png'),
            iconAnchor: [12, 24],
            iconSize: [20, 25],
          })
    
        let bigIcon = new L.Icon.Big(); 
        var marker1 = L.marker([list.latitude, list.longitude], { icon: bigIcon }).addTo(map);
        if(searchLat && searchLng){
          console.log('inside search it is called')
          var marker2 = L.marker([searchLat , searchLng], { icon: jobIcon }).addTo(map);
        }
        else{
          var marker2 = L.marker([details.jobLat , details.jobLng], { icon: jobIcon }).addTo(map);
        }
        let bound = [];
        bound.push([list.latitude,list.longitude]);
        bound.push([searchLat||details.jobLat , searchLng||details.jobLng]);
        map.fitBounds(bound,{padding:[5,5]});
           
        // map.fitBounds(featureGroup, getBounds())
    }
  }

}
export const detailMapService = new DetailMapService();
