import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import ItemDetail from "../../components/CustomScreen/ItemDetail";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import CloseIcon from "@material-ui/icons/Close";
import { isEmpty } from "loadsh";
import { object } from "prop-types";

class ItemList extends Component {
  drawerItemId = "";
  constructor(props) {
    super(props);

    this.state = {
      openItemDetailsDrawer: false,
    };
  }

  getDetailsClickHandler = (index) => {
    this.drawerItemId = index;
    this.setState({
      openItemDetailsDrawer: !this.state.openItemDetailsDrawer,
    });
  };

  closeDrawer = () => {
    this.setState({
      openItemDetailsDrawer: false,
    });
  };

  getItemDetails = () => {
    console.log("list", this.drawerItemId);
    return (
      <SwipeableDrawer
        anchor="bottom"
        open={this.state.openItemDetailsDrawer}
        onClose={this.closeDrawer}
      >
        <div className="p15 auto-scroll-modal">
          <div className="mb30">
            <span>
              {this.props.itemList &&
              this.drawerItemId != undefined &&
              this.props.itemList[this.drawerItemId]
                ? this.props.itemList[this.drawerItemId].itemCategory
                : ""}
            </span>
            <span className="fs14 fontItalic fl-right sub-categ">
              {this.props.itemList &&
              this.drawerItemId != undefined &&
              this.props.itemList[this.drawerItemId]
                ? this.props.itemList[this.drawerItemId].subCategory
                : ""}
              <Button
                size="small"
                onClick={this.closeDrawer}
                className="CloseBtn"
              >
                <CloseIcon className="text-gray" />
              </Button>
            </span>
          </div>
          <ItemDetail
            closeDrawer={this.closeDrawer}
            list={
              this.props.itemList &&
              this.drawerItemId != undefined &&
              this.props.itemList[this.drawerItemId]
                ? this.props.itemList[this.drawerItemId].attributes
                : ""
            }
          />
        </div>
      </SwipeableDrawer>
    );
  };

  renderCardLayout = () => {
    let cards = [];
    // Object.values(this.props.itemList).map((list,index)=>{
    for (let i = 0; i < this.props.itemList.length; i++) {
      const list = this.props.itemList[i];
      cards.push(
        <Card className="mb10 no-box-shadow">
          <CardContent onClick={() => this.getDetailsClickHandler(i)}>
            <div className="leftImgCategDiv">
              {list &&
              list.image &&
              !isEmpty(list.image) &&
              (list.image.indexOf("http") != -1 ||
                list.image.indexOf("https") != -1) ? (
                <img style={{ height: "40px" }} src={list.image} alt="" />
              ) : (
                <span className="mr20 fs12">{list.image}</span>
              )}
              <span className="fs14 ml10">{list.itemCategory}</span>
            </div>
            <div className="rightCatRefDiv text-center">
              <div className="fs12 fontItalic mb10">{list.subCategory}</div>
              <div className="fs12">{list.referenceNumber}</div>
            </div>

            <CardActions>
              <Button size="small" className="action-btn">
                {window.sessionStorage.getItem("languageDirection") == "ltr" ? (
                  <NavigateNextIcon className="text-gray" />
                ) : (
                  <NavigateBeforeIcon className="text-gray" />
                )}
              </Button>
              {this.getItemDetails(list, i)}
            </CardActions>
          </CardContent>
        </Card>
      );
    }
    // })

    return cards;
  };

  render() {
    if (this.props.itemList) {
      return <div className="bg-extra-light">{this.renderCardLayout()}</div>;
    }
  }
}

export default ItemList;
