import React from "react";
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { Drawer } from "@material-ui/core";

export default class CurbsideNeedHelpDrawer extends React.Component {

    render() {
        return (
            <Drawer
                anchor="bottom"
                open={this.props.showNeedHelpDrawer}
                onClose={() => this.props.setHelpModel(false)}
            >
                <div className="curb-side-order-info-header curbside-pickup-need-help">
                    <h4>How can we help you?</h4>
                    <CloseIcon fontSize="large" style={{cursor: "pointer"}} onClick={() => this.props.setHelpModel(false)} />

                    {this.props.needHelp && this.props.needHelp.callSupport &&
                        <div className="pre-check-order-confirm-row order-confirm-box-sapce-one" onClick={() => window.open(`tel:${this.props.needHelp.callSupport}`,'_self', 'noopener')}>
                            <div className="order-icon-box" style={{ backgroundColor: this.props.secondaryBgColor }}>
                                <CallOutlinedIcon style={{ color: this.props.primaryBgColor }} />
                            </div>
                            <div className="order-right-content-info">
                                <h4>Call for Support</h4>
                                <span>{this.props.needHelp.callSupport}</span>
                            </div>
                        </div>
                    }

                    {this.props.needHelp && this.props.needHelp.emailSupport &&
                        <div className="pre-check-order-confirm-row order-confirm-box-sapce-one" onClick={() => window.open(`mailto:${this.props.needHelp.emailSupport}`,'_self', 'noopener')}>
                            <div className="order-icon-box" style={{ backgroundColor: this.props.secondaryBgColor }}>
                                <EmailOutlinedIcon style={{ color: this.props.primaryBgColor }} />
                            </div>
                            <div className="order-right-content-info">
                                <h4>Write to Us</h4>
                                <span>{this.props.needHelp.emailSupport}</span>
                            </div>
                        </div>
                    }

                </div>
            </Drawer>
        )
    }
}