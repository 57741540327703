import axios from 'axios';
import { isEmpty } from 'loadsh';
import { hereMapService } from '../../HereMap/HereMapService';




class MapViewService {
  details;
  url;
  index;
  markerUpdate;
  jobMarker;
  


 
  async initializeMap(obj, details, url, list) {

    try {
      this.details = details;
      this.url = url;
      this.markerUpdate= obj;
      const map = this.map = L.map('map', { closePopupOnClick: false,attributionControl: false}).setView([28, 77], 2);
      map.panTo(new L.LatLng(26, 77));
      let herePoweredAccount =  window.localStorage.getItem('isHerePoweredAccount') === 'true' ? true : false
      if(herePoweredAccount){
        hereMapService.getHereMapLayer(this.map)
      }
      else{
        L.tileLayer('https://map.fareye.co/styles/klokantech-basic/{z}/{x}/{y}.png', { maxZoom: 20, zoom : 4, id: 'fareye' }).addTo(map);
      }
     this.setNearestLocation(map, details, list)
     this.setFeIcon(details);
      return map;
    } catch (err) {
      console.log("err", err);
    }
  }

   setNearestLocation(map, details , list) {
     console.log('list', list)
     var that = this;
    if (map && !isEmpty(list)) {
      var count = 0; 
      // var bounds = L.latLngBounds() // Instantiate LatLngBounds object
      var bounds = [];
      for (var i = 0; i < list.length; i++) {

        var greenIcon = new L.Icon({
          iconUrl: require('../../../images/location.svg'),
          iconRetinaUrl: require('../../../images/location.svg'),
          iconAnchor: [12, 30],
          iconSize: [28, 41],
        })
        var jobIcon =  new L.Icon({
          iconUrl: require('../../../images/job-icon.png'),
          iconRetinaUrl: require('../../../images/job-icon.png'),
          iconAnchor: [12, 24],
          iconSize: [20, 25],
        }) 
        let lat_lng = [list[i].latitude, list[i].longitude]
        let marker = L.marker([list[i].latitude, list[i].longitude], { icon: greenIcon }).addTo(map)
        .on('click', function(e) {
          if(marker){
              marker.setIcon(greenIcon);  
          }   
            marker = e.target.setIcon(greenIcon);
            that.findIndexFromLatLngList(list, e.latlng)
          }.bind(this));    
          marker._icon.id = 'marker' + count++;  
          bounds.push(lat_lng)      
      } 
       this.jobMarker = L.marker([details.jobLat , details.jobLng], { icon: jobIcon }).addTo(map);
       let customOptions =
       {
         'maxWidth': '400',
         'width': '200',
         'className' : 'popupCustom'
         }
      this.jobMarker.bindPopup("Delivery Address", customOptions,{closeOnClick:true}).openPopup();
      if(!isEmpty(this.markerUpdate.props.searchLocation) && this.markerUpdate.props.searchLocation.latitude) {
        this.setSearchLocation(this.markerUpdate.props.searchLocation);
        bounds = [...bounds, [this.markerUpdate.props.searchLocation.latitude, this.markerUpdate.props.searchLocation.longitude]];
      }
      this.markerUpdate.props.mapFitBound(bounds,details.jobLat,details.jobLng,map, {paddingBottomRight: [20, 250],paddingTopLeft:[20,50]})
    }
  }

  findIndexFromLatLngList(list,latlng){
    for(var i =0 ;i<list.length;i++){
      if(list[i].latitude == latlng.lat && list[i].longitude==latlng.lng){
        this.index= i ;
        this.markerUpdate.props.points(this.index);
        return i;
      }
    }
    return -1;
  }

  setFeIcon(){
    if(this.details && this.details.customerInteractionThemeSettingsDTO && this.details.customerInteractionThemeSettingsDTO.jobLocationIcon){
      this.jobIcon =  new L.Icon({
        iconUrl: this.details&& this.details.customerInteractionThemeSettingsDTO && this.details.customerInteractionThemeSettingsDTO.jobLocationIcon,
        iconRetinaUrl: this.details&& this.details.customerInteractionThemeSettingsDTO && this.details.customerInteractionThemeSettingsDTO.jobLocationIcon,
        iconUrl: require('../../../images/ico.png'),
        iconRetinaUrl: require('../../../images/ico.png'),
        iconAnchor: [12, 24],
        iconSize: [20, 25],
      })
    }
  }

  setSearchLocation(location) {
    if(isEmpty(location) || !location.latitude || !location.longitude) {
      return;
    }

    const searchLocationIcon = new L.Icon({
      iconUrl: require('../../../images/marker-icon.svg'),
      iconRetinaUrl: require('../../../images/marker-icon.svg'),
      iconSize: [15, 22],
    })
    const searchLocationMarker = L.marker([location.latitude , location.longitude], { icon: searchLocationIcon }).addTo(this.map);
  }

  async fetchPudoData(url, lat, lng, masterCode) {
    try {
       const response = await axios.get("/app/rest/fetch_pudo_data", {
        params: {
          url,
          lat,
          lng,
          masterCode
        }
      });
  
      if(response && response.status == 200) {
        if(!isEmpty(response.data)) {
          return response.data;
        }
      }     
    } catch(err) {
    }
  }

}
export const mapViewService = new MapViewService();
