import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import "../../components/Map/leaflet.css"
import {processUpdateFormService } from '../../services/ProcessUpdateFormService'
import * as CustomerInteractionAction from '../../CustomerInteractionActions';
import * as LivetrackingAction from '../LiveTracking/LiveTrackingAction';
import FieldExecutiveDetail from '../../components/FieldExecutiveDetail';
import {isEmpty} from 'loadsh'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { trackingInaccuracyConstants, predictMovementConstants } from './liveTrackingConstants';
import { includes } from 'lodash';

const UNFOCUS_REFRESH_DURATION = 4 * 60 * 1000; // 4 min

class EmbedTracking extends React.Component{

    IS_QUEUE_RUN = false;
    prevWindowHeight
     constructor(props){
         super(props);
         this.state = {
             eta:'',
             isEmbed:true,
             showOnlyMap:false,
             showInaccuracyMessage: false,
         }
         this.fakeLocation = {}
         this.locationUpdated = false;
         this.intervalId = null;
         this.lastUnfocusedTimestamp = null;
     }

  setListenerForUnactiveWindow = () => {
    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState === 'visible') {
        if(this.lastUnfocusedTimestamp && Math.abs(Date.now() - this.lastUnfocusedTimestamp) > UNFOCUS_REFRESH_DURATION) {
          console.log("Updating state with new values, refreshing...");
          window.location.reload();
        }
        this.setTrackLogsInterval();
      } else {
        this.clearTrackLogsInterval();
        this.lastUnfocusedTimestamp = Date.now();
      }
    }.bind(this));
  }

     async componentDidMount(){
      if(!isEmpty(this.props.trackingDetails.liveTrackingPreviousJobsInfo) && this.props.trackingDetails.liveTrackingPreviousJobsInfo.enabled) {
        await this.props.actions.getPreviousJobsInfo(this.props.url, this.props.trackingDetails);
      }
        this.setDeferTasks();
        if(this.props.trackingDetails){
          this.getMapBaseLayer();
          this.setListenerForUnactiveWindow();
        }
         if(window.location.href.indexOf('&embed=true')!=-1){
          this.setState({
            showOnlyMap:true,
          })
        }
      }

  setDeferTasks = () => {
    setTimeout(() => {
      this.setState({showInaccuracyMessage: true});
    }, 30000);
  }


    async componentDidUpdate(prevProps){ 
      if( this.props.trackingDetails.liveTrackingPreviousJobsInfo && this.props.trackingDetails.liveTrackingPreviousJobsInfo.enabled 
        && this.props.previousJobCount!=-1 && this.props.previousJobCount>0  && this.props.tripEta["time"] ){
          setTimeout(function(){
            if(this.state.showOnlyMap && (this.props.tripEta) && (this.props.tripEta.actualEta) && (this.props.tripEta.actualEta.includes("Today") )){ 
              this.props.actions.updatePopup(this.props.tripEta["time"],this.props.trackingDetails);
            }
           }.bind(this),300);
           
       }
    }
      componentWillUnmount(){
        this.clearTrackLogsInterval();
      }

      async getMapBaseLayer(){
        this.isMapLoad = false;
        this.map = await this.props.actions.initializeMap(this,this.props.trackingDetails,this.props.url,this.state.isEmbed);
        this.setTrackLogsInterval();
        window.addEventListener("resize", () => {setTimeout(() => {}, 500)});
      }


      setTrackLogsInterval = () => {
        if(this.intervalId) {
          this.clearTrackLogsInterval();
        }

        this.intervalId = setInterval(this.getTrackLogs.bind(this), predictMovementConstants.TRACKLOGS_INTERVAL);
      }

      clearTrackLogsInterval = () => {
        if(!this.intervalId) {
          return;
        }

        clearInterval(this.intervalId);
        this.intervalId = null;
      }

      renderInaccuracyMessage = () => {
        // this is hidden using css to render condition from the liveTracking service itself
        let driverText = this.props.trackingDetails && this.props.trackingDetails.executiveLabel;
        driverText = !isEmpty(driverText) ? driverText : 'Driver';
        return (
          <div className='inaccurate-tracking-message' id={trackingInaccuracyConstants.TRACKING_INACCURACY_MESSAGE_ID}>
            <ErrorOutlineIcon style={{'color': 'yellow', marginRight: '4px'}}/>
            <span>{`${driverText} is on your way, location may not be real-time due to network glitches.`}</span>
          </div>
        )
      }

    

      // async getTrackLogs(){
      //   if(this.props.trackingDetails && this.props.trackingDetails.jobId  && (this.props.trackingDetails.userId || this.props.trackingDetails.sourceId) && !this.IS_QUEUE_RUN ){
      //     const data = await processUpdateFormService.getTrackLogs(this.props.trackingDetails.jobId, this.props.trackingDetails.trackLogSource, this.props.trackingDetails.userId,this.props.url,this.dateTime,this.props.trackingDetails.sourceId);
      //     const eta = await this.props.actions.setTrackLogData(data);
      //      if(eta && !isEmpty(eta) && eta!=this.state.eta){
      //        this.setState({'eta':eta});
      //      }
      //   }
      // }

      async getTrackLogs(){
        let eta={};
        eta = await this.props.actions.startFakeMovement(this.props.trackingDetails,this.props.url);
        if(eta && !isEmpty(eta) && eta!="0 mins" && eta!=this.state.eta){
           this.setState({'eta':eta});
         }
    
     }

     clearZoom=()=>{
      this.map = this.props.actions.clearZoom();
    }

      render(){
        if(!this.prevWindowHeight){
            this.prevWindowHeight = window.innerHeight;
            
          } 
          console.log("previHeight",this.prevWindowHeight,window.innerWidth)
         return (
            <div id="top-area" className="ci-tracking-map-conatiner">
              {this.state.showInaccuracyMessage && this.renderInaccuracyMessage()}
              <div className="ci-map-mobile-divider"></div>
              <div id="map" style={{height:this.state.showOnlyMap== false?'448px':'100VH', borderRadius: '4px'}}/>
              <div className="re-center" style={{bottom:"40px"}} onClick={()=>this.clearZoom()} >
                  <img src={require('../../images/map-recenter.png')} alt=""/>
              </div>
            </div>
         )
      }
}

function mapStateToProps(state) {
    return {
      trackingDetails: state.customerInteractionReducer.trackingDetails,
      url:state.customerInteractionReducer.url,
      jobUpdated:state.customerInteractionReducer.jobUpdated,
      previousJobCount:state.customerInteractionReducer.previousJobCount,
      tripEta: state.customerInteractionReducer.tripEta,
    };
  }
  
  
  function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ ...CustomerInteractionAction,...LivetrackingAction }, dispatch)
    }
  }
  
  export default connect(mapStateToProps,mapDispatchToProps)(EmbedTracking);