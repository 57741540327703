import {setState} from '../../Utils/setState'
import {isEmpty} from 'loadsh'
import {detailMapService}  from './DetailMapService';


export function initializeMap(obj,detail,url, list, searchLat, searchLng){
    return async function (dispatch) {
        try{
            const map = await detailMapService.initializeMap(obj,detail,url, list, searchLat, searchLng);
            return map;
        }catch(err){
          console.log("not working");
        }
    }
}    







