import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

export default class CurbsideOrderConfirmedText extends React.Component {

    renderSlides = () => {
        if (this.props.curbsidePickupData && this.props.curbsidePickupData.preCheckInData && this.props.curbsidePickupData.preCheckInData.useHeroImage) {
            return this.props.curbsidePickupData.preCheckInData.s3ImageList && this.props.curbsidePickupData.preCheckInData.s3ImageList.map((s3Image, i) => {
                return (
                    <SwiperSlide key={i}>
                        <img src={s3Image.s3URL} alt="" />
                    </SwiperSlide>
                )
            });
        } else {
            return (
                <SwiperSlide key={"default"}>
                    <img src={require("../../../../images/banner/confirm-pickup-banner.svg")} alt="" />
                </SwiperSlide>
            )
        }
    }

    isEnableNavigation = () => {
        if (this.props.curbsidePickupData && this.props.curbsidePickupData.preCheckInData) {
            if (this.props.curbsidePickupData.preCheckInData.useHeroImage && (this.props.curbsidePickupData.preCheckInData.s3ImageList && this.props.curbsidePickupData.preCheckInData.s3ImageList.length > 1)) {
                return true;
            }
        }
        return false;
    }

    render() {
        return (
            <div>

                <div className="pre-check-banner-container">
                    {/* <img src={orderConfirmBanner} alt="" /> */}
                    <Swiper navigation={this.isEnableNavigation()} spaceBetween={12} slidesPerView={1} className="custom-swiper-slider-curbside"

                        onSlideChange={(s) => console.log(s)}
                        onSwiper={(swiper) => console.log(swiper)} >
                        {this.renderSlides()}
                    </Swiper>
                    {/* <img src={require("../../../../images/banner/confirm-pickup-banner.svg")} alt="" /> */}
                </div>

                <div className="pre-check-order-confirm-container">
                    <div className="pre-check-order-details-info">
                        <h1>{this.props.curbsidePickupData.preCheckInData && this.props.curbsidePickupData.preCheckInData.headerText}</h1>
                        <p>{this.props.curbsidePickupData.preCheckInData && this.props.curbsidePickupData.preCheckInData.subText}</p>
                    </div>
                    <button type="button" className="submit-button"
                        onClick={this.props.onPreCheckInSubmit}>{this.props.curbsidePickupData.preCheckInData && this.props.curbsidePickupData.preCheckInData.buttonText}</button>
                </div>

            </div>

        )
    }
}