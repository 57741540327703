import { cloneDeep, isEmpty } from "lodash";
import { dummyBannerDetails, dummyFeedbackNodeData, dummyLastMileAndStatusDetails, dummyTrackingDetails } from "../constant/previewData";
import API from "../../../Config/API";
import { restClientService } from "../../../services/RestClientService";
import Axios from "axios";
import { successCategoryDummyData } from "../constant/successCategoryData";
import { failCategoryDummyData } from "../constant/failCategoryData";

class CiDiyService {

    getTrackingDetailsForPreview= async (diyConfig)=>{
        var statusCategory = diyConfig?.ciDiySettingStatus;
        var dummyData= {
            ...dummyTrackingDetails,
            ...dummyLastMileAndStatusDetails,
            marketingAssets:{...dummyBannerDetails} 
        }

        if(!diyConfig){
            return dummyData;
        }
        if(diyConfig.deviceType=="MOBILE"){
            dummyData["isMobileType"]=true;
            dummyData["screen"]="layoutScreen";
        }else{
            dummyData["isMobileType"]=false;
            dummyData["screen"]="DeskTopView";
        }

        if(diyConfig.themeSetting?.companyLogo){
            dummyData.companyLogo = diyConfig.themeSetting.companyLogo
        }

        if(diyConfig.rawImageObj?.companyLogo){
           dummyData.companyLogo = await new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(diyConfig.rawImageObj.companyLogo);
            reader.onload = () => resolve(reader.result);
          });
            
        }

        var ciDiyStatusWiseSetting = diyConfig?.ciDiyStatusWiseSetting;
        if (!isEmpty(ciDiyStatusWiseSetting)) {
            var marketingAsset = ciDiyStatusWiseSetting?.marketingAssetDIY;
            if (!isEmpty(marketingAsset)) {
                dummyData.marketingAssets = marketingAsset;
                if(marketingAsset?.enableCarousel==false && marketingAsset?.enableBanner==false ){
                    dummyData.marketingAssets= dummyBannerDetails;
                }
            }
            
            var feedbackEnabled = ciDiyStatusWiseSetting?.feedbackDIY?.enableFeedback;
            if(feedbackEnabled || ( statusCategory =="success" && feedbackEnabled==undefined)||(statusCategory =="fail" && feedbackEnabled==undefined)){
                // dummyData = { ...dummyData,...dummyFeedbackNodeData};
                
                var categoryWiseDummyData =dummyFeedbackNodeData;
                if(statusCategory =="success"){
                    categoryWiseDummyData = successCategoryDummyData;
                }
                if(statusCategory== "fail"){
                    categoryWiseDummyData = failCategoryDummyData;
                }
                var feedBackNode = JSON.parse(categoryWiseDummyData.feedback);
                if(!isEmpty(ciDiyStatusWiseSetting?.feedbackDIY?.feedbackHeading)){
                    feedBackNode["feedbackHeading"] = ciDiyStatusWiseSetting?.feedbackDIY?.feedbackHeading;
                }
                if(ciDiyStatusWiseSetting?.feedbackDIY?.reasonv3){
                    feedBackNode["reasonv3"] = ciDiyStatusWiseSetting?.feedbackDIY?.reasonv3;
                }
                var updatedDummyFeedbackNodeData = {...categoryWiseDummyData,...{feedback:JSON.stringify(feedBackNode)}};
                dummyData = { ...dummyData,...updatedDummyFeedbackNodeData};
            }
            var helpObject = {};
            if(!isEmpty(ciDiyStatusWiseSetting?.supportDIY?.helpLineEmail)){
                helpObject["helpLineEmail"] = ciDiyStatusWiseSetting?.supportDIY?.helpLineEmail;
            }
            if(!isEmpty(ciDiyStatusWiseSetting?.supportDIY?.helpLineContact)){
                helpObject["helpLineContact"] = ciDiyStatusWiseSetting?.supportDIY?.helpLineContact;
            }
            dummyData = { ...dummyData,...{help:JSON.stringify(helpObject)}};

            var feDetailsDIY = ciDiyStatusWiseSetting?.feDetailsDIY;
            if(!isEmpty(feDetailsDIY)){
                var feDetailsObj = {}
                var userName = "FirstName LastName";
                if(feDetailsDIY?.feDisplayNameConfig == "firstName"){
                    userName = "FirstName";
                }
                else if(feDetailsDIY?.feDisplayNameConfig == "lastName"){
                    userName = "LastName";
                }
                else if(feDetailsDIY?.feDisplayNameConfig == "both"){
                    userName = "FirstName LastName";
                }
                else if(feDetailsDIY?.feDisplayNameConfig == "none"){
                    userName = "none";
                }
                feDetailsObj["userName"] = userName;
                if(feDetailsDIY?.callToFE && feDetailsDIY?.callToFE=="allowed"){
                    feDetailsObj["callToFE"] = true;
                }
                else{
                    feDetailsObj["callToFE"] = false;
                }
                if(feDetailsDIY?.executiveMessage ){
                    feDetailsObj["executiveMessage"] = feDetailsDIY?.executiveMessage;
                }
                if(feDetailsDIY?.messageToFE && feDetailsDIY?.messageToFE == "allowed"){
                    feDetailsObj["messageToFE"] = true;
                    if(!isEmpty(feDetailsDIY?.feMsgPageHeader)){
                        feDetailsObj["feMsgPageHeader"] = feDetailsDIY?.feMsgPageHeader;
                    }
                    if(!isEmpty(feDetailsDIY?.feMsgSupportingText)){
                        feDetailsObj["feMsgSupportingText"] = feDetailsDIY?.feMsgSupportingText;
                    }
                    if((feDetailsDIY?.preMessage)){
                        feDetailsObj["preMessage"] = feDetailsDIY?.preMessage;
                    }
                }
                else{
                    feDetailsObj["messageToFE"] = false;
                }

                dummyData = { ...dummyData,...feDetailsObj};
            }
        }
        var marketingAssetImgObj = diyConfig?.marketingAssetImgObj;
        if(!isEmpty(marketingAssetImgObj)){
            if(marketingAssetImgObj["bannerDetails"]?.['url']){
                marketingAssetImgObj["bannerDetails"]['url'] = await new Promise(resolve => {
                    const reader = new FileReader();
                    reader.readAsDataURL(marketingAssetImgObj["bannerDetails"]['url']);
                    reader.onload = () => resolve(reader.result);
                  }); 
            }
            if(marketingAssetImgObj["carouselDetails"]){
                await Promise.all( marketingAssetImgObj["carouselDetails"].map( async(marketingAssetCarouselSingle, index) => {
                    if(marketingAssetCarouselSingle?.['url']){
                            marketingAssetImgObj["carouselDetails"][index]['url'] = await new Promise(resolve => {
                            const reader = new FileReader();
                            reader.readAsDataURL(marketingAssetCarouselSingle['url']);
                            reader.onload = () => resolve(reader.result);
                          }); 
                    }
                }))
            }
            dummyData.marketingAssets = marketingAssetImgObj;
        }
        
        
        dummyData["customerInteractionThemeSettingsDTO"] = {
            ...dummyData.customerInteractionThemeSettingsDTO,
            ...diyConfig.themeSetting,
            "extraInfo":( diyConfig.themeSetting && (diyConfig.themeSetting.privacyAndPolicy || diyConfig.themeSetting.termAndCondition) &&  [
                {
                  "label": "Privacy and Policy",
                  "value1": diyConfig.themeSetting.privacyAndPolicy,
                  "sequence": 2
                },
                {
                  "label": "Terms and Conditions",
                  "value1": diyConfig.themeSetting.termAndCondition,
                  "sequence": 1
                }
              ])
        }
        dummyData["needHelp"] = diyConfig.themeSetting?.helpSectionText;

        return dummyData;
    }

    getThemeFromCiDiyConfig =async(url,trackingDetails)=>{
        
        url = url.replace(/\s/g, "+");
        if(url.indexOf('&embed=true')!= -1){
          url = url.substring(0, url.indexOf('&embed=true'));
        }
        url = await restClientService.updateUrl(url);

        let hitUrl =API.GET_CI_DIY_THEME;
        const res = await  Axios.get(hitUrl, {params: {url:url} });
        if(res && res.data){
            var dummyData= {
                ...trackingDetails 
            }
            dummyData["customerInteractionThemeSettingsDTO"] = {
                ...dummyData.customerInteractionThemeSettingsDTO,
                ...res.data,};
            if (res.data.termAndCondition || res.data.privacyAndPolicy) {
                dummyData["customerInteractionThemeSettingsDTO"]["extraInfo"] = [
                    {
                        "label": "Terms and Condition",
                        "value1": res.data.termAndCondition ? res.data.termAndCondition : "",
                        "sequence": 1
                    },
                    {
                        "label": "Privacy Policy",
                        "value1": res.data.privacyAndPolicy ? res.data.privacyAndPolicy : "",
                        "sequence": 2
                    }
                ]
            }
            dummyData["needHelp"] = res.data?.helpSectionText;
            if(!isEmpty(res.data["companyLogo"])){
                dummyData["companyLogo"] = res.data["companyLogo"];
            }
            return dummyData;
        }
    }
}


const ciDiyService = new CiDiyService();
export default ciDiyService;