import { Grid } from "antd";
import { isEmpty } from "lodash";
import React from "react";
import AddressDetails from "../AddressDetails";
function renderAddressContent(props) {
  const trackingDetails = props.trackingDetails;
  if (
    trackingDetails &&
    trackingDetails?.processUpdateForm
  ) {
    let forms = JSON.parse(trackingDetails.processUpdateForm);
    return (
      <div className="desktopDeliverySec hi">
        <AddressDetails
          forms={forms}
          screen={trackingDetails.screen}
          addressLabel={trackingDetails.addressLabel}
          contactLabel={trackingDetails.contactLabel}
        />
      </div>

    );
  }
};
function renderAttributeList(props) {
  let attributeList = props.trackingDetails.orderLevelLayoutScreenDTO?.attributeList;
  // let attributeList = [
  //   {
  //     "value": "Trip-22879-1709814111968",
  //     "sequence": 1,
  //     "key": "order_ref_num",
  //     "label": "Order Number"
  //   },
  //   {
  //     "value": "Raghav19",
  //     "sequence": 2,
  //     "key": "CN",
  //     "label": "Name"
  //   },
  //   {
  //     "value": "sdfsdf",
  //     "sequence": 3,
  //     "key": "Address",
  //     "label": "Address"
  //   }
  // ];
  // will sort here based on sequnce 
  return (<div>{attributeList && attributeList.map((attribute) => {
    return (
      // <div className="mobileViewOrderDetails bg-white">
      //   <div container>
      //     <div item xs={12}>
      //       {
      //         <div className="flex justify-between align-top">
      //           <p className="fs14  text-grey ci-add-label">
      //             {attribute.label}
      //           </p>
      //           <p className="fs14  align-right">{attribute.value}</p></div>}
      //     </div>
      //   </div>
      // </div>



<div className="mobileViewOrderDetails">
<p
  className="fs14  text-grey ci-add-label"
  style={{
    width:'30%'
    }}
    >
  {attribute.label}
</p>
<p
  className="fs14  align-right"
  style={{
    paddingLeft:'2px',
     width:'70%'
    }}
>
  {attribute.value}
</p>
</div>
      

      
    );
  })}
  </div>
  )
}

function OrderDetails(props) {
  return (
    <>
      {renderAttributeList(props)}
      {/* {renderAddressContent(props)} */}
    </>
  )


}

export default OrderDetails;
