import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { isEmpty } from "loadsh";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from 'swiper';
import { PudoOtherDetails } from "../PudoOtherDetails";

SwiperCore.use([Navigation]);
// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { LocationIcon, ClockIcon } from "../../containers/AvailableLocationV3/PudoReturnMap/Icons";


class CarouselComponent extends Component {
  containerConstants = window.containerConstants;

  storeCurrentIndex;
  slideIndex = 0;

  componentDidMount() {
    if (this.storeCurrentIndex) {
      this.slider.slickGoTo(this.storeCurrentIndex)

    }
    if (this.slideIndex !== null && document.getElementById('marker' + this.slideIndex)) {
      document.getElementById('marker' + this.slideIndex).style.width = "44px";
      document.getElementById('marker' + this.slideIndex).style.height = "50px";
      document.getElementById('marker' + this.slideIndex).style.objectFit = "fill";
      document.getElementById('marker' + this.slideIndex).src = require('../../images/big-icon.svg');
    }
    if (this.props.availableList) {
      let index = 1;
      let total = this.props.availableList.length;
      for (index; index <= total; index++) {
        if (!isEmpty(document.getElementById('marker' + index))) {
          document.getElementById('marker' + index).style.width = "28px";
          document.getElementById('marker' + index).style.objectFit = "fill";
          document.getElementById('marker' + index).src = require('../../images/location.svg');
        }
      }
    }

  }
  componentDidUpdate() {

    if (this.storeCurrentIndex !== undefined) {
      this.slider.slickGoTo(this.storeCurrentIndex);

    }

    if (this.storeCurrentIndex !== null && document.getElementById('marker' + this.storeCurrentIndex)) {
      document.getElementById('marker' + this.storeCurrentIndex).style.width = "44px";
      document.getElementById('marker' + this.storeCurrentIndex).style.height = "50px";
      document.getElementById('marker' + this.storeCurrentIndex).style.objectFit = "fill";
      document.getElementById('marker' + this.storeCurrentIndex).src = require('../../images/big-icon.svg');
    }
  }

  beforeMarkerChange = (current, next) => {
    if (!isEmpty(document.getElementById('marker' + current))) {
      document.getElementById('marker' + current).style.width = "28px";
      document.getElementById('marker' + current).style.objectFit = "fill";
      document.getElementById('marker' + current).src = require('../../images/location.svg');
      if (!isEmpty(document.getElementById('marker' + next))) {
        document.getElementById('marker' + next).style.width = "44px";
        document.getElementById('marker' + next).style.width = "50px";
        document.getElementById('marker' + next).style.objectFit = "fill";
        document.getElementById('marker' + next).src = require('../../images/big-icon.svg');
      }
    }
    else {
      // document.getElementById('marker' + next).style.width="28px";
      // document.getElementById('marker' + next).src='../../images/location.svg';
    }
  }

  getResponsiveObject = () => {
    var respo = {
      0: {
        slidesPerView: 1
      }
    };
    // assuiming width with less then 800 are mobile device
    for (var i = 200; i <= 800; i++) {
      respo[i] = { slidesPerView: i / 155 };
    }
    // in desktop view carousel-mainDiv is fixed 860 so fixing his value as 2.98
    respo[801] = { slidesPerView: 2.90 }
    return respo;
  }

  getCarouselItems = (carouselList) => {
    const items = carouselList.map((singleCarouselObj, index) => {
      return (<SwiperSlide key={index}>
        <a
          id="carousel"
          name={index}
          href={singleCarouselObj.redirectUrl} target="_blank" onClick={() => { dataLayer.push({ carouselImgIndex: index, carouselImgName: singleCarouselObj.name }) }}>
          <img
            src={singleCarouselObj.url}
            alt={singleCarouselObj.name}
          />
        </a>
      </SwiperSlide>)
    });
    return items;
  }

  render() {
    const { availableList, carouselList } = this.props;
    this.storeCurrentIndex = this.props.currentIndex;

    // pudo card-carousel-setting-object
    const settings2 = {
      dots: false,
      infinite: false,
      fade: false,
      arrows: false,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      cssEase: "linear",
      swipeToSlide: true,
      centerMode: true,
      centerPadding: "20px",
      beforeChange: (current, next) => this.beforeMarkerChange(current, next),

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
            speed: 300,
            cssEase: "linear",
            centerMode: true,
            centerPadding: "20px",
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "20px",
            infinite: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "20px",
            infinite: false,
          },
        },
      ],
    };

    return (
      <React.Fragment>
        {carouselList && carouselList.length > 2 && (
          <Swiper
            slidesPerView={1}
            breakpoints={this.getResponsiveObject()}
            navigation
            className="carousel-item"
          >
            {this.getCarouselItems(carouselList)}
          </Swiper>
        )
        }

        {availableList && availableList.length > 0 && (
          <div className="available-list-slider">
            <Slider className='ci-pudo-return-swiper'
              ref={(slider) => (this.slider = slider)} {...settings2}>
              {availableList.map((singleCarouselCard, index) => {
                let timings = "";
                if (!isEmpty(singleCarouselCard.timing)) {
                  timings = singleCarouselCard.timing.split(',');
                }
                var trimmedStoreName = "";
                if (!isEmpty(singleCarouselCard) && !isEmpty(singleCarouselCard.name) && singleCarouselCard.name.length > 28) {
                  trimmedStoreName = singleCarouselCard.name.substr(0, 24) + "...";
                }
                return (
                  <div key={singleCarouselCard.id}>
                    <Card
                      className="ci-pudo-return-swipe-slider"
                      id="pudoCard"
                    >
                      <div className='ci-pudo-return-swiper-details'>
                        <div className='ci-pudo-return-swiper-list'>
                          <button><LocationIcon /><span>{index + 1}</span></button>
                          <h3 title={trimmedStoreName ? singleCarouselCard.name : ""}>{trimmedStoreName ? trimmedStoreName : singleCarouselCard.name}</h3>
                          <h6>{singleCarouselCard.distance} km</h6>
                        </div>
                        <div className='ci-pudo-return-swiper-address'>
                          <div className="address-lines flex">
                            <p>
                              {singleCarouselCard.addressLine1 &&
                                singleCarouselCard.addressLine1}
                              {singleCarouselCard.addressLine2 &&
                                ", " + singleCarouselCard.addressLine2}
                              {singleCarouselCard.landmark &&
                                ", " + singleCarouselCard.landmark}
                              {singleCarouselCard.pincode &&
                                ", " + singleCarouselCard.pincode}
                            </p>
                          </div>
                          <div>
                            {this.props.calledFromViewDetail && this.props.currentIndex == index? 
                            null:
                            <button className="viewDetailPudoCard" onClick={() =>
                              this.props.onForwardArrowClick(
                                singleCarouselCard,
                                index
                              )
                            }>View Details</button>}
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                );
              })}
            </Slider>
          </div>
        )
        }
      </React.Fragment>
    );
  }
}
export default CarouselComponent;
