// const countryNamesAndCodes = [
//     { name: 'English', code: 'en', label: 'English',  direction: 'ltr' },
//     { name: 'Arabic', code: 'ar', label: 'Arabic',  direction: 'rtl' },
//     { name: 'Danish', code: 'da', label: 'Danish',  direction: 'ltr' },
//     { name: 'Hindi', code: 'hi', label: 'हिन्दी',  direction: 'ltr' },
//     { name: 'Portuguese', code: 'pt', label: 'Português',  direction: 'ltr' },
//     { name: 'Spanish', code: 'es', label: 'Español',  direction: 'ltr' },
//     { name: 'Finnish', code: 'fi', label: 'Finnish',  direction: 'ltr' },
//     { name: 'Malay', code: 'ms', label: 'Malay',  direction: 'ltr' },
//     { name: 'Portuguese', code: 'pt'},
//     { name: 'Romanian', code: 'ro', label: 'Romanian',  direction: 'ltr' },
//     { name: 'Urdu', code: 'ur', label: 'Urdu',  direction: 'rtl' },
//     { name: 'Vietnamese', code: 'vi', label: 'Tiếng Việt',  direction: 'ltr' },
//     { name: 'Thai', code: 'th', label: 'Thai',  direction: 'ltr' },
//   ];

export const COUNTRY_LOCALIZATION_MAP = {
  ar: {
    name: "Arabic",
    code: "ar",
    label: "Arabic",
    direction: "rtl",
  },
  bg: { name: "Bulgarian", code: "bg", label: "Bulgarian", direction: "ltr" },
  cs: { name: "Czech", code: "cs", label: "Czech", direction: "ltr" },
  da: { name: "Danish", code: "da", label: "Danish", direction: "ltr" },
  nl: {
    name: "Dutch",
    code: "nl",
    label: "Dutch",
    direction: "ltr",
  },
  en: {
    name: "English",
    code: "en",
    label: "English",
    direction: "ltr",
  },
  fi: { name: "Finnish", code: "fi", label: "Finnish", direction: "ltr" },
  fr: {
    name: "French",
    code: "fr",
    label: "French",
    direction: "ltr",
  },
  de: {
    name: "German",
    code: "de",
    label: "German",
    maxPriority: false,
    direction: "ltr",
  },
  el: { name: "Greek", code: "el", label: "Greek", direction: "ltr" },
  hi: { name: "Hindi", code: "hi", label: "हिन्दी", direction: "ltr" },
  hu: { name: "Hungarian", code: "hu", label: "Hungarian", direction: "ltr" },
  it: {
    name: "Italian",
    code: "it",
    label: "Italian",
    direction: "ltr",
  },
  ms: { name: "Malay", code: "ms", label: "Malay", direction: "ltr" },
  pl: {
    name: "Polish",
    code: "pl",
    label: "Polish",
    direction: "ltr",
  },
  pt: { name: "Portuguese", code: "pt", label: "Português", direction: "ltr" },
  ro: { name: "Romanian", code: "ro", label: "Romanian", direction: "ltr" },
  sk: { name: "Slovak", code: "sk", label: "Slovak", direction: "ltr" },
  es: {
    name: "Spanish",
    code: "es",
    label: "Spanish",
    direction: "ltr",
  },
  th: { name: "Thai", code: "th", label: "Thai", direction: "ltr" },
  ur: { name: "Urdu", code: "ur", label: "Urdu", direction: "rtl" },
  vi: { name: "Vietnamese", code: "vi", label: "Tiếng Việt", direction: "ltr" },
};

const countryNamesAndCodes ={
  'en':'ltr',
  'ar':'rtl',
  'da':'ltr',
  'hi':'ltr',
  'pt':'ltr',
  'es':'ltr',
  'fi':'ltr',
  'ms':'ltr',
  'ro':'ltr',
  'ur':'rtl',
  'vi':'ltr',
  'th':'ltr',
  'fr':'ltr',
  'el':'ltr',
  'bg':'ltr',
  'nl':'ltr',
  'de':'ltr',
  'cs':'ltr',
  'hu':'ltr',
  'it':'ltr',
  'pl':'ltr',
  'sk':'ltr',
}
  
  export default countryNamesAndCodes;
  