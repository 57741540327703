import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as CustomerInteractionAction from "../../CustomerInteractionActions";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Header from "../../components/Header";
import LayoutScreen from "../LayoutScreen";
import OrderInformation from "../../components/OrderInformation";
import DeliveryInfo from "../../components/DeliveryInfo";
import StatusTimeLine from "../../components/StatusTimeLine";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import Grid from "@material-ui/core/Grid";
import {isEmpty} from 'loadsh';
import './style.css';
import { listUtilities } from "../../services/ListUtilities";
import { commonUtilities } from "../../services/commonUtilities";

class OrderTracking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      liveTracking: false,
      layoutScreen: false,
      openDeliveryInfo: true,
      openTrackingHistory: true,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    this.setState({
      height:
        window.innerHeight -
        document.getElementById("top-area").offsetHeight -
        document.getElementById("top-area").offsetHeight,
    });
    this.addingEventListner();
   
  }
  addingEventListner = () => {
    var orderInformation = document.getElementById('orderInformationForDiyMobile');
    if (orderInformation && this.props.preview) {
      const buttonForEdit = commonUtilities.createButtonElement();
      orderInformation.classList.add("borderHover");
      orderInformation.insertBefore(buttonForEdit, orderInformation.firstChild);
      buttonForEdit.addEventListener("click", () => {
        window.parent.postMessage("orderInformation", "*");
      })
    }
    var trackerTimeLine = document.getElementById('trackerTimeLineForDiyMobile');
    if (trackerTimeLine && this.props.preview) {
      const buttonForEdit = commonUtilities.createButtonElement();
      trackerTimeLine.classList.add("borderHover");
      trackerTimeLine.insertBefore(buttonForEdit, trackerTimeLine.firstChild);
      buttonForEdit.addEventListener("click", () => {
        window.parent.postMessage("trackerTimeLine", "*");
      })
    }
  }

  updateWindowDimensions() {
    this.setState({
      height:
        window.innerHeight -
        document.getElementById("top-area").offsetHeight -
        document.getElementById("top-area").offsetHeight,
    });
  }

  showLayoutScreen = () => {
    this.setState(
      { layoutScreen: !this.state.layoutScreen },
      console.log("layout", this.state.layoutScreen)
    );
  };
  
  getAddress=()=>{
    let details = this.props.trackingDetails.processUpdateForm ?JSON.parse(this.props.trackingDetails.processUpdateForm):'';
    let address= '';
    if(!isEmpty(details.addressLine1)){
       address=details.addressLine1;
    }
    if(!isEmpty(details.addressLine2)){
      address = !isEmpty(address)?address+", "+details.addressLine2:details.addressLine2;
    }
    if(!isEmpty(details.landMark)){
     address = !isEmpty(address)?address+", "+details.landMark:details.landMark;
 
    }
     if(!isEmpty(details.pinCode)){
       address = !isEmpty(address)?address+", "+details.pinCode:details.pinCode;      
     }
     if(!isEmpty(details.contact)){
      address = !isEmpty(address)?address+", "+details.contact:details.contact;      
    }
        
    return address;
 }
 
 checkIsTrackerTimelineAndNoDeliveryInfo=()=>{
   if(this.props.trackingDetails.processStatusLogDomainList && this.props.trackingDetails.processStatusLogDomainList.length<=0 && this.getAddress() && this.getAddress()!=''){
      return true;
   }
   return false;
 } 
 
  
  renderDesktopViewOrderTracking = () =>{
    return(
      <div className="ci-background-grey ci-ordertracking">
      <div
          className="content orderTrackingScreen desktopView ci-desktopView"
        >
          <Grid container className="bg-white p40 relative">
          <Grid item xs={7}>
          <div className="leftpanel">
          {this.getAddress() && this.getAddress()!=''?
          <div className="orderTrackingScreen ci-trackerOrder mb30">
          <ExpansionPanel expanded={this.state.openDeliveryInfo}
          defaultExpanded={true}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon className="expandicon" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => {
                this.setState({
                  openDeliveryInfo: !this.state.openDeliveryInfo,
                });
              }}
              className="collapsibleHeading"
            >
              <Typography style={{ color: this.props.secondaryTextColor }}>
              {containerConstants.formatString(containerConstants.DeliveryInformation)}         
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className="height30 width-100">
                {this.props.trackingDetails &&
                this.props.trackingDetails.introText ? (
                  <p
                    className="bold fs14 pv15"
                    style={{
                      color: this.props.trackingDetails.fontColor,
                      fontFamily: this.props.trackingDetails.fontType,
                      fontSize: this.props.trackingDetails.fontSize,
                    }}
                  >
                    {this.props.trackingDetails.introText}
                  </p>
                ) : null}
                <div className="ci-ot-deliveryinfo">
                  <DeliveryInfo
                    trackingDetails={this.props.trackingDetails}
                    secondaryBgColor={this.props.secondaryBgColor}
                    // secondaryTextColor={this.props.secondaryTextColor}
                  />
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          </div>:''}
          { this.checkIsTrackerTimelineAndNoDeliveryInfo()==false && ((this.props.trackingDetails.layoutScreen.orderInformationDTOList && listUtilities.checkOrderDto(this.props.trackingDetails.layoutScreen.orderInformationDTOList))||
          (this.props.trackingDetails.layoutScreen.attributeList && listUtilities.showOrderInfo(this.props.trackingDetails.layoutScreen.attributeList)))?
          <div className="orderTrackingScreen ci-trackerOrder">
          <ExpansionPanel defaultExpanded={true} id="orderInformation">
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon className="expandicon" />}
              aria-controls="panel3a-content"
              id="panel3a-header"
              className="collapsibleHeading"
            >
              <Typography style={{ color: this.props.secondaryTextColor }}>
                {this.props.trackingDetails.layoutScreen.orderInfoTitle?
                this.props.trackingDetails.layoutScreen.orderInfoTitle:containerConstants.formatString(containerConstants.OrderInformation)}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className="bg-white width-100" style={{padding:0}}>
                <div className="ci-ot-deliveryinfo">
                  <OrderInformation
                    trackingDetails={this.props.trackingDetails}
                    arrayList={
                      this.props.trackingDetails.layoutScreen.orderInformationDTOList
                    }
                    attributeList={
                      this.props.trackingDetails.layoutScreen.attributeList
                    }
                    secondaryBgColor={this.props.secondaryBgColor}
                    // secondaryTextColor={this.props.secondaryTextColor}
                  />
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          </div>:''}
          </div>
          </Grid>
          <Grid item xs={5}>
          {this.checkIsTrackerTimelineAndNoDeliveryInfo() && this.checkIsTrackerTimelineAndNoDeliveryInfo()==true && ((this.props.trackingDetails.layoutScreen.orderInformationDTOList && listUtilities.checkOrderDto(this.props.trackingDetails.layoutScreen.orderInformationDTOList))||
          (this.props.trackingDetails.layoutScreen.attributeList && listUtilities.showOrderInfo(this.props.trackingDetails.layoutScreen.attributeList)))?
          <div className="orderTrackingScreen ci-trackerOrder">
          <ExpansionPanel defaultExpanded={true} id="orderInformation">
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon className="expandicon" />}
              aria-controls="panel3a-content"
              id="panel3a-header"
              className="collapsibleHeading"
            >
              <Typography style={{ color: this.props.secondaryTextColor }}>
                {this.props.trackingDetails.layoutScreen.orderInfoTitle?
                this.props.trackingDetails.layoutScreen.orderInfoTitle:containerConstants.formatString(containerConstants.OrderInformation)}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className="bg-white width-100">
                <OrderInformation
                  trackingDetails={this.props.trackingDetails}
                  arrayList={
                    this.props.trackingDetails.layoutScreen.orderInformationDTOList
                  }
                  attributeList={
                    this.props.trackingDetails.layoutScreen.attributeList
                  }
                  secondaryBgColor={this.props.secondaryBgColor}
                  // secondaryTextColor={this.props.secondaryTextColor}
                />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          </div>  
          :
          <div className="orderTrackingScreen ci-trackerOrder web-scroll-tracker">
          <ExpansionPanel expanded={this.state.openTrackingHistory}
          defaultExpanded={true} id="trackerTimeline">
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon className="expandicon" />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              className="collapsibleHeading"
              onClick={() => {
                this.setState({
                  openTrackingHistory: !this.state.openTrackingHistory,
                });
              }}
            >
              <Typography style={{ color: this.props.secondaryTextColor }}>
                {containerConstants.formatString(containerConstants.TrackerTimeline)}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className="status_log_screen">
                <StatusTimeLine
                  trackingDetails={this.props.trackingDetails}
                  secondaryBgColor={this.props.secondaryBgColor}
                  // secondaryTextColor={this.props.secondaryTextColor}
                />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          </div>}
          </Grid>
          </Grid>
        </div>
        </div>
        
    )
  }
  render() {
    if (this.state.layoutScreen) {
      return (
        <LayoutScreen
          secondaryBgColor={this.props.secondaryBgColor}
          secondaryTextColor={this.props.secondaryTextColor}
          fontSize={this.props.fontSize}
          fontFamily={this.props.fontFamily}
          primaryBgColor={this.props.primaryBgColor}
          primaryTextColor={this.props.primaryTextColor}
          isReturn={this.props.isReturn}
          openQuickActionForm={this.props.openProcessForm}
          screen={this.props.trackingDetails.screen}
          pudoPoints={this.props.pudoPoints}
          openPudoScreen={this.props.openPudoScreen}
          pudoTitle={
            this.props.trackingDetails &&
            this.props.trackingDetails.pudo &&
            this.props.trackingDetails.pudo.title
          }
          subLabel={
            this.props.trackingDetails &&
            this.props.trackingDetails.pudo &&
            this.props.trackingDetails.pudo.subLabel
          }
          preview={this.props.preview}
        />
      );
    }
    return (
      <div>
        <div id="top-area">
          {this.props.landingLayout || this.props.surveyForm? 
           <div className="feedback-custom custom-order-tracking-header">
           <Header
             title={containerConstants.formatString(containerConstants.OrderDetails)}
             landingLayout={this.props.landingLayout}
             back={true}
             backScreen={this.props.back}
             needHelp={false}
             secondaryBgColor={
               this.props.trackingDetails
                 .customerInteractionThemeSettingsDTO &&
               this.props.trackingDetails.customerInteractionThemeSettingsDTO
                 .secondaryBgColor
             }
             secondaryTextColor={
               this.props.trackingDetails
                 .customerInteractionThemeSettingsDTO &&
               this.props.trackingDetails.customerInteractionThemeSettingsDTO
                 .secondaryTextColor
             }
             primaryBgColor={
               this.props.trackingDetails
                 .customerInteractionThemeSettingsDTO &&
               this.props.trackingDetails.customerInteractionThemeSettingsDTO
                 .primaryBgColor
             }
             primaryTextColor={
               this.props.trackingDetails
                 .customerInteractionThemeSettingsDTO &&
               this.props.trackingDetails.customerInteractionThemeSettingsDTO
                 .primaryTextColor
             }
           />
         </div>
          :
          this.props.backtoLayoutScreen ? (
            <div className="feedback-custom custom-order-tracking-header">
              <Header
                title={containerConstants.formatString(containerConstants.OrderDetails)}
                back={true}
                backScreen={this.showLayoutScreen}
                needHelp={false}
                secondaryBgColor={
                  this.props.trackingDetails
                    .customerInteractionThemeSettingsDTO &&
                  this.props.trackingDetails.customerInteractionThemeSettingsDTO
                    .secondaryBgColor
                }
                secondaryTextColor={
                  this.props.trackingDetails
                    .customerInteractionThemeSettingsDTO &&
                  this.props.trackingDetails.customerInteractionThemeSettingsDTO
                    .secondaryTextColor
                }
                primaryBgColor={
                  this.props.trackingDetails
                    .customerInteractionThemeSettingsDTO &&
                  this.props.trackingDetails.customerInteractionThemeSettingsDTO
                    .primaryBgColor
                }
                primaryTextColor={
                  this.props.trackingDetails
                    .customerInteractionThemeSettingsDTO &&
                  this.props.trackingDetails.customerInteractionThemeSettingsDTO
                    .primaryTextColor
                }
              />
            </div>
          ) : null}
        </div>
        <div>
        {this.props.trackingDetails.isMobileType?
        <div
        className="content overflow-y orderTrackingScreen "
        style={{
          height: window.innerHeight - 55,
          backgroundColor: "white",
        }}
      >
        {this.getAddress() && this.getAddress()!=''?<ExpansionPanel expanded={this.state.openDeliveryInfo}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => {
              this.setState({
                openDeliveryInfo: !this.state.openDeliveryInfo,
              });
            }}
            className="collapsibleHeading"
          >
            <Typography style={{ color: this.props.secondaryTextColor }}>         
            {containerConstants.formatString(containerConstants.DeliveryInformation)}         
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className="height30 width-100">
              {this.props.trackingDetails &&
              this.props.trackingDetails.introText ? (
                <p
                  className="bold fs14 pv15"
                  style={{
                    color: this.props.trackingDetails.fontColor,
                    fontFamily: this.props.trackingDetails.fontType,
                    fontSize: this.props.trackingDetails.fontSize,
                  }}
                >
                  {this.props.trackingDetails.introText}
                </p>
              ) : null}
              <div className="ci-ot-deliveryinfo mb5">
                <DeliveryInfo
                  trackingDetails={this.props.trackingDetails}
                  secondaryBgColor={this.props.secondaryBgColor}
                  // secondaryTextColor={this.props.secondaryTextColor}
                />
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>:null}
        {((this.props.trackingDetails.layoutScreen.orderInformationDTOList && listUtilities.checkOrderDto(this.props.trackingDetails.layoutScreen.orderInformationDTOList))||
          (this.props.trackingDetails.layoutScreen.attributeList && listUtilities.showOrderInfo(this.props.trackingDetails.layoutScreen.attributeList))||
          (this.props.trackingDetails.layoutScreen.orderAttributeList && listUtilities.showOrderInfo(this.props.trackingDetails.layoutScreen.orderAttributeList)))?
        <div id="orderInformationForDiyMobile">
        <ExpansionPanel id="orderInformation">
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            className="collapsibleHeading"
          >
            <Typography style={{ color: this.props.secondaryTextColor }}>
              {this.props.trackingDetails.layoutScreen.orderInfoTitle?
              this.props.trackingDetails.layoutScreen.orderInfoTitle:containerConstants.formatString(containerConstants.OrderInformation)}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className="bg-white width-100">
              <OrderInformation
                trackingDetails={this.props.trackingDetails}
                arrayList={
                  this.props.trackingDetails.layoutScreen.orderInformationDTOList
                }
                attributeList={
                  this.props.trackingDetails.layoutScreen.attributeList
                }
                orderAttributeList={
                  this.props.trackingDetails.layoutScreen.orderAttributeList
                }
                secondaryBgColor={this.props.secondaryBgColor}
                // secondaryTextColor={this.props.secondaryTextColor}
              />
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        </div>
        :''}
        <div id = "trackerTimeLineForDiyMobile">
        <ExpansionPanel expanded={this.state.openTrackingHistory} id="trackerTimeline">
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className="collapsibleHeading"
            onClick={() => {
              this.setState({
                openTrackingHistory: !this.state.openTrackingHistory,
              });
            }}
          >
            <Typography style={{ color: this.props.secondaryTextColor }}>
            {containerConstants.formatString(containerConstants.TrackerTimeline)}              
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className="status_log_screen">
              <StatusTimeLine
                trackingDetails={this.props.trackingDetails}
                secondaryBgColor={this.props.secondaryBgColor}
                // secondaryTextColor={this.props.secondaryTextColor}
              />
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        </div>
      </div>
        :
        this.renderDesktopViewOrderTracking()}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    trackingDetails: state.customerInteractionReducer.trackingDetails,
    url: state.customerInteractionReducer.url,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...CustomerInteractionAction }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderTracking);
