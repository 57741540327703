import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import LocateAddress from "../LocateAddress";
import ViewListIcon from "@material-ui/icons/ViewList";
import MyLocation from "@material-ui/icons/MyLocation";
import PudoScreenList from "../../components/PudoScreenList";
import CarouselComponent from "../../components/Carousel";
import ShopDetail from "../ShopDetail";
import { isEmpty } from "loadsh";
import MapView from "../MapView";
import TextField from "@material-ui/core/TextField";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { setPudoPoints } from "../MapView/MapViewAction";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import * as CustomerInteractionAction from "../../CustomerInteractionActions";
import EventScreen from "../EventScreen/index";
import { Input,Alert } from 'antd';
import TableLoader from "../../components/TableLoader";
import { BackIconArrow, SearchIcon, GpsIcon, ListViewIcon, ClockIcon, LocationIcon, DarkSearchIcon} from "./PudoReturnMap/Icons";
import { isEqual } from "lodash";
import { processUpdateFormService } from "../../services/ProcessUpdateFormService";
import { mapViewService } from "../MapView/services/MapViewService";
class AvailableLocationV3 extends React.Component {
  storeSingleDetailIndex;
  map;
  bounds;
  jobLatitude;
  jobLongitude;
  searchLocationLat;
  searchLocationLng;
  isBackButtonClicked = false;
  constructor(props) {
    super(props);
    this.state = {
      pudoList: false,
      shopDetail: false,
      currentMarkerIndex: "",
      showLocateAddress: false,
      addressValue: "",
      sortedOnJob: true,
      pudoUpdated: false,
      pudoError: false,
      searchValue: "",
      pudoPoints: props.pudoPoints,
      searchLocation: null,
    };
    this.openPudoScreenList = this.openPudoScreenList.bind(this);
    this.clickBackAvailableLocationScreen = this.clickBackAvailableLocationScreen.bind(
      this
    );
    this.openCardDetail = this.openCardDetail.bind(this);
    this.closeCardDetail = this.closeCardDetail.bind(this);
    this.points = this.points.bind(this);
    this.mapFitBound = this.mapFitBound.bind(this);
    this.setCurrentLocation = this.setCurrentLocation.bind(this);
    this.updatePudoPointAccordingToCurrentLocation = this.updatePudoPointAccordingToCurrentLocation.bind(
      this
    );

    this.unitMultiplier = null;
    this.updatePudo = this.updatePudo.bind(this);
    this.herePoweredAccount =  window.localStorage.getItem('isHerePoweredAccount') === 'true' ? true : false
  }

  openPudoScreenList() {
    this.setState({
      pudoList: true,
    });
  }
  clickBackAvailableLocationScreen() {
    this.setState({
      pudoList: false,
      showLocateAddress: false,
    });
    this.isBackButtonClicked = false;
  }
  openCardDetail(singleDetail, index) {
    this.storeSingleDetail = singleDetail;
    this.storeSingleDetailIndex = index;
    if (!isEmpty(singleDetail)) {
      this.setState({
        shopDetail: true,
      });
    }
  }

  closeCardDetail(singleDetail) {
    this.setState({
      shopDetail: false,
      pudoError: false,
    });
    this.isBackButtonClicked = false;
  }

  points(index) {
    this.setState({
      ...index,
      currentMarkerIndex: index,
    });
  }

  renderCardLayout = () => {
    return (
      <CarouselComponent
        availableList={this.state.pudoPoints}
        onForwardArrowClick={this.openCardDetail}
        currentIndex={this.state.currentMarkerIndex}
        primaryBgColor={this.props.primaryBgColor}
        unit={
          this.props.pudoConfig &&
          this.props.pudoConfig.distanceUnit == "kilometers"
            ? "Km"
            : this.props.pudoConfig.distanceUnit
        }
        primaryTextColor={this.props.primaryTextColor}
        timingLabel={this.props.pudoConfig && this.props.pudoConfig.timingLabel ?this.props.pudoConfig.timingLabel:""}
      />
    );
  };

  componentDidMount() {
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + this.props.url
    ); // on back button from browser/mobile it will set current url
    window.addEventListener("popstate", this.onBackButtonEvent);
  }
  componentWillUnmount = () => {
    window.removeEventListener("popstate", this.onBackButtonEvent);
  };

  onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!this.isBackButtonClicked) {
      this.isBackButtonClicked = true;
      setTimeout(
        function () {
          if (this.state.showLocateAddress) {
            this.setState({ showLocateAddress: false });
            window.history.pushState(
              null,
              null,
              window.location.pathname + "?" + this.props.url
            ); // on back button from browser/mobile it will set current url
            this.isBackButtonClicked = false;
          } else if (this.state.shopDetail) {
            this.closeCardDetail();
            window.history.pushState(
              null,
              null,
              window.location.pathname + "?" + this.props.url
            ); // on back button from browser/mobile it will set current url
          } else if (this.state.pudoList) {
            this.clickBackAvailableLocationScreen();
            window.history.pushState(
              null,
              null,
              window.location.pathname + "?" + this.props.url
            ); // on back button from browser/mobile it will set current url
          } else {
            this.backToScreen();
          }
        }.bind(this),
        100
      );
    }
  };

  backToScreen() {
    if (!this.state.sortedOnJob) {
      if (
        this.props.trackingDetails &&
        this.props.trackingDetails.jobLat &&
        this.props.trackingDetails.jobLng
      ) {
        this.sortPudoPoints(
          this.props.trackingDetails.jobLat,
          this.props.trackingDetails.jobLng
        );
      }
    }
    this.props.onBackClick();
    this.isBackButtonClicked = false;
  }

  componentDidUpdate(prevProps) {
    if (
      !this.unitMultiplier &&
      this.props.trackingDetails &&
      this.props.trackingDetails.pudo &&
      this.props.trackingDetails.pudo.distanceUnit
    ) {
      // conversion from kilometers to conversion unit
      switch (this.props.trackingDetails.pudo.distanceUnit) {
        case "miles":
          this.unitMultiplier = 0.6214;
          break;

        case "kilometers":
          this.unitMultiplier = 1;
          break;

        case "yards":
          this.unitMultiplier = 1093.61;
          break;

        default:
          this.unitMultiplier = 1;
      }
    }

    if(!isEqual(prevProps.pudoPoints, this.props.pudoPoints)) {
      this.setState({pudoPoints: [...this.props.pudoPoints]});
    }
  }

  onPudoAddressSearch = async (inputAddress) => {
    if(isEmpty(inputAddress)) {
      this.setState({pudoPoints: this.props.pudoPoints, searchLocation: null});
      return;
    }

    const addressObj = await processUpdateFormService.searchAddress(inputAddress, this.props.url, false);
    if(isEmpty(addressObj) || !addressObj.latitude) {
      this.setState({pudoPoints:[],searchLocation: null});
      console.log("invalid location received, returning...");
      return;
    }
    const searchLocation = {
      latitude: addressObj.latitude,
      longitude: addressObj.longitude,
    }

    const newPudoData = await mapViewService.fetchPudoData(this.props.url, addressObj.latitude,addressObj.longitude,this.props.trackingDetails.masterCode);
    if(newPudoData && newPudoData.visibleList && !isEqual(this.state.pudoPoints , newPudoData.visibleList)){
      this.setState({pudoPoints:newPudoData.visibleList,searchLocation:searchLocation});}
  }

  getDistanceBetweenLatLng = (lat1, lng1, lat2, lng2) => {
    const p = 0.017453292519943295;
    const c = Math.cos;
    let a =
      0.5 -
      c((lat2 - lat1) * p) / 2 +
      (c(lat1 * p) * c(lat2 * p) * (1 - c((lng2 - lng1) * p))) / 2;
    let distanceInKilometer = 12742 * Math.asin(Math.sqrt(a));
    let convertedDistance = this.unitMultiplier
      ? distanceInKilometer * this.unitMultiplier
      : distanceInKilometer;
    return convertedDistance.toFixed(2);
  };

  sortPudoPoints = (lat, lng) => {
    if (!isEmpty(this.state.pudoPoints)) {
      const changedPudoPoints = [];
      this.state.pudoPoints.forEach((pudoPoint) => {
        let temp = { ...pudoPoint };
        temp.distance = this.getDistanceBetweenLatLng(
          lat,
          lng,
          pudoPoint.latitude,
          pudoPoint.longitude
        );
        changedPudoPoints.push(temp);
      });
      changedPudoPoints.sort((a, b) => (a.distance < b.distance ? -1 : 1));
      this.props.actions.setPudoPoints(changedPudoPoints);
    }
  };

  setSearchAddress = (address) => {
    if (!isEmpty(address)) {
      this.searchLocationLat = address.geometry ?address.geometry.location.lat : address.latitude;
      this.searchLocationLng = address.geometry ?address.geometry.location.lng : address.longitude;
      this.sortPudoPoints(
        this.searchLocationLat ,
        this.searchLocationLng 
      );
      this.setState({
        showLocateAddress: false,
        sortedOnJob: false,
        addressValue: address.formatted_address,
      });
    }
  };

  onInputClear = () => {
    if (
      this.props.trackingDetails &&
      this.props.trackingDetails.jobLat &&
      this.props.trackingDetails.jobLng
    ) {
      this.sortPudoPoints(
        this.props.trackingDetails.jobLat,
        this.props.trackingDetails.jobLng
      );
    }
    this.setState({
      addressValue: "",
      sortedOnJob: true,
    });
  };

  setCurrentLocation = () => {
    this.mapFitBound(
      this.bounds,
      this.jobLatitude,
      this.jobLongitude,
      this.map
    );
  };

  updatePudoPointAccordingToCurrentLocation = () => {
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition((position) => {
        this.sortPudoPoints(
          position.coords.latitude,
          position.coords.longitude
        );
        this.setState({
          showLocateAddress: false,
          sortedOnJob: false,
          addressValue: "Your Address",
        });
      });
    }
  };

  mapFitBound(bounds, lat, lng, map) {
    this.map = map;
    this.bounds = bounds;
    this.jobLatitude = lat;
    this.jobLongitude = lng;
    map.fitBounds([bounds, this.jobMarker], {
      paddingBottomRight: [20, 250],
      paddingTopLeft: [20, 50],
    });
  }

  backToPreviousPage = () => {
    this.backToScreen();
  };

  updatePudo = async (pudo) => {
    let form = {
      processInteractionKeysDtos: [],
    };
    let updateList = this.props.updateProcessAttributeList;
    for (let i = 0; i < updateList.length; i++) {
      if (
        this.props.dsMappedKeyValue[pudo.id][updateList[i].dsAttributeKey] !=
          undefined &&
        !isEmpty(
          this.props.dsMappedKeyValue[pudo.id][updateList[i].dsAttributeKey]
        )
      ) {
        let attributes = {
          key: updateList[i]["processAttributeKey"],
          value: this.props.dsMappedKeyValue[pudo.id][
            updateList[i].dsAttributeKey
          ],
          typeId: updateList[i]["processAttributeTypeId"],
        };
        form["processInteractionKeysDtos"].push(attributes);
      }
    }

    if (!isEmpty(form["processInteractionKeysDtos"])) {
      let res = await this.props.actions.updateFormData(
        form.processInteractionKeysDtos,
        this.props.trackingDetails.pudo.updateProcessMasterCode,
        this.props.pudpReferenceNumber,
        this.props.url,
        "IS_PUDO",
        pudo,
        "",
        "",
        "",
        this.props.trackingDetails
      );
      if (res == "200") {
        this.errorMessage = "";
        this.setState({ pudoUpdated: true });
      } else {
        this.errorMessage = containerConstants.formatString(
          containerConstants.OopsSomethingWentWrongPleaseTryAfterSomeTime
        );
        this.setState({ pudoUpdated: false, pudoError: true });
      }
    }
  };

  checkAddressValue = (addressValue) => {
    if (!isEmpty(addressValue)) {
      return addressValue;
    }
  };
  showPudoSearchedList=()=>{
    return (
      <>
      
      {(Array.isArray(this.state.pudoPoints) && isEmpty(this.state.pudoPoints))?
        <div className="ci-pudo-return-map-preferred-store">
          <div className="mt20">
            <Alert
              message="No store available"
              description="Sorry, there is no store available in the area. Try searching in a different location."
              type="warning"
              showIcon
            />
          </div>
        </div>
      :
      <PudoScreenList
      availableList={this.state.pudoPoints}
      backAvailableLocation={this.clickBackAvailableLocationScreen}
      onForwardArrowClick={this.openCardDetail}
      primaryBgColor={this.props.primaryBgColor}
      unit={this.props.pudoConfig.distanceUnit}
      primaryTextColor={this.props.primaryTextColor}
      />
      }
      </>
    );
  }
  backButtonFunction=()=>{
    if(this.state.showLocateAddress){
      this.setState({ showLocateAddress:false})
    }
    else{
      this.backToPreviousPage()
    }
  }

  clearSearch = () => {
    this.setState({searchValue: ""});
    this.onPudoAddressSearch("");
    if (!this.state.showLocateAddress) {
      this.backToPreviousPage()
    }
  }

  render() {
    if(this.state.pudoPoints==null)
    return (
      <div>
      <div style={{position: 'absolute', top: '45%', transform: 'translate(-50%, -50%)', left: '50%'}}>Fetching PudoStores</div> 
      <TableLoader />
     </div>
    );
    if (this.state.pudoUpdated) {
      this.props.updatedShop();
    }
    if (this.state.shopDetail) {
      return (
        <div className="ci-pudo-return-store-details-container">
        <ShopDetail
          detail={this.storeSingleDetail}
          detailIndex={this.storeSingleDetailIndex}
          gobackBtn={this.closeCardDetail}
          primaryBgColor={this.props.primaryBgColor}
          unit={
            this.props.trackingDetails.pudo &&
            this.props.trackingDetails.pudo.distanceUnit &&
            (this.props.trackingDetails.pudo.distanceUnit == "kilometers"
              ? "Km"
              : this.props.trackingDetails.pudo.distanceUnit)
          }
          delayMessage={
            this.props.trackingDetails.pudo &&
            this.props.trackingDetails.pudo.delayMessage
          }
          attemptCount={
            this.props.trackingDetails.pudo &&
            this.props.trackingDetails.pudo.attemptCount
          }
          updatePudoShops={this.updatePudo}
          availableList={this.state.pudoPoints}
          onForwardArrowClick={this.openCardDetail}
          currentIndex={this.state.currentMarkerIndex}
          timingLabel={this.props.pudoConfig && this.props.pudoConfig.timingLabel ?this.props.pudoConfig.timingLabel:""}
          primaryTextColor={this.props.primaryTextColor}
          searchLocationLat={this.searchLocationLat}
          searchLocationLng={this.searchLocationLng}
          pudoConfig={this.props.trackingDetails.pudo}
          openPudoScreenList={this.openPudoScreenList}

        />
        </div>
      );
    }

    // if (this.state.showLocateAddress) {
      
    // }

    if (this.state.pudoList) {
      return (
        <>
        <div className='ci-pudo-return-map-header-search-bar'>
        <button className='back-arrow' onClick={() => this.setState({ pudoList: false })}><BackIconArrow/></button>
        <h3>Pick your preferred store</h3>
        <button className="dark-search-icon" onClick={() => this.setState({ showLocateAddress: true , pudoList: false})} id="searchPudoMobile"><DarkSearchIcon/></button>
        </div>
        <PudoScreenList
            availableList={this.state.pudoPoints}
            backAvailableLocation={this.clickBackAvailableLocationScreen}
            onForwardArrowClick={this.openCardDetail}
            primaryBgColor={this.props.primaryBgColor}
            unit={this.props.pudoConfig.distanceUnit}
            primaryTextColor={this.props.primaryTextColor}
          />
        </>
      );
    } else {
      return (
        <div className="ci-pudo-return-map-view-mobile">
            <div className='ci-pudo-return-map-header-search-bar'>
              <button className='back-arrow' onClick={()=>this.backButtonFunction()}><BackIconArrow/></button>
              
            <Input
            id="searchPudoMobile" 
            size="large" 
            placeholder="Search by block, area, city, etc"
            value={this.state.searchValue}
            onChange={e => {this.setState({ searchValue: e.target.value, showLocateAddress: true })}} 
            onKeyUp={e => e.keyCode == 13 && this.onPudoAddressSearch(this.state.searchValue)}
            className='input-search-field' />
            {!this.state.searchValue?
            <button className="search-icon"><SearchIcon/></button>:
            <button className="search-icon"
            onClick={()=>this.clearSearch()}
            >&#10006;</button>
            }
          </div>
            {this.state.showLocateAddress || isEmpty(this.state.pudoPoints)?
            this.showPudoSearchedList():
          <div className='ci-pudo-return-map-container-view'>
            <div className='ci-pudo-return-map-frame-view'>  
              <MapView
                key={this.state.searchLocation && this.state.searchLocation.latitude}
                availableLocationList={this.state.pudoPoints}
                points={this.points}
                mapFitBound={this.mapFitBound}
                searchLocation={this.state.searchLocation}
              />
            </div>
            <div className='ci-pudo-return-gpd-icon' onClick={this.setCurrentLocation}>
                <GpsIcon/>
            </div>  
            <div className='ci-pudo-return-list-view-icon' onClick={this.openPudoScreenList} id="listViewMobile">
                <ListViewIcon id="listViewMobile"/><p>View List</p>
            </div>  
              
            <div className='ci-pudo-return-bottom-drawer'>
                <h4 className='title-h4'>Pick your preferred store</h4>
                {this.renderCardLayout()}
            </div>
            </div> } 
          </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  trackingDetails: state.customerInteractionReducer.trackingDetails,
  url: state.customerInteractionReducer.url,
  updateProcessAttributeList: state.pudoReducer.updateProcessAttributeList,
  dsMappedKeyValue: state.pudoReducer.dsMappedKeyValue,
  pudpReferenceNumber: state.pudoReducer.referenceNumber,
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { setPudoPoints, ...CustomerInteractionAction },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AvailableLocationV3);
