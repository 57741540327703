import { isEmpty } from "lodash";
import Axios from "axios";
import API from "../../Config/API";
import { processUpdateFormService } from "../../services/ProcessUpdateFormService";
import { restClientService } from "../../services/RestClientService";

class ShipmentService {

    getTrackingDetailsForShipment = async (url) => {
        try {
            const deviceType = await processUpdateFormService.getDeviceType();
            url = url.replace(/\s/g, "+");
            // if (url.indexOf('&embed=true') != -1) {
            //     url = url.substring(0, url.indexOf('&embed=true'));
            // }
            url = await restClientService.updateUrl(url);

            let hitUrl = API.GET_SHIPMENT_TRACKING_DETAILS;
            const res = await Axios.get(hitUrl, { params: { url: url,deviceType: deviceType } });
            if (res && res.data) {
                window.localStorage.setItem('isHerePoweredAccount',res.data.herePoweredAccount);
                return res.data;
            }
        } catch (err) {
            return "notFound";
        }
    }
}


const shipmentService = new ShipmentService();
export default shipmentService;