import {isEmpty} from 'loadsh'
import  CONSTANTS  from '../Utils/Constants';

class ListUtilities {
  //to check whether the list entered is empty or not
    showOrderInfo = (attributeList) => {
        if (!isEmpty(attributeList)) {
          let i = 0;
    
          for (i = 0; i < attributeList.length; i++) {
            if (attributeList[i] && attributeList[i].value && !isEmpty(attributeList[i].value)) {
              return true;
            }
          }
    
        }
      return false;
      }
   checkOrderDto =(orderInfo)=>{
      if(!isEmpty(orderInfo)){
         for(let i in orderInfo){
          if(orderInfo[i].itemName || orderInfo[i].itemQuantity || orderInfo[i].itemImage || this.showOrderInfo(orderInfo[i].attributeValueList))
          return true;
         }
      }
      return false;
   }
}
export const listUtilities = new ListUtilities();