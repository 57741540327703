import {isEmpty} from 'loadsh'


class EventService {

     getShipmentCount(details){
       let count = 0;

        if(details && details.processStatusLogDomainList 
            && details.processUpdateForm){
           if(details && details.eventProcessMasterId){
                let statusLogs = details.processStatusLogDomainList;
                statusLogs.map((status)=>{
                    if(status.processStatusLogDto && status.processStatusLogDto[0] &&
                        status.processStatusLogDto[0].processMasterId == details.eventProcessMasterId){
                            count = count+1;
                    }
                })
           }
        }
        return count;
    }

    getEmailSupportNumber (details){
        if(details && details.help && !isEmpty(details.help)){
              let help = JSON.parse(details.help);
              if(help.helpLineEmail && !isEmpty(help.helpLineEmail)){
                 return help.helpLineEmail;
              }
              
        }
    }

    getCallSupportNumber(details){
        let support = {};
        if(details && details.help && !isEmpty(details.help)){
              let help = JSON.parse(details.help);
              if(help.helpLineContact && !isEmpty(help.helpLineContact)){
                  return help.helpLineContact;
              }
        }
    }
}


export const eventService = new EventService(); 