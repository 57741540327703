import React from "react";
import "../style";
import { isEmpty } from "loadsh";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import StatusTimeLine from "./StatusTimeLine";

class Shipment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false,
    };

    this.getStatusDetails = this.getStatusDetails.bind(this);
  }

  getStatusDetails = () => (event) => {
    this.setState({ showStatus: true });
  };

  getShipmentDetails = () => {
    let log = [];
    if (this.props.logs) {
      log.push(
        <div
          className="bg-white group shipment"
          onClick={this.getStatusDetails()}
        >
          <div className="ship-title clearfix">
            <span>{this.props.logs[0].referenceNumber}</span>
          </div>

          <div class="ship-detail-status clearfix">
            <div class="ship-block clearfix">
              <span>
                {this.props.logs[0].status}
                <br />
                <small>
                  {" "}
                  {this.props.logs[0].statusTime
                    ? this.props.logs[0].statusTime.substring(0, 10)
                    : ""}
                </small>
              </span>
            </div>

            <div class="ship-detail clearfix">
              <span style={{ float: "right", fontSize: "12px" }}> > </span>
            </div>
          </div>
        </div>
      );
    }
    return log;
  };

  getNewTime = (deliveryTime) => {
    if (deliveryTime) {
      let time = deliveryTime.trim();
      let newTime;
      let a = parseInt(time.substring(0, 2));
      let b = time.substring(3, 5);
      if (a == 0) {
        newTime = time.substring(0, 6) + " AM";
      } else if (a > 12) {
        newTime = a - 12 + ":" + b + " PM";
      } else {
        newTime = a + ":" + b + " AM";
      }
      return newTime;
    }
  };

  
  getStatusLogs = () => {
    return (
      <Drawer
        anchor="bottom"
        open={this.state.showStatus}
        onClose={(event) => {
          this.setState({ showStatus: false });
        }}
      >
        <div className="multipartShipmentStatus">
          <StatusTimeLine trackingDetails={this.props.trackingDetails} />
        </div>
      </Drawer>
    );
  };

  render() {
    return (
      <div>
        {this.getShipmentDetails()}
        {this.getStatusLogs()}
      </div>
    );
  }
}

export default Shipment;
