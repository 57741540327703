export const trackLogSource = {
  GTS: 'GTS',
  FAREYE_MOBI_USER_SUMMARY: 'FAREYE_MOBI_USER_SUMMARY'
}

export const predictMovementConstants = {
  TRACKLOGS_INTERVAL: 5000, // 5 sec
  BACK_LOCATION_TOLERANCE: 2000,
  STANDARD_MOVEMENT_SPEED_KMPH: 45,
  BEHIND_LATLONG_MOVEMENT_SEGMENTS: 4,
  BEHIND_LATLONG_MOVEMENT_STANDARD_TIME_IN_SEC: 15,
  SAME_LOCATION_TOLERANCE_IN_METERS: 50,
  STANDARD_MOVEMENT_SEGMENTS: 2,
}

export const trackingInaccuracyConstants = {
  TRACKING_INACCURACY_MESSAGE_ID: "tracking-inaccuracy-message",
  INACCURACY_THRESHOLD_SECONDS: 6 * 60, // 6 min
}

export const mapLayoutSource = {
  GOOGLE: 'GOOGLE',
  FAREYE: 'FAREYE'
}
