import { Snackbar } from "@material-ui/core";
import React from "react";

export default class CurbsideOrderShareLink extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    handleShareButton = async () => {
        let shareTextBody = this.props.curbsidePickupData && this.props.curbsidePickupData.otherDetailsData && this.props.curbsidePickupData.otherDetailsData.shareTextBody;
        console.log("link to shared " + window.location.href)
        try {
            await navigator.share({ text: shareTextBody, url: window.location.href });
            console.log("Shared");
        } catch (err) {
            navigator.clipboard.writeText(window.location.href);
            this.setState({ open: true });
            console.log("Error", err);
        }
    }

    render() {
        return (
            <div>
                <div className="share-link-container">
                    <h4>{this.props.curbsidePickupData && this.props.curbsidePickupData.otherDetailsData && this.props.curbsidePickupData.otherDetailsData.shareTextBody}</h4>
                    <button className="order-share-button" onClick={this.handleShareButton}>Share this Link</button>
                    {/* <img src={staffBanner} className="share-order-staff-picture" alt="" /> */}
                    <img src={require("../../../images/banner/share-link-banner-staff.svg")} alt="" />

                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={this.state.open}
                        autoHideDuration={3500}
                        onClose={() => this.setState({ open: false })}
                        message="Copied to Clipboard">
                    </Snackbar>
                </div>
            </div>
        )
    }
}


