import React from "react";
import RightTick from "../images/tick-inside-a-circle.svg";
import Header from "../components/Header";
import { MyContext } from "../containers/context";

class FailedMessage extends React.Component {
  render() {
    return (
      <MyContext.Consumer>
        {({ primaryBgColor, primaryTextColor, fontSize }) => (
          <div>
            <div id="top-area">
              <Header
                primaryBgColor={primaryBgColor}
                primaryTextColor={primaryTextColor}
              />
            </div>
            <img
              src={require("../images/unable-track.svg")}
              className="successImg"
            />
            <p
              style={{
                textAlign: "center",
                marginTop: "30px",
                marginLeft: "10%",
                marginRight: "10%",
                fontSize: fontSize,
              }}
            >
              {containerConstants.formatString(
                containerConstants.WeAreUnableToTrackYourShipmentRightNow
              )}
            </p>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
export default FailedMessage;
