import React from 'react';
import { render } from 'react-dom';
import ProcessUpdateForm from './containers/ProcessUpdateForm';
import UpdateAddress from './containers/UpdateAddress';
import LocateAddress from './containers/LocateAddress';
import { Provider } from 'react-redux';
import { createStore,applyMiddleware } from 'redux';
import thunk from 'redux-thunk'
import reducer from './combineReducers';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import GlobalStyles from "./style";
import ClientSpecificStyle from "./clientSpecificStyle";
import Screen from './containers/Index'
import ReturnScreen from './containers/return-index';
import CurbsidePickup from './containers/CurbsidePickupContainer/index'
import { isEmpty } from 'lodash';
import { storeClickCountService } from './StoreClickCountService';
import DiyTracking from './containers/DIY/index'
import PudoIframe from './containers/PudoIframe';
import ShipmentTracking from './containers/ShipmentTracking';
import NewPrototype from './containers/Prototype/index'

// import { createLogger } from 'redux-logger';

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer,applyMiddleware(thunk));


// const store = createStore(reducer,  composeEnhancers(
//   applyMiddleware(thunk, createLogger({ predicate: (getState, action) => process.env.NODE_ENV != 'production' }))
// ));

const App = () => (
  <Provider store={store}>
    <GlobalStyles/>
    <ClientSpecificStyle/>
    <Router>
      <div >
        <Switch>
          <Route path="/pudo-iframe/:companyCode/:dataStore/:pincode/:type/:returnsUrl" component={PudoIframe} />
          <Route path="/tracking" component={Screen} />
          <Route path="/universal-tracking"  component={(props) => <Screen {...props} universalTracking="true" />}/>
          <Route exact path="/tracking?param1=1&param2=2" component={Screen} />
          <Route path="/curbside_pickup" component={CurbsidePickup} />
          <Route path="/return-tracking" component={ReturnScreen} />
          <Route path="/d-tracking" component={DiyTracking} />
          <Route path="/shipmentTracking" component={ShipmentTracking} />
          <Route path="/new-tracking" component={NewPrototype} />
          <Route path="/d-tracking-preview" render={(props)=><DiyTracking {...props} preview={true}/>}  />
        </Switch>
      </div>
    </Router>
  </Provider>
);

render(<App />, document.getElementById('root'));