import React from "react";
import { isEmpty } from "loadsh";
import DOMPurify from "dompurify";
import OrderAddressList from "../../components/CustomScreen/OrderAddressList";

/* here we show individual items details from this component */
function ItemDetail(props) {
  let details = [];
  let otherDetails = [];
  if (!isEmpty(props.list)) {
    props.list.map((item) => {
      if (
        item &&
        item.value &&
        !isEmpty(item.value) &&
        (item.value.indexOf("http") != -1 || item.value.indexOf("https") != -1)
      ) {
        details.push(
          <div className="mb20">
            <p className="fs12 mb10 text-gray">{item.label}</p>
            <div style={{ height: "165px", width: "100%" }}>
              <img className="height-100" src={item.value} alt="" />
            </div>
          </div>
        );
      } else if (
        item &&
        item.value &&
        !isEmpty(item.value) &&
        item.value.indexOf("<html>") != -1
      ) {
        otherDetails.push(
          <div>
            <p
              className="mb10 fs14"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(item.value),
              }}
            ></p>
          </div>
        );
      } else {
        otherDetails.push(
          <div>
            <p className="text-gray fs12 mb10">{item.label}</p>
            <p className="mb10 fs14">{item.value}</p>
          </div>
        );
      }
    });
  }

  return (
    <React.Fragment>
      {details}
      <div className="itemDetails">{otherDetails}</div>
      <div>
        <OrderAddressList list={props.attributeList} single={true} />
      </div>
    </React.Fragment>
  );
}

export default ItemDetail;
