import React from "react";

export const BackIconArrow =() =>{
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7170_87775)">
            <path d="M16.875 10H3.125" stroke="#647788" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.75 4.375L3.125 10L8.75 15.625" stroke="#647788" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_7170_87775">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
};

export const SearchIcon =() =>{
    return(
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7170_87783)">
            <path d="M7.25 12.5C10.1495 12.5 12.5 10.1495 12.5 7.25C12.5 4.35051 10.1495 2 7.25 2C4.35051 2 2 4.35051 2 7.25C2 10.1495 4.35051 12.5 7.25 12.5Z" stroke="#ADADAD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.9624 10.9625L13.9999 14" stroke="#ADADAD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_7170_87783">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
};

export const GpsIcon =() =>{
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7170_87764)">
            <path d="M10.0002 6.66665C8.1585 6.66665 6.66683 8.15831 6.66683 9.99998C6.66683 11.8416 8.1585 13.3333 10.0002 13.3333C11.8418 13.3333 13.3335 11.8416 13.3335 9.99998C13.3335 8.15831 11.8418 6.66665 10.0002 6.66665ZM17.4502 9.16665C17.0668 5.69165 14.3085 2.93331 10.8335 2.54998V1.66665C10.8335 1.20831 10.4585 0.833313 10.0002 0.833313C9.54183 0.833313 9.16683 1.20831 9.16683 1.66665V2.54998C5.69183 2.93331 2.9335 5.69165 2.55016 9.16665H1.66683C1.2085 9.16665 0.833496 9.54165 0.833496 9.99998C0.833496 10.4583 1.2085 10.8333 1.66683 10.8333H2.55016C2.9335 14.3083 5.69183 17.0666 9.16683 17.45V18.3333C9.16683 18.7916 9.54183 19.1666 10.0002 19.1666C10.4585 19.1666 10.8335 18.7916 10.8335 18.3333V17.45C14.3085 17.0666 17.0668 14.3083 17.4502 10.8333H18.3335C18.7918 10.8333 19.1668 10.4583 19.1668 9.99998C19.1668 9.54165 18.7918 9.16665 18.3335 9.16665H17.4502ZM10.0002 15.8333C6.77516 15.8333 4.16683 13.225 4.16683 9.99998C4.16683 6.77498 6.77516 4.16665 10.0002 4.16665C13.2252 4.16665 15.8335 6.77498 15.8335 9.99998C15.8335 13.225 13.2252 15.8333 10.0002 15.8333Z" fill="#647788"/>
            </g>
            <defs>
            <clipPath id="clip0_7170_87764">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
};

export const ListViewIcon =() =>{
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7170_87766)">
            <path d="M7.5 5H16.875" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.5 10H16.875" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.5 15H16.875" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.125 5H4.375" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.125 10H4.375" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.125 15H4.375" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_7170_87766">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
};

export const ClockIcon =() =>{
    return(
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7441_11274)">
            <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#647788" stroke-miterlimit="10"/>
            <path d="M8 4.5V8H11.5" stroke="#647788" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_7441_11274">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
};
export const LocationIcon =() =>{
    return(
        <svg width="25" height="32" viewBox="0 0 25 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.352 0C19.0891 0 24.2712 5.18222 24.2712 11.4009C24.2712 15.8056 18.0525 25.1336 14.4254 30.834C13.389 32.3887 11.3161 32.3887 10.5386 30.834C6.65186 25.1335 0.433594 15.8055 0.433594 11.4009C0.433594 5.1821 5.87424 0 12.352 0Z" fill="#EB4E4E"/>
        </svg>
    );
};

export const DarkSearchIcon =() =>{
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7170_87676)">
            <path d="M9.0625 15.625C12.6869 15.625 15.625 12.6869 15.625 9.0625C15.625 5.43813 12.6869 2.5 9.0625 2.5C5.43813 2.5 2.5 5.43813 2.5 9.0625C2.5 12.6869 5.43813 15.625 9.0625 15.625Z" stroke="#05223D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.7031 13.7031L17.5 17.5" stroke="#05223D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_7170_87676">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
};

export const ViewMapIcon =() =>{
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7170_87734)">
            <path d="M7.5 14.375L2.5 15.625V4.375L7.5 3.125" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.5 16.875L7.5 14.375V3.125L12.5 5.625V16.875Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.5 5.625L17.5 4.375V15.625L12.5 16.875" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_7170_87734">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
};

// New

export const BackPageArrow = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4_2765)">
            <path d="M16.875 10H3.125" stroke="#647788" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.75 4.375L3.125 10L8.75 15.625" stroke="#647788" stroke-width="1.5625" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_4_2765">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
};

export const PudoLocationIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4_2800)">
            <path d="M9 9.5625C10.2426 9.5625 11.25 8.55514 11.25 7.3125C11.25 6.06986 10.2426 5.0625 9 5.0625C7.75736 5.0625 6.75 6.06986 6.75 7.3125C6.75 8.55514 7.75736 9.5625 9 9.5625Z" stroke="#3F98D8" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.625 7.3125C14.625 12.375 9 16.3125 9 16.3125C9 16.3125 3.375 12.375 3.375 7.3125C3.375 5.82066 3.96763 4.38992 5.02252 3.33502C6.07742 2.28013 7.50816 1.6875 9 1.6875C10.4918 1.6875 11.9226 2.28013 12.9775 3.33502C14.0324 4.38992 14.625 5.82066 14.625 7.3125V7.3125Z" stroke="#3F98D8" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_4_2800">
            <rect width="18" height="18" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
};

export const PudoCallIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4_2772)">
            <path d="M11.2078 2.8125C12.1619 3.06856 13.0318 3.57107 13.7304 4.26959C14.4289 4.96812 14.9314 5.83809 15.1875 6.79219" stroke="#3F98D8" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.6243 4.98517C11.1981 5.13757 11.7215 5.43895 12.1413 5.85878C12.5611 6.27861 12.8625 6.80196 13.0149 7.37579" stroke="#3F98D8" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.50391 8.77499C7.08273 9.97031 8.04929 10.9344 9.24609 11.5101C9.33437 11.5519 9.43202 11.57 9.52942 11.5626C9.62681 11.5552 9.72061 11.5226 9.80156 11.468L11.5594 10.2937C11.637 10.2411 11.7268 10.2089 11.8202 10.2003C11.9137 10.1917 12.0078 10.2069 12.0937 10.2445L15.3844 11.6578C15.4968 11.7046 15.5908 11.7871 15.6518 11.8925C15.7128 11.998 15.7374 12.1206 15.7219 12.2414C15.6176 13.0554 15.2202 13.8036 14.6042 14.3458C13.9882 14.8881 13.1957 15.1873 12.375 15.1875C9.83887 15.1875 7.40661 14.18 5.61329 12.3867C3.81997 10.5934 2.8125 8.16113 2.8125 5.62499C2.81268 4.8043 3.1119 4.0118 3.65416 3.39577C4.19642 2.77975 4.94456 2.38241 5.75859 2.27812C5.87942 2.2626 6.00199 2.28723 6.10745 2.34821C6.2129 2.40919 6.29538 2.50315 6.34219 2.61562L7.75547 5.91327C7.79222 5.99789 7.8076 6.09025 7.80024 6.18222C7.79288 6.27418 7.76302 6.36292 7.71328 6.44062L6.53906 8.22656C6.48679 8.30733 6.4561 8.40017 6.44995 8.49619C6.44379 8.59221 6.46237 8.6882 6.50391 8.77499V8.77499Z" stroke="#3F98D8" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_4_2772">
            <rect width="18" height="18" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
};

export const PudoTimeIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4_2790)">
            <path d="M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z" stroke="#3F98D8" stroke-width="1.125" stroke-miterlimit="10"/>
            <path d="M9 5.0625V9H12.9375" stroke="#3F98D8" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_4_2790">
            <rect width="18" height="18" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
};

export const PudoInfoIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4_2781)">
            <path d="M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z" stroke="#05223D" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.4375 8.4375H9V12.375H9.5625" stroke="#05223D" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.85938 6.75C9.32537 6.75 9.70312 6.37224 9.70312 5.90625C9.70312 5.44026 9.32537 5.0625 8.85938 5.0625C8.39338 5.0625 8.01562 5.44026 8.01562 5.90625C8.01562 6.37224 8.39338 6.75 8.85938 6.75Z" fill="#05223D"/>
            </g>
            <defs>
            <clipPath id="clip0_4_2781">
            <rect width="18" height="18" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
};

export const PudoDownArrowIcon = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_4_2794)">
            <path d="M11.375 5.25L7 9.625L2.625 5.25" stroke="#3F98D8" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_4_2794">
            <rect width="14" height="14" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
};

export const PudoDirectionsIcon = () => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_193_18273)">
            <path d="M13.0251 2.83761L2.44388 6.50011C1.98763 6.65636 2.00013 7.30636 2.46263 7.45011L7.25013 8.92511C7.3284 8.94737 7.39952 8.98962 7.4565 9.0477C7.51348 9.10578 7.55437 9.17769 7.57513 9.25636L9.05013 14.0376C9.19388 14.5001 9.84388 14.5126 10.0001 14.0564L13.6626 3.47511C13.6939 3.38621 13.6994 3.29028 13.6785 3.1984C13.6576 3.10651 13.6111 3.02241 13.5445 2.95577C13.4778 2.88914 13.3937 2.84269 13.3018 2.82179C13.21 2.80088 13.114 2.80637 13.0251 2.83761V2.83761Z" stroke="#3F98D8" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_193_18273">
            <rect width="16" height="16" fill="white" transform="translate(16 0.5) rotate(90)"/>
            </clipPath>
            </defs>
        </svg>
    );
};

export const PudoModalCloseIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_193_18265)">
            <path d="M15.2501 4.75843C14.9251 4.43343 14.4001 4.43343 14.0751 4.75843L10.0001 8.8251L5.9251 4.7501C5.6001 4.4251 5.0751 4.4251 4.7501 4.7501C4.4251 5.0751 4.4251 5.6001 4.7501 5.9251L8.8251 10.0001L4.7501 14.0751C4.4251 14.4001 4.4251 14.9251 4.7501 15.2501C5.0751 15.5751 5.6001 15.5751 5.9251 15.2501L10.0001 11.1751L14.0751 15.2501C14.4001 15.5751 14.9251 15.5751 15.2501 15.2501C15.5751 14.9251 15.5751 14.4001 15.2501 14.0751L11.1751 10.0001L15.2501 5.9251C15.5668 5.60843 15.5668 5.0751 15.2501 4.75843Z" fill="#05223D"/>
            </g>
            <defs>
            <clipPath id="clip0_193_18265">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
};

export const SelectArrowIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="16" fill="#D6DADE"/>
            <g clip-path="url(#clip0_193_18303)">
            <path d="M12.1501 23.5083C12.5584 23.9166 13.2167 23.9166 13.6251 23.5083L20.5501 16.5833C20.8751 16.2583 20.8751 15.7333 20.5501 15.4083L13.6251 8.48325C13.2167 8.07492 12.5584 8.07492 12.1501 8.48325C11.7417 8.89159 11.7417 9.54992 12.1501 9.95825L18.1834 15.9999L12.1417 22.0416C11.7417 22.4416 11.7417 23.1083 12.1501 23.5083Z" fill="white" fill-opacity="0.9"/>
            </g>
            <defs>
            <clipPath id="clip0_193_18303">
            <rect width="20" height="20" fill="white" transform="translate(6 6)"/>
            </clipPath>
            </defs>
        </svg>
    );
};

export const WhiteCloseIconn = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_279_7791)">
            <path d="M15.25 4.75843C14.925 4.43343 14.4 4.43343 14.075 4.75843L9.99998 8.8251L5.92498 4.7501C5.59998 4.4251 5.07498 4.4251 4.74998 4.7501C4.42498 5.0751 4.42498 5.6001 4.74998 5.9251L8.82498 10.0001L4.74998 14.0751C4.42498 14.4001 4.42498 14.9251 4.74998 15.2501C5.07498 15.5751 5.59998 15.5751 5.92498 15.2501L9.99998 11.1751L14.075 15.2501C14.4 15.5751 14.925 15.5751 15.25 15.2501C15.575 14.9251 15.575 14.4001 15.25 14.0751L11.175 10.0001L15.25 5.9251C15.5666 5.60843 15.5666 5.0751 15.25 4.75843Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_279_7791">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );
};

export const MapBackArrow = () => {
    return (
        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.012 5.962V7.438H3.868L8.062 11.65L7 12.712L0.988 6.7L7 0.688L8.062 1.75L3.868 5.962H13.012Z" fill="#727272"/>
        </svg>
    );
};
export const MapSearchIcon = () => {
    return (
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.146 9.212C5.758 9.212 6.322 9.062 6.838 8.762C7.354 8.45 7.762 8.036 8.062 7.52C8.362 7.004 8.512 6.44 8.512 5.828C8.512 5.216 8.362 4.652 8.062 4.136C7.762 3.62 7.354 3.212 6.838 2.912C6.322 2.612 5.758 2.462 5.146 2.462C4.534 2.462 3.97 2.612 3.454 2.912C2.938 3.212 2.524 3.62 2.212 4.136C1.912 4.652 1.762 5.216 1.762 5.828C1.762 6.44 1.912 7.004 2.212 7.52C2.524 8.036 2.938 8.45 3.454 8.762C3.97 9.062 4.534 9.212 5.146 9.212ZM9.646 9.212L13.372 12.938L12.238 14.072L8.512 10.328V9.734L8.296 9.536C7.876 9.908 7.39 10.196 6.838 10.4C6.298 10.592 5.734 10.688 5.146 10.688C4.258 10.688 3.436 10.472 2.68 10.04C1.936 9.608 1.348 9.026 0.916 8.294C0.472 7.538 0.25 6.716 0.25 5.828C0.25 4.94 0.466 4.124 0.898 3.38C1.342 2.624 1.936 2.03 2.68 1.598C3.436 1.166 4.258 0.95 5.146 0.95C6.034 0.95 6.856 1.172 7.612 1.616C8.344 2.048 8.92 2.636 9.34 3.38C9.772 4.124 9.988 4.94 9.988 5.828C9.988 6.428 9.886 7.004 9.682 7.556C9.49 8.096 9.208 8.576 8.836 8.996L9.034 9.212H9.646Z" fill="#A8A8A8"/>
        </svg>
    );
};

export const MapLocateIcon = () => {
    return (
        <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5752 0C19.4717 0 25.0752 5.64068 25.0752 12.5831C25.0752 19.2 18.2864 28.7458 12.5752 32C6.86399 28.6373 0.0751953 19.4169 0.0751953 12.5831C0.0751953 5.64068 5.67864 0 12.5752 0Z" fill="#EB4E4E"/>
        </svg>
    );
};
