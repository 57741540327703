import React from "react";
import { isEmpty } from "lodash";


export const PudoOtherDetails = (props) => {
  let list = []
  if (!isEmpty(props.otherDetails)) {
    let otherDetailsArr = props.otherDetails.split(",");
    for (let i = 0; i < otherDetailsArr.length; i++) {
      if (!isEmpty(otherDetailsArr[i])) {
        list.push(<div>{otherDetailsArr[i]}</div>)
      }
    }
  }
  return (
    <div>{list}</div>
  );

}