import React, { Component } from 'react'
import {WhiteCloseIconn, MapBackArrow, MapSearchIcon, MapLocateIcon, PudoModalCloseIcon,
    PudoLocationIcon, PudoDirectionsIcon, PudoDownArrowIcon, PudoCallIcon, PudoTimeIcon} from "../../LayoutScreen/PudoReturnMap/Icons";

class CIPudoMapDesktopModal extends Component {
  render() {
    return (
      <>
        <div className='ci-pudo-return-desktop-map-modal-container'>
            {/* Header */}
            <div className='returns-modal-box-map-header'>
                <h3>Nearby Stores</h3>
                <button className='close'><WhiteCloseIconn/></button>
            </div>
            {/* Header */}
            {/* Body */}
            <div className='ci-pudo-return-desktop-map-modal-body'>
                <div className='ci-pudo-return-desktop-map-frame'>
                    <img src={require("../../LayoutScreen/PudoReturnMap/desktop-map.jpg")} />
                </div>
                <div className='ci-pudo-return-desktop-map-store-list'>
                    {/* Desktop Map left list store */}
                    <div class="returns-map-list-view">
                        <div class="returns-map-list-search-field">
                            <button class="returns-map-list-back-arrow">
                                <MapBackArrow/>
                            </button>
                            <input class="returns-map-list-search-input" placeholder="Search for Pickup point near you" name="" type="text" />
                            <button class="returns-map-list-search-icon">
                                <MapSearchIcon/>
                            </button>
                        </div>
                        <div class="returns-map-list-group">
                            {/* Row */}
                            <div class="simple-list-store-detail highlighted-store-detail">
                                <div class="returns-map-list-address-row">
                                    <div class="returns-map-list-address-name">
                                    <h4>Dukkan_GAP_04</h4>
                                    <div class="returns-map-list-address-group">
                                        <div class="returns-map-list-point-num">
                                            <MapLocateIcon/>
                                            <span class="address-map-shape" >1</span>
                                        </div>
                                        <div class="returns-map-list-point-right">
                                        <p>Location 1, GAP</p>
                                        <span>09:00 AM : 10:10 PM</span>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="returns-map-list-address-dtl">
                                    <button class="nearest-btn">Nearest</button>
                                    <h6>4.23 miles away</h6>
                                    <div class="pudo-map-select-arrow-div">
                                        <button class="pudo-map-select-arrow">Select</button>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            {/* Row */}
                            <div class="simple-list-store-detail">
                                <div class="returns-map-list-address-row">
                                    <div class="returns-map-list-address-name">
                                    <h4>Shop_GAP_02</h4>
                                    <div class="returns-map-list-address-group">
                                        <div class="returns-map-list-point-num">
                                            <MapLocateIcon/>
                                        <span class="address-map-shape">2</span>
                                        </div>
                                        <div class="returns-map-list-point-right">
                                        <p>Location 2, GAP</p>
                                        <span>09:00 AM : 10:00 PM</span>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="returns-map-list-address-dtl">
                                    <h6>4.23 miles away</h6>
                                    <div class="pudo-map-select-arrow-div">
                                        <button class="pudo-map-select-arrow">Select</button>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            {/* Row */}
                            <div class="simple-list-store-detail">
                                <div class="returns-map-list-address-row">
                                    <div class="returns-map-list-address-name">
                                    <h4>Shop_GAP_02</h4>
                                    <div class="returns-map-list-address-group">
                                        <div class="returns-map-list-point-num">
                                            <MapLocateIcon/>
                                        <span class="address-map-shape">3</span>
                                        </div>
                                        <div class="returns-map-list-point-right">
                                        <p>Location 2, GAP</p>
                                        <span>09:00 AM : 10:00 PM</span>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="returns-map-list-address-dtl">
                                    <h6>4.23 miles away</h6>
                                    <div class="pudo-map-select-arrow-div">
                                        <button class="pudo-map-select-arrow">Select</button>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            {/* Row */}
                        </div>
                    </div>
                    {/* Desktop Map left list store */}
                </div>
                {/* Desktop Map address popup */}
                <div class="return-store-pudo-web-view">
                        <div class="return-pudo-store-dtl-inner">
                            <button class="return-pudo-store-web-close">
                            <PudoModalCloseIcon/>
                            </button>
                            <div class="return-pudo-store-name-title">
                            <h4>Dukkan_GAP_04</h4>
                            <span>4.23 miles away</span>
                            </div>
                            <div class="return-pudo-store-outer-box">
                            <div class="return-pudo-store-list-row">
                                <div class="return-pudo-store-img-icon">
                                <PudoLocationIcon/>
                                </div>
                                <div class="return-pudo-store-data-col">
                                <p>Location 1, GAP, Noida 777777</p>
                                <a href="#" target="_blank"><PudoDirectionsIcon/>Get Directions </a>
                                </div>
                            </div>
                            <div class="return-pudo-store-list-row">
                                <div class="return-pudo-store-img-icon">
                                    <PudoCallIcon/>
                                </div>
                                <div class="return-pudo-store-data-col">
                                <p>
                                    <span style={{color: "rgb(36, 161, 72);"}}>OPEN</span> | 09:00 AM : 10:10 PM
                                    <button className='down-arrow'><PudoDownArrowIcon/></button>
                                </p>
                                </div>
                            </div>
                            <div class="return-pudo-store-ul-list">
                                <div class="return-pudo-store-img-icon" style={{backgroundColor: "rgb(247, 247, 247);"}}>
                                    <PudoTimeIcon/>
                                </div>
                                <div className='return-pudo-store-data-ul-col'>
                                    <ul className='return-pudo-store-info-list'>
                                    <li>Open untill 23:59</li>
                                    <li>Parking</li>
                                    <li>Print in shop</li>
                                    <li>7 Day Opening</li>
                                    <li>Next day drop off</li>
                                    <li>CCTV</li>
                                    <li>Wheelchair Accessible</li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                            <button type="button" class="return-pudo-web-confirm-btn">Confirm Mode of Return</button>
                        </div>
                    </div>
                    {/* Desktop Map address popup */}
            </div>
            {/* Body */}
        </div>
      </>
    )
  }
}

export default CIPudoMapDesktopModal;