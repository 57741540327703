import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class AlertModal extends React.Component {
  alert = () => {
    return (
      <div>
        <Dialog
          open={true}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.props.cancel}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              style={{ textAlign: "justify" }}
            >
              {containerConstants.formatString(
                containerConstants.YouHaveAlreadyAttemptedToMakeAPaymentInLastFewMinutes
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.cancel} color="primary">
              {containerConstants.formatString(containerConstants.No)}
            </Button>
            <Button onClick={this.props.updatePaymentInfo} color="primary">
              {containerConstants.formatString(containerConstants.yes)}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  render() {
    return this.alert();
  }
}

export default AlertModal;
