import React, { Component } from "react";

class TrackingStatus extends Component {
  getSortedStatusLogOnDateTime = (trackingDetails) => {
    let previousDate = "";
    let timings = [];
    let statusLogList = [];
    let status = [];

    for (
      let i = 0;
      i < trackingDetails.processStatusLogDomainList.length;
      i++
    ) {
      if (
        trackingDetails.processStatusLogDomainList[i].processStatusLogDto[0]
          .processMasterId == trackingDetails.eventProcessMasterId
      ) {
        const statusLogs =
          trackingDetails.processStatusLogDomainList[i].processStatusLogDto;

        let statusLogsSortedArray = statusLogs.sort(function (a, b) {
          let dateA = new Date(a.statusTime);
          let dateB = new Date(b.statusTime);
          return dateA.getTime() - dateB.getTime();
        });

        for (let i = 0; i < statusLogsSortedArray.length; i++) {
          if (
            previousDate !==
            statusLogsSortedArray[i].statusTime.substring(0, 10)
          ) {
            let obj = {};

            obj.previousDate = statusLogsSortedArray[i].statusTime.substring(
              0,
              10
            );
            obj.timings = obj.timings || [];
            obj.timings.push(
              statusLogsSortedArray[i].statusTime.substring(10, 19)
            );
            obj.status = obj.status || [];
            obj.status.push(statusLogsSortedArray[i].status);
            statusLogList.push(obj);
            previousDate = statusLogsSortedArray[i].statusTime.substring(0, 10);
          } else {
            statusLogList[statusLogList.length - 1].timings.push(
              statusLogsSortedArray[i].statusTime.substring(10, 19)
            );
            statusLogList[statusLogList.length - 1].status.push(
              statusLogsSortedArray[i].status
            );
          }
        }
        return statusLogList;
      }
    }
  };

  renderStatusLog = () => {
    let Shipments = [];
    if (
      this.props.trackingDetails &&
      this.props.trackingDetails.processStatusLogDomainList &&
      this.props.trackingDetails.processUpdateForm
    ) {
      let form = JSON.parse(this.props.trackingDetails.processUpdateForm);
      if (form && this.props.trackingDetails.eventProcessMasterId) {
        let statusLogs = this.props.trackingDetails.processStatusLogDomainList;
        let statusLogList = this.getSortedStatusLogOnDateTime(
          this.props.trackingDetails
        );
        statusLogs.map((status) => {
          if (
            status.processStatusLogDto &&
            status.processStatusLogDto[0] &&
            status.processStatusLogDto[0].processMasterId ==
              this.props.trackingDetails.eventProcessMasterId
          ) {
            Shipments.push(
              <div style={{ overflowY: "auto", maxHeight: "300px" }}>
                <ul className="timeline">
                  {statusLogList.map((status) => {
                    return (
                      <li>
                        <p
                          className="date-timeline text-gray"
                          style={{ marginBottom: 15 }}
                        >
                          {status.previousDate ? status.previousDate : ""}
                        </p>

                        <div className="flex">
                          <div className="left-ci-timeline">
                            {status.timings.map((time) => {
                              return <p>{time}</p>;
                            })}
                          </div>
                          <div className="right-ci-timeline">
                            {status.status.map((statusData) => {
                              return <p>{statusData}</p>;
                            })}
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          }
        });
      }
    }
    return Shipments;
  };

  render() {
    return <div>{this.renderStatusLog()}</div>;
  }
}

export default TrackingStatus;
