import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getThemeFromDiyConfig, setTrackingDetailsForPreview } from './service/ciDiyActions';
import Screen from '../Index'

class DiyTracking extends Component{
    constructor(props) {
        super(props);
    
        this.state = {
        };
    
        this.url = this.props.location.search.substring(1);
        this.ciDiy = this.props.preview ? false : true ;
      }

    componentDidMount(){
        if(this.props.preview){
            this.props.setTrackingDetailsForPreview(null)
        }

        window.addEventListener("message",(e)=>{
            if(e.data?.type =="CI_DIY_SETTING"){
                this.handleDataFromCiDiySetting(e.data.payload)
            }
        })
    }

    handleDataFromCiDiySetting = (diyConfig)=>{
        console.log("DIY-CONFIG", diyConfig)
        this.props.setTrackingDetailsForPreview(diyConfig)
    }

    render(){
        console.log("DATA_trackingDetails", this.props.trackingDetails)
        return  (
           <Screen {...this.props } ciDiy = {this.ciDiy}/>
        )
    }
    
}


var mapStateToProps=(state)=>({
      trackingDetails: state.customerInteractionReducer.trackingDetails,
})
 
  
  export default connect(mapStateToProps, {setTrackingDetailsForPreview, getThemeFromDiyConfig})(DiyTracking)