import { curbsidePickupActions } from './curbsidePickupActionConstants';
import curbsidePickupInitialState from './curbsidePickupInitialState'

export default function curbsidePickupReducer(state = curbsidePickupInitialState, action) {

    switch (action.type) {

        case curbsidePickupActions.SET_CURBSIDE_PAGE_FLOW: {
            return state.set('curbsidePageFlow', action.payload);
        }

        case curbsidePickupActions.SET_TIME_SLOT_PAGE_DATA: {
            let curbsidePickupJson = state.get('curbsidePickupJson');
            return state.set('curbsidePickupJson', {...curbsidePickupJson, timeSlotPageData: action.payload});
        }

        case curbsidePickupActions.SET_CURBSIDE_PICKUP_URL: {
            return state.set('curbsidePickupUrl', action.payload);
        }

        case curbsidePickupActions.SET_CURBSIDE_PICKUP_DATA: {
            return state.set('curbsidePickupData', action.payload);
        }

        default: {
            return state;
        }
    }
}