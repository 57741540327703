import React from "react";
import PrintIcon from "../Utils/icons/PrintIcon";
import DirectionIcon from "../Utils/icons/DirectionIcon";


class ReturnInstruction extends React.Component {
    constructor(props) {
        super(props);
        this.parserToken = [
            "<print_button>",
            "<store_details>",
            "<date>"
        ]
    }

    getStoreAddress = () => { 
        let addressToken = [];
        if(this.props.storeDetailsData.addressLine1) { 
            addressToken.push(this.props.storeDetailsData.addressLine1);
        }

        if(this.props.storeDetailsData.addressLine2) { 
            addressToken.push(this.props.storeDetailsData.addressLine2);
        }

        if(this.props.storeDetailsData.pincode) { 
            addressToken.push(this.props.storeDetailsData.pincode);
        }

        return addressToken.join(', ')
    }
    getStoreHtml = () => {
        var lat = this.props.storeDetailsData.storeLocation.latitude;
        var lng = this.props.storeDetailsData.storeLocation.longitude;
        let mapLocationUrl = null;
        if (lat && lng) {
            mapLocationUrl = "http://maps.google.com/?z=8&q=" + lat + "," + lng;
        }
        return (<div><h5>{this.props.storeDetailsData.storeName}</h5><address>{this.getStoreAddress()}</address><a href={mapLocationUrl} target="_blank"><DirectionIcon color={this.props.primaryBgColor}/>Get Directions</a></div>);

    }
     openLabelPdf=()=>{
        const result = this.props.labelPDF.split(",");
        if (Array.isArray(result)) {
            result.forEach(url => {
              if (url) {
                    window.open(url, '_blank', 'noopener,noreferrer');
              }
            });
          } else if (this.props.labelPDF) {
            window.open(this.props.labelPDF, '_blank', 'noopener');
          }
     }
    getPrintHtml = () => {
        return (<div><button type="button" className="order-return-label-btn" onClick={this.openLabelPdf}><PrintIcon />Return Label</button></div>)
    }

    getJoiner = (depth) => {
        if (depth == 0) {
            return this.getPrintHtml();
        }
        else if ( depth == 1) {
            return this.getStoreHtml();
        }
        else if (depth == 2) {
            return this.props.lastDate;
        }
    }

    getJoinedConvertedTokens = (convertedTokens, depth) => {
        if(convertedTokens.length <=1) {
            return convertedTokens;
        }
        var t = [convertedTokens[0]];
        for(var i = 1; i<convertedTokens.length;i++) {
            t.push(this.getJoiner(depth));
            t.push(convertedTokens[i]);
        }
        return t;
    }

    getFormattedDescription = (description, depth) => {

        var descriptionTokens = description.split(this.parserToken[depth]);
        var convertedTokens = [];

        if (depth < 2) {
            for (var el of descriptionTokens) {
                convertedTokens.push(this.getFormattedDescription(el, depth + 1))
            }
        }
        else {
            convertedTokens = descriptionTokens;
        }
        
        return this.getJoinedConvertedTokens(convertedTokens, depth);

    }

    render() {
        return (
            <div className="return-instruction-box">
                <div className="return-instructions-header">
                    <h3>{this.props.instructionDetails.instructionHeading}</h3>
                </div>
                <div className="return-instruction-steps">
                    {this.props.instructionDetails.stepsArray.map((step) => {
                        let descriptionList = step.description?.split("||");
                        let description = this.getFormattedDescription(descriptionList[0], 0);
                        return (
                            <div className="return-instruction-step">
                                <h4>{step.heading}</h4>
                                <p>{description}</p>
                                {descriptionList[1] && <a style={{color:this.props.primaryBgColor}} href={descriptionList[1]}>{descriptionList[1]}</a>}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default ReturnInstruction;