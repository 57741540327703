import React from "react";
import { isEmpty } from "loadsh";

export default function OrderAddressList(props) {
  const detail = [];
  if (!isEmpty(props.list)) {
    {
      props.list.map((item) => {
        detail.push(
          <div>
            <p className="text-gray fs12 mb10">{item.label}</p>
            <p className="mb10 fs14">{item.value}</p>
          </div>
        );
      });
    }
  }
  if (props.single) {
    return <div>{detail}</div>;
  } else {
    return <div className="p15">{detail}</div>;
  }
}
