import { isEmpty } from "lodash";
import React from "react";
import Banner from "../../../components/Banner";
import CarouselComponent from "../../../components/Carousel";

export default class MarketingAsset extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        let className = null;
        if (this.props.deviceType == 'mobile') {
            className = "layout-scr-market-asset";
        }
        else {
            className = "desktop-view-market-asset"
        }

        return (
            <div className={className}>
                {!isEmpty(this.props.marketingAssetsData.bannerDetails) && this.props.marketingAssetsData.enableBanner && (
                    <Banner
                        image={
                            this.props.marketingAssetsData.bannerDetails.url
                        }
                        link={
                            this.props.marketingAssetsData.bannerDetails.redirectUrl
                        }
                    />
                )}
                {this.props.marketingAssetsData.enableCarousel && !isEmpty(this.props.marketingAssetsData.carouselDetails) && (
                    <div className="carousel-wrapper background-grey clearfix ">
                        <CarouselComponent
                            secondaryTextColor={this.props.secondaryTextColor}
                            carouselList={
                                this.props.marketingAssetsData && this.props.marketingAssetsData.carouselDetails
                            }
                        />
                    </div>
                )}
            </div>
        );
    }
}
