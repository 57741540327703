import { isEmpty } from "lodash";

class MediaLinkService {

  isFooterMediaLinkPresentInTheme(theme) {
    if(isEmpty(theme)) {
      return false;
    }

    if( 
      !theme.facebook &&
      !theme.twitter &&
      !theme.instagram &&
      !theme.youtube &&
      !theme.tiktok &&
      !theme.linkedIn &&
      !theme.pinterest &&
      isEmpty(theme.extraInfo)
    ) {
      return false;
    }

    return true;
  }

}

export const mediaLinkService = new MediaLinkService();
