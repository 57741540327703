import { isEmpty } from "lodash";
import React from "react";
import { processUpdateFormService } from "../../../services/ProcessUpdateFormService";
import FeedbackV3 from "../../FeedbackV3";
import { Carousel } from 'antd';
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import SocialMedia from "../../../components/SocialMedia";
import Header from "../../../components/Header";
import MarketingAsset from "../components/MarketingAsset";
import CurbsideNeedHelpDrawer from "../components/CurbsideNeedHelpDrawer";
import CONSTANTS from "../../../Utils/Constants";
import Grid from "@material-ui/core/Grid";
import CurbsideFeedbackOrderInfoDetail from "../components/CurbsideFeedbackOrderInfoDetail";

export default class CurbsideFeedback extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            feedPopup: false,
            showNeedHelpDrawer: false
        }
    }

    setHelpModel = (show) => {
        this.setState({ showNeedHelpDrawer: show });
    }

    getNeedHelp = () => {
        let helpSectionText, callSupport, emailSupport;
        if (this.props.trackingDetails && this.props.trackingDetails.type === "CURBSIDE_PICKUP" && this.props.trackingDetails.customerInteractionThemeSettingsDTO && !isEmpty(this.props.trackingDetails.customerInteractionThemeSettingsDTO)) {
            helpSectionText = this.props.trackingDetails.customerInteractionThemeSettingsDTO.helpSectionText;
            helpSectionText = helpSectionText ? helpSectionText : containerConstants.formatString(containerConstants.NeedHelp);
            callSupport = this.props.trackingDetails.customerInteractionThemeSettingsDTO.supportContactNumber;
            emailSupport = this.props.trackingDetails.customerInteractionThemeSettingsDTO.supportEmail;
        }

        if (callSupport || emailSupport) {
            return {
                helpSectionText,
                callSupport,
                emailSupport
            }
        }
        return undefined;
    }

    closeRattingScreenDrawer = () => {
        setTimeout(function () {
            document.body.style.overflowY = "auto";
        }.bind(this), 300);
    }

    renderPodImages = (feedback) => {
        if (feedback && feedback.podImage && !isEmpty(feedback.podImage) && (!feedback.podImageArrayChild || isEmpty(feedback.podImageArrayChild)) && this.checkIfPodImageExist(feedback.podImage)) {
            return (
                this.state.feedPopup ?
                    <div className='feed_popup'>
                        <div className="podsinglepop">
                            <img width="100%" src={feedback.podImage} onClick={this.togglePopup.bind(this)} className="" />
                            <div className="cross align-center flex" onClick={this.togglePopup.bind(this)}><button><i class="bi bi-x-lg"></i>&nbsp; Close</button></div>
                        </div>
                    </div>
                    :
                    <div className="layer">
                        <img width="100%" src={feedback.podImage} onClick={this.togglePopup.bind(this)} className="" />
                    </div>
            );
        }
        else if (feedback.podImageArrayList && !isEmpty(feedback.podImageArrayList) && feedback.podImageArrayList.length == 1 && this.checkIfPodImageExist(feedback.podImageArrayList[0])) {
            return (
                this.state.feedPopup ?
                    <div className='feed_popup'>
                        <div className="podsinglepop">
                            <img width="100%" src={feedback.podImageArrayList[0]} onClick={this.togglePopup.bind(this)} className="" />
                            <div className="cross align-center flex" onClick={this.togglePopup.bind(this)}><button><i class="bi bi-x-lg"></i>&nbsp; Close</button></div>
                        </div>
                    </div>
                    :
                    <div className="relative">
                        <div className="layer"></div>
                        <img width="100%" src={feedback.podImageArrayList[0]} onClick={this.togglePopup.bind(this)} className="" />
                    </div>
            );
        }
        else if (feedback.podImageArrayList && !isEmpty(feedback.podImageArrayList)) {
            return (
                this.state.feedPopup ?
                    <div className='feed_popup'>
                        {this.renderCarousel(feedback, true)}
                        <div className="cross align-center flex" onClick={this.togglePopup.bind(this)}><button><i class="bi bi-x-lg"></i>&nbsp; Close</button></div>
                    </div>
                    :
                    this.renderCarousel(feedback, false)
            );
        }
    }

    checkIfPodImageExist = (img) => {
        if ((img && !isEmpty(img))) {
            if ((img.indexOf("http://") == 0 || img.indexOf("https://") == 0)) {
                return true;
            }
            else
                return false;
        }
        else {
            return false;
        }
    }

    renderCarousel = (feedback, status) => {
        return (
            <div className="feedback_carousel">
                <Carousel effect="fade"
                    arrows nextArrow={<span className="right_arrow"><FiChevronRight /></span>}
                    prevArrow={<span className="left_arrow"><FiChevronLeft /></span>}
                >
                    {this.arrayPod(feedback)}
                </Carousel>
            </div>
        )
    }

    togglePopup = () => {
        // alert("Hello")
        this.setState({
            feedPopup: !this.state.feedPopup
        });
    }

    arrayPod = (feedback) => {
        let arr = [];
        {
            for (let i = 0; i < feedback.podImageArrayList.length; i++) {
                if (this.checkIfPodImageExist(feedback.podImageArrayList[i])) {
                    arr.push(<div className="box_curbside">
                        <div className="layer"></div>
                        <img src={feedback.podImageArrayList[i]} onClick={this.togglePopup.bind(this)} className="" />
                    </div>)
                }
            }
        }
        return arr;
    }

    renderFeedback = (feedback, marginTopVal) => {
        return (
            <div className="feedback_page" style={{ marginTop: marginTopVal }}>
                {this.renderPodImages(feedback)}
                <div className="feedbackScreenNew">
                    <FeedbackV3
                        primaryBgColor={this.props.primaryBgColor}
                        primaryTextColor={this.props.primaryTextColor}
                        closeRattingScreenDrawer={this.closeRattingScreenDrawer}
                        secondaryBgColor={this.props.secondaryBgColor}
                        secondaryTextColor={this.props.secondaryTextColor}
                        feedbackInfo={this.props.feedbackInfo}
                        type={CONSTANTS.CURBSIDE_PICKUP}
                    />
                </div>
            </div>
        )
    }

    render() {
        const needHelp = this.getNeedHelp();
        if (this.props.deviceType == 'mobile') {
            return (
                <CurbsideFeedbackMobileView
                    {...this.props}
                    checkIfPodImageExist={this.checkIfPodImageExist}
                    renderFeedback={this.renderFeedback}
                    arrayPod={this.arrayPod}
                    showNeedHelpDrawer={this.state.showNeedHelpDrawer}
                    setHelpModel={this.setHelpModel}
                    showNeedHelp={needHelp ? true : false}
                    needHelp={needHelp}
                />
            )
        } else {
            return (
                <CurbsideFeedbackWebView
                    {...this.props}
                    checkIfPodImageExist={this.checkIfPodImageExist}
                    renderFeedback={this.renderFeedback}
                    arrayPod={this.arrayPod}
                    showNeedHelpDrawer={this.state.showNeedHelpDrawer}
                    setHelpModel={this.setHelpModel}
                    showNeedHelp={needHelp ? true : false}
                    needHelp={needHelp}
                />
            )
        }
    }
}

class CurbsideFeedbackMobileView extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (!this.props.isFeedbackInfoFetch) {
            this.props.fetchFeedbackInfo(this.props.curbsidePickupUrl, false, CONSTANTS.CURBSIDE_PICKUP)
        }
    }

    render() {
        if (!this.props.trackingDetails.feedback) {
            return;
        }
        let feedback = JSON.parse(this.props.trackingDetails.feedback);
        let marginTopVal = "0px"
        if ((!(feedback.podImage && !isEmpty(feedback.podImage) && (this.props.checkIfPodImageExist(feedback.podImage) || (feedback.podImageArrayChild && !isEmpty(feedback.podImageArrayChild))))) || ((feedback.podImageArrayChild && !isEmpty(feedback.podImageArrayChild)) && !(this.props.checkIfPodImageExist(feedback.podImageArrayList[0]) || this.props.arrayPod(feedback).length >= 1))) {
            marginTopVal = "100px"
        }
        return (
            <React.Fragment>
                <Header
                    logo={this.props.companyLogo}
                    curbsidePickup={true}
                    primaryBgColor={"primaryBgColor"}
                    primaryTextColor={"primaryTextColor"}
                    fontSize={"fontSize"}
                    fontFamily={"fontFamily"}
                    deviceType={true}
                    needHelp={this.props.showNeedHelp}
                    setHelpModel={() => this.props.setHelpModel(true)}
                    helpSectionText={this.props.needHelp && this.props.needHelp.helpSectionText}
                />

                {this.props.showNeedHelp &&
                    <CurbsideNeedHelpDrawer
                        {...this.props}
                    />
                }
                <div className="curbside-mobile-screen">
                {this.props.renderFeedback(feedback, marginTopVal)}

                <CurbsideFeedbackOrderInfoDetail curbsidePickupData={this.props.curbsidePickupData}></CurbsideFeedbackOrderInfoDetail>

                {
                    this.props.curbsidePickupData.otherDetailsData &&
                    this.props.curbsidePickupData.otherDetailsData.marketingAssets &&
                    this.props.marketingAssetsData &&
                    <MarketingAsset
                        {...this.props}
                        marketingAssetsData={this.props.marketingAssetsData}
                    />
                }
                </div>
                {this.props.layoutScreen && !this.props.layoutScreen.hideSocialMedia &&
                    <SocialMedia
                        curbsidePickup={true}
                        layoutScreen={this.props.layoutScreen}
                    />
                }
            </React.Fragment>
        )
    }

}

class CurbsideFeedbackWebView extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (!this.props.isFeedbackInfoFetch) {
            this.props.fetchFeedbackInfo(this.props.curbsidePickupUrl, false, CONSTANTS.CURBSIDE_PICKUP)
        }
    }

    render() {
        if (!this.props.trackingDetails.feedback) {
            return;
        }
        let feedback = JSON.parse(this.props.trackingDetails.feedback);
        let marginTopVal = "0px"
        if ((!(feedback.podImage && !isEmpty(feedback.podImage) && (this.props.checkIfPodImageExist(feedback.podImage) || (feedback.podImageArrayChild && !isEmpty(feedback.podImageArrayChild))))) || ((feedback.podImageArrayChild && !isEmpty(feedback.podImageArrayChild)) && !(this.props.checkIfPodImageExist(feedback.podImageArrayList[0]) || this.props.arrayPod(feedback).length >= 1))) {
            marginTopVal = "100px"
        }
        return (
            <React.Fragment>
                <Header
                    logo={this.props.companyLogo}
                    curbsidePickup={true}
                    primaryBgColor={"primaryBgColor"}
                    primaryTextColor={"primaryTextColor"}
                    fontSize={"fontSize"}
                    fontFamily={"fontFamily"}
                    deviceType={false}
                    needHelp={this.props.showNeedHelp}
                    setHelpModel={() => this.props.setHelpModel(true)}
                    helpSectionText={this.props.needHelp && this.props.needHelp.helpSectionText}
                />

                {this.props.showNeedHelp &&
                    <CurbsideNeedHelpDrawer
                        {...this.props}
                    />
                }
                <div className="curbside-web-wrapper feedback-curbside-cont">
                    <div className="curbside-web-container curbside-manage">
                        <Grid container className="bg-white">
                            <Grid item xs={7}>
                                <div className="width-80">
                                    <div className="desktopDeliverySec">
                                        {this.props.renderFeedback(feedback, marginTopVal)}
                                        <CurbsideFeedbackOrderInfoDetail curbsidePickupData={this.props.curbsidePickupData}></CurbsideFeedbackOrderInfoDetail>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                        {
                            this.props.curbsidePickupData.otherDetailsData &&
                            this.props.curbsidePickupData.otherDetailsData.marketingAssets &&
                            this.props.marketingAssetsData &&
                            <MarketingAsset
                                {...this.props}
                                marketingAssetsData={this.props.marketingAssetsData}
                            />
                        }

                    </div>
                </div>

                {this.props.layoutScreen && !this.props.layoutScreen.hideSocialMedia &&
                    <SocialMedia
                        curbsidePickup={true}
                        layoutScreen={this.props.layoutScreen}
                    />
                }

            </React.Fragment>
        )
    }

}