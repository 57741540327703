import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import "./styleMobilePrototype.css"
import Lottie from "lottie-react";
import LottieAnimation from "../NewDesktopViewMobile/LottieAnimation.json";
import { prototypeService } from "../../services/prototypeService";
import * as CustomerInteractionAction from "../../CustomerInteractionActions";
import { processUpdateFormService } from "../../services/ProcessUpdateFormService";
import { getThemeFromDiyConfig } from "../DIY/service/ciDiyActions";
import SocialMedia from "../../components/SocialMedia";
import { isEmpty } from "lodash";
import { mediaLinkService } from "../../services/MediaLinkService";
import DeliveryDetails from "../Prototype/deliveryDetails";
import HelpSectionPrototype from "../Prototype/helpSectionPrototype";
import OrderInfoPrototype from "../Prototype/orderInfoPrototype";
import CarouselListPrototype from "../Prototype/carouselListPrototype";
import StatusTimeLinePrototype from "../Prototype/statusTimelinePrototype";

class MobileViewPrototype extends Component {
  merchantCode;
  deviceType;
  containerConstants = window.containerConstants;
  isPudoUpdate = false;
  constructor(props) {
    super(props);

    this.state = {
      showNeedHelpDialog: false,
      openForm: false,
      formIndex: 0,
      showPudoScreen: false,
      landingLayout: false,
      showPudoSavedAlert: false,
      isEmbed: false,
      gettingTrackingDetailsData: false,
      marketingAssets: ''
    };
  }
  async componentDidMount() {
    if (this.props.ciDiy) {
      await this.props.getThemeFromDiyConfig(this.url, this.props.trackingDetails);
      this.setState({ gettingTrackingDetailsData: true })
    }
    const deviceType = await processUpdateFormService.getDeviceType();
    this.deviceType = deviceType;



    await this.props.actions.getTrackingDetails(this.url, this.props.isReturn, this.props.trackingDetails);


    const marketingAssets = await processUpdateFormService.getMarketingAssets(this.props.url, null, this.props.isReturn);
    if (!isEmpty(marketingAssets)) {
      this.setState({ marketingAssets: marketingAssets });
    }


  }

  renderSocialMedia = () => {

    if (
      !isEmpty(this.props.trackingDetails) ||
      !isEmpty(this.props.trackingDetails.customerInteractionThemeSettingsDTO) ||
      mediaLinkService.isFooterMediaLinkPresentInTheme(this.props.trackingDetails.customerInteractionThemeSettingsDTO)
    ) {
      return (
            <SocialMedia
              theme={this.props.trackingDetails.customerInteractionThemeSettingsDTO}
              layoutScreen={this.props.trackingDetails.layoutScreen}
              secondaryBgColor={this.props.secondaryBgColor}
            />
      );
    }
  };

  render() {
    console.log(this.props.trackingDetails)
    let forms = "";
    if (
      this.props.trackingDetails &&
      this.props.trackingDetails.processUpdateForm
    ) {
      forms = JSON.parse(this.props.trackingDetails.processUpdateForm);
    }
    return (

      <div className="wrapper">
        <div className="container-mobile-prototype">

          <div className="wdcard relative hero-status-slider" style={{ height: 376, background: '#503c5b' }}>
            <div className="absolute inner-hero-status" style={{ inset: 0, height: 400 }}>
              <Lottie className="lottie-animation" style={{ borderRadius: 16, overflow: 'overlay' }} animationData={LottieAnimation} loop={true} />
            </div>

            <div className="absolute p15 hero-header" style={{ left: 0, right: 0, background: 'transparent' }}>
              <div className="flex p10" style={{ borderRadius: 8, width: 36, height: 36, background: 'rgba(255, 255, 255, 0.3)' }}><img src="../../images/burger-menu.png" alt="" /></div>
              <div><img src="../../images/disney-logo.png" alt="" /></div>
              <div className="flex p10" style={{ borderRadius: 8, width: 36, height: 36, background: 'rgba(255, 255, 255, 0.3)' }}><img src="../../images/Translate.png" alt="" /></div>
            </div>
            <div className="absolute p15 wdcard" style={{ inset: 0, background: 'transparent' }}>
              <div className="text-eta" style={{ zIndex: 10 }}>
                <p className="bold fs24" style={{ color: '#26152F' }}>
                  {containerConstants.formatString(containerConstants.Arriving)} {prototypeService.getETAString(this.props.trackingDetails?.layoutScreen?.eta, this.props.trackingDetails)}
                </p>
                <p>
                  {this.props.trackingDetails?.layoutScreen?.etaHeading ? this.props.trackingDetails.layoutScreen.etaHeading : ''}
                </p>
              </div>
            </div>

          </div>

          <div className="wdcard mt20 p15 mb20 m-mv10 m-mh10">
            <p className="fs16 pv15">Order Number: {this.props.trackingDetails.referenceNumber}</p>
            <OrderInfoPrototype
              trackingDetails={this.props.trackingDetails}
              arrayList={
                this.props.trackingDetails?.layoutScreen?.orderInformationDTOList
              }
              attributeList={
                this.props.trackingDetails?.layoutScreen?.attributeList
              }
              secondaryBgColor={this.props.secondaryBgColor}
            />

            
            <DeliveryDetails
              forms={forms}
              screen={this.props.trackingDetails.screen}
              addressLabel={this.props.trackingDetails.addressLabel}
              contactLabel={this.props.trackingDetails.contactLabel}
            />

          </div>

          <div className="p15 wdcard m-mv10 m-mh10" style={{ minHeight: 80, maxHeight: 376 }}>
            <header className="flex pb15 align-center mb10" style={{ borderBottom: '1px solid #F0F0F0' }}>
              <div>
                <img src="../../images/fedex.png" alt="" />
              </div>
              <div className="ml15">
                <p>
                  Shipped with UPS
                </p>
                <p>
                  Tracking ID:  {this.props.trackingDetails.referenceNumber}
                </p>
              </div>
            </header>
            <StatusTimeLinePrototype
              trackingDetails={this.props.trackingDetails}
            />

          </div>


          {/* marketing banner */}
          <div className="marketing-banner m-mv10 m-mh10">
            <div style={{ backgroundImage: "url('../../images/bigImage.png')" }}>
              fdasf

            </div>
          </div>


          {/* Carousal */}

          <div className="flex m-mv10 m-mh10" style={{ overflowY: 'auto' }}>
            
          <CarouselListPrototype
              marketingAssets= {this.state.marketingAssets}
              mobileView={true}
              />
          </div>



          {/* Call to action */}
          <div className="m-mv10 m-mh10 pv20 ">
            <HelpSectionPrototype
              trackingDetails={this.props.trackingDetails}
              screen={this.props.trackingDetails.screen}
              fontFamily={this.props.fontFamily}
              fontSize={this.props.fontSize} />
          </div>
          <div className="mv10">
            {this.renderSocialMedia()}
          </div>
        </div>
      </div>


    )
  }
}
function mapStateToProps(state) {
  return {
    trackingDetails: state.customerInteractionReducer.trackingDetails,
    notFound: state.customerInteractionReducer.notFound,
    feedback: state.customerInteractionReducer.feedback,
    pudoPoints: state.pudoReducer.pudoPoints,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...CustomerInteractionAction, getThemeFromDiyConfig },
      dispatch
    ),
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(MobileViewPrototype);