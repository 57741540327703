import React, { Component } from "react";
import SubHeader from "../../components/SubHeader";
import Header from "../../components/Header";
import { isEmpty } from "loadsh";
import OrderAddressList from "../../components/CustomScreen/OrderAddressList";
import ItemList from "./ItemList";
import "./style.css";
import ItemDetail from "../../components/CustomScreen/ItemDetail";

class CustomScreen extends Component {
  render() {
    const {
      primaryBgColor,
      primaryTextColor,
      fontSize,
      fontFamily,
      secondaryTextColor,
      secondaryBgColor,
    } = this.props;
    let count = 0;
    if(this.props.trackingDetails.customScreen && this.props.trackingDetails.customScreen.childView && !isEmpty(this.props.trackingDetails.customScreen.childView)){
      count =  this.props.trackingDetails.customScreen.childView.length;
    }
     
    return (
      <div>
        <Header
          heading={this.props.trackingDetails.companyName}
          logo={this.props.trackingDetails.companyLogo}
          back={true}
          backScreen={this.props.backScreen}
          primaryBgColor={primaryBgColor}
          primaryTextColor={primaryTextColor}
          fontSize={fontSize}
          fontFamily={fontFamily}
          customScreen={
            this.props.trackingDetails.customScreen &&
            !isEmpty(this.props.trackingDetails.customScreen)
          }
        />
        <div className="ci-background-grey">
        <div className="custom-screen-div">
        <SubHeader
          trackingDetails={this.props.trackingDetails}
          fontFamily={
            fontFamily ? fontFamily : this.props.trackingDetails.fontType
          }
          secondaryTextColor={
            secondaryTextColor
              ? secondaryTextColor
              : this.props.trackingDetails.fontColor
          }
          fontSize={fontSize ? fontSize : this.props.trackingDetails.fontSize}
          secondaryBgColor={
            secondaryBgColor
              ? secondaryBgColor
              : this.props.trackingDetails.secondaryBgColor
          }
        />
        {count == 1 || count == 0 ? (
          <div className="p15 mt20">
            <ItemDetail
              list={
                count == 0?"":
                this.props.trackingDetails.customScreen.childView[0].attributes
              }
              attributeList={
                this.props.trackingDetails.customScreen.attributeList
              }
            />
          </div>
        ) : (
          <div>
            <OrderAddressList
              list={this.props.trackingDetails.customScreen.attributeList}
            />
            <div className="p15 bg-extra-light">
              <p className="text-gray fs14">
                {containerConstants.formatString(containerConstants.Items)} :{" "}
                {count}{" "}
              </p>
            </div>
            <ItemList
              itemList={this.props.trackingDetails.customScreen.childView}
            />
          </div>
        )}
        </div>
        </div>
      </div>
    );
  }
}

export default CustomScreen;
