import React from 'react'
import "./leaflet.css"
import { Map as LeafletMap, TileLayer, Marker, Popup,Circle } from 'react-leaflet';
import Fab from '@material-ui/core/Fab';
import MyLocation from '@material-ui/icons/MyLocation';
import {withStyles} from '@material-ui/core/styles';
import {isEmpty} from 'loadsh'
import { hereMapService } from '../../containers/HereMap/HereMapService';



export const pointerIcon = new L.Icon({
  iconUrl: require('../../images/marker-icon.png'),
  iconRetinaUrl: require('../../images/marker-icon.png'),
  iconAnchor: [12, 30],
  // popupAnchor: [10, -44],
  iconSize: [28, 41],
  // shadowUrl: require('../../images/marker-shadow.png'),
  
})


const GetCurrentLocation = withStyles(theme => ({
  root: {
    color: "#000",
    backgroundColor: "#fff",
    position: 'absolute', 
    right: 10, 
    top: 10,
    zIndex: 9999999999999,
    '&:hover': {
      backgroundColor: "#fff",
    },
  },
}))(Fab);

class Map extends React.Component {
 
  
  render() {
     let rad = (this.props.radius && this.props.radius!=0)?parseInt(this.props.radius):null;
     this.herePoweredAccount =  window.localStorage.getItem('isHerePoweredAccount') === 'true' ? true : false
    return (
     <div className="relative">
     <GetCurrentLocation size="small" color="secondary" aria-label="Add"  onClick={this.props.currentLocation}>
              <MyLocation />
            </GetCurrentLocation>
          <LeafletMap
              center={[this.props.lat, this.props.lng]}
              zoom={12}
              attributionControl={this.herePoweredAccount ? true :  false}
              zoomControl={false}
              doubleClickZoom={true}
              scrollWheelZoom={true}
              dragging={true}
              animate={true}
              easeLinearity={0.35}
              onClick={this.props.handleClick}
          >
            {this.herePoweredAccount ? hereMapService.getHereMapTileLayer(false,false) : <TileLayer url="https://map.fareye.co/styles/klokantech-basic/{z}/{x}/{y}.png" attribution="<attribution>" />}
            <Marker position={[this.props.lat, this.props.lng]} icon={pointerIcon}>
              {rad?  
              <Circle 
                  center={{lat:parseFloat(this.props.circleCenter[0] || "0"), lng:parseFloat(this.props.circleCenter[1] || "0")}}
                  fillColor="#FF0000" 
                  radius={rad}/>:null}
            </Marker>
          </LeafletMap>
          
      </div>
    );
  }
}

export default Map