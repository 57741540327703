import { isEmpty } from "lodash";

import shipmentService from "./shipmentService";
import CONSTANTS from "../../Utils/Constants";
import { setState } from "../../Utils/setState";
import { processUpdateFormService } from "../../services/ProcessUpdateFormService";
import { fetchFeedbackInfo } from "../../CustomerInteractionActions";
import { restClientService } from "../../services/RestClientService";


export function setTrackingDetailsForShipment(url){
    return async function(dispatch){
        try{
            var trackingDetails = await shipmentService.getTrackingDetailsForShipment(url);
        
            if(!isEmpty(trackingDetails)){
                dispatch(setState(CONSTANTS.UPDATE_TRACKING_DETAILS,trackingDetails))
            }
            dispatch(setState(CONSTANTS.SET_SHIPMENT_TRACKING,true))
        } catch(err){
            console.log("error in fetching shipment tracking details",err);
        }
    }
}
export function saveFeedbackShipment(url,feedbackObj,details,isV2, type){
    return async function(dispatch){
        try{
            let processUpdateFormJson = JSON.parse(details.processUpdateForm)?JSON.parse(details.processUpdateForm):"";
            let timeStampKey ="";
            if(!isEmpty(processUpdateFormJson)){
                timeStampKey = processUpdateFormJson["processUpdateTimeStamp"]?processUpdateFormJson["processUpdateTimeStamp"]:"";
           
            }
            let timeStampValue = moment().format('YYYY-MM-DD HH:mm:ss');
            let res = await restClientService.saveFeedbackForShipment(url,feedbackObj, type,timeStampKey,timeStampValue);
            if(res==200){
             dispatch(fetchFeedbackInfo(url, false, type));
            }   
           
           return res;
        } catch(err){
            console.log("err",err);
        }
    }
}



